import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";
import ImageZoom from "react-image-zooom";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteSpaceThunk,
  ListSpacesThunk,
  getFloorsSideNavThunk,
} from "../../../../slices/thunks";
import { getCaptureDataSrcs } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
// import { resetMessages, setSelected } from "../../../../slices/property/floors/reducer";
import {
  resetMessages,
  setSelected,
} from "../../../../slices/property/spaces/reducer";
import { CreateSpaceForm } from "./createForm";
import UpdateFloorModal from "../New";
import { useParams } from "react-router-dom";
import {
  PrivateFileWraper,
  ZoomImageComponent,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";
import { ScanSpacesModal } from "../scan/ScanModal";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";


const SpaceUpload = () => {
  document.title = "Spaces | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");

  const dataSrcs = [];

  let { id } = useParams();

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [showScanModal, setShowScanModal] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const {
    selectedProperty,
    spaces,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
    levelImg,
    levelName,
  } = useSelector((state) => ({
    spaces: state.spaceUpload.spaces,
    numOfPages: state.spaceUpload.total_pages,
    numOfCaptures: state.spaceUpload.count,
    pageSize: state.spaceUpload.page_size,
    currentPage: state.spaceUpload.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.spaceUpload.loading,
    error: state.spaceUpload.error,
    success: state.spaceUpload.success,
    levelImg: state.spaceUpload.levelImg,
    levelName: state.spaceUpload.levelName,
  }));

  // useEffect(() => {
  //   // setFilterParams({
  //   //   dataSource: "All",
  //   //   page: 1,
  //   // });
  //   if(selectedProperty){
  //     navigate({pathname:'/floorplans'})
  //   }
  // }, [selectedProperty]);

  useEffect(() => {
    dispatch(ListSpacesThunk(id, filterParams));
  }, [id, filterParams,showScanModal]);

  function onDeleteClick() {
    dispatch(
      DeleteSpaceThunk(captureDelete, () => {
        setCaptureDelete(null);
        if (selectedProperty) {
          dispatch(getFloorsSideNavThunk(selectedProperty.id));
        }
      })
    );
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListSpacesThunk(id, filterParams));
      dispatch(getFloorsSideNavThunk(selectedProperty.id));
    }
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Length",
        accessor: "length",
        filterable: false,
      },
      {
        Header: "Width",
        accessor: "width",
        filterable: false,
      },
      {
        Header: "Height",
        accessor: "height",
        filterable: false,
      },
      {
        Header: "Total Sq Ft",
        accessor: "totalSqFt",
        // Cell: (cell) => {
        //   return cell.row.original.width * cell.row.original.height
        // },
      },
      {
        Header: "Space Type",
        accessor: "spaceType",
        filterable: false,
      },
      {
        Header: "Window(s)",
        accessor: "windows",
        filterable: false,
      },
      {
        Header: "Door(s)",
        accessor: "doors",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const WraperView = useRef(null);

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
      // let filteredOrders = orders;
      // if (type !== "all") {
      // filteredOrders = orders.filter((order) => order.status === type);
      // }
      // setOrderList(filteredOrders);
    }
  };

  useEffect(()=>{
    if(levelImg){
      dispatch(setSelectedImageFiles([levelImg]))
    }
  },[levelImg])

  
  return (
    <div className="page-content">
      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={orderList}
      /> */}

      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <ScanSpacesModal
        show={showScanModal}
        toggle={() => setShowScanModal(!showScanModal)}
      />

      <UpdateFloorModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      />

      {/* <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      /> */}

      <Container fluid>
        <BreadCrumb
          title={["Floorplan(s)", "Spaces(s)"]}
          pageTitle="Property Workbook"
          pageTitleComponent={
            <>
              <h4 to="">
                {levelName + " Spaces/Locations"}
                {/* Location  */}
                {/* <TooltipItem id={1} data={locationTooltip} /> */}
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              {levelImg && (
                <div style={{ width: "100%" }}>
                  <div
                    ref={WraperView}
                    className="center"
                    style={{ maxWidth: "1000px" }}
                  >
                    {WraperView.current ? (
                      <PrivateFileWraper
                        id={levelImg.id}
                        module={"privateFile"}
                        ViewComponent={ZoomImageComponent}
                        width={WraperView.current.offsetWidth}
                      />
                    ) : null}
                  </div>
                </div>
              )}
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">New/List/View/Edit</h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      {}
                      <button
                        type="button"
                        // className="btn btn-primary add-btn"
                        className="btn btn-primary btn-label previestab"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        id="create-btn"
                        onClick={() => {
                          setShowScanModal(true);
                        }}
                      >
                        {/* <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "} */}
                        <i className="ri-search-line label-icon align-middle fs-16 me-2"></i>{" "}
                        Scan Spaces
                      </button>
                    </div>
                  </div>
                </div>
                <Row className="align-items-center gy-3">
                  {/* <div className="col-sm">
                   
                  </div> */}

                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {/* <Link
                      to="/3dCapture/create"
                        type="button"
                        className="btn btn-primary add-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        id="create-btn"
                        // onClick={() => { setIsEdit(false); toggle(); }}
                      >
                        <i className="ri-add-line align-bottom me-1 btn-success"></i> Create
                        New
                      </Link>{" "} */}
                      {/* <button type="button" className="btn btn-secondary"
                    //    onClick={() => setIsExportCSV(true)}
                       >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                      {/* {" "} */}
                      {/* {isMultiDeleteButton && (
                        <button
                          className="btn btn-danger"
                          // onClick={() => setDeleteModalMulti(true)}
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                      )} */}
                    </div>
                  </div>
                  <div>
                    <div className="py-3">
                      <CreateSpaceForm
                        id={id}
                        disabled={success || error}
                        onSuccess={OnUpdateSuccess}
                      />
                    </div>
                    {/* <Row>
                      <SearchWithFilter />
                    </Row> */}
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Spaces
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.name);
                          }}
                          href="#"
                        >
                          {src.iconClass && <i className={src.iconClass}></i>}
                          {src.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={spaces || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      // baseLink={"/3dCapture"}
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                  {/* {captures && captures.length ? (
                                     ) : (
                    
                  )} */}
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SpaceUpload;
