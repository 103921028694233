import { useSelector } from "react-redux";
import { FeedbackButton } from "../Common/feedbackComponents/FeedbackButton";
import { useState } from "react";
import EmojiFeedback from "../Common/feedbackComponents/EmojiFeedbackForm";

export const useFeedbackForm = () => {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const [showFeedbackForm,setShowFeedbackForm] = useState(false) 

  function toggle(){
    setShowFeedbackForm(!showFeedbackForm)
  }

  return user && user.show_feedback ? (
    <>
      <FeedbackButton onClick={toggle} />
      <EmojiFeedback isOpen={showFeedbackForm} toggle={toggle} />
    </>
  ) : null;
};
