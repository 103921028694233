import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../TableContainer";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";

import { ListelementSettingsThunk } from "../../../slices/thunks";
import { Error, toastError } from "../../../Components/error";
import { resetMessages } from "../../../slices/property/elementSettings/reducer";

import { StatusCheckBox } from "./statusCheckbox";
import { UpdatePropertyElementsConfig } from "../../../helpers/backend_helper";
import { toast } from "react-toastify";
import { refreshElementSettings } from "../../../slices/property/dropdown/reducer";
import { get_reportFunc } from "../../../Components/utils/get_report";
import { MODULESLINKS } from "../../../Components/constants/modules";

const PropertyElementsSettingsPage = () => {
  document.title = "Property Elements Settings | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const [btnloading, setbtnLoading] = useState(false);

  const dispatch = useDispatch();

  const [settingsState, setSettingsState] = useState({
    Appliance: "true",
    Flooring: "false",
    Finishes: "true",
    HVAC: "true",
    Inventory: "true",
    Insurance: "true",
    Utilities: "true",
  });

  const { selectedProperty, elementSettings, error, loading, success } =
    useSelector((state) => ({
      elementSettings: state.elementSettings.elementSettings,
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.elementSettings.loading,
      error: state.elementSettings.error,
      success: state.elementSettings.success,
    }));

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListelementSettingsThunk(selectedProperty.id));
    }
  }, [selectedProperty]);

  useEffect(() => {
    var newObj = {};
    for (var setting of elementSettings) {
      newObj[setting.module] = setting.state? "true" : "false";
    }
    setSettingsState(newObj);
  }, [elementSettings]);

  function changeObjState(key, newVal) {
    var newObj = { ...settingsState };
    newObj[key] = newVal;
    setSettingsState(newObj);
  }

  function onCreateReportClick(module) {
    setbtnLoading(true);
    get_reportFunc(module)(selectedProperty.id)
      .then((res) => toast.info("Your report is now being created"))
      .catch((err) => toastError(err))
      .finally(() => setbtnLoading(false));
  }

  // function onDeleteClick(){
  //   dispatch(DeleteelementSettingsThunk(captureDelete,()=>{
  //     setCaptureDelete(null)
  //   }))
  // }

  // function OnUpdateSuccess() {
  //   if (selectedProperty) {
  //     dispatch(ListelementSettingsThunk(selectedProperty.id, filterParams));
  //   }
  // }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = [
    {
      Header: "Module Name",
      accessor: "module",
      filterable: false,
      // Cell: (cell) => {
      //   return (
      //     <Link to="#" className="fw-medium link-primary">
      //       {cell.value}
      //     </Link>
      //   );
      // },
    },
    {
      Header: "Number of Entries",
      accessor: "count",
      filterable: false,
    },
    {
      Header: "Last Entry Date",
      accessor: "lastUpdate",
      filterable: false,
      Cell: (capture) => (
        <>
          {capture.row.original.lastUpdate.date},
          <small className="text-muted">
            {" "}
            {capture.row.original.lastUpdate.time}
          </small>
        </>
      ),
    },
    {
      Header: "Status",
      id: "status",
      accessor: "state",
      Cell: (capture) => (
        <StatusCheckBox
          onChange={(val) => changeObjState(capture.row.original.module, val)}
          val={settingsState[capture.row.original.module]}
        />
      ),
    },
    {
      Header: "Action",
      Cell: (capture) => {
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
            <li className="list-inline-item edit">
              {btnloading ? (
                <Spinner size="xs" className="me-2">
                  Loading...
                </Spinner>
              ) : (
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => onCreateReportClick(capture.row.original.module)}
                >
                  <i className="bx bxs-file-export fs-16"></i>
                </Link>
              )}
            </li>
            <li className="list-inline-item">
              <Link
                to={`${MODULESLINKS[capture.row.original.module]}`}
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  // const orderData = cellProps.row.original;
                  // setCaptureDelete(capture.row.original.id);
                  
                }}
              >
                <i className="ri-share-forward-fill fs-16"></i>
              </Link>
            </li>
          </ul>
        );
      },
    },
  ];

  function updateSettings() {
    setbtnLoading(true);
    var outObj = {};
    const keys = Object.keys(settingsState);
    for (var key of keys) {
      outObj[key] = settingsState[key] === "true" || settingsState[key] === true ? true : false;
    }
    UpdatePropertyElementsConfig(selectedProperty.id, outObj)
      .then((res) => {
        dispatch(refreshElementSettings());
        toast.success("Property Elements Settings is updated successfuly");
      })
      .catch((err) => toastError(err))
      .finally(() => setbtnLoading(false));
  }

  useEffect(() => {
    dispatch(resetMessages());
  }, []);

  return (
    <div className="page-content">
      {/* <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateInvModal 
        edited={isEdit}
        toggle={()=> setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      /> */}

      <Container fluid>
        <BreadCrumb title="Settings" pageTitle="Property Elements" />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardBody className="pt-0">
                <div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={elementSettings || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      hasPagination={false}
                      // baseLink={"/3dCapture"}
                      // onPageChange={changePage}
                      // numOfPages={numOfPages}
                      // currentPage={currentPage}
                      // pageSize={pageSize}
                      // total={numOfCaptures}
                    />
                  )}
                </div>
                <div className="text-end">
                  <button
                    onClick={updateSettings}
                    disabled={btnloading || success || error}
                    className="btn btn-primary w-lg"
                  >
                    {btnloading ? (
                      <Spinner size="sm" className="me-2">
                        Loading...
                      </Spinner>
                    ) : null}
                    Save Settings
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyElementsSettingsPage;
