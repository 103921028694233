import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  // getInventoryTypes,
  // getFloorFileTypes,
  getSpaceSelectSingleList,
  getApplianceTypes,
  getApplianceEnergyTypes,
  retrieveAppliance,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateapplianceThunk,
  CreateFloorThunk,
  CreateimageThunk,
  CreateinventoryThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListApplianceThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListinventoryThunk,
  ListSpacesThunk,
  UpdateApplianceThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// import { SpacesDropdown } from "./spacesDropdown";
// import { spaceFailure } from "../../../../slices/property/spaces/reducer";
// import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
// import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  applianceFailure,
  createSucess,
} from "../../../../slices/property/appliance/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";

// import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const CreateImageForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [appliance, setAppliance] = useState(null);

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect()

  const [StatusSelectComponent , getStatusVal , setStatus] = useStatusSelect();

  // const [initialValues , setInitialValues]  = useState()

  useEffect(() => {
    console.log(update, id);
    if (update && id >= 0) {
      retrieveAppliance(id).then((res) => setAppliance(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: appliance ? appliance.name : "",
      cost: appliance ? appliance.cost || "" : "",
      make: appliance ? appliance.make || "" : "",
      model: appliance ? appliance.model || "" : "",
      serialNumber: appliance ? appliance.serialNumber || "" : "",
      warrantyDetails: appliance ? appliance.warrantyDetails || "" : "",
      energyRate: appliance ? appliance.energyRate || "" : "",
      notes: appliance ? appliance.notes || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      cost: Yup.number()
        .positive("must be a number"),
      make: Yup.string(),
      model: Yup.string(),
      serialNumber: Yup.string(),
      warrantyDetails: Yup.string(),
      energyRate: Yup.number()
        .integer("must be integer")
        .positive(),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // appliance type
  const [applianceList, setapplianceList] = useState([]);
  const [selectedAppliance, setselectedAppliance] = useState("");

  // appliance energy type
  const [applianceEnergyList, setapplianceEnergyList] = useState([]);
  const [selectedapplianceEnergy, setselectedapplianceEnergy] = useState("");

  const [SpaceSelectList, setSpaceSelectList] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState("");

  const [date, setDate] = useState("");
  const [installationDate, setinstallationDate] = useState("");

  const [
    installationComponent,
    getInstallationData,
    setInstallationType,
    setContractor,
  ] = InstallationTypeAndSearch();

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.appliance.btnLoading,
  }));

  useEffect(() => {
    getApplianceTypes().then((res) => setapplianceList(res));
  }, []);

  useEffect(() => {
    getApplianceEnergyTypes().then((res) => setapplianceEnergyList(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectSingleList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res.map((space) => ({ value: parseInt(space.id), label: space.name }))
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != appliance[key]) {
        formdata[key] = values[key]
      }
    }

    var imagesfiles = getImageVal()
    var [modifiedImages,Images] = getCreateAndDeleteFiles(appliance.images.map((inst) => inst.id),imagesfiles)
    if (modifiedImages){
      formdata["images"] = Images
    }
    
    
    var reciepts = getRecieptVal()
    var [modifiedreciepts,recieptsFiles] = getCreateAndDeleteFiles(appliance.reciepts.map((inst) => inst.id),reciepts)
    if (modifiedreciepts){
      formdata["reciepts"] = recieptsFiles
    }

    if (date && date !== appliance.purchaseDate) {
      formdata["purchaseDate"] =  date
    }

    const statusVal = getStatusVal();
    if (statusVal && statusVal !== appliance.status ){
      formdata["status"] =  statusVal
    }

    if (installationDate && installationDate !== appliance.installationDate) {
      formdata["installationDate"] =  installationDate
    }
    if (selectedAppliance != appliance.applianceType) {
      formdata["applianceType"] =  selectedAppliance
    }
    if (selectedapplianceEnergy != appliance.energyType) {
      formdata["energyType"] =  selectedapplianceEnergy
    }
    if (selectedSpace != appliance.applianceLocation) {
      formdata["applianceLocation"] =  selectedSpace
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata["installationType"] = installationData.installationType;
      if (installationData.installationType === "Service Provider") {
        formdata[
          "installationContractor"] =
          installationData.installationContractor.value
        
      }
    }

    if (Object.keys(formdata).length === 0) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdateApplianceThunk(id, formdata, onUpdateSucces));
    }

    // return formdata;
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      formdata.append(key, values[key]);
    }
    // formdata.append("image", selectedFileAppliance);
    // formdata.append("reciept", selectedFileReciept);

    var imagesfiles = getImageVal()
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }
    var reciepts = getRecieptVal()
    for (let reciept of reciepts) {
      formdata.append("reciepts", reciept);
    }

    
    formdata.append("purchaseDate", date);
    formdata.append("installationDate", installationDate);
    formdata.append("applianceType", selectedAppliance);
    formdata.append("energyType", selectedapplianceEnergy);
    formdata.append("applianceLocation", selectedSpace);
    formdata.append("status",getStatusVal());

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata.append("installationType", installationData.installationType);
      if (installationData.installationType === "Service Provider") {
        formdata.append(
          "installationContractor",
          installationData.installationContractor.value
        );
      }
    }

    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    // checkOrError(
    //   selectedFileAppliance,
    //   errs,
    //   "you need to upload the device photo"
    // );

    // checkOrError(
    //   selectedFileReciept,
    //   errs,
    //   "you need to upload the reciept photo"
    // );

    // checkOrError(date, errs, "you need to add purchase date");

    // checkOrError(installationDate, errs, "you need to add installation date");

    checkOrError(selectedAppliance, errs, "you need to select appliance type");

    // checkOrError(
    //   selectedapplianceEnergy,
    //   errs,
    //   "you need to select appliance energy type"
    // );

    // checkOrError(
    //   selectedSpace,
    //   errs,
    //   "you need to select the appliance location at your property"
    // );

    const installationData = getInstallationData();
    
    checkOrError(
      !(
        installationData.installationType &&
        installationData.installationType === "Service Provider" &&
        !installationData.installationContractor
      ),
      errs,
      "you need to select Installation Contractor for installation of Type Service Provider"
    );

    const statusVal = getStatusVal();
    checkOrError(
      statusVal,
      errs,
      "you need to select status"
    );

    if (errs.length > 0) {
      dispatch(applianceFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListApplianceThunk(selectedProperty.id));
      };
      // if(){}
      dispatch(
        CreateapplianceThunk(
          selectedProperty.id,
          getFormdata(values),
          onSuccess
        )
      );
    }
  }

  useEffect(() => {
    if (appliance) {
      setselectedAppliance(appliance.applianceType);
      setSelectedSpace(appliance.applianceLocation);
      setDate(appliance.purchaseDate);
      setinstallationDate(appliance.installationDate);
      setselectedapplianceEnergy(appliance.energyType);
      setInstallationType(appliance.installationType);
      setStatus({label:appliance.status,value:appliance.status});
      dispatch(setSelectedImageFiles(appliance.images))
      dispatch(setSelectedRecieptFiles(appliance.reciepts))
      // console.log(appliance.installationContractor)
      if (appliance.installationContractor) {
        
        setContractor({
          label: appliance.installationContractor.name,
          value: appliance.installationContractor.id,
        });
      } else {
        setContractor("");
      }
    }
  }, [appliance]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      {RecieptModal}
      {ImageModal}
      <Row>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedAppliance(e.target.value)}
              value={selectedAppliance}
            >
              <option value="" disabled selected>
                Appliance Type
              </option>
              {applianceList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedapplianceEnergy(e.target.value)}
              value={selectedapplianceEnergy}
            >
              <option value="" disabled selected>
                Energy Type
              </option>
              {applianceEnergyList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col className="my-2" lg={4}>
          {StatusSelectComponent}
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="cost-input"
              placeholder="Cost $"
              name="cost"
              value={validation.values.cost}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.cost && validation.touched.cost ? true : false
              }
            />

            {validation.errors.cost && validation.touched.cost ? (
              <FormFeedback type="invalid">
                {validation.errors.cost}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <>
          <Col lg={6}>
            {/* <FilePond
              files={imagesfiles}
              onupdatefiles={setimagesFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={["image/png", "image/jpeg"]}
              labelIdle={"Upload Images: Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {ImageViewerComponent}
          </Col>
          <Col lg={6}>
            {/* <FilePond
              files={reciepts}
              onupdatefiles={setrecieptsFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "file/pdf",
                "application/pdf",
              ]}
              labelIdle={"Upload Receipt(s): Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {RecieptViewerComponent}
          </Col>
        </>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="make-input"
              placeholder="Make"
              name="make"
              value={validation.values.make}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.make && validation.touched.make ? true : false
              }
            />

            {validation.errors.make && validation.touched.make ? (
              <FormFeedback type="invalid">
                {validation.errors.make}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="model-input"
              placeholder="model"
              name="model"
              value={validation.values.model}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.model && validation.touched.model
                  ? true
                  : false
              }
            />

            {validation.errors.model && validation.touched.model ? (
              <FormFeedback type="invalid">
                {validation.errors.model}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="serialNumber-input"
              placeholder="Serial Number"
              name="serialNumber"
              value={validation.values.serialNumber}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.serialNumber &&
                validation.touched.serialNumber
                  ? true
                  : false
              }
            />

            {validation.errors.serialNumber &&
            validation.touched.serialNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.serialNumber}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="energyRate-input"
              placeholder="Energy Rate - kwhs"
              name="energyRate"
              value={validation.values.energyRate}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.energyRate && validation.touched.energyRate
                  ? true
                  : false
              }
            />

            {validation.errors.energyRate && validation.touched.energyRate ? (
              <FormFeedback type="invalid">
                {validation.errors.energyRate}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={date}
                className="form-control"
                onChange={(date, dateStr) => {
                  setDate(dateStr);
                }}
                placeholder="Purchase Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={installationDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  setinstallationDate(dateStr);
                }}
                placeholder="Installation Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        
        <Col lg={4}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setSelectedSpace(e.target.value)}
              value={selectedSpace}
            >
              <option value="" disabled selected>
                Appliance Location
              </option>
              {SpaceSelectList.map((type) => (
                <option value={type.value}>{type.label}</option>
              ))}
            </select>
          </div>
        </Col>
        
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="warrantyDetails-input"
              placeholder="Warranty Details"
              name="warrantyDetails"
              value={validation.values.warrantyDetails}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.warrantyDetails &&
                validation.touched.warrantyDetails
                  ? true
                  : false
              }
            />

            {validation.errors.warrantyDetails &&
            validation.touched.warrantyDetails ? (
              <FormFeedback type="invalid">
                {validation.errors.warrantyDetails}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {installationComponent}
        <Col lg={10}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update Appliance</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
