import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  success: null,
  elements:null,
  selected:null,
  count: null,
  page_size:null,
  total_pages: null,
  loading:false,
  btnLoading:false
};

const elementSlice = createSlice({
  name: "element",
  initialState,
  reducers: { 
    setelements(state,action){
      state.elements = action.payload.results
      state.count = action.payload.count
      state.total_pages = action.payload.total_pages
      state.page_size = action.payload.page_size
      state.page_num = action.payload.page_num
      state.loading = false
    },
    setIsLoading(state,action){
        state.loading = true
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    elementFailure(state,action){
      state.error = action.payload;
      state.loading = false;
    },
    setSelected(state,action){
      state.selected = action.payload
    },
    deleteSuccess(state,action){
      state.success = "Deleted Successfully"
      state.elements = state.elements.filter((element)=> element.id != action.payload)
      state.loading = false;
    },
    setBtnLoading(state,action){
      state.btnLoading = action.payload;
    },
    createFailure(state,action){
      state.error = action.payload;
      state.btnLoading = false;
    },
    createSucess(state,action){
      state.success = action.payload || "Created Successfully"
      state.btnLoading = false;
    },
  }
});


export const {
  setIsLoading,
  setelements,
  resetMessages,
  elementFailure,
  setSelected,
  deleteSuccess,
  setBtnLoading,
  createFailure,
  createSucess
} = elementSlice.actions;

export default elementSlice.reducer;