
import {  setIsLoading , setInsurance , InsuranceFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getInsurance, createInsurance, deleteInsurance } from "../../../helpers/backend_helper";

import { updateInsurance } from "../../../helpers/backend_helper";

export const ListInsuranceThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getInsurance(pid,params)
    .then((res)=> dispatch(setInsurance(res)))
    .catch((err) => dispatch(InsuranceFailure(err)))

}

export const CreateInsuranceThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createInsurance(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateInsuranceThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateInsurance(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteInsuranceThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteInsurance(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(InsuranceFailure(err)))
    .finally(()=> closeModal())
}