
import {  set2Dfloors } from "./reducer";

import { getFloorsSideNav } from "../../helpers/backend_helper";



export const getFloorsSideNavThunk = (pid) => async (dispatch) => {
    // dispatch(setIsLoading())
    // deleteCapture(cid)
    // .then((res)=> dispatch(deleteSuccess(cid)))
    // .catch((err)=> dispatch(captureFailure(err)))
    // .finally(()=> closeModal())
    getFloorsSideNav(pid)
    .then((res)=> dispatch(set2Dfloors(res)))
    .catch((err)=> console.log(err))
}
