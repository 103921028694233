import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { ToastContainer } from "react-toastify";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Icons
import FeatherIcon from "feather-icons-react";
import { ListCard } from "./ListCard";

// import action
import {
    ListPropertiesThunk ,
    // deleteProjectList as onDeleteProjectList,
} from "../../../slices/thunks";
import { Pagination } from "./pagination";

const List = ({ projectList }) => {
  const dispatch = useDispatch();

  const { projectLists , numOfPages , numOfProperties , pageSize , currentPage } = useSelector((state) => ({
      projectLists: state.listProperty.properties,
      numOfPages: state.listProperty.total_pages,
      numOfProperties: state.listProperty.count,
      pageSize: state.listProperty.page_size,
      currentPage: state.listProperty.page_num
  }));

  const start = (currentPage-1)*pageSize +1;
  const end =  start+ (projectLists|| []).length -1;

  // const item

  // ListPropertiesThunk
  const [project, setProject] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  // useEffect(() => {
  //     dispatch(ListPropertiesThunk());
  // }, [dispatch]);

  useEffect(() => {
      setProject(projectLists);
  }, [projectLists]);

  // const search = useLocation().search;

  // // delete
  const onClickData = (project) => {
      // setProject(project);
      setDeleteModal(true);
  };

  const handleDeleteProjectList = () => {
      // if (project) {
          // dispatch(onDeleteProjectList(project));
          setDeleteModal(false);
      // }
  };

  

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteProjectList()}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Row className="g-4 mb-3">
        <div className="col-sm-auto">
          <div>
            <Link to="/create-property" className="btn btn-success">
              <i className="ri-add-line align-bottom me-1"></i> Add New Property
            </Link>
          </div>
        </div>
        <div className="col-sm-3 ms-auto">
          <div className="d-flex justify-content-sm-end gap-2">
            <div className="search-box ms-2 col-sm-7">
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
              />
              <i className="ri-search-line search-icon"></i>
            </div>

            <select
              className="form-control w-md"
              data-choices
              data-choices-search-false
            >
              <option value="All">All</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
              <option value="Last Year">Last Year</option>
              <option value="This Month">This Month</option>
              <option value="Today">Today</option>
              <option value="Yesterday" defaultValue>
                Yesterday
              </option>
            </select>
          </div>
        </div>
      </Row>

      <div className="row">
        {(projectLists || []).map((item, index) => (
                <ListCard
                key={index}
                linkTo={`/property/${item.id}/`}
                title={item.nickname}
                zipCode={item.zipCode}
                last_modified={item.last_modified}
                location={item.city}
                type={item.propertyType}
              />        
                
          ))}
      </div>
      <Row className="g-0 text-center text-sm-start align-items-center mb-4">
        <Col sm={6}>
          <div>
            <p className="mb-sm-0 text-muted">
              Showing <span className="fw-semibold">{Math.min(start,end)}</span> to{" "}
              <span className="fw-semibold">{end}</span> of{" "}
              <span className="fw-semibold text-decoration-underline">{numOfProperties}</span>{" "}
              entries
            </p>
          </div>
        </Col>

        <Col sm={6}>
          <Pagination
          onPageChange={(newPage) => dispatch(ListPropertiesThunk({'page':newPage}))}
          NumPages={numOfPages} 
          baseLink={'/property/list/'} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default List;
