import { useState } from "react"
import {RecieptInputModal} from "../Common/fileSelectComponents/recieptWraper"
import { unSelectFile } from "../../slices/property/propertyFilesReciepts/reducer";
import { SelectedFilesComponent } from "../Common/fileSelectComponents/selectedFiles";
import { useSelector } from "react-redux";


export const useRecieptSelect = (detail=false,tittleText="Reciept(s)") => {

    const [showReciept , setShowReciept] = useState(false)
    const data = useSelector((state) => ({
        selectedFiles: state.propertyFilesReciepts.selectedFiles,
      }));
    
    const getVal = () => {
        return data.selectedFiles.map((file) => file.id)
    }

    return [
        <RecieptInputModal show={showReciept} toggle={() => setShowReciept(false)}/>,
        <SelectedFilesComponent tittleText={tittleText} detail={detail} {...data} unSelectFile={unSelectFile} className="shadow-lg" view onClick={() => setShowReciept(true)} />,
        getVal
    ]    
}