import { useState } from "react";
import image from "../../assets/images/verified.png";
import { Tooltip } from "reactstrap";

export const VerifiedAdminIcon = ({id}) => {
  const idText = `verfiedIcon-${id}`;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <>
      <img id={idText} style={{width:'16px',height:'16px',cursor:'pointer',verticalAlign:'baseline'}} src={image} />
      <Tooltip
        isOpen={tooltipOpen}
        toggle={() => setTooltipOpen(!tooltipOpen)}
        placement="bottom"
        target={idText}
      >
        MatterCost Staff
      </Tooltip>
    </>
  );
};
