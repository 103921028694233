import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStatuses, getTagsProperty } from "../../helpers/backend_helper";
import Select from "react-select";
import { MultiDropdown } from "../Common/multipleSelect/MultiDropdown";
import { setRefreshDropdown } from "../../slices/property/propertyFiles/reducer";

export const useStatusSearch = (placeholder) => {
  const [selectValues, setValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    GetStatuses().then((res) =>
      setValues(res.map((elm) => ({ value: elm, label: elm })))
    );
    setSelectedValue([]);
  }, []);

  const handleChange = (val) => {
    setSelectedValue(val);
  };

  function getVal() {
    return selectedValue.map((val) => val.value).join(",");
  }

  return [
    <MultiDropdown
      defaultValue={[]}
      onChange={handleChange}
      value={selectedValue}
      noOptionsMessage={() => "No Tags Found.."}
      options={selectValues}
      placeholder={placeholder}
    />,
    selectedValue,
    getVal,
  ];
};
