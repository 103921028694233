import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  success: null,
  btnloading: false,
  property: {},
  gallery: [],
  galleryErr: null,
  galleryDeleteErr: null,
  galleryDeleteSuccess: null,
  galleryLoading: false,
};

const viewPropertySlice = createSlice({
  name: "viewProperty",
  initialState,
  reducers: {
    editProductSuccess(state, action) {
      state.btnloading = false;
      state.success = action.payload.msg;
      state.property = action.payload.newInst || state.property;
    },
    editProductFailure(state, action) {
      state.btnloading = false;
      state.error = action.payload;
    },
    setIsLoading(state, action) {
      state.btnloading = true;
    },
    resetIsLoading(state, action) {
      state.btnloading = false;
    },
    resetMessages(state, action) {
      state.error = null;
      state.success = null;
    },
    setProperty(state, action) {
      state.property = action.payload;
      state.btnloading = false;
    },
    setGallery(state, action) {
      state.gallery = action.payload;
      state.galleryLoading = false;
    },
    setGalleryLoading(state, action) {
      state.galleryLoading = true;
    },
    setGalleryErr(state, action) {
      state.galleryErr = action.payload;
      state.galleryLoading = false;
    },
    deleteGallerySuccess(state, action) {
      state.gallery = state.gallery.filter(
        (gallery) => gallery.id != action.payload
      );
      state.galleryDeleteSuccess = "Your Gallery image is deleted successfully";
      state.galleryLoading = false;
    },
    deleteGalleryError(state, action) {
      state.galleryDeleteErr = action.payload;
      state.galleryLoading = false;
    },
    resetGalleryDelete(state, action) {
      state.galleryDeleteErr = null;
      state.galleryDeleteSuccess = null;
    },
  },
});

export const {
  setIsLoading,
  editProductFailure,
  editProductSuccess,
  resetMessages,
  setProperty,
  setGallery,
  setGalleryLoading,
  setGalleryErr,
  deleteGallerySuccess,
  deleteGalleryError,
  resetGalleryDelete,
  resetIsLoading,
} = viewPropertySlice.actions;

export default viewPropertySlice.reducer;
