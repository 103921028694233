import { Col, Row, Input, Label, Form, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFloorTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getCaptureDataSrcs,
  searchContractors,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateCaptureThunk,
  CreateFloorThunk,
  CreateimageThunk,
  CreateMyContractorsThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListCapturesThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListSpacesThunk,
  UpdateCaptureThunk,
  UpdateMyContractorsThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// import { SpacesDropdown } from "./spacesDropdown";
// import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { myContractorsFailure } from "../../../../slices/property/myContractors/reducer";
import { toast } from "react-toastify";

export const CreateForm = ({
  selected = null,
  onSuccess = () => {},
}) => {
 

  

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.myContractors.btnLoading,
  }));

  
  const [name, setname] = useState(
    selected  ? selected.name : ""
  );

  const [email, setEmail] = useState("");

  const [emailUser, setEmailUser] = useState({});
  const [phoneUser, setPhoneUser] = useState({});

  const [phoneNumber, setphoneNumber] = useState("");
  const [notes, setnotes] = useState("");


  useEffect(() => {
    if (selected) {
      setname(selected.name);
      setEmail(selected.email);
      setphoneNumber(selected.phoneNumber);
      setnotes(selected.notes);
    }
  }, [selected]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getdata() {
    return {
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      user: emailUser.id || phoneUser.id,
      notes:notes
    };
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Contractor");
    checkOrError(
      email || phoneNumber || emailUser || phoneUser,
      errs,
      "you need to add either a email or phoneNumber for your contractor"
    );
    

    if (errs.length > 0) {
      dispatch(myContractorsFailure(errs));
    } else {
      if (selected) {
        dispatch(UpdateMyContractorsThunk(selected.id, getdata(), onSuccess));
      } else {
        dispatch(CreateMyContractorsThunk(selectedProperty.id, getdata(), onSuccess));
      }
    }
  }

  function handleEmailSearch() {
    if (email) {
      searchContractors({ email: email })
        .then((res) => setEmailUser(res))
        .catch((err) => {
          setEmailUser({})
          toast.error(err.message)
        });
    }
  }

  function handlePhoneSearch() {
    if (phoneNumber) {
      searchContractors({ phoneNumber: phoneNumber })
        .then((res) => setPhoneUser(res))
        .catch((err) => {
          setPhoneUser({})
          toast.error(err.message)
        });
    }
  }

  return (
    <Form action="#">
      <Row>
        <Col lg={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name of Contractor"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email of Contractor"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                onClick={handleEmailSearch}
                class="btn btn-primary"
                type="button"
              >
                <i class="mdi mdi-magnify"></i>
              </button>
            </div>
          </div>
          {emailUser.id ? (
            <span className="p-2">
              User Profile :{" "}
              <Link to={`/profile/${emailUser.id}`}>{emailUser.name}</Link>
            </span>
          ) : null}
        </Col>
        <Col lg={4}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Phone Number of contractor"
                value={phoneNumber}
                onChange={(e) => setphoneNumber(e.target.value)}
              />
              <button
                onClick={handlePhoneSearch}
                class="btn btn-primary"
                type="button"
              >
                <i class="mdi mdi-magnify"></i>
              </button>
            </div>
          </div>
          {phoneUser.id ? (
            <span className="p-2">
              User Profile :{" "}
              <Link to={`/profile/${phoneUser.id}`}>{phoneUser.name}</Link>
            </span>
          ) : null}
        </Col>

        <Col lg={10}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>

        <Col lg={2}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id={selected ? "update-btn" : "create-btn"}
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {selected ? (
              <>Update Contractor</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
