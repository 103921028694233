import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Container,
  Form,
  Input,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  Button,
} from "reactstrap";
import { SupportCaseRetrieve } from "../../helpers/backend_helper";
import { Status } from "./Status";
import { FileLinkViewer } from "../../Components/Common/privateImageViewer/privateImageViewer";
import { CreateMsgModal } from "./createMsgModal";
import Linkify from "linkify-react";
import { VerifiedAdminIcon } from "../../Components/Common/VerfiedAdmin";
import { ChangeStatusModal } from "./changeStatusModal";
import { useSelector } from "react-redux";
import { CloseCaseModal } from "./closeCaseModal";

const SupportDetailsPage = () => {
  document.title = "DwellDoc | Support";

  const [supportCase, setSupportCase] = useState({});
  const [refresh, setRefresh] = useState(false);

  const { id } = useParams();

  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  useEffect(() => {
    SupportCaseRetrieve(id).then((res) => setSupportCase(res));
  }, [refresh]);

  const [showCreate, setshowCreate] = useState(false);

  function toggleCreate() {
    setshowCreate(!showCreate);
  }

  const [showChange, setshowChange] = useState(false);

  function toggleChange() {
    setshowChange(!showChange);
  }

  const [showClose, setshowClose] = useState(false);

  function toggleClose() {
    setshowClose(!showClose);
  }

  const onSuccess = () => {
    setRefresh(!refresh);
    // toggleCreate()
  };

  return (
    <React.Fragment>
      <CreateMsgModal
        isOpen={showCreate}
        toggle={toggleCreate}
        caseID={id}
        onSuccess={onSuccess}
      />
      <ChangeStatusModal
        isOpen={showChange}
        toggle={toggleChange}
        caseID={id}
        onSuccess={onSuccess}
      />
      <CloseCaseModal 
        isOpen={showClose}
        toggle={toggleClose}
        caseID={id}
        onSuccess={onSuccess}
      />
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              {/* <img src={progileBg} className="profile-wid-img" alt="" /> */}
              <div className="overlay-content"></div>
            </div>
          </div>
          <Row>
            <Card style={{ marginTop: "-80px" }} className="p-0">
              <CardHeader className="p-0">
                <div className="d-flex align-items-center justify-content-between">
                  <h4 className="p-4">Support Ticket Details</h4>
                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2 mx-3">
                      {user.is_staff ? (
                        <button
                          className="btn btn-danger add-btn"
                          onClick={toggleChange}
                        >
                          Change Ticket Status
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger add-btn"
                          onClick={toggleClose}
                          disabled={supportCase.status==='Closed'}
                        >
                          Close Ticket
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-4">
                <Row>
                  <Col md={6}>
                    <div className="text-muted">
                      <h5 className="fs-14">Id :</h5>
                      <p>
                        <Link
                          to={`/support/${supportCase.id}`}
                          className="text-primary fw-medium"
                        >
                          #{supportCase.id}
                        </Link>
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-muted">
                      <h5 className="fs-14">Status :</h5>
                      <p>
                        <Status value={supportCase.status} />
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-muted">
                      <h5 className="fs-14">Tittle :</h5>
                      <p>
                        <span className="fw-medium">{supportCase.tittle}</span>
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-muted">
                      <h5 className="fs-14">Creation Date :</h5>
                      {supportCase.uploadDate && (
                        <p>
                          <>
                            {supportCase.uploadDate.date},
                            <small className="text-muted">
                              {" "}
                              {supportCase.uploadDate.time}
                            </small>
                          </>
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="text-muted">
                      <h5 className="fs-14">Last Activity :</h5>
                      {supportCase.lastUpdate && (
                        <p>
                          <>
                            {supportCase.lastUpdate.date},
                            <small className="text-muted">
                              {" "}
                              {supportCase.lastUpdate.time}
                            </small>
                          </>
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>

          <Row>
            <Card className="mt-5 p-0">
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h4 className="card-title mb-0 flex-grow-1">Messages</h4>
                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      <button
                        className="btn btn-danger add-btn"
                        onClick={toggleCreate}
                      >
                        <i className="ri-add-line align-bottom"></i> Add New
                        Message
                      </button>{" "}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="p-0">
                {(supportCase.messages || []).map((msg) => (
                  <Row className="">
                    <Col
                      className="p-4 border border-2 border-light fw-normal"
                      md={2}
                    >
                      <h5 className="fs-17">
                        {msg.user.name}{" "}
                        {msg.user.is_staff && <VerifiedAdminIcon id={msg.id} />}
                      </h5>

                      {/* <p></p> */}
                      {msg.user.email && <p>{msg.user.email}</p>}

                      <p>
                        <>
                          {msg.uploadDate.date},
                          <small className="text-muted">
                            {" "}
                            {msg.uploadDate.time}
                          </small>
                        </>
                      </p>
                    </Col>
                    <Col
                      className="p-4 lh-lg fw-normal border border-2 border-light"
                      md={10}
                    >
                      <Linkify>
                        <p className="show-white-space">{msg.content}</p>
                      </Linkify>

                      {msg.attachements.length > 0 && (
                        <>
                          <div className="mt-2 text-muted">
                            <h5 className="fs-14">Attachements :</h5>
                          </div>
                          <Row>
                            {msg.attachements.map((attachement) => (
                              <FileLinkViewer
                                spinnerClassName={"m-2"}
                                file={attachement}
                                module={"supportFile"}
                              />
                            ))}
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SupportDetailsPage;
