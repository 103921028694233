import SimpleBar from "simplebar-react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FileLinkViewer } from "../privateImageViewer/privateImageViewer";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const SelectItem = ({ item, unSelectFile,hideClose=false }) => {
  const dispatch = useDispatch();
  return (
    <ListGroupItem>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <div className="d-flex">
            <div className="flex-shrink-0 avatar-xs">
              <div className="avatar-title bg-soft-danger text-danger rounded">
                <i className="ri-file-3-fill"></i>
              </div>
            </div>
            <div className="flex-shrink-0 ms-2">
              {/* <h6 className="fs-14 mb-0">{item.name}</h6> */}
              <FileLinkViewer
                className="fs-14 mb-0 d-block"
                file={item}
                module={"privateFile"}
              />
              <small className="text-muted">{item.tag}</small>
            </div>
          </div>
        </div>
        {!hideClose && 
        <div className="flex-shrink-0">
          <div
            onClick={() => dispatch(unSelectFile(item.id))}
            className="p-1"
            style={{ cursor: "pointer" }}
          >
            <i
              style={{ fontSize: "20px", color: "red" }}
              className="ri-close-circle-fill"
            ></i>
          </div>
        </div>
        }
      </div>
    </ListGroupItem>
  );
};

export const SelectedFilesComponent = ({
  selectedFiles,
  unSelectFile,
  className = "mt-1 shadow-lg",
  view = false,
  onClick = () => {},
  detail = false,
  tittleText = "",
  updateFunc=()=>{},
}) => {

  // const [loading , setLoading] = useState(false)

  // function onSaveClick() {
  //   updateFunc()
  //   .then((res) => toast.success("Updated Successfully"))
  //   .catch((err) => toast.error(err.msg))
  // }

  return (
    <Card className={className}>
      <CardHeader className={"d-flex align-items-center justify-content-between"}>
        <h6>
          {tittleText ? tittleText : "Selected File(s) "}
          <small className="text-muted">
            {"  "}
            {selectedFiles.length} file(s)
          </small>
        </h6>
        {view && !detail && (
          <button
            type="button"
            className="btn btn-soft-primary btn-sm flex-shrink-0"
            onClick={onClick}
          >
            Select or Upload File <i className="ri-arrow-right-line align-bottom"></i>
          </button>
        )}
      </CardHeader>
      <CardBody>
        <SimpleBar
          className="my-1"
          style={{ minheight: "120px", maxHeight: "215px" }}
        >
          {selectedFiles.length === 0 ? (
            <p className="text-muted text-center">No Selected Files</p>
          ) : (
            <ListGroup>
              {(selectedFiles || []).map((item) => (
                <SelectItem hideClose={detail} unSelectFile={unSelectFile} item={item} />
              ))}
            </ListGroup>
          )}
        </SimpleBar>
        {/* {detail && (
          <div className="d-flex align-items-start gap-3">
            <button
              type="button"
              className="btn btn-primary right ms-auto"
              data-nexttab="pills-info-desc-tab"
              onClick={onSaveClick}
              disabled={
                loading
              }
            >
              {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
              Save Changes
            </button>
          </div>
        )} */}
      </CardBody>
    </Card>
  );
};
