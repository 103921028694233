const format = {
  header: {
    title: "",
    data: "",
  },
  data: [
    {
      title: "",
      data: "",
    },
  ],
};

export const capturetooltip = {
  header: {
    title: "3D Capture",
    data: "Enables the upload and visualization of 3D models of your property.",
  },
  data: [
    {
      title: "Importance",
      data: "Medium - Provides an immersive view and accurate spatial understanding of your property.",
    },
    {
      title: "Format",
      data: "Matterport 360 View, Matterport 3D Space.",
    },
    {
      title: "Usage",
      data: "Displayed on the property dashboard. Used for rendering visual walkthroughs and integrating with other property data to provide comprehensive insights.",
    },
    {
      title: "Benefits",
      data: "Gain a detailed perspective of your space, facilitating planning, maintenance, and potential renovations.",
    },
    {
      title: "Privacy Assurance",
      data: "Your URL is securely stored. We value your privacy and won't share without your consent.",
    },
    {
      title: "Update Information",
      data: "For accurate representation, update your 3D capture after significant property modifications.",
    },
  ],
};

export const captureNewEDitListTooltip = {
  header: {
    title: "3D Capture - Add/List/View/Edit - Overview ",
    data: "Manage, view, and share your 3D captures seamlessly within Mattercost.",
  },
  data: [
    {
      title: "New",
      data: "Add new captures from external sources by providing the URL. Once added, you can view and share them within Mattercost.",
    },
    {
      title: "List",
      data: "Consolidated list of all your added 3D captures.",
    },
    {
      title: "View",
      data: "Click on a capture's name to view the full details and content.",
    },
    {
      title: "Edit",
      data: "Update or modify any information you've added related to a specific 3D capture.",
    },
  ],
};

export const captureNametooltip = {
  header: {
    title: "3D Capture - Name",
    data: "Assign a unique name to your capture for easy identification within Mattercost.",
  },
};

export const captureDataSrctooltip = {
  header: {
    title: "3D Capture - Data Source",
    data: "While we currently support only Matterport, we'll be expanding to more sources soon.",
  },
};

export const captureDisplayUrltooltip = {
  header: {
    title: "3D Capture - Display URL",
    data: "Matterport URL; this is the link we use to showcase the capture on Mattercost.",
  },
};

export const captureSpacestooltip = {
  header: {
    title: "3D Capture - Included Spaces",
    data: "Distinct areas or rooms present within the 3D capture.",
  },
};

export const captureActionstooltip = {
  header: {
    title: "3D Capture - Actions",
    data: "Options to modify or remove details of your 3D capture.",
  },
};

export const captureDetailTooltip = {
  header: {
    title: "3D Capture - Detail",
    data: "Showcases the provided URL. This is the view that service providers will access when shared.",
  },
};

export const locationTooltip = {
  header: {
    title: "Location",
    data: "Enables the user to specify a location range, ensuring discretion until more precise details are required.",
  },
  data: [
    {
      title: "Importance",
      data: "High - Location affects construction costs, local regulations, and accessibility.",
    },
    {
      title: "Format",
      data: "Powered by Google Maps Location Service.",
    },
    {
      title: "Usage",
      data: "Showcased on the property dashboard. Utilized for pinpointing locations within projects; anonymized data aids MatterCost in discerning property requirements and potential costs. (How we use data)",
    },
    {
      title: "Benefits",
      data: "Provides flexibility in acquiring construction quotes without revealing the property's exact location, safeguarding user's privacy.",
    },
    {
      title: "Privacy Assurance",
      data: "Precise location details are kept confidential. We prioritize your privacy and only access specific location data when permitted by you.",
    },
    {
      title: "Update Information",
      data: "For accurate project planning and cost estimation, always ensure the location information is up-to-date.",
    },
  ],
};

export const locationNeighbourhoodtooltip = {
  header: {
    title: "Location - Neighborhood",
    data: "Pinpoint a closer locale. We recommend using the neighborhood name or the closest town.",
  },
};

export const locationAreatooltip = {
  header: {
    title: "Location - Area",
    data: "Set a general vicinity. We suggest choosing the nearest major town or city where most contractors are based.",
  },
};

export const locationExacttooltip = {
  header: {
    title: "Location - Exact Address Pin",
    data: "Define the precise location. It's best to select the exact property address for accurate results.",
  },
};

export const FloorPlantooltip = {
  header: {
    title: "Floorplan(s)",
    data: "Floorplans allow users to upload and visualize their property, breaking it down into distinct levels. These levels automatically populate the menu, streamlining the addition of specific spaces for enhanced planning and accurate cost estimation.",
  },
  data: [
    {
      title: "Importance",
      data: "High - Floorplans provide a clear understanding of property dimensions, layout, and the spatial relationship of rooms, influencing project scope and cost.",
    },
    {
      title: "Format",
      data: "Supported in JPEG, PNG, PDF, and SVG formats.",
    },
    {
      title: "Usage",
      data: "Displayed on the property dashboard. Incorporated in project planning and management; aids MatterCost in providing accurate cost estimations and resource allocations.",
    },
    {
      title: "Benefits",
      data: "Allows for precise project scoping, ensuring contractors have a clear idea of the project layout and requirements, which can lead to more accurate bids and timelines.",
    },
    {
      title: "Privacy Assurance",
      data: "Your floorplan details remain confidential. We respect your privacy and ensure that floorplans are only shared with relevant parties based on your permissions.",
    },
    {
      title: "Update Information",
      data: "To maintain accurate project planning and resource allocation, regularly update floorplans following significant property changes or renovations.",
    },
  ],
};


export const FloorPlanNewListTooltip = {
  header: {
    title: "Floorplan(s) - New/List/View/Edit",
    data: "Manage, view, and share your Floorplan seamlessly within MatterCost. Create Floorplans for all levels with spaces.",
  },
  data: [
    {
      title: "New",
      data: "Upload your property's floorplans for a detailed spatial representation. Once added, they become accessible and shareable within MatterCost.",
    },
    {
      title: "List",
      data: "Browse a comprehensive list of all your uploaded floorplans.",
    },
    {
      title: "View",
      data: "Click on a floorplan's name to explore the full layout and associated details.",
    },
    {
      title: "Edit",
      data: "Adjust or revise any information or annotations you've appended to a specific floorplan.",
    },
  ],
};

export const FloorplanNameTooltip = {
  header: {
    title: "Floorplan(s) - Name",
    data: "Choose a unique name for your floorplan to identify and reference it easily within MatterCost.",
  },
};

export const FloorplanFileNameTooltip = {
  header: {
    title: "Floorplan(s) - Filename",
    data: "This reflects the original filename of the uploaded floorplan. Ensure it's clear for easy identification.",
  },
};

export const FloorplanLevelTooltip = {
  header: {
    title: "Floorplan(s) - Level",
    data: "Specify which level or floor of your property this floorplan represents, e.g., 'Ground Floor', 'Basement', etc.",
  },
};

export const FloorplanActionTooltip = {
  header: {
    title: "Floorplan(s) - Action",
    data: "Options to modify or remove details of your Floorplan(s).",
  },
};

export const Insurancetooltip = {
  header: {
    title: "Insurance",
    data: "Enables users to store, track, and manage their property insurance policies, ensuring peace of mind with easy access to vital coverage details.",
  },
  data: [
    {
      title: "Importance",
      data: "High - Keeping insurance information at your fingertips aids in swift claim processing, understanding coverage limits, and ensuring timely policy renewals.",
    },
    {
      title: "Format",
      data: "Support for textual policy descriptions, policy numbers, images or scans of physical documents (JPEG, PNG, etc.), coverage amounts, renewal dates, and provider contact information.",
    },
    {
      title: "Usage",
      data: "Displayed prominently on the property dashboard. MatterCost can send reminders about upcoming policy expirations, recommend coverage adjustments based on property updates, and streamline the claim process.",
    },
    {
      title: "Benefits",
      data: "Simplifies policy management for homeowners, ensuring they never miss a renewal or update. Also provides a centralized location for all insurance-related information, making it easier during claims or when shopping for new coverage.",
    },
    {
      title: "Privacy Assurance",
      data: "Your insurance details are held in the utmost confidence. We value your privacy, guaranteeing that insurance data is only shared with individuals or entities you specifically allow.",
    },
    {
      title: "Update Information",
      data: "For optimal protection and streamlined insurance management, always keep policy details updated, particularly after any significant changes to your property or coverage.",
    },
  ],
};


export const InsuranceNewListTooltip = {
  header: {
    title: "Insurance New/List/View/Edit",
    data: "Mattercost's Insurance section provides users a streamlined space to manage and review property insurance policies, ensuring you remain informed and protected.",
  },
  data: [
    {
      title: "New",
      data: "Record a new insurance policy, detailing its coverage, premiums, and relevant contacts for easy accessibility.",
    },
    {
      title: "List",
      data: "Navigate a structured summary of all your insurance policies linked to the property.",
    },
    {
      title: "View",
      data: "Click on a specific insurance policy to access its comprehensive details, from policy terms to claim history.",
    },
    {
      title: "Edit",
      data: "Update or adjust the specifics of an existing insurance policy, ensuring that your coverage information remains current and accessible.",
    },
  ],
};

export const insuranceNameTooltip = {
  header: {
    title: "Name",
    data: "Assign a custom name to each finish for easy identification.",
  },
};


export const Finishestooltip = {
  header: {
    title: "Finishes",
    data: "Enables users to document and visualize detailed information about finishes within their property, enhancing upkeep and renovation planning.",
  },
  data: [
    {
      title: "Importance",
      data: "Medium - Capturing precise finish details helps in maintaining aesthetic consistency, assuring quality, and in future renovations or touch-ups.",
    },
    {
      title: "Format",
      data: "Support for textual information, color swatches, and image uploads (JPEG, PNG, etc.).",
    },
    {
      title: "Usage",
      data: "Displayed on the property dashboard. Assists MatterCost in streamlining project management, ensuring contractors are informed about material quality and aesthetics.",
    },
    {
      title: "Benefits",
      data: "Ensures homeowners have detailed records for maintenance or warranty claims, provides contractors with precise information for seamless integrations or touch-ups, and offers an easy reference for future property updates.",
    },
    {
      title: "Privacy Assurance",
      data: "Details about your finishes remain confidential. We uphold your privacy and ensure that finish details are only shared with those you approve.",
    },
    {
      title: "Update Information",
      data: "For accurate maintenance and renovation planning, it's advised to keep the finishes information updated, especially after any significant change or fresh installations.",
    },
  ],
};


export const FinishesNewListTooltip = {
  header: {
    title: "Finishes - New/List/View/Edit",
    data: "Manage, document, and reference your property's finishes seamlessly within Mattercost.",
  },
  data: [
    {
      title: "New",
      data: "Add new finishes by providing detailed information about type, color, placement, and more. Once added, they're cataloged and easily accessible within Mattercost.",
    },
    {
      title: "List",
      data: "Browse a comprehensive list of all your documented finishes.",
    },
    {
      title: "View",
      data: "Click on a finish's name to view the full details, images, and associated notes.",
    },
    {
      title: "Edit",
      data: "Update or modify any information you've added related to a specific finish.",
    },
  ],
};

export const FinishesNameTooltip = {
  header: {
    title: "Finishes - Name",
    data: "Assign a custom name to each finish for easy identification.",
  },
};

export const FinishesTypeTooltip = {
  header: {
    title: "Finishes - Finish Type",
    data: "Choose from a comprehensive list, such as paint, wallpaper, brick, stucco, etc.",
  },
};

export const FinishesPlacementTooltip = {
  header: {
    title: "Finishes - Placement",
    data: "Indicate where the finish is located - wall, ceiling, trim, etc.",
  },
};


export const FinishesInstallerTooltip = {
  header: {
    title: "Finishes - Installer",
    data: "If professionally done, keep the contractor's information handy for follow-ups or future projects.",
  },
};

export const FinishesInstallDateTooltip = {
  header: {
    title: "Finishes - Installation Date",
    data: "Document when the finish was installed.",
  },
};

export const FinishesSpacesTooltip = {
  header: {
    title: "Finishes - Spaces/Locations",
    data: "Indicate in which room and area the finish has been applied.",
  },
};

export const FinishesActionsTooltip = {
  header: {
    title: "Finishes - Actions",
    data: "Access options to modify or delete the finish details as needed.",
  },
};


export const Flooringtooltip = {
  header: {
    title: "Flooring",
    data: "Enables users to document, categorize, and visualize distinct flooring types and materials across their property, ensuring clarity in maintenance and renovation decisions.",
  },
  data: [
    {
      title: "Importance",
      data: "Medium - Accurate flooring details are paramount for determining wear and tear, understanding maintenance schedules, and planning renovations or replacements.",
    },
    {
      title: "Format",
      data: "Support for textual information, imagery (JPEG, PNG, etc.), and details such as material, brand, installation date, and more.",
    },
    {
      title: "Usage",
      data: "Featured prominently on the property dashboard. Assists MatterCost in generating accurate cost estimates, foreseeing potential maintenance needs, and connecting users with appropriate flooring experts.",
    },
    {
      title: "Benefits",
      data: "Helps homeowners maintain their property's aesthetic and functional value by tracking the lifespan and condition of various flooring materials, ensures contractors and service providers have a comprehensive understanding of the flooring context, and streamlines decisions on repairs or replacements.",
    },
    {
      title: "Privacy Assurance",
      data: "Your flooring details are held in strict confidence. We respect your privacy, ensuring flooring data is shared only with those you permit.",
    },
    {
      title: "Update Information",
      data: "For continued accuracy and to align with property wear and changes, it's recommended to periodically update the flooring information, especially post-installations or significant alterations.",
    },
  ],
};


export const FlooringNewListTooltip = {
  header: {
    title: "Flooring New/List/View/Edit",
    data: "Flooring in Mattercost lets users document different flooring types and conditions, enhancing property value assessment and ensuring precise project planning with contractors.",
  },
  data: [
    {
      title: "New",
      data: "Add and specify details of a new flooring type or area, helping to keep track of materials, conditions, and installation dates.",
    },
    {
      title: "List",
      data: "Browse a comprehensive list of all the flooring details you've added to your property.",
    },
    {
      title: "View",
      data: "Click on a specific flooring entry to examine its full details, from material to warranty information.",
    },
    {
      title: "Edit",
      data: "Update or adjust the details of a specific flooring, ensuring your records are always up-to-date and accurate for future renovations or evaluations.",
    },
  ],
};

export const FlooringNameTooltip = {
  header: {
    title: "Flooring - Name",
    data: "Assign a custom name to each type of flooring for quick identification.",
  },
};

export const FlooringTypeTooltip = {
  header: {
    title: "Flooring - Flooring Type",
    data: "Choose from diverse flooring options such as hardwood, laminate, tile, carpet, etc.",
  },
};

export const FlooringPlacementTooltip = {
  header: {
    title: "Flooring - Placement",
    data: "Specify whether the flooring covers the entire space or only a particular area.",
  },
};


export const FlooringInstallerTooltip = {
  header: {
    title: "Flooring - Installer",
    data: "DIY or if professionally done, keep the contractor's information handy for follow-ups or future projects.",
  },
};

export const FlooringInstallDateTooltip = {
  header: {
    title: "Flooring - Installation Date",
    data: "Document when the flooring was installed.",
  },
};

export const FlooringSpacesTooltip = {
  header: {
    title: "Flooring - Spaces/Locations",
    data: "Indicate in which room and area the finish has been applied.",
  },
};

export const FlooringActionsTooltip = {
  header: {
    title: "Flooring - Actions",
    data: "Access options to modify or delete the flooring details as needed.",
  },
};