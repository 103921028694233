
import {  setIsLoading , setProperties   } from "./reducer";

import { getAllProperties } from "../../../helpers/backend_helper";

export const ListPropertiesThunk = (params) => async (dispatch) => {

    dispatch(setIsLoading())

    getAllProperties(params)
    .then((res)=> dispatch(setProperties(res)))
    .catch((err) => dispatch(createProductFailure(err.message)))
    // createProperty(data)
    // .then((res)=> dispatch(createProductSuccess()))

}