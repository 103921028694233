import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  registrationError: null,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
  signup1: {
    email:"",
    name:"",
    password: "",
    confirm_password: ''
  },
  signup2: {
    firstName:"",
    lastName:"",
    role: "",
    contactNumber: '',
    address:'',
    city:'',
    state:'',
    country:'United States',
    zipCode:''
  }
};

const registerSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsLoading(state, action){
      state.loading = true;
    },
    setSignUp1(state,action) {
      state.signup1 = action.payload
    },
    setSignUp2(state,action) {
      state.signup2 = action.payload
    },
    registerUserSuccessful(state, action) {
      state.message = action.payload;
      state.loading = false;
      state.success = true;
      state.registrationError = null;
      state.signup1 = initialState.signup1
      state.signup2 = initialState.signup2
    },
    emailChecked(state,action){
      state.message = action.payload
      state.success = true;
      state.loading = false;
      state.registrationError = null;
      state.error = false
    },
    registerUserFailed(state, action) {
      state.user = null;
      state.loading = false;
      state.registrationError = action.payload;
      state.error = true;
    },
    resetRegisterFlagChange(state) {
      state.success = false;
      state.error = false;
    },
    apiErrorChange(state, action){
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
    }
  }
});

export const {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange ,
  setIsLoading,
  setSignUp1,
  setSignUp2,emailChecked
} = registerSlice.actions;

export default registerSlice.reducer;