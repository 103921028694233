import { useState } from "react";
import { Spinner } from "reactstrap";

const ImageOrLoad = (props) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      <img
        {...props}
        style={loading ? { ...props.style, display: "none" } : props.style}
        onLoad={() => {
            setLoading(false)
        }}
      />
      {loading && <Spinner />}
    </>
  );
};

export default ImageOrLoad;
