
import {  setIsLoading , setCaptures , captureFailure , deleteSuccess, setBtnLoading, createSucess, createFailure } from "./reducer";

import { propertyCaptures , deleteCapture, createCapture, updateCapture } from "../../../helpers/backend_helper";

export const ListCapturesThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    propertyCaptures(pid,params)
    .then((res)=> dispatch(setCaptures(res)))
    .catch((err) => dispatch(captureFailure(err)))

}

export const DeleteCaptureThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
    dispatch(setIsLoading())
    deleteCapture(cid)
    .then((res)=> dispatch(deleteSuccess(cid)))
    .catch((err)=> dispatch(captureFailure(err)))
    .finally(()=> closeModal())
}

export const CreateCaptureThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createCapture(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateCaptureThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateCapture(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}