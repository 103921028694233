
import {  setIsLoading , setutility , utilityFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getutility,createutility ,deleteutility } from "../../../helpers/backend_helper";

export const ListutilityThunk = (pid,params) => async (dispatch) => {

    const type = params['type'] || 'Electric'
    dispatch(setIsLoading(type))

    getutility(pid,params)
    .then((res)=> dispatch(setutility({...res,type:type})))
    .catch((err) => dispatch(utilityFailure({...err,type:type})))

}

export const CreateutilityThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createutility(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const DeleteutilityThunk = (util,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setBtnLoading(true))
    deleteutility(util.id,util.type)
    .then((res)=> dispatch(deleteSuccess(util.id)))
    .catch((err)=> dispatch(utilityFailure(err)))
    .finally(()=> closeModal())
}

// export const DeletefloorThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
//     dispatch(setIsLoading())
//     deletefloor(cid)
//     .then((res)=> dispatch(deleteSuccess(cid)))
//     .catch((err)=> dispatch(floorFailure(err)))
//     .finally(()=> closeModal())
// }