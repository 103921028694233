import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Modal,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteHVACThunk,
  DeleteimageThunk,
  DeleteinventoryThunk,
  ListHVACThunk,
  ListimagesThunk,
  ListinventoryThunk,
} from "../../../../slices/thunks";
import { getCaptureDataSrcs } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import { resetMessages } from "../../../../slices/property/HVAC/reducer";
import { CreateImageForm } from "./createForm";
import UpdateHVACModal from "../New";
import { reloadState as reloadStateImage, saveState as saveStateImage, setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { reloadState as reloadStateReciept, saveState as saveStateReciept, setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { StatusComponent } from "../../../../Components/Common/Status";

const HVACUpload = () => {
  document.title = "HVAC | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");

  const dataSrcs = [];
  const mountedRef = useRef()
  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const {
    selectedProperty,
    HVAC,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    HVAC: state.HVAC.HVAC,
    numOfPages: state.HVAC.total_pages,
    numOfCaptures: state.HVAC.count,
    pageSize: state.HVAC.page_size,
    currentPage: state.HVAC.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.HVAC.loading,
    error: state.HVAC.error,
    success: state.HVAC.success,
  }));

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      dataSource: "All",
      page: 1,
    });
  }
  mountedRef.current = true;
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListHVACThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  useEffect(()=>{
    dispatch(setSelectedImageFiles([]))
    dispatch(setSelectedRecieptFiles([]))
  },[])

  function onDeleteClick() {
    dispatch(
      DeleteHVACThunk(captureDelete, () => {
        dispatch(ListHVACThunk(selectedProperty.id));
        setCaptureDelete(null);
      })
    );
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListHVACThunk(selectedProperty.id, filterParams));
    }
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  useEffect(()=>{
    if(isEdit){
      dispatch(saveStateImage())
      dispatch(saveStateReciept())
    }else{
      dispatch(reloadStateImage())
      dispatch(reloadStateReciept())
    }
  },[isEdit])

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/HVAC/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Type",
        accessor: "HVACType",
        filterable: false,
      },
      {
        Header: "Location",
        accessor: "unitLocation",
        filterable: false,
      },
      {
        Header: "Purchase Date",
        accessor: "purchaseDate",
        filterable: false,
      },
      {
        Header: "Make and Model",
        accessor: "",
        filterable: false,
        Cell: (cell) => {
          return (
            <>
              {cell.row.original.make} - {cell.row.original.model}
            </>
          );
        },
      },
      {
        Header: "Warranty Details",
        accessor: "warrantyDetails",
        filterable: false,
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          return <StatusComponent value={cell.value} />;
        },
      },
      {
        Header: "Date Created",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link
                  to={`/HVAC/${capture.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
    }
  };

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateHVACModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />

      <Container fluid>
        <BreadCrumb title="HVAC" pageTitle="Property Workbook" />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">New/List/View/Edit</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap"></div>
                  </div>
                  <div>
                    <div className="py-3">
                      <CreateImageForm />
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Items
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.name);
                          }}
                          href="#"
                        >
                          {src.iconClass && <i className={src.iconClass}></i>}
                          {src.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={HVAC || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
                
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HVACUpload;
