import { useState } from "react";
import Select, { components } from "react-select";
// import "./styles.css";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "#FFFFFF";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#FFFFFF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        style={{ marginRight: "10px" }}
        checked={isSelected}
      />
      {children}
    </components.Option>
  );
};

export function MultiDropdown(props) {

  return (
    <div className="App">
      <Select
        {...props}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option: InputOption,
        }}
        styles={{
          Menu: (base, state) => ({
            ...base,
            background: "#FFFFFF",
          }),
          control: (base, state) => ({
            ...base,
            background: "#FFFFFF",
          }),
        }}
      />
    </div>
  );
}
