import React from "react";

export const StatusComponent = ({value}) => {
    return (
        <React.Fragment>
            {value === "Planned" ? <span className="badge text-bg-primary text-uppercase">{value}</span>
                : value === "Pending" ? <span className="badge text-bg-secondary text-uppercase">{value}</span>
                    : value === "Completed" ? <span className="badge text-bg-success text-uppercase">{value}</span>
                    : value === "In Progress" ? <span className="badge text-bg-info text-uppercase">{value}</span>
                    : value === "On Hold" ? <span className="badge text-bg-warning text-uppercase">{value}</span>
                    : value === "To Be Reviewed" ? <span className="badge text-bg-danger text-uppercase">{value}</span>
                    : value === "Archived" ? <span className="badge text-bg-dark text-uppercase">{value}</span>
                    : value === "Deferred" ? <span className="badge text-bg-light text-uppercase">{value}</span>

                        // : value === "" ? <span className="badge text-bg-danger text-uppercase">{value}</span>
                            : null
            }
        </React.Fragment>
    );
};