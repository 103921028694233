import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Accordion,
  AccordionItem,
  Collapse,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";


// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteimageThunk,
  DeleteutilityThunk,
  ListimagesThunk,
  ListutilityThunk,
} from "../../../../slices/thunks";
import {
  getCaptureDataSrcs,
  getutilityTypes,
  utilitySpaces,
} from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import { resetMessages, resetUtility, setFilterParams } from "../../../../slices/property/utilites/reducer";
import { CreateBillForm } from "./createForm";
import UpdateBillModal from "../New";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
import { FileLinkViewer, PrivateFileWraper } from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import { reloadState as reloadStateImage, saveState as saveStateImage, setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { reloadState as reloadStateReciept, saveState as saveStateReciept, setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";


const UtilityUpload = () => {
  document.title = "Home utility | DwellDoc";

  // const [activeTab, setActiveTab] = useState("1");

  const [types, setTypes] = useState([]);

  const [dataSrcs, setDataSrcs] = useState([]);

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const [accordState , setAccordState] = useState([])

  const colors = useMemo(()=>({
    'Electric': '#FFFACD',
    'Natural Gas': '#FFB6C1',
    'Water and Sewer': '#D3D3D3',
    'Water': '#87CEEB',
    'Sewer': '#F5DEB3',
    'Trash': '#98FB98',
    'Other':'#C0C0C0'
  }),[])

  // const [accordparams,setAccordParams] = useState({ 
  //   filters:[],
  //   changed:-1
  // })

  const dispatch = useDispatch();

  // const [filterParams, setFilterParams] = useState({
  //   dataSource: "All",
  //   page: 1,
  // });

  const {
    selectedProperty,
    utility,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    btnLoading,
    error,
    success,
    filters,
    changed
  } = useSelector((state) => ({
    utility: state.utility.utility,
    numOfPages: state.utility.total_pages,
    numOfCaptures: state.utility.count,
    pageSize: state.utility.page_size,
    currentPage: state.utility.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.utility.loading,
    btnLoading: state.utility.btnLoading,
    error: state.utility.error,
    success: state.utility.success,
    filters: state.utility.filters,
    changed: state.utility.changed
  }));

  useEffect(() => {
    // setFilterParams({
    //   type: null,
    //   page: 1,
    // });
    dispatch(resetUtility())
    setAccordState(accordState.map(() => false))
  }, [selectedProperty]);

  // useEffect(() => {
  //   if (selectedProperty) {
  //     dispatch(ListutilityThunk(selectedProperty.id, filterParams));
  //   }
  // }, [filterParams]);

  function onDeleteClick() {
    
    
    dispatch(
      DeleteutilityThunk(captureDelete, () => {
        onCreateSuccess(captureDelete.type)
        setCaptureDelete(null);
      })
    );
  }

  function OnUpdateSuccess(type) {
    // console.log(edited.type)
    onCreateSuccess(type)
    // if (selectedProperty) {
    //   dispatch(ListutilityThunk(selectedProperty.id, ));
    // }
  }

  function onCreateSuccess(type) {
    console.log(filters)
    const key = filters.findIndex((elem) => elem.type==type)
    dispatch(setFilterParams({
      filters:filters,
      changed:key
    }))
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  useEffect(() => {
    getutilityTypes().then((res) => {
      setTypes(res);
      const srcs = Object.keys(res);
      setDataSrcs(
        srcs.map((src) => ({
          iconClass: "ri-store-2-fill me-1 align-bottom",
          name: src,
        }))
      );
      setAccordState(srcs.map(()=> false))
      dispatch(setFilterParams({
        filters:srcs.map((src)=> ({ "type" : src , "page":1 })),
        changed:-1
      }))
    });
  }, []);

  const [ SpacesViewModalComp , setSpacesId , setshowParams ] = useSpaceViewer({func: utilitySpaces})

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Bill",
        accessor: "file",
        filterable: false,
        Cell: (cell) => {
          const lvlimg = cell.row.original.file;
          return lvlimg ? (
            <FileLinkViewer module={"privateFile"} lazyLoad={true} file={lvlimg} />
          ) : null;
        },
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Bill Date",
        accessor: "date",
        Cell: (cell) => {
          return <>{cell.row.original.date.date}</>;
        },
      },
      {
        Header: "Usage Information",
        accessor: "usageInfo",
      },
      {
        Header: "Spaces",
        id: "spaces",
        accessor: "spaces",
        Cell: (capture) => (
          <Link
          to="#"
            onClick={() =>
              setshowParams({
                id:capture.row.original.id,
                params:{type:capture.row.original.type}
              })
            }
          >
            See List
          </Link>
        ),
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page,type,key) => {
    
    const oldPage = filters[key].page

    if (page != oldPage) {
      let newArr = [...filters]   
      newArr[key] = {...newArr[key] ,page:page };
      
      dispatch(setFilterParams({
        filters:newArr,
        changed:key
      })) 
      
    }
  };


  useEffect(()=>{

    if(changed >= 0){
      if(selectedProperty){
        dispatch(ListutilityThunk(selectedProperty.id, filters[changed]));
      }
      dispatch(setFilterParams({
        filters:filters,
        changed:-1
      }))
    }
   
  },[changed])

  
  const t_accordion = (type,key) => {
    if(! utility[type] ){
      dispatch(ListutilityThunk(selectedProperty.id, {'type':type}));
    }
    let newArr = [...accordState]; 
    newArr[key] = !newArr[key]; // 
    setAccordState(newArr)
  };

  useEffect(()=>{
    dispatch(setSelectedImageFiles([]))
    dispatch(resetMessages())
  },[])

  useEffect(()=>{
    if(!isEdit){
      dispatch(reloadStateImage())
    }
  },[isEdit])

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={btnLoading}
      />

      <UpdateBillModal
        edited={isEdit}
        types={types}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      />
      {SpacesViewModalComp}
      <Container fluid>
        <BreadCrumb title="Utilities" pageTitle="Property Workbook" />

        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">New/List/View/Edit</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap"></div>
                  </div>
                  <div>
                    <div className="py-3">
                      <CreateBillForm onSuccess={onCreateSuccess} />
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Accordion
                    className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-success"
                    id="accordionBordered"
                  >
                    {dataSrcs?.map((src, key) => (
                      <AccordionItem style={{borderColor:colors[src.name]}} > 
                      
                        <h2
                          className="accordion-header"
                          style={{backgroundColor:'#FFFACD'}}
                          // id="accordionborderedExample3" #FFFACD
                        >
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !accordState[key],
                            })}
                            type="button"
                            onClick={() => t_accordion(src.name, key)}
                            style={{ cursor: "pointer" , color:'#050a30' }}
                          >
                            {src.name}
                          </button>
                        </h2>
                        <Collapse
                          isOpen={accordState[key]}
                          className="accordion-collapse"
                          // id="accor_borderedExamplecollapse3"
                        >
                          <div className="accordion-body">
                            {loading[src.name] ? (
                              <Loader />
                            ) : (
                              <TableContainer
                                columns={columns}
                                data={utility[src.name] || []}
                                divClass="table-responsive table-card mb-1"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted text-uppercase"
                                SearchPlaceholder="Search for order ID, customer, order status or something..."
                                onPageChange={(page) => changePage(page,src.name,key)}
                                numOfPages={numOfPages[src.name] || 1}
                                currentPage={currentPage[src.name] || 1 }
                                pageSize={pageSize[src.name] || 0}
                                total={numOfCaptures[src.name] || 0}
                              />
                            )}
                          </div>
                        </Collapse>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UtilityUpload;
