import React from 'react';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { Container } from 'reactstrap';

import List from './List';

const PropertyList = () => {
    document.title = "Properties List | DwellDoc";
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Property List" pageTitle="Properties" />
                    <List />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PropertyList;