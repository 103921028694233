import { setSuccess } from "./reducer"
import { getActUser } from "../../../helpers/backend_helper";
import { loadingActions } from "../../loading/reducer";

export const getActivateUser = (uid,token) => async (dispatch) => {
    
    dispatch(loadingActions.setLoading(true));
    await getActUser(uid,token)
    .then(()=> dispatch(setSuccess(true)))
    .catch(()=> dispatch(setSuccess(false)))
    .finally(()=> dispatch(loadingActions.setLoading(false)))
    
}
