module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://devapi.mattercost.com/api",
    // API_URL: "https://devapi.dwelldoc.app/api",
    API_URL: "https://api.dwelldoc.app/api",
    // API_URL: "https://api.mattercost.com/api",
    // API_URL: "http://localhost:8000/api",
  }
};