import { createSlice } from "@reduxjs/toolkit";
import { publish } from "../../../events";

export const initialState = {
  error: null,
  success: null,
  images:null,
  selected:null,
  count: null,
  page_size:null,
  total_pages: null,
  loading:false,
  btnLoading:false
};

const imageslice = createSlice({
  name: "image",
  initialState,
  reducers: { 
    setimages(state,action){
      state.images = action.payload.results
      state.count = action.payload.count
      state.total_pages = action.payload.total_pages
      state.page_size = action.payload.page_size
      state.page_num = action.payload.page_num
      state.loading = false
    },
    setIsLoading(state,action){
        state.loading = true
    },
    setBtnLoading(state,action){
      state.btnLoading = action.payload;
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    createFailure(state,action){
      state.error = action.payload;
      state.btnLoading = false;
    },
    imageFailure(state,action){
      state.error = action.payload;
      state.loading = false;
    },
    setSelected(state,action){
      state.selected = action.payload
    },
    createSucess(state,action){
      state.success = "Created Successfully"
      state.btnLoading = false;
    },
    deleteSuccess(state,action){
      state.success = "Deleted Successfully"
      state.images = state.images.filter((image)=> image.id != action.payload)
      state.loading = false;
      state.count -= 1
    }
  }
});


export const {
  setIsLoading,
  setimages,
  resetMessages,
  imageFailure,
  setSelected,
  deleteSuccess,
  createSucess,
  setBtnLoading,
  createFailure
} = imageslice.actions;

export default imageslice.reducer;