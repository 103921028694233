import { useMemo } from "react";
import { Card, CardBody, Progress, Spinner } from "reactstrap";

const ErrMsgComponent = ({ appended, error }) => {
  if (error instanceof Array) {
    const out = error.map((err) => (
      <ErrMsgComponent appended={appended} error={err} />
    ));
    return <>{out}</>;
  } else if (error instanceof Object) {
    const keys = Object.keys(error);
    const out = keys.map((key) => (
      <ErrMsgComponent
        appended={appended ? appended + " : " + key : key}
        error={error[key]}
      />
    ));
    return <>{out}</>;
  } else {
    return (
      <h6 className="mt-1">
        <b>{appended +" : " } </b>
        {error}
      </h6>
    );
  }
};

export const FileProgressBar = ({ error, finished, value, title }) => {
  const className = useMemo(
    () => (error.err ? "danger" : finished ? "success" : "secondary"),
    [finished, error]
  );

  return (
    <div className="file-progress-bar">
      <Card className="card-progress-bar bg-light overflow-hidden shadow-none">
        <CardBody>
          <div className="d-flex">
            <div className="flex-grow-1">
              {error.err ? (
                <>
                  <h6 className="mb-0">
                    <b className={`text-${className}`}>
                      Something went wrong, please try again
                    </b>
                  </h6>
                  <ErrMsgComponent error={error.msg} appended={""} />
                </>
              ) : !finished && value === 100 ? (
                <>
                  <h6 className="mb-0">
                    <b className={`text-${className}`}>Processing File </b>
                  </h6>
                </>
              ) : (
                <h6 className="mb-0">
                  <b className={`text-${className}`}>{value}%</b>{" "}
                  {value === 100
                    ? "Uploaded successfully"
                    : "Upload in progress..."}
                </h6>
              )}
            </div>
            <div className="flex-shrink-0">
              <h6 className="mb-0">{title}</h6>
            </div>
          </div>
        </CardBody>
        <div className="progress-bar-div">
          <Progress
            value={value}
            color={className}
            className={`bg-soft-${className} rounded-0`}
          />
        </div>
      </Card>
    </div>
  );
};
