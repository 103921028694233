
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { resetLogout } from '../../../slices/auth/login/reducer';
import { useSearchParams } from 'react-router-dom';

import AuthSlider from '../authCarousel';
import CoverSuccessMsg from '../SuccessMessage/CoverSuccessMsg';
import { getActivateUser } from '../../../slices/thunks';

const ActivateEmail = () => {
    document.title="Activate Email | DwellDoc";
    const dispatch = useDispatch()

    const Success = useSelector((state)=> state.activateEmail.Success)

    const [searchParams, setSearchParams] = useSearchParams();

    const uid = searchParams.get('uid')        
    const token = searchParams.get('token')

    useEffect(()=>{
        if(uid && token){
            dispatch(getActivateUser(uid,token))
        }
    },[searchParams])

    if(Success){
        return (
            <CoverSuccessMsg success={true} msg={"Your account is now activated successfully"} redirectLink={'/signIn'} redirectMsg={'Login'} />          
        ) 
    }else{
        return (
            <CoverSuccessMsg success={false} msg={"Some error happens, please try again"} redirectLink={'/signIn'} redirectMsg={'Login'} />
        )
    }

}

export default ActivateEmail;