
export const FeedbackButton = ({onClick}) => {

    return <div className="customizer-setting d-none d-md-block">
            <div
            onClick={onClick}
            className="btn-info btn-rounded shadow-lg btn btn-icon btn-lg p-2"
            >
            <i className="ri-chat-smile-3-fill fs-22"></i>
            </div>
        </div>
} 