
import {  setIsLoading , setreports , reportFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { ReportsGet , ReportDelete } from "../../../helpers/backend_helper";

export const ListreportsThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    ReportsGet(pid,params)
    .then((res)=> dispatch(setreports(res)))
    .catch((err) => dispatch(reportFailure(err)))

}

export const DeletereportThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    ReportDelete(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(reportFailure(err)))
    .finally(()=> closeModal())
}


