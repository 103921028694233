import React, { useState } from "react";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FilesTableSelect from "./imagesTable";
import { UploadImageComponent } from "../../../pages/property/PropertyFiles/upload/createImageModal";
import { useDispatch } from "react-redux";

export const SelectFilesModal = ({
  show,
  toggle,
  multiple = true,
  ...props
}) => {
  const [verticalTab, setverticalTab] = useState("1");
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setverticalTab(tab);
    }
  };
  const dispatch = useDispatch();
  const onCreateSuccess = () => {
    dispatch(props.setRefreshDropdown(true));
  };

  return (
    <Modal size="xl" isOpen={show} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select File(s)</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={3}>
            <Nav pills className="flex-column" id="v-pills-tab">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalTab === "1",
                  })}
                  onClick={() => {
                    toggleVertical("1");
                  }}
                  id="v-pills-home-tab"
                >
                  Choose From My Files
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    "mb-2": true,
                    active: verticalTab === "2",
                  })}
                  onClick={() => {
                    toggleVertical("2");
                  }}
                  id="v-pills-profile-tab"
                >
                  Upload From Your Local Device
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col lg={9}>
            <TabContent
              activeTab={verticalTab}
              className="text-muted mt-4 mt-lg-0"
              id="v-pills-tabContent"
            >
              <TabPane tabId="1" id="v-pills-home">
                <FilesTableSelect
                  multiple={multiple}
                  {...props}
                  refresh={verticalTab === "2"}
                />
              </TabPane>
              <TabPane tabId="2" id="v-pills-profile">
                <UploadImageComponent onSuccess={onCreateSuccess} />
                {/* <div className="flex-grow-1 ms-3">
                  <p className="mb-0">Upload from gallery</p>
                </div> */}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export function SelectFilesWithoutModal({
  show,
  toggle,
  multiple = true,
  ...props
}) {
  const [verticalTab, setverticalTab] = useState("1");
  const toggleVertical = (tab) => {
    if (verticalTab !== tab) {
      setverticalTab(tab);
    }
  };
  const dispatch = useDispatch();
  const onCreateSuccess = () => {
    dispatch(props.setRefreshDropdown(true));
  };
  return (
    <Row>
      <Col lg={3}>
        <Nav pills className="flex-column" id="v-pills-tab">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                "mb-2": true,
                active: verticalTab === "1",
              })}
              onClick={() => {
                toggleVertical("1");
              }}
              id="v-pills-home-tab"
            >
              Choose From My Files
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                "mb-2": true,
                active: verticalTab === "2",
              })}
              onClick={() => {
                toggleVertical("2");
              }}
              id="v-pills-profile-tab"
            >
              Upload From Your Local Device
            </NavLink>
          </NavItem>
        </Nav>
      </Col>
      <Col lg={9}>
        <TabContent
          activeTab={verticalTab}
          className="text-muted mt-4 mt-lg-0"
          id="v-pills-tabContent"
        >
          <TabPane tabId="1" id="v-pills-home">
            <FilesTableSelect
              multiple={multiple}
              {...props}
              refresh={verticalTab === "2"}
            />
          </TabPane>
          <TabPane tabId="2" id="v-pills-profile">
            <UploadImageComponent onSuccess={onCreateSuccess} />
            {/* <div className="flex-grow-1 ms-3">
                  <p className="mb-0">Upload from gallery</p>
                </div> */}
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
}
