import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  success: null,
  btnloading:false,
  propertyID: null,
};

const createPropertySlice = createSlice({
  name: "createProperty",
  initialState,
  reducers: { 
    createProductSuccess(state,action){
        state.btnloading = false
        state.success = "Your property is created successfully"
        state.propertyID = action.payload.id
    },
    createProductFailure(state,action){
        state.btnloading = false
        state.error = action.payload
    },
    setIsLoading(state,action){
        state.btnloading = true
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    resetState(state,action){
      state.error = null;
      state.success = null;
      state.btnloading = false;
      state.propertyID = null;
    }
  }
});


export const {
  setIsLoading,
  createProductFailure,
  createProductSuccess,
  resetMessages,
  resetState
} = createPropertySlice.actions;

export default createPropertySlice.reducer;