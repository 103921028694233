
import {  setIsLoading , setappliance , applianceFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getAppliance, createAppliance, deleteAppliance } from "../../../helpers/backend_helper";

import { updateAppliance } from "../../../helpers/backend_helper";

export const ListApplianceThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getAppliance(pid,params)
    .then((res)=> dispatch(setappliance(res)))
    .catch((err) => dispatch(applianceFailure(err)))

}

export const CreateapplianceThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createAppliance(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateApplianceThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateAppliance(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteapplianceThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteAppliance(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(applianceFailure(err)))
    .finally(()=> closeModal())
}

// export const DeletefloorThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
//     dispatch(setIsLoading())
//     deletefloor(cid)
//     .then((res)=> dispatch(deleteSuccess(cid)))
//     .catch((err)=> dispatch(floorFailure(err)))
//     .finally(()=> closeModal())
// }