import { useEffect, useMemo, useState } from "react";
import {
  getAllPropertyType,
  getAllPropertyUse,
} from "../../helpers/backend_helper";
import { Label } from "reactstrap";

export const usePropertyType = () => {
  const [propertyType, setpropertyType] = useState([]);
  // const [selectedpropertyType, setSelectedpropertyType] = useState("");

  const [propertyUse, setPropertUse] = useState([]);
  // const [selectedPropertyUse, setSelectedUse] = useState("");
  const [state,setState] = useState({
    use:"",
    type:""
  })

  useEffect(() => {
    getAllPropertyUse()
      .then((res) => setPropertUse(res))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
      getAllPropertyType()
        .then((res) => setpropertyType(res))
        .catch((err) => console.log(err));
  }, []);

  const setUse = (val) => {
    setState({
      use:val,
      type:""
    })
  }

  const setType = (val) => {
    setState((oldstate) => ({
      ...oldstate,
      type:val
    })
    )
  }

  const getState = () => {
    return state
  }

  const types = useMemo(()=>{
    return propertyType.filter((type) => type.use == state.use )
  }
  ,[state.use,propertyType])

  return [
    <>
        <div className="mb-3">
          <Label htmlFor="choices-use-input" className="form-label">
            Used For
          </Label>
          <select
            className="form-select"
            data-choices
            data-choices-search-false
            id="choices-use-input"
            value={state.use}
            onChange={(e) => setUse(e.target.value)}
          >
             <option disabled value="">
                Select Property Use
            </option>
            {propertyUse?.map((use) => (
              <option key={use.id} value={use.id}>
                {use.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
        <Label htmlFor="choices-type-input" className="form-label">
          Propert Type
        </Label>
        <select
          className="form-select"
          data-choices
          data-choices-search-false
          id="choices-type-input"
          value={state.type}
          onChange={(e) => setType(e.target.value)}
        >
            <option disabled value="">
                Select Property Type
            </option>
          {types?.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </div>
    </>,
    setState,
    getState
  ];
};
