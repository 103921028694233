export function RefreshButton({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="btn btn-light waves-effect"
    >
      <i class="mdi mdi-refresh fs-16"></i>
    </button>
  );
}
