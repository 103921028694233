
import { unSelectFile } from "../../../slices/property/propertyFilesReciepts/reducer";
import React from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  ListfilesThunk,
} from "../../../slices/property/propertyFilesReciepts/thunk";
import { selectAll, toggleFile, unSelectAll } from "../../../slices/property/propertyFilesReciepts/reducer";
import { setRefreshDropdown } from "../../../slices/property/propertyFiles/reducer";
import { SelectFilesModal } from "./SelectFilesModal";



export const RecieptInputModal = ({show,toggle}) => {
    const data = useSelector((state) => ({
        images: state.propertyFilesReciepts.files,
        numOfPages: state.propertyFilesReciepts.total_pages,
        numOfCaptures: state.propertyFilesReciepts.count,
        pageSize: state.propertyFilesReciepts.page_size,
        currentPage: state.propertyFilesReciepts.page_num,
        selectedProperty: state.PropertiesDropdown.selected,
        loading: state.propertyFilesReciepts.loading,
        error: state.propertyFilesReciepts.error,
        success: state.propertyFilesReciepts.success,
        refreshDrop: state.files.refreshDropdown,
        allChecked: state.propertyFilesReciepts.allChecked,
        selectedFiles: state.propertyFilesReciepts.selectedFiles,
      }));

      const functions = {
        unSelectFile,
        selectAll,
        toggleFile,
        unSelectAll,
        ListfilesThunk,
        setRefreshDropdown,
      }
      
    return <SelectFilesModal {...functions} {...data} show={show} toggle={toggle}/>

}