import { userForgetPasswordSuccess, userForgetPasswordError , setLoading } from "./reducer"
import { api } from "../../../config";

export const userForgetPassword = (email) => async (dispatch) => {
    
    dispatch(setLoading());
    
    const obj = {
        email:email,
    }
    
    await fetch(api.API_URL+'/user/restPassword/',{
        method:'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify(obj)
    })
    .then(async (res) =>{
        if(res.ok){
            dispatch(userForgetPasswordSuccess("Reset password email is sent to you")) 
        }
        else{
            throw await res.json();  
        }
    }).catch((err)=>{
        dispatch(userForgetPasswordError(err))
    })
    
}