// External
import styled from "styled-components";
// Components
import Emoji from "./Emoji";
import { useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
// import { Flex } from "../../styles/globalStyles";
// Reactions array
// import { reactions } from "./data";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { submitFeedback } from "../../../helpers/backend_helper";
import { useDispatch } from "react-redux";
import { hideFeedback } from "../../../slices/auth/login/reducer";

const reactions = ["Awful", "Bad", "Ok", "Good", "Amazing"];

const EmojiFeedback = ({ isOpen, toggle }) => {
  const [activeReaction, setActiveReaction] = useState("");
  const [comment, setComment] = useState("");
  const [isloading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch()

  function getData() {
    return {
      reaction: activeReaction,
      comment: comment,
    };
  }

  function handleClick() {
    if (!activeReaction) {
      toast.error("You need to select your feedback");
    }
    setLoading(true);
    submitFeedback(getData())
      .then(() => {
        toast.success("Thank You For Submiting Your Feedback, We really appreciate your time.")
        dispatch(hideFeedback())
      })
      .catch((err) => toast.error(err.message));
  }

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      {success ? (
        <ModalBody className='text-center p-4'>
          <div className="text-end">
            <button
              type="button"
              onClick={toggle}
              className="btn-close text-end"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div>
            <i style={{fontSize:"100px"}} className="ri-checkbox-circle-line text-success" ></i>
            <h4 className="mb-3 mt-1">
              Thank You For Submiting Your Feedback
            </h4>
            <p className="text-muted fs-15 mb-4">
              We really appreciate your time.
            </p>
          </div>
        </ModalBody>
      ) : (
        <>
          <ModalHeader toggle={toggle}>How was your experience?</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs={1}></Col>
              {reactions.map((reaction) => (
                <Emoji
                  reaction={reaction}
                  key={reaction}
                  isSelected={activeReaction === reaction}
                  setActiveReaction={setActiveReaction}
                />
              ))}
              <Col xs={1}></Col>
            </Row>
            <p className="text-center text-muted fs-17 mt-3">
              &nbsp; {activeReaction}{" "}
            </p>
            <div className="my-3">
              <Label className="form-label" htmlFor="matterport-capture-uri">
                Comment (optional)
              </Label>
              <Row>
                <div className="input-group">
                  <textarea
                    type="text"
                    rows={5}
                    className="form-control"
                    id="comment"
                    name="comment"
                    placeholder="Additional Comments"
                    aria-describedby="button-addon2"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </Row>
            </div>
            <div className="d-flex align-items-start gap-3 mt-4">
              <button
                type="button"
                className="btn btn-primary ms-auto"
                data-nexttab="pills-info-desc-tab"
                onClick={handleClick}
                disabled={isloading}
              >
                {isloading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Submit Feedback
              </button>
            </div>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};
export default EmojiFeedback;
