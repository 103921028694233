import { useDispatch, useSelector } from "react-redux";
import { getInfo } from "../../slices/thunks";
import { useEffect } from "react";
import { resetAuthorization, setAuthorization } from "../../helpers/api_helper";

export const sessionStorage_transfer = function(event,sessionCallback) {
    if(!event) { event = window.event; } // ie suq
    if(!event.newValue) return;          // do nothing if no value to work with
    if (event.key == 'getSessionStorage') {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      // the other tab should now have it, so we're done with it.
      localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
      // another tab sent data <- get it
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
      sessionCallback(); // to change login state if user was logged in another tab
    }
};

export const useSessionStorageKeepMeLogin = () => {

    const dispatch = useDispatch()

    useEffect(()=>{
        const sessionToken = sessionStorage.getItem("userToken");
        const localStorageToken = localStorage.getItem("userToken");
        
        const sessionCallback = () => {
            const token = sessionStorage.getItem("userToken");
            if (token) {
                setAuthorization(sessionToken)
                dispatch(getInfo(token));
            }
        }

        if(localStorageToken){
            dispatch(getInfo(localStorageToken));
        }
        else if(sessionToken){
            setAuthorization(sessionToken)
            dispatch(getInfo(sessionToken));
        }
        else if (!sessionToken) {
          localStorage.setItem('getSessionStorage', 'foobar');
          localStorage.removeItem('getSessionStorage', 'foobar');
        } 
    
        function storageListener(event){
            sessionStorage_transfer(event,sessionCallback)
        }
      
        window.addEventListener("storage", storageListener,false);
        return (()=>{
            window.removeEventListener("storage", storageListener);
        })    
    
    },[])

    return null
}