
import {  setIsLoading , setFLOORING , FLOORINGFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getFLOORING, createFLOORING, deleteFLOORING } from "../../../helpers/backend_helper";

import { updateFLOORING } from "../../../helpers/backend_helper";

export const ListFLOORINGThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getFLOORING(pid,params)
    .then((res)=> dispatch(setFLOORING(res)))
    .catch((err) => dispatch(FLOORINGFailure(err)))

}

export const CreateFLOORINGThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createFLOORING(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateFLOORINGThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateFLOORING(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteFLOORINGThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteFLOORING(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(FLOORINGFailure(err)))
    .finally(()=> closeModal())
}