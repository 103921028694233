import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";

import classnames from "classnames";

import { CustomSelect } from "../../../Components/utils/select";

import { useAddress } from "./address";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

import LocationPicker from "react-location-picker";

import Dropzone from "react-dropzone";

import { Error } from "../../../Components/error";


// backend
import {
  getAllPropertyUse,
  getAllPropertyType,
} from "../../../helpers/backend_helper";

import { useSelector, useDispatch } from "react-redux";

import {
  resetMessages,
  createProductFailure,
  setIsLoading,
  resetState,
} from "../../../slices/property/createProperty/reducer";
import {
  PropertiesDropdownThunk,
  createPropertyThunk,
} from "../../../slices/thunks";
import { loadingActions } from "../../../slices/loading/reducer";
import axios from "axios";
//
// import cities from "../../../assets/data/cities.json";
// import zips from "../../../assets/data/zips.json";



const CreateProperty = ({zips , cities}) => {
  document.title = "Create Property | DwellDoc";

  // const countries = Object.keys(cities);

  const [formLoading, setformLoading] = useState(false);

  const [propertyNickName, setpropertyNickName] = useState("");

  const [propertyUse, setPropertUse] = useState(null);
  const [selectedPropertyUse, setSelectedUse] = useState("");

  const [aptUnit, setaptUnit] = useState("");

  const [addressComponents, getAddress, setAddressState] = useAddress({cities:cities});

  const [zipCode, setzipCode] = useState("");

  const [propertyType, setpropertyType] = useState(null);
  const [selectedpropertyType, setSelectedpropertyType] = useState("");

  const [currentPosition, setCurrentPosition] = useState("");

  const [pickedPosition, setpickedPosition] = useState("");

  const [address, setaddress] = useState("");

  const [matterportUri, setMatterportUri] = useState("");
  const [matterportUriView, setmatterportUriView] = useState(null);

  const [showLocation, setShowLocation] = useState(false);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  // Dropzone file upload
  const [selectedFiles, setselectedFiles] = useState([]);
  const dispatch = useDispatch();

  const { btnLoading, success, error, propertyID, user } = useSelector(
    (state) => ({
      btnLoading: state.createProperty.btnloading,
      success: state.createProperty.success,
      error: state.createProperty.error,
      propertyID: state.createProperty.propertyID,
      user: state.Login.user,
    })
  );

  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successfunc, errorfunc);
    } else {
      console.log("Geolocation not supported");
    }
  }

  function successfunc(position) {
    const lat = position.coords.latitude;
    const lng = position.coords.longitude;
    setCurrentPosition({ lat, lng });
    setpickedPosition({ lat, lng });
  }

  function errorfunc() {
    console.log("Unable to retrieve your location");
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        // preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles((oldfiles) => [...oldfiles, ...files]);
  }

  useEffect(() => {
    getAllPropertyUse()
      .then((res) => setPropertUse(res))
      .catch((err) => console.log(err));
    dispatch(resetState());
    toggleTab(1);
  }, []);

  useEffect(() => {
    if (selectedPropertyUse) {
      getAllPropertyType({ use: selectedPropertyUse })
        .then((res) => setpropertyType(res))
        .catch((err) => console.log(err));
    }
  }, [selectedPropertyUse]);

  useEffect(() => {
    if (propertyUse?.length > 0) {
      setSelectedUse(propertyUse[0].id);
    }
  }, [propertyUse]);

  useEffect(() => {
    if (propertyType?.length > 0) {
      setSelectedpropertyType(propertyType[0].id);
    }
  }, [propertyType]);

  useEffect(() => {
    if (error) {
      setTimeout(() => dispatch(resetMessages()), 10000);
    } else if (success) {
      toggleTab(3);
    }
  }, [success, error]);

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function zipfiles(callback) {
    const zip = require("jszip")();
    for (let file = 0; file < selectedFiles.length; file++) {
      zip.file(selectedFiles[file].name, selectedFiles[file]);
    }

    zip.generateAsync({ type: "blob" }).then(callback);
  }

  function getFormData(callback) {
    var formdata = new FormData();
    if (pickedPosition) {
      formdata.append(
        "mapsLocation",
        "https://maps.google.com/?q=" +
          (pickedPosition ? pickedPosition.lat + "," + pickedPosition.lng : "")
      );
    }

    formdata.append("address", address);
    formdata.append("propertyType", selectedpropertyType);

    // formdata.append("aptUnit", aptUnit);
    formdata.append("zipCode", zipCode);

    if (matterportUri) {
      formdata.append("matterportUri", matterportUri);
    }
    formdata.append("nickname", propertyNickName);

    const cityAddress = getAddress();

    if (cityAddress.hasState) {
      formdata.append(
        "city",
        cityAddress.city.cityName + ", " + cityAddress.state
      );
    } else {
      formdata.append("city", cityAddress.city.cityName);
    }

    if (selectedFiles) {
      if (selectedFiles.length == 1) {
        formdata.append("floorPlanFile", selectedFiles[0]);
        callback(formdata);
      } else {
        zipfiles((content) => {
          formdata.append("floorPlanFile", content);
          callback(formdata);
        });
      }
    }
    return formdata;
  }

  function handleClick() {
    dispatch(setIsLoading());
    var errs = [];
    var city = null;

    checkOrError(
      selectedpropertyType,
      errs,
      "you need to select property type"
    );

    checkOrError(zipCode, errs, "you need to add Zip Code for your property");

    checkOrError(address, errs, "you need to add address");

    checkOrError(
      propertyNickName,
      errs,
      "you need to add Name for your property"
    );

    try {
      getAddress();
    } catch (e) {
      errs.push(e);
    }

    if (errs.length > 0) {
      dispatch(createProductFailure(errs));
    } else {
      const onSuccess = () => dispatch(PropertiesDropdownThunk(user.id));
      getFormData((data) =>
        dispatch(createPropertyThunk(data, user.first_login, onSuccess))
      );
    }
  }

  // function onCityClick() {
  //   const { city } = getAddress();
  //   const position = city.coordinates;
  //   const obj = {
  //     lat: parseFloat(position.lat),
  //     lng: parseFloat(position.lon),
  //   };
  //   setCurrentPosition(obj);
  //   setpickedPosition(obj);
  //   setShowLocation(false);
  // }

  // function onCurrentLocationClick() {
  //   handleLocationClick();
  //   setShowLocation(false);
  // }

  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  function tab1Click() {
    var errs = [];
    var city = null;

    checkOrError(
      selectedpropertyType,
      errs,
      "you need to select property type"
    );

    checkOrError(address, errs, "you need to fill address field");

    checkOrError(
      propertyNickName,
      errs,
      "you need to add Name for your property"
    );

    checkOrError(zipCode, errs, "you need to add Zip Code for your property");

    // checkOrError(aptUnit, errs, "you need to add Apt/unit for your property");

    if (errs.length > 0) {
      dispatch(createProductFailure(errs));
    } else {
      toggleTab(2);
    }
  }

  function zipAutoRefill() {
    const zipdata = zips[zipCode];
    if (zipdata) {
      setAddressState(zipdata);
    } else {
      dispatch(
        createProductFailure(
          "We are sorry this Zip Code doesn't exists in our database. Please select your country, state, and city manually"
        )
      );
    }
  }

  useEffect(() => {
    setactiveTab(1);
  }, []);

  return (
    <React.Fragment>
      {/* <LocationModal
        onCityClick={onCityClick}
        onCurrentLocationClick={onCurrentLocationClick}
        onCloseClick={() => setShowLocation(false)}
        show={showLocation}
      /> */}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Create Property"
            pageTitle="Properties"
            pageLink={"/properties"}
          />
          <Row>
            <div className="p-2">
              {user.first_login && (
                <Alert color="info">
                  You need to create your first property to continue your
                  journey on the website
                </Alert>
              )}
              {success && success ? (
                <Alert color="success">{success}</Alert>
              ) : null}

              {error && error ? <Error error={error} /> : null}
            </div>

            <Col lg={12}>
              <Card>
                <CardBody className="checkout-tab">
                  <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                    <Nav
                      className="nav-pills nav-justified custom-nav"
                      role="tablist"
                    >
                      <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === 1,
                              done: activeTab <= 3 && activeTab >= 0,
                            },
                            "p-3 fs-15"
                          )}
                          onClick={() => {
                            toggleTab(1);
                          }}
                          disabled={
                            success || !(activeTab <= 3 && activeTab > 0)
                          }
                        >
                          <i
                            style={{ color: "red" }}
                            className="ri-alert-fill fs-16 p-2 bg-soft-primary rounded-circle align-middle me-2"
                          ></i>
                          Mandatory fields
                        </NavLink>
                      </NavItem>
                      {/* <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === 2,
                              done: activeTab <= 3 && activeTab > 1,
                            },
                            "p-3 fs-15"
                          )}
                          onClick={() => {
                            toggleTab(2);
                          }}
                          disabled={
                            success || !(activeTab <= 3 && activeTab > 1)
                          }
                        >
                          <i
                            style={{ color: "#cbcb48" }}
                            className="ri-alert-fill fs-16 p-2 bg-soft-primary rounded-circle align-middle me-2"
                          ></i>
                          Optional fields
                        </NavLink>
                      </NavItem> */}
                      <NavItem role="presentation">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === 3,
                              done: activeTab <= 3 && activeTab > 2,
                            },
                            "p-3 fs-15"
                          )}
                          onClick={() => {
                            toggleTab(3);
                          }}
                          disabled={
                            success || !(activeTab <= 3 && activeTab > 2)
                          }
                        >
                          <i
                            style={{ color: "green" }}
                            className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary rounded-circle align-middle me-2"
                          ></i>
                          Finish
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId={1} id="pills-bill-info">
                      <Row>
                        <Col lg={4}>
                          <Card>
                            <CardHeader>
                              <h5 className="card-title mb-0">Property data</h5>
                            </CardHeader>
                            <CardBody>
                              <div className="mb-3">
                                <Label
                                  htmlFor="property-nickname"
                                  className="form-label"
                                >
                                  Property Name
                                </Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="property-nickname"
                                  name="nickname"
                                  placeholder="Property Name (to recognize it in your dashboard)"
                                  value={propertyNickName}
                                  onChange={(e) =>
                                    setpropertyNickName(e.target.value)
                                  }
                                />
                              </div>
                              {/* <div className="mb-3">
                                <Label
                                  htmlFor="property-apt"
                                  className="form-label"
                                >
                                  Apt/unit
                                </Label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="property-apt"
                                  name="apt"
                                  placeholder="Apt/unit"
                                  value={aptUnit}
                                  onChange={(e) => setaptUnit(e.target.value)}
                                />
                              </div> */}
                            </CardBody>
                          </Card>

                          <div className="card">
                            <div className="card-header">
                              <h5 className="card-title mb-0">Property Tags</h5>
                            </div>
                            <CardBody>
                              <div className="mb-3">
                                <Label
                                  htmlFor="choices-use-input"
                                  className="form-label"
                                >
                                  Used For
                                </Label>
                                <select
                                  className="form-select"
                                  data-choices
                                  data-choices-search-false
                                  id="choices-use-input"
                                  value={selectedPropertyUse}
                                  onChange={(e) =>
                                    setSelectedUse(e.target.value)
                                  }
                                >
                                  {propertyUse?.map((use) => (
                                    <option key={use.id} value={use.id}>
                                      {use.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div className="mb-3">
                                <Label
                                  htmlFor="choices-type-input"
                                  className="form-label"
                                >
                                  Propert Type
                                </Label>
                                <select
                                  className="form-select"
                                  data-choices
                                  data-choices-search-false
                                  id="choices-type-input"
                                  value={selectedpropertyType}
                                  onChange={(e) =>
                                    setSelectedpropertyType(e.target.value)
                                  }
                                >
                                  {propertyType?.map((type) => (
                                    <option key={type.id} value={type.id}>
                                      {type.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </CardBody>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <Card>
                            <CardHeader>
                              <h5 className="card-title mb-0">
                                Property Location
                              </h5>
                            </CardHeader>
                            <CardBody>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="zipCode-link"
                                >
                                  Zip Code
                                </Label>
                                <Row>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="zipCode-link"
                                      name="zipCodeLink"
                                      placeholder="Your Property Zip Code"
                                      value={zipCode}
                                      onChange={(e) =>
                                        setzipCode(e.target.value)
                                      }
                                    />
                                    <button
                                      className="btn btn-outline-success"
                                      type="button"
                                      id="zipbtn"
                                      onClick={zipAutoRefill}
                                    >
                                      <i
                                        style={{ fontSize: "20px" }}
                                        className="bx bx-refresh"
                                      ></i>
                                    </button>

                                    <Tooltip
                                      isOpen={tooltipOpen}
                                      toggle={() =>
                                        setTooltipOpen(!tooltipOpen)
                                      }
                                      placement="top"
                                      target="zipbtn"
                                    >
                                      Auto fill city , state using this zipCode
                                    </Tooltip>
                                  </div>
                                </Row>
                              </div>

                              {addressComponents}
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="matterport-capture-uri"
                                >
                                  Address
                                </Label>
                                <Row>
                                  <div className="input-group">
                                    <textarea
                                      type="text"
                                      rows={3}
                                      className="form-control"
                                      id="address"
                                      name="address"
                                      placeholder="Your Property Address"
                                      aria-describedby="button-addon2"
                                      value={address}
                                      onChange={(e) =>
                                        setaddress(e.target.value)
                                      }
                                    />
                                  </div>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          {/* <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="pills-info-desc-tab"
                            onClick={tab1Click}
                            disabled={
                              formLoading || btnLoading || success || error
                                ? true
                                : false
                            }
                          >
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Go to more info
                          </button> */}
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="pills-info-desc-tab"
                            onClick={handleClick}
                            disabled={
                              formLoading || btnLoading || success || error
                                ? true
                                : false
                            }
                          >
                            {formLoading || btnLoading ? (
                              <Spinner size="sm" className="me-2">
                                Loading...
                              </Spinner>
                            ) : null}
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Create Property
                          </button>
                        </div>
                      </Row>
                    </TabPane>
                    {/* <TabPane tabId={2}>
                      <Row>
                        <Col lg={8}>
                          <Card>
                            <CardHeader>
                              <h5 className="card-title mb-0">
                                Property Location
                              </h5>
                            </CardHeader>
                            <CardBody>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="location-link"
                                >
                                  Location Link
                                </Label>
                                <Row>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="location-link"
                                      name="locationLink"
                                      placeholder="Property Location Link"
                                      readOnly
                                      disabled
                                      value={
                                        "https://maps.google.com/?q=" +
                                        (pickedPosition
                                          ? pickedPosition.lat +
                                            "," +
                                            pickedPosition.lng
                                          : "")
                                      }
                                    />
                                    <button
                                      className="btn btn-outline-success"
                                      type="button"
                                      id="button-addon2"
                                      onClick={() => setShowLocation(true)}
                                      // onClick={handleLocationClick}
                                      disabled={currentPosition ? true : false}
                                    >
                                      <i
                                        style={{ fontSize: "20px" }}
                                        className="bx bx-bx bx-map"
                                      ></i>
                                    </button>
                                  </div>
                                </Row>
                              </div>
                              {currentPosition ? (
                                <LocationPicker
                                  containerElement={
                                    <div
                                      style={{ width: "100%", height: "480px" }}
                                    />
                                  }
                                  mapElement={
                                    <div style={{ height: "480px" }} />
                                  }
                                  defaultPosition={currentPosition}
                                  onChange={({ position, address, places }) =>
                                    setpickedPosition({
                                      lat: position.lat,
                                      lng: position.lng,
                                    })
                                  }
                                  raduis={-1}
                                />
                              ) : (
                                <div
                                  style={{
                                    textAlign: "center",
                                    width: '"100%',
                                    height: "480px",
                                    backgroundColor: "black",
                                  }}
                                >
                                  <p
                                    style={{
                                      paddingTop: "50px",
                                      padding: "20px",
                                      fontWeight: 400,
                                      fontSize: "20px",
                                      textAlign: "center",
                                      color: "white",
                                    }}
                                  >
                                    press the location icon to start picking up
                                    your property location , you need to allow
                                    get current location permessions
                                  </p>
                                </div>
                              )}
                            </CardBody>
                          </Card> */}

                    {/* <Card>
                            <CardHeader>
                              <h5 className="card-title mb-0">
                                3D capture
                              </h5>
                            </CardHeader>
                            <CardBody>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="matterport-capture-uri"
                                >
                                  3D Capture URI
                                </Label>
                                <Row>
                                  <div className="input-group">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="matterport-capture-uri"
                                      name="matterport-capture-uri"
                                      placeholder="eg. https://my.matterport.com/show/?m=Y3Bx86zyty9"
                                      aria-describedby="button-addon2"
                                      value={matterportUri}
                                      onChange={(e) =>
                                        setMatterportUri(e.target.value)
                                      }
                                    />
                                    <button
                                      className="btn btn-outline-success"
                                      type="button"
                                      id="button-addon2"
                                      onClick={() => {
                                        setmatterportUriView(matterportUri);
                                      }}
                                    >
                                      Load
                                    </button>
                                  </div>
                                </Row>
                              </div>
                              {matterportUriView ? (
                                <iframe
                                  width="100%"
                                  height="480"
                                  src={matterportUriView}
                                  frameborder="0"
                                  allowfullscreen
                                  allow="xr-spatial-tracking"
                                ></iframe>
                              ) : (
                                <div
                                  style={{
                                    display: "block",
                                    width: "100%",
                                    height: "480px",
                                    backgroundColor: "black",
                                  }}
                                >
                                  &nbsp;
                                </div>
                              )}
                            </CardBody>
                          </Card> */}
                    {/* </Col>
                        <Col lg={4}>
                          <Card>
                            <CardHeader>
                              <h5 className="card-title mb-0">Floorplan</h5>
                            </CardHeader>
                            <CardBody>
                              <div>
                                <p className="text-muted">
                                  Add Floorplan files here.
                                </p>

                                <Dropzone
                                  onDrop={(acceptedFiles) => {
                                    handleAcceptedFiles(acceptedFiles);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone dz-clickable">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <div className="mb-3">
                                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                        </div>
                                        <h5>
                                          Drop file here or click to upload.
                                        </h5>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>

                                <ul
                                  className="list-unstyled mb-0"
                                  id="dropzone-preview"
                                >
                                  {selectedFiles.map((f, i) => {
                                    return (
                                      <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <i
                                                style={{ fontSize: "25px" }}
                                                className="ri-file-fill"
                                              ></i>
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {f.name}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {f.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    );
                                  })}
                                </ul>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Go Back
                          </button>
                          <button
                            type="button"
                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                            data-nexttab="pills-info-desc-tab"
                            onClick={handleClick}
                            disabled={
                              formLoading || btnLoading || success || error
                                ? true
                                : false
                            }
                          >
                            {formLoading || btnLoading ? (
                              <Spinner size="sm" className="me-2">
                                Loading...
                              </Spinner>
                            ) : null}
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            Create Property
                          </button>
                        </div>
                      </Row>
                    </TabPane> */}
                    <TabPane tabId={3} id="pills-finish">
                      <div className="text-center py-5">
                        <div className="mb-4">
                          <lord-icon
                            src="https://cdn.lordicon.com/lupuorrc.json"
                            trigger="loop"
                            colors="primary:#0ab39c,secondary:#405189"
                            style={{ width: "120px", height: "120px" }}
                          ></lord-icon>
                        </div>
                        <h5>
                          Thank you ! Your Property is Created Successfully !
                        </h5>
                        <h3 className="fw-semibold">
                          Property Link:{" "}
                          <Link
                            to={`/property/${propertyID}`}
                            className="text-decoration-underline"
                          >
                            {propertyID}
                          </Link>
                        </h3>
                      </div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProperty;


export const CreatePropertyWraper = () => {
  
  const [cities,setcities] = useState(null)
  const [zips,setzips] = useState(null)

  const dispatch = useDispatch();

  useEffect(()=>{
    const url = window.location.origin;
    dispatch(loadingActions.setLoading(true));
    Promise.all([
      axios.get(url+'/cities.json'),
      axios.get(url+'/zips.json'),
    ]).then((res) => {
      setcities(res[0]);
      setzips(res[1]);
    });
  },[])

  useEffect(()=>{
    if(cities && zips){
      dispatch(loadingActions.setLoading(false));
    }
  },[cities,zips])

  return cities && zips ? <CreateProperty zips={zips} cities={cities} /> :<div></div>
}