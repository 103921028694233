import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import classnames from "classnames";
// import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";

// import {Error } from "../../../../Components"
// "../"
// Import Images

import { useParams } from "react-router-dom";
import { retrieveInsurance } from "../../../../helpers/backend_helper";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import UpdateInvModal from "../New";
import { useDispatch, useSelector } from "react-redux";
import { DeleteInsuranceThunk } from "../../../../slices/thunks";
import { PdfPreview } from "../../../../Components/Common/pdfPreview";
import { PrivateFileWraper, PrivateImageOrPdfWraper } from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

const InsuranceDetails = () => {
  document.title = "Insurance Details | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  let { id } = useParams();
  const [Insurance, setApliance] = useState({});

  // const isPdf = Insurance.insurancePolicy
  //   ? Insurance.insurancePolicy.split(".").pop().toUpperCase() === "PDF"
  //   : false;

  const navigate = useNavigate();

  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(true,"Policy Form",false)


  const { selectedProperty, loading, error, success } = useSelector(
    (state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.Insurance.loading,
      error: state.Insurance.error,
      success: state.Insurance.success,
    })
  );

  useEffect(() => {
    if (id) {
      retrieveInsurance(id).then((res) => setApliance(res));
    }
  }, [id]);

  useEffect(() => {
    if (Insurance.propertyy && selectedProperty.id !== Insurance.propertyy) {
      navigate("/Insurance");
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function onDeleteClick() {
    dispatch(
      DeleteInsuranceThunk(captureDelete, () => {
        navigate("/Insurance");
      })
    );
  }

  function OnUpdateSuccess() {
    if (id) {
      retrieveInsurance(id).then((res) => setApliance(res));
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  useEffect(()=>{
    if(Insurance.insurancePolicy){
      dispatch(setSelectedImageFiles([Insurance.insurancePolicy]))
    }else{
      dispatch(setSelectedImageFiles([]))
    }
    
  },[Insurance])

  useEffect(()=>{
    if(!isEdit){
      if(Insurance.insurancePolicy){
        dispatch(setSelectedImageFiles([Insurance.insurancePolicy]))
      }else{
        dispatch(setSelectedImageFiles([]))
      }
    }
  },[isEdit])

  return (
    <React.Fragment>
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateInvModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />

      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Insurance" pageTitle="Property Workbook" />
          <Card>
            <CardBody>
              <Row className="g-4">
                <Col lg={4}>
                  <div className="sticky-side-div">
                    {/* <Card className="ribbon-box border shadow-none right">
                      {Insurance.insurancePolicy ?
                       <PrivateFileWraper id={Insurance.insurancePolicy} module={"insurancePhoto"} ViewComponent={PrivateImageOrPdfWraper}/>:<></>}
                      
                    </Card> */}
                    {ImageViewerComponent}
                  </div>
                </Col>
                <Col lg={8}>
                  <div>
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn btn-ghost-primary btn-icon"
                        role="button"
                      >
                        <i className="ri-more-fill align-middle fs-16"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="#" className="view-item-btn">
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                          View
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => setIsEdit(Insurance)}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setCaptureDelete(id)}
                          className="remove-item-btn"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <h4>
                      {Insurance.name} - {Insurance.policyForm}
                    </h4>
                    <div className="hstack gap-3 flex-wrap">
                      <div className="text-muted">
                        Policy Start Date :{" "}
                        <span to="" className="text-custom fw-medium">
                          {Insurance.startDate}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Policy End Date :{" "}
                        <span className="text-custom fw-medium">
                          {Insurance.endDate}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Policy Renewal Date :{" "}
                        <span className="text-custom fw-medium">
                          {Insurance.renewalDate}
                        </span>
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Annual Premium
                            </p>
                            <h5 className="fs-17 mb-0">
                              {Insurance.annualPremium}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Dwelling Coverage
                            </p>
                            <h5 className="fs-17 mb-0">
                              {Insurance.dwellingCoverage}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Personal Property Coverage
                            </p>
                            <h5 className="fs-17 mb-0">
                              {Insurance.personalPropertyCoverage}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Liability Coverage
                            </p>
                            <h5 className="fs-17 mb-0">
                              {Insurance.liabilityCoverage}
                            </h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Additional Structure Coverage :</h5>
                      <p>{Insurance.additionalStructureCoverage}</p>
                    </div>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Deductible :</h5>
                      <p>
                        {Insurance.deductibleType} -{" "}
                        {Insurance.deductibleType === "Fixed Dollar"
                          ? "$ " + Insurance.deductibleAmount
                          : Insurance.deductibleType === "Percentage"
                          ? Insurance.deductibleAmount + " %"
                          : Insurance.deductibleAmount}
                      </p>
                    </div>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Special Deductible :</h5>
                      <p>{Insurance.specialDeductible}</p>
                    </div>
                    {Insurance.notes ? (
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Notes :</h5>
                        <p>{Insurance.notes}</p>
                      </div>
                    ) : null}

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">Service Provider :</h5>

                      <Nav tabs className="nav-tabs-custom nav-success">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            Installation Information
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={customActiveTab}
                        className="border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <TabPane id="nav-speci" tabId="1">
                          <div>
                            <p className="mb-2">
                              <i
                                style={{ color: "#299CDB", fontSize: "30px" }}
                                className="mdi mdi-circle-medium me-1 align-middle"
                              ></i>{" "}
                              {Insurance.installationDate}
                            </p>
                            <h5 className="font-size-16 mb-3">
                              Installation Contractor
                            </h5>
                            {Insurance.installationContractor && (
                              <p>{Insurance.installationContractor}</p>
                            )}
                            <div></div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InsuranceDetails;
