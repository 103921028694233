
import {  setIsLoading , createProductSuccess , createProductFailure  } from "./reducer";

import { createProperty } from "../../../helpers/backend_helper";

import { set_first_login } from "../../auth/login/reducer";

export const createPropertyThunk = (data , first_login , onSuccess=()=>{}) => async (dispatch) => {

    dispatch(setIsLoading())

    createProperty(data)
    .then((res)=> {
        dispatch(createProductSuccess(res))
        if(first_login){
            dispatch(set_first_login())
        }
        onSuccess()
    })
    .catch((err) => dispatch(createProductFailure(err.message)))

}