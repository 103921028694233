import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    galleryLoading : false,
    gallerySuccessMessage: null,
    galleryErrorMessage: null
}

const gallerySlice = createSlice({
    name:'gallery',
    initialState:{isLoading:false},
    reducers:{
        setGalleryLoading(state,action){
            state.galleryLoading = action.payload
        },
        setGallerySuccessMessage(state,action){
            state.galleryLoading = false 
            state.gallerySuccessMessage = action.payload
            state.galleryErrorMessage = null
        },
        setGalleryErrorMessage(state,action){
            state.galleryLoading = false 
            state.galleryErrorMessage = action.payload
            state.gallerySuccessMessage = null
        },
    }
})

const galleryReducer = gallerySlice.reducer; 

export default galleryReducer;

export const galleryActions = gallerySlice.actions;