
import {  setIsLoading , setsupport , supportFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import {  SupportCasesGet } from "../../helpers/backend_helper";

export const ListsupportThunk = (params) => async (dispatch) => {

    dispatch(setIsLoading())

    SupportCasesGet(params)
    .then((res)=> dispatch(setsupport(res)))
    .catch((err) => dispatch(supportFailure(err)))

}

// export const CreatesupportThunk = (pid,data,onSuccess) => async (dispatch) => {
//     dispatch(setBtnLoading(true))

//     createsupport(pid,data)
//     .then((res) => {
//         dispatch(createSucess()) 
//         onSuccess()
//     })
//     .catch((err)=> dispatch(createFailure(err)))
// }

// export const UpdatesupportThunk = (pk,data,onSuccess) =>  async (dispatch) => {
//     dispatch(setBtnLoading(true))

//     updatesupport(pk,data)
//     .then((res) => {
//         dispatch(createSucess("Updated successfully")) 
//         onSuccess()
//     })
//     .catch((err)=> dispatch(createFailure(err)))

// }

// export const DeletesupportThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
//     dispatch(setIsLoading())
    
//     deletesupport(fid)
//     .then((res)=> dispatch(deleteSuccess(fid)))
//     .catch((err)=> dispatch(supportFailure(err)))
//     .finally(()=> closeModal())
// }

// export const DeletefloorThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
//     dispatch(setIsLoading())
//     deletefloor(cid)
//     .then((res)=> dispatch(deleteSuccess(cid)))
//     .catch((err)=> dispatch(floorFailure(err)))
//     .finally(()=> closeModal())
// }