import {
  setIsLoading,
  editProductFailure,
  editProductSuccess,
  resetMessages,
  deleteGallerySuccess,
  deleteGalleryError,
  setProperty,
  setGallery,
  setGalleryLoading,
  setGalleryErr,
  resetIsLoading,
} from "./reducer";

import {
  EditProperty,
  PropertyReportCreate,
  createProperty,
  deleteGallery,
  getGallery,
  getProperty,
} from "../../../helpers/backend_helper";
import { toastError } from "../../../Components/error";
import { toast } from "react-toastify";

export const getGalleryThunk = (pid) => async (dispatch) => {
  dispatch(setGalleryLoading());

  getGallery(pid)
    .then((res) => dispatch(setGallery(res)))
    .catch((err) => dispatch(setGalleryErr(err)));
};

export const getPropertyThunk = (pid) => async (dispatch) => {
  dispatch(setIsLoading());

  getProperty(pid)
    .then((res) => dispatch(setProperty(res)))
    .catch((err) => dispatch(editProductFailure(err)));
};

export const EditPropertyThunk =
  (pk, data, onSuccess = null) =>
  async (dispatch) => {
    dispatch(setIsLoading());

    EditProperty(pk, data)
      .then((res) => {
        dispatch(
          editProductSuccess({
            msg: "Your property is updated successfully",
            newInst: res,
          })
        );
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((err) => dispatch(editProductFailure(err.message)));
  };
export const deleteGalleryThunk = (id) => async (dispatch) => {
  dispatch(setGalleryLoading());

  deleteGallery(id)
    .then((res) => dispatch(deleteGallerySuccess(id)))
    .catch((res) => dispatch(deleteGalleryError()));

};

export const requestReportThunk = (id) => async (dispatch) => {
  dispatch(setIsLoading());


  PropertyReportCreate(id)
    .then((res) =>
      toast.info(
        "Your report is now being created. You can find it in reports Section."
      )
    )
    .catch((err) => toastError(err))
    .finally(() => dispatch(resetIsLoading()));
};
