import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const FileSelectModal = ({ isOpen, toggle, files, setFiles }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size="lg"
      className="border-0"
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle}>Select Files</ModalHeader>
      <ModalBody>
        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          labelIdle={"Upload Your File(s): Drag here or Click to browse"}
          name="files"
          className="filepond filepond-input-multiple"
        />
      </ModalBody>
    </Modal>
  );
};

export const useButtonFileUpload = (loading) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);

  function toggle() {
    setShowModal(!showModal);
  }

  function getFiles() {
    return selectedFiles;
  }

  return [
    <>
      <button
        disabled={loading}
        onClick={toggle}
        type="button"
        className="btn btn-light"
        data-bs-dismiss="modal"
      >
        {loading ? (
          <Spinner size="sm" className="me-2">
            Loading...
          </Spinner>
        ) : null}
        Attach Files
      </button>
      <span style={{ marginLeft: "5px" }} className="text-muted fs-14">
        {selectedFiles.length} selected file(s)
      </span>
    </>,
    <FileSelectModal
      isOpen={showModal}
      toggle={toggle}
      files={selectedFiles}
      setFiles={setSelectedFiles}
    />,
    getFiles,
  ];
};
