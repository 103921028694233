import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getFinishesTypes,
  getFinishesPlacementTypes,
  getInstallationTypes,
  // getFinishesplacementTypes,
  // retrieveFinishes,
  editRetrieveFinishes,
  getNotesBE,
  RetrieveNoteCategoriesBE,
  retrieveNoteBE,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFinishesThunk,
  CreatenotesThunk,
  ListFinishesThunk,
  ListnotesThunk,
  UpdateFinishesThunk,
  UpdatenotesThunk,
} from "../../../../slices/thunks";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FinishesFailure,
  createSucess,
} from "../../../../slices/property/finishes/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { get_spaces } from "../../../../Components/utils/spacesCompare";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";
import { useCategoryTags } from "../../../../Components/Hooks/useCategoryTags";
import {
  get_date_time_from_utc_str,
  get_date_tz,
} from "../../../../Components/utils/get_date";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const CreateForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
  className = null
}) => {
  const [note, setNote] = useState(null);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [ImageModal, ImageViewerComponent, getImageVal] = useImageSelect();

  const [refresh, setRefresh] = useState(false);

  const [selectTagsDropdown, selectedTag, setTagVal, getTagVal] =
    useCategoryTags(
      refresh,
      RetrieveNoteCategoriesBE,
      "Search or Add your category"
    );

  useEffect(() => {
    if (update && id >= 0) {
      retrieveNoteBE(id).then((res) => setNote(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      note: note ? note.note || "" : "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("This field is required"),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // Finishes type

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.notes.btnLoading,
  }));

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(res)
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != note[key]) {
        formdata[key] = values[key];
      }
    }

    formdata["date"] = get_date_tz(date, time);

    const tag = getTagVal();

    formdata["category"] = tag;

    var imagesfiles = getImageVal();
    var [modifiedImages, Images] = getCreateAndDeleteFiles(
      note.images.map((inst) => inst.id),
      imagesfiles
    );
    if (modifiedImages) {
      formdata["images"] = Images;
    }

    const spaces = get_spaces(note.spaces, selectedSpaces);
    if (spaces) {
      formdata["spaces"] = [spaces.join(",")];
    }

    if (Object.keys(formdata).length === 0) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdatenotesThunk(id, formdata, onUpdateSucces));
    }
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val) {
        formdata.append(key, values[key]);
      }
    }

    var imagesfiles = getImageVal();
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }

    formdata.append("date", get_date_tz(date, time));

    if (selectedSpaces.length > 0) {
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    const tag = getTagVal();
    if (tag) {
      formdata.append("category", tag);
    }

    return formdata;
  }

  function onSubmit(values) {
    const onSuccess = () => {
      dispatch(ListnotesThunk(selectedProperty.id));
      setRefresh(!refresh);
    };
    dispatch(
      CreatenotesThunk(selectedProperty.id, getFormdata(values), onSuccess)
    );
  }

  useEffect(() => {
    if (note) {
      setSelectedSpaces(
        note.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      dispatch(setSelectedImageFiles(note.images));
      setTagVal(note.category);
      let dateobj = get_date_time_from_utc_str(note.date);
      setDate(dateobj.date);
      setTime(dateobj.time);
    }
  }, [note]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className={className? "needs-validation "+className : "needs-validation"}
      noValidate
      action="index"
    >
      {ImageModal}
      <Row>
        <Col lg={12}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="note-input"
              placeholder="Notes/Comments"
              name="note"
              value={validation.values.note}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.note && validation.touched.note ? true : false
              }
            />

            {validation.errors.note && validation.touched.note ? (
              <FormFeedback type="invalid">
                {validation.errors.note}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="my-2" lg={12}>
          {/* <div className="input-group my-2" style={{ padding: 0 }}> */}
          {ImageViewerComponent}
          {/* </div> */}
        </Col>

        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <Flatpickr
              value={date}
              className="form-control"
              onChange={(date, dateStr) => {
                setDate(dateStr);
              }}
              placeholder="Entry Date"
              style={{
                backgroundColor: "white",
                border: "1px solid lightgray",
              }}
              // disableMobile={true}
              options={{ disableMobile: true }}
            />
          </div>
        </Col>

        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <Flatpickr
              value={time}
              className="form-control"
              onChange={(date, dateStr) => {
                setTime(dateStr);
              }}
              placeholder="Entry Time"
              style={{
                backgroundColor: "white",
                border: "1px solid lightgray",
              }}
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
              }}
            />
          </div>
        </Col>

        <Col lg={3}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="my-2" style={{ padding: 0 }}>
            {selectTagsDropdown}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update Note</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
