import {createSlice} from '@reduxjs/toolkit';

const sideNavSlice = createSlice({
    name:'sideNav',
    initialState:{floors:[] , floorsState:[]},
    reducers:{
        set2Dfloors(state,action){
            state.floors = action.payload
            state.floorsState = state.floors.map((f) => false)
        },
        toggleFloor(state,action){
            state.floorsState[action.payload] = !state.floorsState[action.payload]
        }
    }
})

const loadingReducer = sideNavSlice.reducer; 

export default loadingReducer;

export const {
    set2Dfloors,
    toggleFloor
} = sideNavSlice.actions;