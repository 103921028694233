import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  GetLocation,
  retrieveCapture,
  updateLocationData,
} from "../../../../helpers/backend_helper";
import { TooltipItem } from "../../../../Components/Common/ToolTipItem";

import { captureDetailTooltip, capturetooltip } from "../../../../Components/constants/tooltips";
import { useNavigate, useParams } from "react-router-dom";

export const CaptureDetail = (props) => {
  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [capture,setCapture] = useState(null);

  document.title = "3D Capture Detail | DwellDoc";

  let {id} = useParams()

  const navigate = useNavigate();


  useEffect(() => {
    if (capture && selectedProperty.id !== capture.id) {
      navigate("/3dcapture");
    }
  }, [selectedProperty]);

  useEffect(()=>{
    if(id){
      retrieveCapture(id)
      .then((res) => setCapture(res))
      .catch((err) => toast.error(err))
    }
  },[id])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Property Workbook"
            pageTitle="3D Capture"
            pageTitleComponent={
              <>
                <h4 to="">
                  3D Capture <TooltipItem id={1} data={capturetooltip} />
                </h4>
              </>
            }
          />
          <Row>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {capture ? capture.name : "3D Capture"} <TooltipItem id={2} data={captureDetailTooltip} />
                  </h5>
                </CardHeader>
                <CardBody>
                  {capture ? (
                    <iframe
                      width="100%"
                      height="480"
                      src={capture.displayUrl}
                      frameborder="0"
                      allowfullscreen
                      allow="xr-spatial-tracking"
                    ></iframe>
                  ) : (
                    <div
                      style={{
                        display: "block",
                        width: "100%",
                        height: "480px",
                        backgroundColor: "black",
                      }}
                    >
                      &nbsp;
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};
