
import { getAllPropertyElementsSettingsTable } from "../../../helpers/backend_helper";
import {  setIsLoading , setelementSettings , invFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

// import { getelementSettings,createelementSettings ,deleteelementSettings ,deleteImage } from "../../../helpers/backend_helper";

export const ListelementSettingsThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getAllPropertyElementsSettingsTable(pid,params)
    .then((res)=> dispatch(setelementSettings(res)))
    .catch((err) => dispatch(invFailure(err)))

}

// export const CreateelementSettingsThunk = (pid,data,onSuccess) => async (dispatch) => {
//     dispatch(setBtnLoading(true))

//     createelementSettings(pid,data)
//     .then((res) => {
//         dispatch(createSucess()) 
//         onSuccess()
//     })
//     .catch((err)=> dispatch(createFailure(err)))
// }

// export const DeleteelementSettingsThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
//     dispatch(setIsLoading())
    
//     deleteelementSettings(fid)
//     .then((res)=> dispatch(deleteSuccess(fid)))
//     .catch((err)=> dispatch(invFailure(err)))
//     .finally(()=> closeModal())
// }

// export const DeletefloorThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
//     dispatch(setIsLoading())
//     deletefloor(cid)
//     .then((res)=> dispatch(deleteSuccess(cid)))
//     .catch((err)=> dispatch(floorFailure(err)))
//     .finally(()=> closeModal())
// }