import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTagsProperty } from "../../helpers/backend_helper";
import Select from "react-select";

const selectValues = [
    {
        "label":"Opened",
        "value":"Opened"
    },
    {
        "label":"Pending",
        "value":"Pending"
    },
    {
        "label":"Closed",
        "value":"Closed"
        
    }
]

const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFFFFF",
    }),
    menu: (base, state) => ({
        ...base,
        background: "#FFFFFF",
      }),
  };

export const SupportStatusDropdown = ({placeholder,selectedValue,handleChange,selectValues}) => {
    return <Select
    defaultValue={[]}
    closeMenuOnSelect={true}
    hideSelectedOptions={false}
    onChange={handleChange}
    value={selectedValue}
    options={selectValues}
    placeholder={placeholder}
    styles={customStyles}
    searchable={false}
  />
}

export const useSupportStatus = (placeholder="Select Case Status") => {
  
  const [selectedValue,setSelectedValue] = useState("")

  const handleChange = (val) => {
    setSelectedValue(val)
  }
  
  function getVal() {
    return selectedValue.label
  }


  return [
        <SupportStatusDropdown selectValues={selectValues} placeholder={placeholder} selectedValue={selectedValue} handleChange={handleChange} />,
        getVal
    ]
};