import React, { useState, useEffect } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
} from "reactstrap";
// import { Link } from 'react-router-dom';

//SimpleBar
import SimpleBar from "simplebar-react";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import { useSelector } from "react-redux";

import { PropertiesDropdownThunk } from "../../slices/thunks";

import { setSelected } from "../../slices/property/dropdown/reducer";

const PropertyDropdown = () => {
  const dispatch = useDispatch();

  const { properties, loading, selected, user } = useSelector((state) => ({
    properties: state.PropertiesDropdown.properties,
    loading: state.PropertiesDropdown.loading,
    selected: state.PropertiesDropdown.selected,
    user: state.Login.user,
  }));

  useEffect(() => {
    dispatch(PropertiesDropdownThunk(user.id));
  }, []);

  const [isPropertiesDropdown, setIsPropertiesDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsPropertiesDropdown(!isPropertiesDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isPropertiesDropdown}
        toggle={toggleProfileDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          style={{ padding: 0, cursor: "pointer" }}
          type="button"
          tag="button"
          className="btn btn-ghost-secondary"
        >
          {!loading ? (
            selected ? (
              <div class="input-group">
                <input
                  value={
                    selected.nickname +
                    " - " +
                    selected.address +
                    ", " +
                    selected.city +
                    ", " +
                    selected.zipCode
                  }
                  style={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    caretColor: "transparent",
                  }}
                  class="form-select"
                  id="inputGroupSelect02"
                  type="text"
                  size={75}
                />
                <Link to="/property/list/">
                  <label
                    style={{ backgroundColor: "#405189",cursor:'pointer' }}
                    class="input-group-text"
                    for="inputGroupSelect02"
                    // onClick={toggleProfileDropdown}
                  >
                    <i
                      className="mdi mdi-home-outline"
                      style={{ fontSize: "20px", color: "white" }}
                    />
                  </label>
                </Link>
              </div>
            ) : (
              <div class="input-group">
                <select
                  style={{ cursor: "pointer", backgroundColor: "white" }}
                  disabled
                  class="form-select"
                  id="inputGroupSelect02"
                >
                  <option selected>You need to create property</option>
                </select>
                <Link to="/property/list/">
                  <label
                    style={{ backgroundColor: "#405189" ,cursor:'pointer'}}
                    class="input-group-text"
                    for="inputGroupSelect02"
                  >
                    <i
                      className="mdi mdi-home-outline"
                      style={{ fontSize: "20px", color: "white" }}
                    />
                  </label>
                </Link>
              </div>
            )
          ) : (
            <Spinner />
          )}
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-xl dropdown-menu-end p-0"
          aria-labelledby="page-header-cart-dropdown"
        >
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fs-16 fw-semibold"> My Properties</h6>
              </Col>
              <div className="col-auto">
                <span className="badge badge-soft-warning fs-13">
                  {" "}
                  <span className="cartitem-badge">
                    {" "}
                    {properties.length}{" "}
                  </span>{" "}
                  Properties
                </span>
              </div>
            </Row>
          </div>
          <SimpleBar style={{ maxHeight: "300px" }}>
            <div className="p-2">
              <div
                className="text-center empty-cart"
                id="empty-cart"
                style={{ display: "none" }}
              >
                <div className="avatar-md mx-auto my-3">
                  <div className="avatar-title bg-soft-info text-info fs-36 rounded-circle">
                    <i className="bx bx-cart"></i>
                  </div>
                </div>
                <h5 className="mb-3">You Don't have Properties!</h5>
                <Link
                  to="/create-property"
                  className="btn btn-success w-md mb-3"
                >
                  Create New Property
                </Link>
              </div>
              {properties.map((item, key) => (
                <div
                  onClick={() =>
                    dispatch(setSelected({ idx: key, uid: user.id }))
                  }
                  style={
                    item.id === selected.id
                      ? { backgroundColor: "#f3f3f9" }
                      : { cursor: "pointer" }
                  }
                  className="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2"
                  key={key}
                >
                  <div className="d-flex align-items-center">
                    <div className="flex-1">
                      <p className="mb-0 fs-14 text-muted">
                        {item.nickname +
                          " - " +
                          item.address +
                          ", " +
                          item.city +
                          ", " +
                          item.zipCode}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </SimpleBar>
          <div
            className="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
            id="checkout-elem"
          >
            <Link
              to="/create-property"
              className="btn btn-success text-center w-100"
            >
              Create New Property
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default PropertyDropdown;
