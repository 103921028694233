import { useEffect, useRef, useState } from "react";
import ImageOrLoad from "../ImageOrLoad";
import { get_file_url, get_files_url } from "../../utils/get_file";
import { Spinner, UncontrolledCarousel } from "reactstrap";
import { Link } from "react-router-dom";
import { GalleryCard } from "../gallery/GalleryCard";
import emptyImage from "../../../assets/images/empty-image.jpg";
import { PdfPreview } from "../pdfPreview";
import ImageZoom from "react-image-zooom";
// import { UncontrolledReactSVGPanZoom } from "react-svg-pan-zoom";
import { ReactSVG } from "react-svg";
import { ReactSvgPanZoomLoader } from "react-svg-pan-zoom-loader";
import {
  INITIAL_VALUE,
  ReactSVGPanZoom,
  TOOL_NONE,
  fitSelection,
  zoomOnViewerCenter,
  fitToViewer,
} from "react-svg-pan-zoom";
// import {ReactSVGPanZoom} from 'react-svg-pan-zoom'
// import

export function PrivateImageViewer({ id, module, ...props }) {
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_file_url(id, module).then((res) => {
      setImage(res);
      setLoading(false);
    });
  }, [id, module]);

  return <>{loading ? <Spinner /> : <ImageOrLoad {...props} {...res} />}</>;
}

export const FileLinkViewer = ({ file, module, ...props }) => {
  const [loading, setLoading] = useState(false);

  function clicked() {
    setLoading(true);
    get_file_url(file.id, module)
      .then((res) => window.open(res.file, "_blank"))
      .finally(() => setLoading(false));
  }
  return loading ? (
    <Spinner size="xs" className="me-2" {...props} />
  ) : (
    <Link {...props} onClick={clicked} to="">
      {"/" + file.name}
    </Link>
  );
};

export function PrivateFileWraper({
  id,
  module,
  ViewComponent,
  lazyLoad = false,
  ...props
}) {
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_file_url(id, module, lazyLoad).then((res) => {
      setImage(res);
      setLoading(false);
    });
  }, [id, module]);

  // const refreshFunc = () => {
  //     setLoading(true)
  //     get_file_url(id,module).then((res) => {
  //         setImage(res)
  //         setLoading(false)
  //     })
  // }

  return (
    <>
      {loading ? <Spinner /> : <ViewComponent {...props} file={image} module={module} />}
    </>
  );
}

export const PrivateGalleryCard = ({ module, imgSrc, ...props }) => {
  const [image, setImage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_file_url(imgSrc, module).then((res) => {
      setImage(res);
      setLoading(false);
    });
    // console.log("recalled")
  }, [imgSrc, module]);

  return loading ? (
    <Spinner />
  ) : (
    <GalleryCard {...props} imgSrc={image.file} imgCaption={image.name} />
  );
};

export const PrivateCarousal = ({ items, module, ...props }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // console.log("here",items)
    if (items && items.length > 0) {
      setLoading(true);
      get_files_url(
        items.map((image) => image.image),
        module
      )
        .then((res) => setImages(res))
        .finally(() => setLoading(false));
    } else if (items.length == 0) {
      setLoading(false);
      setImages([]);
    }
  }, [items]);

  return loading ? (
    <Spinner />
  ) : (
    <UncontrolledCarousel
      {...props}
      items={(images && images.length > 0
        ? images
        : [{ file: emptyImage }]
      ).map((img, idx) => ({
        altText: " ",
        caption: img.caption || " ",
        key: idx,
        src: img.file,
      }))}
    />
  );
};

export const PrivateImageOrPdfWraper = ({ file, module }) => {
  const isPdf = file.name
    ? file.name.split(".").pop().toUpperCase() === "PDF"
    : false;

  return isPdf ? (
    <PdfPreview pdf={file.file} />
  ) : (
    <ImageOrLoad src={file.file} alt="" className="img-fluid rounded" />
  );
};

export const ZoomImageComponent = ({ file , width }) => {
  // console.log(file.extension);

  const Viewer = useRef(null);
  const [tool, setTool] = useState(TOOL_NONE);
  const [value, setValue] = useState(INITIAL_VALUE);

  useEffect(() => {
    if (Viewer && Viewer.current){
      Viewer.current.zoom(0, 0, 0.2);
    }
  }, []);

  return file.extension === "svg" ? (
    <ReactSvgPanZoomLoader
      src={file.file}
      render={(content) => (
        <div>
          <ReactSVGPanZoom
            ref={Viewer}
            width={width}
            height={500}
            tool={tool}
            onChangeTool={setTool}
            value={value}
            onChangeValue={setValue}
            detectAutoPan={false}
            // onZoom={e => console.log('zoom')}
            // onPan={e => console.log('pan')}
            // onClick={event => console.log('click', event.x, event.y, event.originalEvent)}
            // style={{width:"100%"}}
          >
            <svg>{content}</svg>
          </ReactSVGPanZoom>
        </div>
      )}
    />
  ) : (
    <ImageZoom src={file.file} alt="Zoom-images" zoom="300" />
  );
};
