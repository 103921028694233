import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getHVACTypes,
  getHVACEnergyTypes,
  retrieveHVAC,
  editRetrieveHVAC,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateHVACThunk,
  CreateFloorThunk,
  CreateimageThunk,
  CreateinventoryThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListHVACThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListinventoryThunk,
  ListSpacesThunk,
  UpdateHVACThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  HVACFailure,
  createSucess,
} from "../../../../slices/property/HVAC/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { get_spaces } from "../../../../Components/utils/spacesCompare";
import { SelectFilesModal } from "../../../../Components/Common/fileSelectComponents/SelectFilesModal";
import { RecieptInputModal } from "../../../../Components/Common/fileSelectComponents/recieptWraper";
import { ImageInputModal } from "../../../../Components/Common/fileSelectComponents/imageWraper";
import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";


registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const CreateImageForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [HVAC, setHVAC] = useState(null);

  const [selectedSpaces, setSelectedSpaces] = useState([]);
  const [StatusSelectComponent , getStatusVal , setStatus] = useStatusSelect();
  // const [files, setFiles] = useState([]);

  // const [imagesfiles, setimagesFiles] = useState([]);
  // const [reciepts, setrecieptsFiles] = useState([]);

  useEffect(() => {
    // console.log(update, id);
    if (update && id >= 0) {
      editRetrieveHVAC(id).then((res) => setHVAC(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: HVAC ? HVAC.name : "",
      cost: HVAC ? HVAC.cost || "" : "",
      make: HVAC ? HVAC.make || "" : "",
      model: HVAC ? HVAC.model || "" : "",
      serialNumber: HVAC ? HVAC.serialNumber || "" : "",
      warrantyDetails: HVAC ? HVAC.warrantyDetails || "" : "",
      energyRate: HVAC ? HVAC.energyRate || "" : "",
      notes: HVAC ? HVAC.notes || "" : "",
      unitLocation: HVAC ? HVAC.unitLocation || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      cost: Yup.number().positive("must be a positive number"),
      make: Yup.string(),
      model: Yup.string(),
      serialNumber: Yup.string(),
      warrantyDetails: Yup.string(),
      energyRate: Yup.number()
        .integer("must be integer")
        .positive("must be a positive number"),
      unitLocation: Yup.string(),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // HVAC type
  const [HVACList, setHVACList] = useState([]);
  const [selectedHVAC, setselectedHVAC] = useState("");

  // HVAC energy type
  const [HVACEnergyList, setHVACEnergyList] = useState([]);
  const [selectedHVACEnergy, setselectedHVACEnergy] = useState("");

  const [SpaceSelectList, setSpaceSelectList] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);

  const [date, setDate] = useState("");
  const [installationDate, setinstallationDate] = useState("");

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect()

  const [
    installationComponent,
    getInstallationData,
    setInstallationType,
    setContractor,
  ] = InstallationTypeAndSearch();

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.HVAC.btnLoading,
  }));

  const [selectedFileReciept, setFileReciept] = useState(null);

  useEffect(() => {
    getHVACTypes().then((res) => setHVACList(res));
  }, []);

  useEffect(() => {
    getHVACEnergyTypes().then((res) => setHVACEnergyList(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = new FormData();
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != HVAC[key]) {
        formdata[key] = values[key];
      }
    }
    var imagesfiles = getImageVal()
    var [modifiedImages,Images] = getCreateAndDeleteFiles(HVAC.images.map((inst) => inst.id),imagesfiles)
    if (modifiedImages){
      formdata["images"] = Images
    }
    
    var reciepts = getRecieptVal()
    var [modifiedreciepts,recieptsFiles] = getCreateAndDeleteFiles(HVAC.reciepts.map((inst) => inst.id),reciepts)
    if (modifiedreciepts){
      formdata["reciepts"] = recieptsFiles
    }
    
    const statusVal = getStatusVal();
    if (statusVal && statusVal !== HVAC.status ){
      formdata["status"] =  statusVal
    }

    if (date && date !== HVAC.purchaseDate) {
      formdata["purchaseDate"] =  date;
    }
    if (installationDate && installationDate !== HVAC.installationDate) {
      formdata["installationDate"] =  installationDate;
    }
    if (selectedHVAC != HVAC.HVACType) {
      formdata["HVACType"] = selectedHVAC;
    }
    if (selectedHVACEnergy != HVAC.energyType) {
      formdata["energyType"] = selectedHVACEnergy;
    }
    
    const spaces = get_spaces(HVAC.spaces,selectedSpaces)
    if(spaces){
      formdata["spaces"] = [spaces.join(",")]
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata["installationType"] = installationData.installationType;
      if (installationData.installationType === "Service Provider") {
        formdata["installationContractor"] = installationData.installationContractor.value
        
      }
    }
    if(Object.keys(formdata).length === 0){
      dispatch(createSucess("All data are up to date"));
    }else {
      dispatch(UpdateHVACThunk(id, formdata, onUpdateSucces));
    }
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      formdata.append(key, values[key]);
    }
    var imagesfiles = getImageVal()
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }
    var reciepts = getRecieptVal()
    for (let reciept of reciepts) {
      formdata.append("reciepts", reciept);
    }

    formdata.append("purchaseDate", date);
    formdata.append("installationDate", installationDate);
    formdata.append("HVACType", selectedHVAC);
    formdata.append("energyType", selectedHVACEnergy);
    formdata.append("status",getStatusVal());

    if (selectedSpaces.length > 0) {
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata.append("installationType", installationData.installationType);
      if (installationData.installationType === "Service Provider") {
        formdata.append(
          "installationContractor",
          installationData.installationContractor.value
        );
      }
    }

    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    const installationData = getInstallationData();

    checkOrError(
      !(
        installationData.installationType &&
        installationData.installationType === "Service Provider" &&
        !installationData.installationContractor
      ),
      errs,
      "you need to select Installation Contractor for installation of Type Service Provider"
    );

    const statusVal = getStatusVal();
    checkOrError(
      statusVal,
      errs,
      "you need to select status"
    );

    if (errs.length > 0) {
      dispatch(HVACFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListHVACThunk(selectedProperty.id));
      };
      dispatch(
        CreateHVACThunk(selectedProperty.id, getFormdata(values), onSuccess)
      );
    }
  }

  useEffect(() => {
    if (HVAC) {
      setselectedHVAC(HVAC.HVACType);
      setSelectedSpaces(
        HVAC.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setDate(HVAC.purchaseDate);
      setinstallationDate(HVAC.installationDate);
      setselectedHVACEnergy(HVAC.energyType);
      setInstallationType(HVAC.installationType);
      setStatus({label:HVAC.status,value:HVAC.status});
      // console.log(HVAC.images)
      dispatch(setSelectedImageFiles(HVAC.images))
      dispatch(setSelectedRecieptFiles(HVAC.reciepts))
      if (HVAC.installationContractor) {
        // console.log(HVAC.installationContractor,HVAC.installationContractor)
        setContractor({
          label: HVAC.installationContractor.name,
          value: HVAC.installationContractor.id,
        });
      } else {
        setContractor("");
      }
    }
  }, [HVAC]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      {/* <RecieptInputModal show={true} /> */}
      {/* <ImageInputModal show={true} /> */}
      {RecieptModal}
      {ImageModal}
      <Row>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedHVAC(e.target.value)}
              value={selectedHVAC}
            >
              <option value="" disabled selected>
                HVAC Type
              </option>
              {HVACList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        {/* <Col lg={3}>
          <div className="form-group my-2">
            <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            >
              <label
                for={update ? "update-" + "files-reciept" : "files-reciept"}
                style={{ backgroundColor: "#299CDB", width: "100%" }}
                class="btn btn-txt m-0"
              >
                {selectedFileReciept
                  ? selectedFileReciept.name
                  : recieptName
                  ? recieptName
                  : "Upload Reciept Image"}
              </label>
              <input
                id={update ? "update-" + "files-reciept" : "files-reciept"}
                style={{ display: "None", backgroundColor: "#299CDB" }}
                type="file"
                accept="image/png ,image/jpeg , file/pdf, application/pdf"
                onChange={(e) => setFileReciept(e.target.files[0])}
              />
            </div>
          </div>
        </Col> */}
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedHVACEnergy(e.target.value)}
              value={selectedHVACEnergy}
            >
              <option value="" disabled selected>
                Energy Type
              </option>
              {HVACEnergyList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col className="my-2" lg={4}>
          {StatusSelectComponent}
        </Col>
       
        <>
          <Col lg={6}>
            {/* <FilePond
              files={imagesfiles}
              onupdatefiles={setimagesFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={["image/png", "image/jpeg"]}
              labelIdle={"Upload Images: Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {ImageViewerComponent}
          </Col>
          <Col lg={6}>
            {/* <FilePond
              files={reciepts}
              onupdatefiles={setrecieptsFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "file/pdf",
                "application/pdf",
              ]}
              labelIdle={"Upload Receipt(s): Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {RecieptViewerComponent}
          </Col>
        </>

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="unit-input"
              placeholder="Unit Location"
              name="unitLocation"
              value={validation.values.unitLocation}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.unitLocation &&
                validation.touched.unitLocation
                  ? true
                  : false
              }
            />

            {validation.errors.unitLocation &&
            validation.touched.unitLocation ? (
              <FormFeedback type="invalid">
                {validation.errors.unitLocation}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="make-input"
              placeholder="Make"
              name="make"
              value={validation.values.make}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.make && validation.touched.make ? true : false
              }
            />

            {validation.errors.make && validation.touched.make ? (
              <FormFeedback type="invalid">
                {validation.errors.make}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="model-input"
              placeholder="model"
              name="model"
              value={validation.values.model}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.model && validation.touched.model
                  ? true
                  : false
              }
            />

            {validation.errors.model && validation.touched.model ? (
              <FormFeedback type="invalid">
                {validation.errors.model}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="serialNumber-input"
              placeholder="Serial Number"
              name="serialNumber"
              value={validation.values.serialNumber}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.serialNumber &&
                validation.touched.serialNumber
                  ? true
                  : false
              }
            />

            {validation.errors.serialNumber &&
            validation.touched.serialNumber ? (
              <FormFeedback type="invalid">
                {validation.errors.serialNumber}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={date}
                className="form-control"
                onChange={(date, dateStr) => {
                  setDate(dateStr);
                }}
                placeholder="Purchase Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={installationDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  setinstallationDate(dateStr);
                }}
                placeholder="Installation Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              // placeholder={"Location(s) Served"}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="energyRate-input"
              placeholder="Energy Rate - kwhs"
              name="energyRate"
              value={validation.values.energyRate}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.energyRate && validation.touched.energyRate
                  ? true
                  : false
              }
            />

            {validation.errors.energyRate && validation.touched.energyRate ? (
              <FormFeedback type="invalid">
                {validation.errors.energyRate}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="cost-input"
              placeholder="Cost $"
              name="cost"
              value={validation.values.cost}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.cost && validation.touched.cost ? true : false
              }
            />

            {validation.errors.cost && validation.touched.cost ? (
              <FormFeedback type="invalid">
                {validation.errors.cost}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {installationComponent}
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="warrantyDetails-input"
              placeholder="Warranty Details"
              name="warrantyDetails"
              value={validation.values.warrantyDetails}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.warrantyDetails &&
                validation.touched.warrantyDetails
                  ? true
                  : false
              }
            />

            {validation.errors.warrantyDetails &&
            validation.touched.warrantyDetails ? (
              <FormFeedback type="invalid">
                {validation.errors.warrantyDetails}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={6}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update HVAC</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
