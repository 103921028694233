import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  // getInventoryTypes,
  // getFloorFileTypes,
  // getSpaceSelectList,
  // getInsuranceTypes,
  // retrieveInsurance,
  editRetrieveInsurance,
  getInsurancePolicyTypes,
  getInsuranceDeductableTypes,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateInsuranceThunk,
  ListInsuranceThunk,

  UpdateInsuranceThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  InsuranceFailure,
  createSucess,
} from "../../../../slices/property/Insurance/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";


import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const CreateImageForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [Insurance, setInsurance] = useState(null);

  // const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [files, setFiles] = useState([]);

  // const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Policy Form",false)

  useEffect(() => {
    console.log(update, id);
    if (update && id >= 0) {
      editRetrieveInsurance(id).then((res) => setInsurance(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: Insurance ? Insurance.name : "",
      dwellingCoverage: Insurance ? Insurance.dwellingCoverage || "" : "",
      personalPropertyCoverage: Insurance ? Insurance.personalPropertyCoverage || "" : "",
      liabilityCoverage: Insurance ? Insurance.liabilityCoverage || "" : "",
      additionalStructureCoverage: Insurance ? Insurance.additionalStructureCoverage|| "" : "",
      deductibleAmount: Insurance ? Insurance.deductibleAmount || "" : "",
      specialDeductible: Insurance ? Insurance.specialDeductible || "" : "",
      notes: Insurance ? Insurance.notes || "" : "",
      annualPremium: Insurance ? Insurance.annualPremium || "" : "",
      startDate: Insurance ? Insurance.startDate || "" : "",
      endDate: Insurance ? Insurance.endDate || "" : "",
      renewalDate: Insurance ? Insurance.renewalDate || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      dwellingCoverage: Yup.string(),
      personalPropertyCoverage: Yup.string(),
      liabilityCoverage: Yup.string(),
      additionalStructureCoverage: Yup.string(),
      deductibleAmount: Yup.string(),
      specialDeductible: Yup.string(),
      annualPremium: Yup.string(),
      notes: Yup.string(),
      startDate: Yup.string(),
      endDate: Yup.string(),
      renewalDate: Yup.string(),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // Insurance type
  const [policyList, setPolicyList] = useState([]);
  const [selectedPolicy, setselectedPolicy] = useState("");

  const [deductableList, setdeductableList] = useState([]);
  const [selectedDeductable, setselectedDeductable] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.Insurance.btnLoading,
  }));


  const placeholder = useMemo(()=> {
    
      if(selectedDeductable === "Fixed Dollar"){
        return "Deductible Amount $ (ex 19.99)"
      }else if(selectedDeductable === "Percentage"){
        return "Deductible Percentage % (ex: 1% of dwelling coverage)"
      }else{
        return "Deductible Amount"
      }
     
  },[selectedDeductable])

  useEffect(() => {
    getInsurancePolicyTypes().then((res) => setPolicyList(res));
  }, []);

  useEffect(() => {
    getInsuranceDeductableTypes().then((res) => setdeductableList(res));
  }, []);



  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != Insurance[key]) {
        formdata.append(key, values[key]);
      }
    }
    
    const insurancePolicy = getImageVal()
    if (insurancePolicy.length > 0 ){
      if(!Insurance.insurancePolicy || insurancePolicy[0] !== Insurance.insurancePolicy.id){
        formdata.append("insurancePolicy", insurancePolicy[0]);
      }
    }else{
      formdata.append("insurancePolicy", "");
    }

    if (files.length > 0) {
      formdata.append("insurancePolicy", files[0].file);
    }

    if(selectedPolicy && selectedPolicy !== Insurance.policyForm){
      formdata.append("policyForm", selectedPolicy);
    }
    if(selectedDeductable && selectedDeductable !== Insurance.deductibleType){
      formdata.append("deductibleType", selectedDeductable);
    }

    if (formdata.entries().next().done) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdateInsuranceThunk(id, formdata, onUpdateSucces));
    }

  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      formdata.append(key, values[key]);
    }

    const insurancePolicy = getImageVal()
    if (insurancePolicy.length > 0){
      formdata.append("insurancePolicy", insurancePolicy[0]);
    }

    formdata.append("policyForm", selectedPolicy);
    formdata.append("deductibleType", selectedDeductable);
    
    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    // checkOrError(selectedPolicy, errs, "you need to select Form of policy");

    // checkOrError(
    //   selectedDeductable,
    //   errs,
    //   "you need to select Deductable type"
    // );

    if (errs.length > 0) {
      dispatch(InsuranceFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListInsuranceThunk(selectedProperty.id));
      };
      dispatch(
        CreateInsuranceThunk(selectedProperty.id, getFormdata(values), onSuccess)
      );
    }
  }

  useEffect(() => {
    if (Insurance) {
      setselectedPolicy(Insurance.policyForm);
      setselectedDeductable(Insurance.deductibleType);
      if(Insurance.insurancePolicy){
        dispatch(setSelectedImageFiles([Insurance.insurancePolicy]))
      }else{
        dispatch(setSelectedImageFiles([]))
      }
      
    }
  }, [Insurance]);

  // const fileName = useMemo(()=>{
  //   return Insurance && Insurance.insurancePolicy ? "/" + Insurance.insurancePolicy.split("/").splice(-1) +" if you want to change file drag or browse new one" : "No Policy File Attached, You can Drag or Browse File";
  // },[Insurance])  
  
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      {ImageModal}
      <Row>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedPolicy(e.target.value)}
              value={selectedPolicy}
            >
              <option value="" disabled selected>
                Form of Policy
              </option>
              {policyList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        {/* <Col lg={3}>
          <div className="form-group my-2">
            <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            >
              <label
                for={update ? "update-" + "files-reciept" : "files-reciept"}
                style={{ backgroundColor: "#299CDB", width: "100%" }}
                class="btn btn-txt m-0"
              >
                {selectedFileReciept
                  ? selectedFileReciept.name
                  : recieptName
                  ? recieptName
                  : "Upload Reciept Image"}
              </label>
              <input
                id={update ? "update-" + "files-reciept" : "files-reciept"}
                style={{ display: "None", backgroundColor: "#299CDB" }}
                type="file"
                accept="image/png ,image/jpeg , file/pdf, application/pdf"
                onChange={(e) => setFileReciept(e.target.files[0])}
              />
            </div>
          </div>
        </Col> */}
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="unit-input"
              placeholder="Dwelling Coverage"
              name="dwellingCoverage"
              value={validation.values.dwellingCoverage}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.dwellingCoverage &&
                validation.touched.dwellingCoverage
                  ? true
                  : false
              }
            />

            {validation.errors.dwellingCoverage &&
            validation.touched.dwellingCoverage ? (
              <FormFeedback type="invalid">
                {validation.errors.dwellingCoverage}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="unit-input"
              placeholder="Personal Property Coverage"
              name="personalPropertyCoverage"
              value={validation.values.personalPropertyCoverage}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.personalPropertyCoverage &&
                validation.touched.personalPropertyCoverage
                  ? true
                  : false
              }
            />

            {validation.errors.personalPropertyCoverage &&
            validation.touched.personalPropertyCoverage ? (
              <FormFeedback type="invalid">
                {validation.errors.personalPropertyCoverage}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="unit-input"
              placeholder="Liability Coverage"
              name="liabilityCoverage"
              value={validation.values.liabilityCoverage}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.liabilityCoverage &&
                validation.touched.liabilityCoverage
                  ? true
                  : false
              }
            />

            {validation.errors.liabilityCoverage &&
            validation.touched.liabilityCoverage ? (
              <FormFeedback type="invalid">
                {validation.errors.liabilityCoverage}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="unit-input"
              placeholder="Additional Structure Coverage"
              name="additionalStructureCoverage"
              value={validation.values.additionalStructureCoverage}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.additionalStructureCoverage &&
                validation.touched.additionalStructureCoverage
                  ? true
                  : false
              }
            />

            {validation.errors.additionalStructureCoverage &&
            validation.touched.additionalStructureCoverage ? (
              <FormFeedback type="invalid">
                {validation.errors.additionalStructureCoverage}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* {!(id && update) && ( */}
          <Col lg={12}>
            {/* <FilePond
              files={files}
              onupdatefiles={setFiles}
              allowMultiple={true}
              // acceptedFileTypes={['image/png', 'image/jpeg']}
              maxFiles={1}
              labelIdle={ Insurance && id? fileName : "Upload Insurance Policy: Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {ImageViewerComponent}
          </Col>
        {/* )} */}

        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedDeductable(e.target.value)}
              value={selectedDeductable}
            >
              <option value="" disabled selected>
              Deductible Type
              </option>
              {deductableList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="cost-input"
              placeholder={placeholder}
              name="deductibleAmount"
              value={validation.values.deductibleAmount}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.deductibleAmount && validation.touched.deductibleAmount ? true : false
              }
            />

            {validation.errors.deductibleAmount && validation.touched.deductibleAmount ? (
              <FormFeedback type="invalid">
                {validation.errors.deductibleAmount}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="make-input"
              placeholder="Special Deductible"
              name="specialDeductible"
              value={validation.values.specialDeductible}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.specialDeductible && validation.touched.specialDeductible ? true : false
              }
            />

            {validation.errors.specialDeductible && validation.touched.specialDeductible ? (
              <FormFeedback type="invalid">
                {validation.errors.specialDeductible}
              </FormFeedback>
            ) : null}
          </div>
        </Col>



        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                className="form-control"
                onChange={(date, dateStr) => {
                  validation.setFieldValue("startDate",dateStr,false)
                }}
                placeholder="Policy Start Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                value={validation.values.startDate}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
            <Flatpickr
                className="form-control"
                onChange={(date, dateStr) => {
                  validation.setFieldValue("endDate",dateStr,false)
                }}
                placeholder="Policy End Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                value={validation.values.endDate}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={validation.values.renewalDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  validation.setFieldValue("renewalDate",dateStr,false)
                }}
                placeholder="Renewal Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        {/* <Col lg={4}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              // placeholder={"Location(s) Served"}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col> */}
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              // id="warrantyDetails-input"
              placeholder="Annual Premium"
              name="annualPremium"
              value={validation.values.annualPremium}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.annualPremium &&
                validation.touched.annualPremium
                  ? true
                  : false
              }
            />

            {validation.errors.annualPremium &&
            validation.touched.annualPremium ? (
              <FormFeedback type="invalid">
                {validation.errors.annualPremium}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={10}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update Insurance</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
