import { Col, Form, Row, Spinner } from "reactstrap";
import { usePropertyTags } from "../../Hooks/propertyTags";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GETFilesProperty } from "../../../helpers/url_helper";
import { FileProgressBar } from "./progressBar";
import config from "../../../config";

export const FileWithProgressbar = ({ file , pid , token , onSuccess, onError , onStartUploading}) => {
  const [selectTagsDropdown, selectedTag, setTagVal, getTagVal] = usePropertyTags(false);
  const [name, setName] = useState(file.file.name);
  const [fileUrl,setFileUrl] = useState("")
  const [uploading , setUploading] = useState(false)
  const [uploadProgress,setUploadProgress] = useState(0)
  const [finished, setFinished] = useState(false)
  const [error,setError] = useState({
    err:false,
    msg:""
  })

  useEffect(()=>{
    setFileUrl(URL.createObjectURL(file.file));
    return () => URL.revokeObjectURL(fileUrl)
  },[file])

  function fileClicked() {
        window.open(fileUrl, "_blank")
    }

    function uploadFile() {
        setUploading(true)

        var formData = new FormData();
        formData.append('file', file.file);
        if(name){
            formData.append('name',name);
        }
        const tag = getTagVal()
        if(tag){
            formData.append('tag',tag);
        }
        
        var xhr = new XMLHttpRequest();
        xhr.open('POST',config.api.API_URL+GETFilesProperty(pid),true);
        xhr.setRequestHeader("Authorization","Token "+ token)
        
        xhr.upload.addEventListener('loadstart',()=>{
          if(onStartUploading){
            onStartUploading()
          }
        }
        )
        
        xhr.upload.addEventListener('progress', function (e) {
                    
            if (e.lengthComputable) {
                var percent = Math.round((e.loaded / e.total) * 100);
                setUploadProgress(percent)
            }
        });

        xhr.onerror = function(err) {
          console.log(err)
          setError({
            err:true,
            msg:"Some network error happens, please upload your file again"
          })
        }

        xhr.onload = function(){
            console.log(this.status)
            if(this.status === 201){
              setFinished(true)
              if(onSuccess)
                onSuccess()
            }else if(this.status === 413){
              setError({
                err:true,
                msg:"File is too large, please try again with smaller file ( max upload size is 1 MB )"
              })
              if(onError){
                onError()
              }
            }
            else{
              const errmsg = JSON.parse(this.responseText)
              setError({
                err:true,
                msg:errmsg
              })
              if(onError){
                onError()
              }
            }
        }

        xhr.send(formData);

    }


  return (
    
      <Form>
        <Row className="mb-5" style={{position:'relative'}}>
        <Col lg={5}>
          <div className="form-group">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Col>

        <Col lg={3}>
          <div className="form-group">{selectTagsDropdown}</div>
        </Col>
        
        <Col lg={2}>
         {fileUrl ? <Link onClick={fileClicked} to={""}>{"/" + name}</Link>: <Spinner /> }
        </Col>

        <Col lg={2}>
          <div className="d-flex align-items-start gap-3">
            <button
              type="button"
              className="btn btn-primary add-btn"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              id="create-btn"
              style={{ width: "100%", backgroundColor: "#0AB39C" }}
              onClick={uploadFile}
              disabled={uploading}
            >
              {uploading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
              Upload File
            </button>
          </div>
        </Col>
        {uploading ? <FileProgressBar error={error} finished={finished} value={uploadProgress} title={name} /> : null }
        </Row>
      </Form>
    
  );
};
