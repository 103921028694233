import { Alert } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Error = ({ appended, error , className='' , isToast=false }) => {
  if (error instanceof Array) {
    const out = error.map((err) => <Error appended={appended} error={err} />);
    return <>{out}</>;
  } else if (error instanceof Object) {
    const keys = Object.keys(error);
    const out = keys.map((key) => <Error appended={appended? appended+ " : " +key : key} error={error[key]} />);
    return <>{out}</>;
  } else {
    if(isToast){
      toast.error(`${appended} : ${error}`)
    }else{
      return (
        <Alert className={className} color="danger">
          <div>
            <strong>{appended}</strong>{" "}
            {error}
          </div>
        </Alert>
      );
    }
    
  }
};

export const toastError = (error,appended="") => {
  if (error instanceof Array) {
    error.map((err) => toastError(err,appended));
    return ;
  }
  else if (error instanceof Object) {
    const keys = Object.keys(error);
    keys.map((key) => toastError(error[key],appended? appended+ " : " +key : key));
    return ;
  }else {
    if(appended){
      toast.error(`${appended} : ${error}`)
    }else{
      toast.error(`${error}`)
    }
  }
}
