import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Spinner,
  Label,
  Alert,
  Row,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import closeicon from '../../../assets/images/close.svg';

// import Masonry from "react-masonry-component";
import { useSelector, useDispatch } from "react-redux";

import img12 from "../../../assets/images/addToGallery.png";

import { GalleryCard } from "./GalleryCard";

import { Error } from "../../../Components/error";


import { useParams } from "react-router-dom";
import { AddToGalleryModal } from "./AddToGalleryModal";
import { getGalleryThunk, getPropertyThunk } from "../../../slices/thunks";



import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const ImageDetail = ({
    src,
    onClose
}) =>{
    return (
        <>
        <div onClick={onClose} className="blur-div">
            <button onClick={onClose}>
                <img src={closeicon}/>
            </button>           
        </div>
        
        <img src={src} className='full-screen-img' />
        
        </>
    )
}


export const GallerySlide = ({ 
    isTop,
    toggleTopCanvas,
}) => {

    const {  gallerydata,  galleryLoading } = useSelector((state) => ({
        gallerydata: state.viewProperty.gallery,
        galleryLoading: state.viewProperty.galleryLoading,
      }));
  
    const [modalGallery, setmodal_modalGallery] = useState(false);    
    
    const [src , setSrc] = useState(null);

    let { id } = useParams();

    const dispatch = useDispatch();
  
    function reset_galleryData() {
      dispatch(getGalleryThunk(id));
    }

  function tog_GalleryModal() {
    setmodal_modalGallery(!modalGallery);
  }

  return (
    <>
    
      <Offcanvas
        isOpen={isTop}
        direction="top"
        toggle={toggleTopCanvas}
        id="offcanvasTop"
        style={{ minHeight: "46vh", height: "auto" }}
        onOpened={reset_galleryData}
      >
        <OffcanvasHeader
          toggle={toggleTopCanvas}
          id="offcanvasTopLabel"
          className="border-bottom"
        >
          Gallery
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row
            style={{ width: "100%", height: "100%" }}
            className="gallery-light"
          >
            {
            src && <ImageDetail src={src} onClose={() => setSrc(null)} />
            }
            {galleryLoading ? (
              <div
                className="pt-4"
                style={{ textAlign: "center", height: "300px" }}
              >
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              </div>
            ) : (
              <>
                {gallerydata?.map((gallery, index) => (
                    <GalleryCard
                    key={index}
                    hasClear={true}
                    id={gallery.id}
                    imgSrc={gallery.image}
                    imgCaption={gallery.name}
                    onClick={() => setSrc(gallery.image)}
                    />
                ))}
                <GalleryCard
                key={-1}
                onClick={tog_GalleryModal}
                imgSrc={img12}
                imgCaption={"Add To Gallery"}
                />
                <ToastContainer />
              </>
            )}
          </Row>
        </OffcanvasBody>
      </Offcanvas>

      <AddToGalleryModal
        isOpen={modalGallery}
        toggle={tog_GalleryModal}
        onSuccess={reset_galleryData}
      />
    </>
  );
};
