
export function CompareSpaces(old,current){
    // return 1 when equal return -1 when not equal
    if(old.length !== current.length){
        return -1
    }
    old.sort()
    current.sort()
    for (var i=0;i<old.length;i++){
        if(old[i] != current[i]){
            return -1
        }
    }
    return 1
}


export function get_spaces(old,current){
    const current_spaces = current.map((space) => space.value)
    if(current_spaces.length === 0 && old.length > 0){
        return ["empty"]
    }else if(current_spaces.length > 0 && old.length === 0){
        return current_spaces
    }
    else if (current_spaces.length > 0 && old.length > 0) {
        if(CompareSpaces(old.map((space) => space.id),current_spaces) === -1){
            return current_spaces
        }else{
            return null
        } 
    }else {
        return null
    }
}