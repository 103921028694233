import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Spinner,
} from "reactstrap";
// import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
// import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteFloorThunk,
  ListFloorsThunk,
  getFloorsSideNavThunk,
} from "../../../../slices/thunks";
import {
  FloorsReportCreate,
  getCaptureDataSrcs,
} from "../../../../helpers/backend_helper";
import { Error, toastError } from "../../../../Components/error";
import {
  resetMessages,
  setSelected,
} from "../../../../slices/property/floors/reducer";
import { CreateFloorForm } from "./createForm";
import UpdateFloorModal from "../New";
import { TooltipItem } from "../../../../Components/Common/ToolTipItem";
import {
  FloorPlanNewListTooltip,
  FloorPlantooltip,
  FloorplanActionTooltip,
  FloorplanFileNameTooltip,
  FloorplanLevelTooltip,
  FloorplanNameTooltip,
} from "../../../../Components/constants/tooltips";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import {
  reloadState as reloadStateImage,
  saveState as saveStateImage,
  setSelectedFiles as setSelectedImageFiles,
} from "../../../../slices/property/propertyFilesImages/reducer";
import {
  reloadState as reloadStateReciept,
  saveState as saveStateReciept,
  setSelectedFiles as setSelectedRecieptFiles,
} from "../../../../slices/property/propertyFilesReciepts/reducer";
import { toast } from "react-toastify";

const FloorUpload = () => {
  document.title = "Floorplan(s) | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");
  const mountedRef = useRef()
  // const [dataSrcs , setDataSrcs ] = useState(null);
  const dataSrcs = [];
  //   const dataSrcs = [
  //     {
  //         "name": "At or Above Grade",
  //         "iconClass": "ri-store-2-fill me-1 align-bottom"
  //     },
  //     {
  //         "name": "Below Grade",
  //         "iconClass": "ri-checkbox-circle-line me-1 align-bottom"
  //     }
  // ]

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const {
    selectedProperty,
    floors,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    floors: state.floorUpload.floors,
    numOfPages: state.floorUpload.total_pages,
    numOfCaptures: state.floorUpload.count,
    pageSize: state.floorUpload.page_size,
    currentPage: state.floorUpload.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.floorUpload.loading,
    error: state.floorUpload.error,
    success: state.floorUpload.success,
  }));

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      dataSource: "All",
      page: 1,
    });
    }
    mountedRef.current = true;
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListFloorsThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function onDeleteClick() {
    dispatch(
      DeleteFloorThunk(captureDelete, () => {
        setCaptureDelete(null);
        if (selectedProperty) {
          dispatch(getFloorsSideNavThunk(selectedProperty.id));
        }
      })
    );
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListFloorsThunk(selectedProperty.id, filterParams));
      dispatch(getFloorsSideNavThunk(selectedProperty.id));
    }
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: (
          <>
            <span>
              Name{" "}
              <TooltipItem
                id={3}
                placement="bottom"
                data={FloorplanNameTooltip}
              />
            </span>
          </>
        ),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/level/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      // {
      //   Header: "Floor Plan File Type",
      //   accessor: "fileType",
      //   filterable: false,
      // },
      {
        Header: (
          <>
            <span>
              Filename{" "}
              <TooltipItem
                id={4}
                placement="bottom"
                data={FloorplanFileNameTooltip}
              />
            </span>
          </>
        ),
        accessor: "levelImg",
        filterable: false,
        Cell: (capture) => {
          const lvlimg = capture.row.original.levelImg;

          return lvlimg ? (
            <FileLinkViewer
              module={"privateFile"}
              lazyLoad={true}
              file={lvlimg}
            />
          ) : null;
        },
      },
      {
        Header: (
          <>
            <span>
              Level{" "}
              <TooltipItem
                id={5}
                placement="bottom"
                data={FloorplanLevelTooltip}
              />
            </span>
          </>
        ),
        accessor: "levelType",
        filterable: false,
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },

      {
        Header: (
          <>
            <span>
              Action{" "}
              <TooltipItem
                id={7}
                placement="bottom"
                data={FloorplanActionTooltip}
              />
            </span>
          </>
        ),
        id: "Actions",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  // const [isExportCSV, setIsExportCSV] = useState(false);
  // const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  // const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  // const [deleteModal, setDeleteModal] = useState(false);
  // const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
      // let filteredOrders = orders;
      // if (type !== "all") {
      // filteredOrders = orders.filter((order) => order.status === type);
      // }
      // setOrderList(filteredOrders);
    }
  };

  useEffect(() => {
    dispatch(setSelectedImageFiles([]));
    dispatch(resetMessages());
  }, []);

  useEffect(() => {
    if (!isEdit) {
      dispatch(reloadStateImage());
    }
  }, [isEdit]);

  const [btnloading, setbtnLoading] = useState(false);

  function onCreateReportClick() {
    setbtnLoading(true);
    FloorsReportCreate(selectedProperty.id)
      .then((res) => toast.info("Your report is now being created"))
      .catch((err) => toastError(err))
      .finally(() => setbtnLoading(false));
  }

  return (
    <div className="page-content">
      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={orderList}
      /> */}

      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateFloorModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      />

      {/* <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      /> */}

      <Container fluid>
        <BreadCrumb
          title="Floorplan(s)"
          pageTitle="Property Workbook"
          pageTitleComponent={
            <>
              <h4 to="">
                Floorplan(s) <TooltipItem id={1} data={FloorPlantooltip} />
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                {/* <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0">
                    New/List/View/Edit{" "}
                    <TooltipItem id={2} data={FloorPlanNewListTooltip} />
                  </h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      {}
                      <button
                        type="button"
                        disabled={btnloading || success || error}
                        className="btn btn-soft-info"
                        onClick={onCreateReportClick}
                      >
                        {btnloading ? (
                          <Spinner size="sm" className="me-2">
                            Loading...
                          </Spinner>
                        ) : null}
                        <i className="ri-file-list-3-line align-middle"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                </div> */}

                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    New/List/View/Edit{" "}
                    <TooltipItem id={2} data={FloorPlanNewListTooltip} />
                  </h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      {}
                      <button
                        type="button"
                        disabled={btnloading || success || error}
                        className="btn btn-soft-info"
                        onClick={onCreateReportClick}
                      >
                        {btnloading ? (
                          <Spinner size="sm" className="me-2">
                            Loading...
                          </Spinner>
                        ) : null}
                        <i className="ri-file-list-3-line align-middle"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                </div>
                <Row className="align-items-center gy-3">
                  <div>
                    <div className="py-3">
                      <CreateFloorForm />
                    </div>
                    {/* <Row>
                      <SearchWithFilter />
                    </Row> */}
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Floors
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.name);
                          }}
                          href="#"
                        >
                          {src.iconClass && <i className={src.iconClass}></i>}
                          {src.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={floors || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      // baseLink={"/3dCapture"}
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                  {/* {captures && captures.length ? (
                                     ) : (
                    
                  )} */}
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FloorUpload;
