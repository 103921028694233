import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  properties:[],
  loading:true,
  selected:null,
  elementSettingsRefresh:false,
};

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState,
  reducers: { 
    setProperties(state,action){
      const {uid , properties} = action.payload
      state.properties = properties
      const oldSelected = JSON.parse(localStorage.getItem(`selectedProperty,${uid}`))
      const idx = state.properties.findIndex((property) => property.id == oldSelected)
      state.selected = state.properties.length > 0 ? (idx>=0 ?  state.properties[idx] : state.properties[0]) : null 
      localStorage.setItem(`selectedProperty,${uid}`,JSON.stringify(state.selected.id))
      state.loading = false
    },
    setIsLoading(state,action){
        state.loading = action.payload
    },
    setSelected(state,action){
      const {uid , idx} = action.payload
      state.selected = state.properties[idx]
      localStorage.setItem(`selectedProperty,${uid}`,JSON.stringify(state.selected.id))
    },
    refreshElementSettings(state,action){
      console.log("called")
      state.elementSettingsRefresh = !state.elementSettingsRefresh
    }
  }
});


export const {
  setIsLoading,
  setProperties,
  setSelected,
  refreshElementSettings
} = dropdownSlice.actions;

export default dropdownSlice.reducer;