
import {  setIsLoading , setFinishes , FinishesFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getFinishes, createFinishes, deleteFinishes } from "../../../helpers/backend_helper";

import { updateFinishes } from "../../../helpers/backend_helper";

export const ListFinishesThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getFinishes(pid,params)
    .then((res)=> dispatch(setFinishes(res)))
    .catch((err) => dispatch(FinishesFailure(err)))

}

export const CreateFinishesThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createFinishes(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateFinishesThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateFinishes(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteFinishesThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteFinishes(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(FinishesFailure(err)))
    .finally(()=> closeModal())
}