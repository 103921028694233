function getTimezoneOffset() {
    // Get the timezone as a string (e.g., "America/New_York")
    const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a date object representing the current time
    const now = new Date();

    // Get the current UTC offset in minutes
    const currentUtcOffsetInMinutes = now.getTimezoneOffset();

    // Convert the UTC offset to a string in the format "+HH:MM" or "-HH:MM"
    const offsetHours = Math.floor(Math.abs(currentUtcOffsetInMinutes) / 60);
    const offsetMinutes = Math.abs(currentUtcOffsetInMinutes) % 60;
    const offsetSign = currentUtcOffsetInMinutes > 0 ? "-" : "+";

    const offsetString = `${offsetSign}${padZero(offsetHours)}:${padZero(offsetMinutes)}`;

    return { timezoneString, offsetString };
}

function padZero(num) {
    return num < 10 ? `0${num}` : num.toString();
}

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function formatTime(date) {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function get_date_tz(date,time){
    const { timezoneString, offsetString } = getTimezoneOffset();
    let now = new Date();
    if(!date) {
        date = formatDate(now)
        if (!time){
            time = formatTime(now)
            return date + " " + time + " " + offsetString
        }
    }

    if(!time){
        time = "12:00"
        return date + " " + time + " " + offsetString
    }

    return date + " " + time + " " + offsetString
}


function convertUtcDateToLocalDateString(utcDateString) {
    const utcDate = new Date(utcDateString);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    // const localDateString = utcDate.toLocaleDateString("es-CL", options);
    // const offsetMinutes = utcDate.getTimezoneOffset();
    // const localDate = new Date(utcDate.getTime() - offsetMinutes * 60000);

    // Extract local components
    const year = utcDate.getFullYear();
    const month = String(utcDate.getMonth() + 1).padStart(2, '0');
    const day = String(utcDate.getDate()).padStart(2, '0');

    const localDateString = `${year}-${month}-${day}`;
    // console.log(localDateString)
    // console.log(utcDate.getHours(),utcDate.getMinutes(),localDate.getHours(),localDate.getMinutes())
    return {
        "date":localDateString,
        "time":formatTime(utcDate)
    };
}

function convertUtcStringToLocalTime(utcTimeString) {
    const utcDate = new Date(utcTimeString);
    const localTime = utcDate.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit', hour12: false });

    return localTime;
}


export function get_date_time_from_utc_str(utcString) {
    return convertUtcDateToLocalDateString(utcString)
}