import { useState , useEffect } from 'react';
import {Link,useLocation} from 'react-router-dom';

const NUMOFPAGESDISPLAYED = 5 // num of pages to be displayed in the pagination

function getPageUrlSearch(oldparams,pageNum){
    return new URLSearchParams([
        ...Array.from(oldparams.entries()),
        ["page",pageNum]
    ]).toString()
}


export const Pagination = ({ onPageChange , NumPages , baseLink})=>{
    
    const search = useLocation().search;
    const [currentPage,setcurrentPage] = useState(1)

    useEffect(()=>{
        const params = new URLSearchParams(search);
        const page = params.get('page')
        if (page){
            setcurrentPage(parseInt(page))
            onPageChange(page);
        }else{
            setcurrentPage(1)
            onPageChange(1);
        }
    },[search])

    function getStartEnd(){
        const rem = currentPage%NUMOFPAGESDISPLAYED
        if ( rem === 1){
            const start = currentPage;
            const end = Math.min(start+(NUMOFPAGESDISPLAYED-1),NumPages);
            return [start , end]
        }
        else if(rem === 0){
            const start = currentPage-(NUMOFPAGESDISPLAYED)+1; 
            const end =  currentPage;
            return [start , end]
        }
        else{
            const start = currentPage-(rem-1); 
            const end =  Math.min(start+(NUMOFPAGESDISPLAYED-1),NumPages);
            return [start , end]
        }
    }

    const [start,end] = getStartEnd();


    function getpagesLinks(){
        
        const params = new URLSearchParams(search);
        params.delete('page')
        // searchparams.ad
        const searchparams = params.toString();
        var arr = []
        var i = start;
        const prevClassName = "page-item"+(currentPage > 1?"":" disabled") 
        const nextClassName = "page-item"+(currentPage < NumPages?"":" disabled")
        
        arr.push((<li key={-1} className={prevClassName}>
              <Link to={{pathname:baseLink,search:getPageUrlSearch(params,currentPage-1)}} className="page-link">
                Previous
              </Link>
            </li>)
        )
        
        // <li key={-1} className={prevClassName}><Link to={{pathname:"/items",search:searchparams+`&page=${currentPage-1}`}} className="page-link">Previous</Link></li>
        
        while(i<=end){
            const className = 'page-item'+(i === currentPage?' active':"") 
            arr.push((<li key={i} className={className}>
                <Link to={{pathname:baseLink,search:getPageUrlSearch(params,i)}} className="page-link">
                  {i}
                </Link>
              </li>)
          )
            
            i+=1
        }

        
        arr.push((<li key={end+1} className={nextClassName}>
            <Link to={{pathname:baseLink,search:getPageUrlSearch(params,currentPage+1)}} className="page-link">
              Next
            </Link>
          </li>)
      )

        // arr.push(<li key={end+2} className={nextClassName}><Link to={{pathname:"/items",search:searchparams+`&page=${NumPages}`}} className="page-link"><span aria-hidden="true">&raquo;</span></Link></li>)

        return arr;
    }

    const pagesComponents = getpagesLinks()

    return (
        <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
            {pagesComponents}
        </ul>
    )
}