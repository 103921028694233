import { Col, Row, Input, Label, Form, Spinner } from "reactstrap";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFloorTypes,
  getFloorFileTypes,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import { CreateFloorThunk, getFloorsSideNavThunk , ListFloorsThunk } from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileValidateType);

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

export const CreateFloorForm = () => {
  const [fileTypes, setFileTypes] = useState([]);
  const [floorTypes, setFloorTypes] = useState([]);

  const {
    selectedProperty,
    loading
  } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.floorUpload.btnLoading
  }));

  const [selectedFile, setFile] = useState([]);
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [fileType, setFileType] = useState("");

  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Floorplan File",false)

  useEffect(()=>{
    if(selectedFile.length > 0){
      // console.log(selectedFile)
      const ext = selectedFile[0].file.name.split('.').pop().toUpperCase()
      // console.log(ext)
      const exists = fileTypes.includes(ext)
      if(!exists){
        const allowedExt = fileTypes.join(',')
        // console.log(fileTypes)
        setFile([])
        setFileType("")
        dispatch(floorFailure(`.${ext} files aren't supported for floor file field, you need to provide a file in one of these extensions ${allowedExt}`));
      }else{
        setFileType(ext)
      }
    }
  },[selectedFile])

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }
  const [notes, setnotes] = useState("");

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    // if (selectedFile.length > 0){
    //   // formdata.append("fileType", fileType);
    //   formdata.append("image", selectedFile[0].file);
    // }
    const image = getImageVal()
    if (image.length > 0){
      formdata.append("image", image[0]);
    }
    formdata.append("levelType", type);
    formdata.append("notes", notes);
    return formdata
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to select a name for your floor plan");

    // checkOrError(fileType, errs, "you need to select floor plan file type");

    // checkOrError(selectedFile, errs, "you need to select a floor plan file");

    checkOrError(type, errs, "you need to select floor plan level");

    if (errs.length > 0) {
      dispatch(floorFailure(errs));
    } else {
        const onSuccess = () => {
            dispatch(ListFloorsThunk(selectedProperty.id))
            dispatch(getFloorsSideNavThunk(selectedProperty.id))
        } 
        dispatch(CreateFloorThunk(selectedProperty.id,getFormdata(), onSuccess))
    }
  }

  useEffect(() => {
    getFloorTypes().then((res) => setFloorTypes(res));
  }, []);

  useEffect(() => {
    getFloorFileTypes().then((res) => setFileTypes(res.map((type)=>type.name)));
  }, []);

  return (
    <Form action="#">
      {ImageModal}
      <Row>
        <Col lg={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Col>
        {/* <Col lg={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                // style={{ backgroundColor: "#eff2f7" }}
                onChange={(e) => setFileType(e.target.value)}
                disabled
                value={fileType}
              >
                <option value="" disabled selected>
                  Floor Plan File Type
                </option>
                {fileTypes.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>
        </Col> */}
        <Col lg={3}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" disabled selected>
                Level Type
              </option>
              {floorTypes.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <>
        <Col lg={12}>
          {/* <div className="form-group my-2">
            <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            >
              <label
                for="files"
                style={{ backgroundColor: "#299CDB", width: "100%" }}
                class="btn btn-txt m-0"
              >
                {selectedFile ? selectedFile.name : "Upload Floor Plan"}
              </label>
              <input
                id="files"
                style={{ display: "None", backgroundColor: "#299CDB" }}
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div> */}
            {/* <FilePond
                files={selectedFile}
                onupdatefiles={setFile}
                allowMultiple={true}
                maxFiles={1}
                // acceptedFileTypes={['image/png', 'image/jpeg']}
                labelIdle={"Upload Floorplan (SVG, PNG, JPG, PDF): Drag here or Click to browse"}
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {ImageViewerComponent}
        </Col>
        </>
        {/* <Col lg={2}></Col> */}
        <Col lg={10}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>
        <Col lg={2}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            <i className="ri-add-line align-bottom me-1 btn-success"></i> Add Level
          </button>
        </Col>
      </Row>
    </Form>
  );
};
