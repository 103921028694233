import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";

import { useSelector, useDispatch } from "react-redux";

import Loader from "../../../../Components/Common/Loader";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";
import {
  DeletefileThunk,
  ListfilesThunk,
} from "../../../../slices/property/propertyFiles/thunk";
import { usePropertyTags } from "../../../../Components/Hooks/propertyTags";
import UpdateFileModal from "../New";
import {
  resetMessages,
  setRefreshDropdown,
} from "../../../../slices/property/propertyFiles/reducer";
import { CreateImageModal } from "./createImageModal";
import { usePropertyFilesSearchTags } from "../../../../Components/Hooks/propertyFilesSearchTags";

// import { DeleteimageThunk , ListimagesThunk } from "../../../../slices/thunks";
// import { Error } from "../../../../Components/error";
// import { resetMessages  } from "../../../../slices/property/propertyImages/reducer";
// import {CreateImageForm} from './createForm';
// import UpdateImageModal from "../New";

const PropertyFilesPage = () => {
  document.title = "Property Files | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");
  const mountedRef = useRef()
  // const [selectTagsDropdown,selectedTag] = usePropertyTags()

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const dispatch = useDispatch();

  const [showCreate, setshowCreate] = useState(false);

  const [state, setState] = useState(false);

  const [filterParams, setFilterParams] = useState({
    tags: "",
    page: 1,
  });

  const {
    selectedProperty,
    images,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
    refresh,
  } = useSelector((state) => ({
    images: state.files.files,
    numOfPages: state.files.total_pages,
    numOfCaptures: state.files.count,
    pageSize: state.files.page_size,
    currentPage: state.files.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.files.loading,
    error: state.files.error,
    success: state.files.success,
    refresh: state.files.refreshDropdown,
  }));

  const [selectTagsDropdown, tagsVal, getTags] = usePropertyFilesSearchTags(
    refresh,
    "Search by Tag"
  );

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams((oldParams) => ({
      ...oldParams,
      page: 1,
      tags: getTags(),
    }));
  }
  }, [state]);

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      page: 1,
    });
  }
  mountedRef.current = true;
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListfilesThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function OnUpdateSuccess() {
    setFilterParams((oldParams) => ({ ...oldParams, page: 1 }));
    dispatch(setRefreshDropdown());
  }

  function onDeleteClick() {
    dispatch(
      DeletefileThunk(captureDelete, () => {
        setCaptureDelete(null);
        OnUpdateSuccess();
      })
    );
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      // {
      //   Header: "Name",
      //   accessor: "name",
      //   filterable: false,
      //   Cell: (cell) => {
      //     return (
      //       <Link to="#" className="fw-medium link-primary">
      //         {cell.value}
      //       </Link>
      //     );
      //   },
      // },
      {
        Header: "Name",
        accessor: "file",
        filterable: false,
        Cell: (capture) => {
          return (
            <FileLinkViewer
              file={capture.row.original}
              module={"privateFile"}
            />
          );
        },
      },
      {
        Header: "Tag",
        accessor: "tag",
        filterable: false,
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
    }
  };

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />
      <CreateImageModal
        show={showCreate}
        toggle={() => setshowCreate(false)}
        onSuccess={OnUpdateSuccess}
      />

      <UpdateFileModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      />

      <Container fluid>
        <BreadCrumb title="Files" pageTitle="Property Workbook" />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            {/* {selectTagsDropdown} */}
            <Card id="orderList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Files</h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      {}
                      <button
                        type="button"
                        className="btn btn-primary add-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        id="create-btn"
                        onClick={() => {
                          setshowCreate(true);
                        }}
                      >
                        <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                        Upload Files
                      </button>
                    </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
              <Row className="g-3 mb-2">
                  <Col sm={8} md={9} lg={10} xxl={11}>{selectTagsDropdown}</Col>
                  <Col sm={4} md={3} lg={2} xxl={1}>
                    <button
                      onClick={() => setState(!state)}
                      type="button"
                      className="btn btn-primary w-100"
                    >
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Filters
                    </button>
                  </Col>
                </Row>
                <div>
                  {/* <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Files
                      </NavLink>
                    </NavItem> */}
                    {/* {dataSrcs?.map((src,key)=> 
                      <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === `${key+2}` },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab(`${key+2}`, src.name);
                        }}
                        href="#"
                      >
                        {src.iconClass && (<i className={src.iconClass}></i> )}    
                        {src.name}
                      </NavLink>
                    </NavItem>
                    ) 
                    } */}
                  {/* </Nav> */}
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={images || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyFilesPage;
