import React from 'react';
import { Link, redirect , useSearchParams } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row ,Button , Alert , Spinner} from 'reactstrap';
import AuthSlider from '../authCarousel';
import { useState , useEffect } from 'react';
import { loginUser } from '../../../slices/auth/login/thunk';

import { resetLoginFlag } from '../../../slices/auth/login/thunk';

//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';


const CoverSignIn = () => {
    document.title="SignIn | DwellDoc";

    const dispatch = useDispatch()

    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')
    const [keepmelogin,setKeepmelogin] = useState(false);

    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [passwordShow, setPasswordShow] = useState(false);

    const [redirectUri,setRedirectUri] = useState(null);    
    // const token = searchParams.get('token')

    const { user ,error, info , loading, errorMsg } = useSelector(state => ({
        user : state.Login.user,
        error: state.Login.error,
        info:state.Login.info,
        loading: state.Login.loading,
        errorMsg: state.Login.errorMsg,

    }));

    function onSuccess() {
        setTimeout(()=>{
            navigate({pathname:redirectUri})
        },3000)
    }

    function signIn(e) {
        e.preventDefault()
        dispatch(loginUser(username,password,keepmelogin,onSuccess))
    }

    useEffect(()=>{
        const redirectUrii =  searchParams.get('redirectUri')
        setRedirectUri(redirectUrii)
    },[searchParams])

    useEffect(() => {
        
        const timeout = info? 0:3000; 
        if(user && redirectUri){
            setTimeout(() =>{
                navigate(redirectUri)
                console.log("redirected")
            } , timeout);
        }
        else if(user){
            setTimeout(() =>{
                navigate("/")
                console.log("redirected")
            } , timeout);
        }
    }, [dispatch, user, navigate,redirectUri ]);

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorMsg]);


    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>

                <div className="auth-page-content overflow-hidden pt-lg-5">                    
                    <Container>
                        <Row className="justify-content-center g-0">
                            <Col lg={6}>
                                <Card className="overflow-hidden">
                                    <Row className="justify-content-center g-0">
                                        {/* <AuthSlider /> */}

                                        <Col lg={12}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p className="text-muted">Sign in to continue to DwellDoc.</p>
                                                </div>

                                                {user && user ? (     
                                                    <Alert color="success">
                                                        Login Successfully, Redirecting...
                                                    </Alert>
                                                ) : null}

                                                {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                                <div className="mt-4">
                                                    <form action="/">

                                                        <div className="mb-3">
                                                            <Label htmlFor="username" className="form-label">Email</Label>
                                                            <Input onChange={(e)=> setUsername(e.target.value)} type="text" className="form-control" id="username" placeholder="Enter your email" required/>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="float-end">
                                                                <Link to="/pass-reset" className="text-muted">Forgot password?</Link>
                                                            </div>
                                                            <Label className="form-label" htmlFor="password-input">Password</Label>
                                                            <div className="position-relative auth-pass-inputgroup mb-3">
                                                                <Input
                                                                onChange={(e)=> setPassword(e.target.value)}
                                                                type={passwordShow ? "text" : "password"} 
                                                                className="form-control pe-5 password-input"
                                                                placeholder="Enter password"
                                                                id="password-input"
                                                                required/>
                                                                <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button"
                                                                    id="password-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                            </div>
                                                        </div>

                                                        <div className="form-check">
                                                            <Input onChange={()=>setKeepmelogin((keepmelogin)=> !keepmelogin)} className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                            <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                        </div>

                                                        
                                                        <div className="mt-4">
                                                            <Button onClick={signIn} disabled={error || loading ? true : false} color="success" className="w-100" type="submit">
                                                            {loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            Sign In</Button>
                                                            
                                                        </div>

                                                        {/* <div className="mt-4 text-center">
                                                            <div className="signin-other-title">
                                                                <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                            </div>

                                                            <div>
                                                                <Button color="primary" className="btn-icon me-1"><i className="ri-facebook-fill fs-16"></i></Button>
                                                                <Button color="danger" className="btn-icon me-1"><i className="ri-google-fill fs-16"></i></Button>
                                                                <Button color="dark" className="btn-icon me-1"><i className="ri-github-fill fs-16"></i></Button>
                                                                <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                            </div>
                                                        </div> */}

                                                    </form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">Don't have an account ? <Link to="/signup" className="fw-semibold text-primary text-decoration-underline"> Signup</Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <footer className="footer start-0">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center">
                                    <p className="mb-0">&copy; 2023 DwellDoc.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>

            </div>
        </React.Fragment>
    );
};

export default CoverSignIn;