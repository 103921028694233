import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTagsProperty } from "../../helpers/backend_helper";
import Select from "react-select";
import { MultiDropdown } from "../Common/multipleSelect/MultiDropdown";
import { setRefreshDropdown } from "../../slices/property/propertyFiles/reducer";

export const usePropertyFilesSearchTags = ( refresh,placeholder) => {
  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [selectValues, setValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    if (selectedProperty) {
      getTagsProperty(selectedProperty.id).then((res) =>
        setValues(res.map((elm) => ({ value: elm.id, label: elm.name })))
      );
      setSelectedValue([])
    }
  }, [selectedProperty]);

  useEffect(()=>{
    if (selectedProperty) {
      getTagsProperty(selectedProperty.id).then((res) =>
        setValues(res.map((elm) => ({ value: elm.id, label: elm.name })))
      );
    }
  },[refresh])


  const handleChange = (val) => {
    setSelectedValue(val);
  };

  function getVal() {
    return selectedValue.map((val) => val.value).join(',');
  }

  return [
    <MultiDropdown 
    defaultValue={[]}
    onChange={handleChange}
    value={selectedValue}
    noOptionsMessage={() => "No Tags Found.."}
    options={selectValues}
    placeholder={placeholder}
    />
    ,
    selectedValue,
    getVal,
  ];
};
