import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getInstallationTypes,
  getcontractorSelect,
} from "../../helpers/backend_helper";
import Select from "react-select";

import { Col } from "reactstrap";

export const InstallationTypeAndSearch = () => {
  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [installationType, setInstallationType] = useState("");

  const [installationTypes, setInstallationTypes] = useState([]);

  const [propertyContractors, setPropertyContractors] = useState([]);

  const [contractor, setContractor] = useState("");

  useEffect(() => {
    if (selectedProperty) {
      getcontractorSelect(selectedProperty.id).then((res) =>
        setPropertyContractors(
          res.map((cont) => ({
            label: cont.name,
            value: cont.id,
          }))
        )
      );
    }
  }, [selectedProperty]);

  useEffect(() => {
    getInstallationTypes().then((res) => setInstallationTypes(res));
  }, []);

  const getData = () => {
    return {
      installationType: installationType,
      installationContractor: contractor,
    };
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFFFFF"})
  }

  return [
    <>
      <Col lg={4}>
        <div className="input-group my-2" style={{ padding: 0 }}>
          <select
            className="form-select"
            id="selectType"
            aria-label="Select"
            onChange={(e) => setInstallationType(e.target.value)}
            value={installationType}
          >
            <option value="" disabled selected>
              Select Installation Type
            </option>
            {installationTypes.map((type) => (
              <option value={type.name}>{type.name}</option>
            ))}
          </select>
        </div>
      </Col>
      {installationType === "Service Provider" ? (
        <Col lg={12}>
          <Select
            options={propertyContractors}
            hideSelectedOptions={false}
            onChange={setContractor}
            value={contractor}
            placeholder={"Installation Contractor"}
            styles={customStyles}
          />
        </Col>
      ) : null}
    </>,
    getData,
    setInstallationType,
    setContractor
  ];
};
