import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  getSpaceSelectList,
  getmaintenanceTypes,
  // getmaintenanceTypes,
//
  editRetrievemaintenance,
  getmaintenancePriorites,
  getmaintenanceFrequencies,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreatemaintenanceThunk,
  ListmaintenanceThunk,
  UpdatemaintenanceThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  maintenanceFailure,
  createSucess,
} from "../../../../slices/property/maintenance/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { setProperties } from "../../../../slices/property/listProperty/reducer";
import { TypesDropdown } from "../../flooring/upload/createForm";
import { get_spaces } from "../../../../Components/utils/spacesCompare";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const CreateImageForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [maintenance, setmaintenance] = useState(null);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  // const [files, setFiles] = useState([]);

  const [imagesfiles, setimagesFiles] = useState([]);
  const [reciepts, setrecieptsFiles] = useState([]);

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect()

  useEffect(() => {
    // console.log(update, id);
    if (update && id >= 0) {
      editRetrievemaintenance(id).then((res) => setmaintenance(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: maintenance ? maintenance.name : "",
      lastCompleted: maintenance ? maintenance.lastCompleted || "" : "",
      nextDueDate: maintenance ? maintenance.nextDueDate || "" : "",
      frequency: maintenance ? maintenance.frequency || "" : "",
      details: maintenance ? maintenance.details || "" : "",
      cost: maintenance ? maintenance.cost || "" : "",
      warrantyDetails: maintenance ? maintenance.warrantyDetails || "" : "",
      notes: maintenance ? maintenance.notes || "" : "",
      priority: maintenance ? maintenance.priority || "" : "",
      budget: maintenance ? maintenance.budget || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      lastCompleted: Yup.string(),
      nextDueDate: Yup.string(),
      frequency: Yup.string(),
      details: Yup.string(),
      cost: Yup.number().positive("must be a positive number"),
      budget: Yup.number().positive("must be a positive number"),
      warrantyDetails: Yup.string(),
      notes: Yup.string(),
      priority: Yup.string()
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  const [maintenanceTypes,setTypes] = useState([])
  const [TypesComponent, getSelectedTypes, setType, setSubType] = TypesDropdown(
    {
      types: maintenanceTypes,
      size1:12,
      size2:4,
      placeholder:"Task Type"
    }
  );

  // maintenance type
  const [priorities, setpriorities] = useState([]);

  // maintenance energy type
  const [frequencies, setfrequencies] = useState([]);

  const [SpaceSelectList, setSpaceSelectList] = useState([]);


  const [
    installationComponent,
    getInstallationData,
    setInstallationType,
    setContractor,
  ] = InstallationTypeAndSearch();

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.maintenance.btnLoading,
  }));


  useEffect(() => {
    getmaintenancePriorites().then((res) => setpriorities(res));
  }, []);

  useEffect(() => {
    getmaintenanceFrequencies().then((res) => setfrequencies(res));
  }, []);

  useEffect(() => {
    getmaintenanceTypes().then((res) => setTypes(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != maintenance[key]) {
        formdata[key] = values[key]
      }
    }

    var imagesfiles = getImageVal()
    var [modifiedImages,Images] = getCreateAndDeleteFiles(maintenance.images.map((inst) => inst.id),imagesfiles)
    if (modifiedImages){
      formdata["images"] = Images
    }
    
    var reciepts = getRecieptVal()
    var [modifiedreciepts,recieptsFiles] = getCreateAndDeleteFiles(maintenance.reciepts.map((inst) => inst.id),reciepts)
    if (modifiedreciepts){
      formdata["reciepts"] = recieptsFiles
    }

    const type = getSelectedTypes();

    if(type.selectedType){
      formdata["taskType"] = type.selectedType;
      formdata["taskSubType"] = type.selectedSubType;
    }

    const spaces = get_spaces(maintenance.spaces,selectedSpaces)
    if(spaces){
      formdata[
        "spaces"] =
        [spaces.join(",")]
      
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata["installationType"] = installationData.installationType;
      if (installationData.installationType === "Service Provider") {
        formdata[
          "installationContractor"] =
          installationData.installationContractor.value
        
      }
    }

    if (Object.keys(formdata).length === 0) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdatemaintenanceThunk(id, formdata, onUpdateSucces));
    }
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      formdata.append(key, values[key]);
    }

    var imagesfiles = getImageVal()
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }
    var reciepts = getRecieptVal()
    for (let reciept of reciepts) {
      formdata.append("reciepts", reciept);
    }

    const type = getSelectedTypes();

    if(type.selectedType){
      formdata.append("taskType", type.selectedType);
      formdata.append("taskSubType", type.selectedSubType);
    }

    if (selectedSpaces.length > 0) {
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata.append("installationType", installationData.installationType);
      if (installationData.installationType === "Service Provider") {
        formdata.append(
          "installationContractor",
          installationData.installationContractor.value
        );
      }
    }

    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    const installationData = getInstallationData();

    const type = getSelectedTypes();

    checkOrError(
      !(type.needSubType && !type.selectedSubType),
      errs,
      `you need to select subType for type: ${type.selectedType}`
    );

    checkOrError(
      !(
        installationData.installationType &&
        installationData.installationType === "Service Provider" &&
        !installationData.installationContractor
      ),
      errs,
      "you need to select Installation Contractor for installation of Type Service Provider"
    );

    if (errs.length > 0) {
      dispatch(maintenanceFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListmaintenanceThunk(selectedProperty.id));
      };
      dispatch(
        CreatemaintenanceThunk(selectedProperty.id, getFormdata(values), onSuccess)
      );
    }
  }

  useEffect(() => {
    if (maintenance) {
      setSelectedSpaces(
        maintenance.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setType(maintenance.taskType);
      setSubType(maintenance.taskSubType);
      setInstallationType(maintenance.installationType);
      dispatch(setSelectedImageFiles(maintenance.images))
      dispatch(setSelectedRecieptFiles(maintenance.reciepts))
      if (maintenance.installationContractor) {
        // console.log(maintenance.installationContractor,maintenance.installationContractor)
        setContractor({
          label: maintenance.installationContractor.name,
          value: maintenance.installationContractor.id,
        });
      } else {
        setContractor("");
      }
    }
  }, [maintenance]);

  useEffect(()=>{
    
    if(validation.values.lastCompleted){
      var date = new Date(validation.values.lastCompleted)
    
      if(validation.values.frequency === 'Monthly'){
        date.setMonth(date.getMonth()+1)
        validation.setFieldValue("nextDueDate",date.toISOString().split('T')[0])
      }else if(validation.values.frequency === 'Quarterly'){
        date.setMonth(date.getMonth()+4)
        validation.setFieldValue("nextDueDate",date.toISOString().split('T')[0])
      }else if(validation.values.frequency === 'Semi-Annually'){
        date.setMonth(date.getMonth()+6)
        validation.setFieldValue("nextDueDate",date.toISOString().split('T')[0])
      }else if(validation.values.frequency === 'Annually'){ 
        date.setFullYear(date.getFullYear()+1)
        validation.setFieldValue("nextDueDate",date.toISOString().split('T')[0])
      }
    }
    

  },[validation.values.lastCompleted , validation.values.frequency])

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >

      {RecieptModal}
      {ImageModal}
      <Row>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => validation.setFieldValue("frequency",e.target.value)}
              value={validation.values.frequency}
            >
              <option value="" disabled selected>
              Frequency
              </option>
              {(frequencies|| []).map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={validation.values.lastCompleted}
                className="form-control"
                onChange={(date, dateStr) => {
                  validation.setFieldValue("lastCompleted",dateStr)
                  // setDate(dateStr);
                }}
                placeholder="Last Completed"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={validation.values.nextDueDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  // setinstallationDate(dateStr);
                  validation.setFieldValue("nextDueDate",dateStr)
                }}
                placeholder="Next Due Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => validation.setFieldValue("priority",e.target.value)}
              value={validation.values.priority}
            >
              <option value="" disabled selected>
              Priority
              </option>
              {(priorities||[]).map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        

        <>
          <Col lg={6}>
            {/* <FilePond
              files={imagesfiles}
              onupdatefiles={setimagesFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={["image/png", "image/jpeg"]}
              labelIdle={"Upload Images: Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {ImageViewerComponent}
          </Col>
          <Col lg={6}>
            {/* <FilePond
              files={reciepts}
              onupdatefiles={setrecieptsFiles}
              allowMultiple={true}
              // maxFiles={3}
              acceptedFileTypes={[
                "image/png",
                "image/jpeg",
                "file/pdf",
                "application/pdf",
              ]}
              labelIdle={"Upload Receipt(s): Drag here or Click to browse"}
              name="files"
              className="filepond filepond-input-multiple"
            /> */}
            {RecieptViewerComponent}
          </Col>
        </>
        {TypesComponent}
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="budget-input"
              placeholder="Budget"
              name="budget"
              value={validation.values.budget}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.budget && validation.touched.budget
                  ? true
                  : false
              }
            />

            {validation.errors.budget && validation.touched.budget ? (
              <FormFeedback type="invalid">
                {validation.errors.budget}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="cost-input"
              placeholder="Cost $"
              name="cost"
              value={validation.values.cost}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.cost && validation.touched.cost ? true : false
              }
            />

            {validation.errors.cost && validation.touched.cost ? (
              <FormFeedback type="invalid">
                {validation.errors.cost}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={8}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="details-input"
              placeholder="Details/Other"
              name="details"
              value={validation.values.details}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.details &&
                validation.touched.details
                  ? true
                  : false
              }
            />

            {validation.errors.details &&
            validation.touched.details ? (
              <FormFeedback type="invalid">
                {validation.errors.details}
              </FormFeedback>
            ) : null}
          </div>
        </Col>


        <Col lg={4}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              // placeholder={"Location(s) Served"}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="warrantyDetails-input"
              placeholder="Warranty Details"
              name="warrantyDetails"
              value={validation.values.warrantyDetails}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.warrantyDetails &&
                validation.touched.warrantyDetails
                  ? true
                  : false
              }
            />

            {validation.errors.warrantyDetails &&
            validation.touched.warrantyDetails ? (
              <FormFeedback type="invalid">
                {validation.errors.warrantyDetails}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {installationComponent}
        <Col lg={10}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update maintenance</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
