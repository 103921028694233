
import {  setIsLoading , setnotes , notesFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { createNoteBE, deleteNoteBE, getNotesBE, updateNoteBE } from "../../../helpers/backend_helper";

export const ListnotesThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getNotesBE(pid,params)
    .then((res)=> dispatch(setnotes(res)))
    .catch((err) => dispatch(notesFailure(err)))

}

export const CreatenotesThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createNoteBE(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdatenotesThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateNoteBE(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeletenotesThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteNoteBE(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(notesFailure(err)))
    .finally(()=> closeModal())
}