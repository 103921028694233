// import { useEffect, useCallback, useRef } from "react";
// import { unstable_usePrompt as usePrompt , useBeforeUnload } from "react-router-dom";
 
// const stepLinks = ["/contact", "/education", "/about", "/confirm"];
 
// export const FormPrompt = ({ hasUnsavedChanges }) => {
//     const onLocationChange = useCallback(
//         ({ nextLocation }) => {
//           if (hasUnsavedChanges) {
//             return !window.confirm(
//               "You have unsaved changes, are you sure you want to leave?"
//             );
//           }
//           return false;
//         },
//         [hasUnsavedChanges]
//       );
     
//       usePrompt(onLocationChange, hasUnsavedChanges);
//       useBeforeUnload(
//         useCallback(
//           (event) => {
//             if (hasUnsavedChanges) {
//               event.preventDefault();
//               event.returnValue = "";
//             }
//           },
//           [hasUnsavedChanges]
//         ),
//         { capture: true }
//       );
     
//       return null;
// };

// function usePrompt(onLocationChange, hasUnsavedChanges) {
//     const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
//     const prevState = useRef(blocker.state);
   
//     useEffect(() => {
//       if (blocker.state === "blocked") {
//         blocker.reset();
//       }
//       prevState.current = blocker.state;
//     }, [blocker]);
// }


import { useEffect } from 'react';
// import { unstable_useBlocker as useBlocker } from 'react-router-dom'

// export function Prompt(props) {
//     const block = props.when
    
//     useEffect(() => {
//         const onBeforeUnload = (e) => {
//           if (props.when) {
//             e.preventDefault();
//             e.returnValue = "";
//           }
//         };
//         window.addEventListener("beforeunload", onBeforeUnload);
     
//         return () => {
//           window.removeEventListener("beforeunload", onBeforeUnload);
//         };
//       }, [props.when]);

//     useBlocker(() => {
//         if (block) {
//             return !window.confirm(props.message)
//         }
//         return false
//     })

//   return (
//     <div key={block}/>
//   )
// }

export function Prompt(props) {
  return (
    <div />
  )
}

export default Prompt