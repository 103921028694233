import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getFLOORINGTypes,
  getFLOORINGPlacementTypes,
  getInstallationTypes,
  // getFLOORINGplacementTypes,
  // retrieveFLOORING,
  editRetrieveFLOORING,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFLOORINGThunk,
  ListFLOORINGThunk,
  UpdateFLOORINGThunk,
} from "../../../../slices/thunks";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FLOORINGFailure,
  createSucess,
} from "../../../../slices/property/flooring/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { get_spaces } from "../../../../Components/utils/spacesCompare";
import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileValidateType);

export const TypesDropdown = ({ types, size1=4 , size2=2 , placeholder="Select Flooring Type" ,subTypePlaceholder="Select SubType" ,otherPlaceholder ="Enter your custom type" }) => {
  const Types = useMemo(
    () =>{
      return types.map((type) => {
        if (type instanceof Object) {
          const label = Object.keys(type)[0];
          return {
            name: label,
            SubTypes: type[label],
          };
        } else {
          return {
            name: type,
            SubTypes: [],
          };
        }
      })
    },
    [types]
  );

  const [selectedType, setSelectedType] = useState({
    selectedType: "",
    selectedSubType: "",
    currentSubTypes: [],
  });

  const [pending , setPending] = useState(null)
  // const [other , setOther] = useState(null)

  const changeType = (val,resetSubType=true) => {
    const idx = Types.findIndex((object) => object.name === val);
    if (idx !== -1) {
      if(resetSubType){
        setSelectedType({
          selectedType: Types[idx].name,
          selectedSubType: "",
          currentSubTypes: Types[idx].SubTypes,
        });
      }else{
        setSelectedType((oldParams) => ({
          ...oldParams,
          selectedType: Types[idx].name,
          currentSubTypes: Types[idx].SubTypes,
        }) );
      }
      
    } else {
      setPending(val)
      setSelectedType({
        selectedType: "",
        currentSubTypes: [],
      });
    }
  };

  useEffect(()=>{
    // // // console.log(Types)
    if(Types && Types.length > 0 && pending){
      changeType(pending,false)
      setPending(null)
    }
  },[Types])

  const changeSubType = (val) => {
    setSelectedType((oldParams) => ({
      ...oldParams,
      selectedSubType: val,
    }));
  };

  const getSelectedTypes = () => {
    return {
      selectedType: selectedType.selectedType,
      selectedSubType: selectedType.selectedSubType,
      needSubType: selectedType.currentSubTypes.length > 0,
    };
  };

  const hasSubType = selectedType && selectedType.currentSubTypes.length > 0;
  const otherSelected = selectedType.selectedType === "Other";
  return [
    <>
      <Col lg={ (hasSubType||otherSelected) ? size2 : size1}>
        <div className="input-group my-2" style={{ padding: 0 }}>
          <select
            className="form-select"
            id="selectType"
            aria-label="Select"
            // style={{ backgroundColor: "#eff2f7" }}
            onChange={(e) => changeType(e.target.value)}
            value={selectedType.selectedType}
          >
            <option value="" disabled selected>
              {placeholder}
            </option>
            {Types.map((type) => (
              <option value={type.name}>{type.name}</option>
            ))}
          </select>
        </div>
      </Col>
      {hasSubType ? (
        <Col lg={size1-size2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
          <select
              className="form-select"
              id="selectType"
              aria-label="Select"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => changeSubType(e.target.value)}
              value={selectedType.selectedSubType}
            >
              <option value="" disabled selected>
                {subTypePlaceholder}
              </option>
              {selectedType.currentSubTypes.map((type) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>
        </Col>
      ) : null}
      { otherSelected ? (
        <Col lg={size1-size2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
          <Input
              type="text"
              className="form-control"
              placeholder={otherPlaceholder}
              value={selectedType.selectedSubType}
              onChange={(e) => changeSubType(e.target.value)}
            />
          </div>
        </Col>
      ) : null}

    </>,
    getSelectedTypes,
    changeType,
    changeSubType,
  ];
};

export const CreateForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [FLOORING, setFLOORING] = useState(null);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [StatusSelectComponent , getStatusVal , setStatus] = useStatusSelect();
  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect()

  useEffect(() => {
    if (update && id >= 0) {
      editRetrieveFLOORING(id).then((res) => setFLOORING(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: FLOORING ? FLOORING.name : "",
      cost: FLOORING ? FLOORING.cost || "" : "",
      brand: FLOORING ? FLOORING.brand || "" : "",
      warrantyDetails: FLOORING ? FLOORING.warrantyDetails || "" : "",
      notes: FLOORING ? FLOORING.notes || "" : "",
      color: FLOORING ? FLOORING.color || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      cost: Yup.number(),
      // .required("This field is required")
      // .positive("must be a number"),
      brand: Yup.string(),
      // .required("This field is required"),
      warrantyDetails: Yup.string(),
      // .required("This field is required"),
      color: Yup.string(),
      // .required("This field is required"),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // FLOORING type
  const [FLOORINGTypesList, setFLOORINGTypesList] = useState([]);

  const [TypesComponent, getSelectedTypes, setType, setSubType] = TypesDropdown(
    {
      types: FLOORINGTypesList,
    }
  );

  // const [selectedFLOORING, setselectedFLOORING] = useState(null);

  // FLOORING energy type
  const [FLOORINGplacementList, setFLOORINGplacementList] = useState([]);
  const [selectedFLOORINGplacement, setselectedFLOORINGplacement] =
    useState(null);

  const [SpaceSelectList, setSpaceSelectList] = useState([]);
  // const [selectedSpace, setSelectedSpace] = useState(null);

  const [
    installationComponent,
    getInstallationData,
    setInstallationType,
    setContractor,
  ] = InstallationTypeAndSearch();

  const [date, setDate] = useState("");
  const [installationDate, setinstallationDate] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.FLOORING.btnLoading,
  }));

  // const [selectedFileReciept, setFileReciept] = useState(null);

  useEffect(() => {
    getFLOORINGTypes().then((res) => setFLOORINGTypesList(res));
  }, []);

  useEffect(() => {
    getFLOORINGPlacementTypes().then((res) => setFLOORINGplacementList(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function Update(values) {
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != FLOORING[key]) {
        formdata[key] = values[key];
      }
    }

    const statusVal = getStatusVal();
    if (statusVal && statusVal !== FLOORING.status ){
      formdata["status"] =  statusVal
    }

    var imagesfiles = getImageVal()
    var [modifiedImages,Images] = getCreateAndDeleteFiles(FLOORING.images.map((inst) => inst.id),imagesfiles)
    if (modifiedImages){
      formdata["images"] = Images
    }
    
    var reciepts = getRecieptVal()
    var [modifiedreciepts,recieptsFiles] = getCreateAndDeleteFiles(FLOORING.reciepts.map((inst) => inst.id),reciepts)
    if (modifiedreciepts){
      formdata["reciepts"] = recieptsFiles
    }

    if (date && date !== FLOORING.purchaseDate) {
      formdata["purchaseDate"] = date;
    }
    if (installationDate && installationDate !== FLOORING.installationDate) {
      formdata["installationDate"] = installationDate;
    }

    const type = getSelectedTypes();
    formdata["flooringType"] = type.selectedType;
    if(type.selectedSubType){
      formdata["flooringSubType"] = type.selectedSubType;
    }

    if (selectedFLOORINGplacement && selectedFLOORINGplacement != FLOORING.placement) {
      formdata["placement"] = selectedFLOORINGplacement;
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata["installationType"] = installationData.installationType;
      if (installationData.installationType === "Service Provider") {
        formdata[
          "installationContractor"] =
          installationData.installationContractor.value
        
      }
    }
    const spaces = get_spaces(FLOORING.spaces,selectedSpaces)
    if(spaces){
      formdata["spaces"] =
        [spaces.join(",")]
      
    }

    if ( Object.keys(formdata).length === 0) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdateFLOORINGThunk(id, formdata, onUpdateSucces));
    }
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      formdata.append(key, values[key]);
    }

    formdata.append("status",getStatusVal());
    
    var imagesfiles = getImageVal()
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }
    var reciepts = getRecieptVal()
    for (let reciept of reciepts) {
      formdata.append("reciepts", reciept);
    }
    
    if (date) {
      formdata.append("purchaseDate", date);
    }
    if (installationDate) {
      formdata.append("installationDate", installationDate);
    }

    const type = getSelectedTypes();

    formdata.append("flooringType", type.selectedType);
    formdata.append("flooringSubType", type.selectedSubType);
    if (selectedFLOORINGplacement) {
      formdata.append("placement", selectedFLOORINGplacement);
    }

    if (selectedSpaces.length > 0) {
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata.append("installationType", installationData.installationType);
      if (installationData.installationType === "Service Provider") {
        formdata.append(
          "installationContractor",
          installationData.installationContractor.value
        );
      }
    }

    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    const type = getSelectedTypes();

    checkOrError(
      type.selectedType,
      errs,
      "you need to select Type for your flooring"
    );
    checkOrError(
      !(type.needSubType && !type.selectedSubType),
      errs,
      `you need to select subType for type: ${type.selectedType}`
    );

    checkOrError(
      selectedFLOORINGplacement,
      errs,
      "you need to select Flooring placement type"
    );

    const statusVal = getStatusVal();
    checkOrError(
      statusVal,
      errs,
      "you need to select status"
    );

    const installationData = getInstallationData();

    checkOrError(
      !(
        installationData.installationType === "Service Provider" &&
        !installationData.installationContractor
      ),
      errs,
      "you need to select Installation Contractor for installation of Type Service Provider"
    );

    if (errs.length > 0) {
      dispatch(FLOORINGFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListFLOORINGThunk(selectedProperty.id));
      };
      dispatch(
        CreateFLOORINGThunk(selectedProperty.id, getFormdata(values), onSuccess)
      );
    }
  }

  useEffect(() => {
    if (FLOORING) {
      setSelectedSpaces(
        FLOORING.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setDate(FLOORING.purchaseDate);
      setinstallationDate(FLOORING.installationDate);
      setselectedFLOORINGplacement(FLOORING.placement);
      setType(FLOORING.flooringType);
      setSubType(FLOORING.flooringSubType);
      setInstallationType(FLOORING.installationType);
      dispatch(setSelectedImageFiles(FLOORING.images))
      dispatch(setSelectedRecieptFiles(FLOORING.reciepts));
      setStatus({label:FLOORING.status,value:FLOORING.status});
      if (FLOORING.installationContractor) {
        setContractor({
          label: FLOORING.installationContractor.name,
          value: FLOORING.installationContractor.id,
        });
      } else {
        setContractor("");
      }
    }
  }, [FLOORING]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      {RecieptModal}
      {ImageModal}
      <Row>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {TypesComponent}

        <Col className="my-2" lg={4}>
          {StatusSelectComponent}
        </Col>

        {/* {!(id && update) && ( */}
          <>
            <Col lg={6}>
              {/* <FilePond
                files={imagesfiles}
                onupdatefiles={setimagesFiles}
                allowMultiple={true}
                // maxFiles={3}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                labelIdle={"Drag & Drop your Flooring Image(s) or Browse"}
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {ImageViewerComponent}
            </Col>
            <Col lg={6}>
              {/* <FilePond
                files={reciepts}
                onupdatefiles={setrecieptsFiles}
                allowMultiple={true}
                // maxFiles={3}
                acceptedFileTypes={['image/png', 'image/jpeg','file/pdf','application/pdf']}
                labelIdle={
                  "Drag & Drop your Flooring Reciept(s)/Barcode or Browse"
                }
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {RecieptViewerComponent}
            </Col>
          </>
        {/* )} */}

        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedFLOORINGplacement(e.target.value)}
              value={selectedFLOORINGplacement}
            >
              <option value="" disabled selected>
                Placement
              </option>
              {FLOORINGplacementList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="cost-input"
              placeholder="Cost per square ft"
              name="cost"
              value={validation.values.cost}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.cost && validation.touched.cost ? true : false
              }
            />

            {validation.errors.cost && validation.touched.cost ? (
              <FormFeedback type="invalid">
                {validation.errors.cost}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="brand-input"
              placeholder="Brand"
              name="brand"
              value={validation.values.brand}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.brand && validation.touched.brand
                  ? true
                  : false
              }
            />

            {validation.errors.brand && validation.touched.brand ? (
              <FormFeedback type="invalid">
                {validation.errors.brand}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
        <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="unit-input"
              placeholder="Color"
              name="color"
              value={validation.values.color}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.color && validation.touched.color
                  ? true
                  : false
              }
            />

            {validation.errors.color && validation.touched.color ? (
              <FormFeedback type="invalid">
                {validation.errors.color}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={date}
                className="form-control"
                onChange={(date, dateStr) => {
                  setDate(dateStr);
                }}
                placeholder="Purchase Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={installationDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  setinstallationDate(dateStr);
                }}
                placeholder="Installation Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              // placeholder={"Location(s) Served"}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="warrantyDetails-input"
              placeholder="Warranty Details"
              name="warrantyDetails"
              value={validation.values.warrantyDetails}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.warrantyDetails &&
                validation.touched.warrantyDetails
                  ? true
                  : false
              }
            />

            {validation.errors.warrantyDetails &&
            validation.touched.warrantyDetails ? (
              <FormFeedback type="invalid">
                {validation.errors.warrantyDetails}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {installationComponent}
        <Col lg={10}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update Flooring</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
