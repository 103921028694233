import { useState } from "react"
import { unSelectFile } from "../../slices/property/propertyFilesImages/reducer";
import { SelectedFilesComponent } from "../Common/fileSelectComponents/selectedFiles";
import { useSelector } from "react-redux";
import { ImageInputModal } from "../Common/fileSelectComponents/imageWraper";


export const useImageSelect = (detail=false,tittleText="Image(s)",multiple=true) => {

    const [showReciept , setShowReciept] = useState(false)
    const data = useSelector((state) => ({
        selectedFiles: state.propertyFilesImages.selectedFiles,
      }));
    
    const getVal = () => {
        return data.selectedFiles.map((file) => file.id)
    }

    return [
        <ImageInputModal multiple={multiple} show={showReciept} toggle={() => setShowReciept(false)}/>,
        <SelectedFilesComponent multiple={multiple} tittleText={tittleText} detail={detail} {...data} unSelectFile={unSelectFile} className="shadow-lg" view onClick={() => setShowReciept(true)} />,
        getVal
    ]
}