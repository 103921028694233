import { Col, Row, Input, Label, Form, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getutilityTypes,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFloorThunk,
  CreateimageThunk,
  CreateinventoryThunk,
  CreateSpaceThunk,
  CreateutilityThunk,
  getFloorsSideNavThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListinventoryThunk,
  ListSpacesThunk,
  ListutilityThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// import { SpacesDropdown } from "./spacesDropdown";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";
import Flatpickr from "react-flatpickr";
import { utilityFailure } from "../../../../slices/property/utilites/reducer";

import { camelize } from "../../../../helpers/api_helper";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview,FilePondPluginFileValidateType);

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

export const CreateBillForm = ({ onSuccess = (type) => {} }) => {
  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const [types, setTypes] = useState([]);

  const [utilTypes, setutilTypes] = useState([]);
  const [date, setDate] = useState("");
  const [varList, setVarList] = useState([]);

  const [additionalValues, setAdditionalValues] = useState({});
  const [notes, setnotes] = useState("");

  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Bill File",false)

  useEffect(() => {
    getutilityTypes().then((res) => {
      setTypes(res);
      const srcs = Object.keys(res);
      setutilTypes(srcs);
    });
  }, []);

  const [type, setType] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.utility.btnLoading,
  }));

  const [selectedFile, setFile] = useState([]);
  const [name, setName] = useState("");

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (type && types) {
      const vars = types[type];
      setVarList(
        vars.map((variable) => ({
          key: camelize(variable),
          placeholder: variable,
        }))
      );

      let objj = {};
      for (let variable of vars) {
        objj[camelize(variable)] = "";
      }
      setAdditionalValues(objj);
    }
  }, [type]);

  // useEffect(() => {
  //   console.log(additionalValues);
  // }, [additionalValues]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    // if (selectedFile) {
      
    // }
    // if (selectedFile.length > 0){
    //   formdata.append("file", selectedFile[0].file);
    // }
    const image = getImageVal()
    if (image.length > 0){
      formdata.append("file", image[0]);
    }
    // formdata.append("spaces", selectedSpaces.join(","));
    if (selectedSpaces.length > 0) {
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => space.value).join(",")
      );
    }
    formdata.append("typee", type);
    formdata.append("date", date);
    formdata.append("notes", notes);
    const keys = Object.keys(additionalValues);
    for (let key of keys) {
      formdata.append(key, additionalValues[key]);
    }
    return formdata;
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Bill ");
    // checkOrError(selectedFile, errs, "you need to select a File for your bill usually your bill image");
    checkOrError(type, errs, "you need to select a type for your bill");
    checkOrError(date, errs, "you need to select a date for your bill");
    // checkOrError(selectedSpaces, errs, "you need to select spaces which related to your bill");

    const keys = Object.keys(additionalValues);
    for (let key of keys) {
      checkOrError(
        additionalValues[key],
        errs,
        `${key} field is mandatory for bills of type ${type}`
      );
    }

    if (errs.length > 0) {
      dispatch(utilityFailure(errs));
    } else {
      dispatch(
        CreateutilityThunk(selectedProperty.id, getFormdata(), () =>
          onSuccess(type)
        )
      );
    }
  }

  return (
    <Form >
      {ImageModal}
      <Row>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Col>
        {/* <Col lg={2}>
          <div className="form-group my-2">
            <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            >
              <label
                for="files"
                style={{ backgroundColor: "#299CDB", width: "100%" }}
                class="btn btn-txt m-0"
              >
                {selectedFile ? selectedFile.name : "Upload Bill"}
              </label>
              <input
                id="files"
                style={{ display: "None", backgroundColor: "#299CDB" }}
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>
          </div>
        </Col> */}
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={date}
                className="form-control"
                onChange={(date, dateStr) => {
                  setDate(dateStr);
                }}
                placeholder="Bill Date"
                options={{disableMobile:true}}
                // style={{ backgroundColor: "#ced4da" }}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" disabled selected>
                Bill Type
              </option>
              {utilTypes.map((type) => (
                <option value={type}>{type}</option>
              ))}
            </select>
          </div>
        </Col>

        {varList?.map((variable) => (
          <Col lg={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="number"
                  className="form-control"
                  placeholder={variable.placeholder}
                  aria-label={variable.placeholder}
                  value={additionalValues[variable.key]}
                  onChange={(e) =>
                    setAdditionalValues((oldVal) => {
                      var copy = { ...oldVal };
                      copy[variable.key] = e.target.value;
                      return copy;
                    })
                  }
                />
              </div>
            </div>
          </Col>
        ))}

        <Col lg={4}>
          <div className="my-2">
            <SpacesDropdown
              value={selectedSpaces}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
            />
          </div>
        </Col>

        <Col lg={12}>
          {/* <FilePond
            files={selectedFile}
            onupdatefiles={setFile}
            allowMultiple={true}
            maxFiles={1}
            // acceptedFileTypes={['image/png', 'image/jpeg']}
            labelIdle={"Upload Bill: Drag here or Click to browse"}
            name="files"
            className="filepond filepond-input-multiple"
          /> */}
          {ImageViewerComponent}
        </Col>
        <Col lg={10}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>

        <Col lg={2}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            <i className="ri-add-line align-bottom me-1 btn-success"></i> Create
            New
          </button>
        </Col>
      </Row>
    </Form>
  );
};
