import { useEffect, useState } from "react";
import { Alert, Col, Row, Tooltip } from "reactstrap";

// const format = {
//   header: {
//     title: "",
//     data: "",
//   },
//   data: [
//     {
//       title: "",
//       data: "",
//     },
//   ],
// };

const TooltipHeader = ({ title, data , toggle }) => {
  return (
    <Alert closeClassName="" className="tooltip-header">
      <Row>
        <Col sm={1}>
          <i
            style={{ fontSize: "17px" }}
            className="bx bxs-info-circle info-icon"
          />
        </Col>
        <Col sm={11}>
          <h5>{title}</h5>
          <p>{data}</p>
        </Col>
      </Row>
      <div onClick={toggle} className="tooltip-closeicon">
        <i className="ri-close-line" />
      </div>
    </Alert>
  );
};

const TooltipBody = ({ elements = [] }) => {
  // console.log(elements)
  return (
    <Alert className="tooltip-body">
      {elements.map((el) => (
        <div>
          <strong>{el.title} : </strong>
          <span>{el.data}</span>
        </div>
      ))}
    </Alert>
  );
};

export const TooltipItem = ({ id, data, size="lg" ,placement = "right" }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(()=>{
    const scrollFunc = function() {
      setTooltipOpen(false)
    }
    window.addEventListener('scroll', scrollFunc);
    return () => window.removeEventListener('scroll',scrollFunc)
  },[])

  return (
    <span>
      <i id={"Tooltip-" + id} className="bx bxs-info-circle info-icon" />
      <Tooltip
        placement={placement}
        isOpen={tooltipOpen}
        toggle={() => setTooltipOpen(!tooltipOpen)}
        target={"Tooltip-" + id}
        autohide={true}
        delay={{show:0,hide: 200}}
        style={{
          padding: "0px",
          backgroundColor: "transparent",
          maxWidth: data.data ? "800px": "400px",
          borderRadius:'4px'
        }}
        trigger={"click"}
        className={"info-tooltip " + placement + ` tooltip-container-${data.data ? "lg": "sm"}`}
      >
        <TooltipHeader toggle={() => setTooltipOpen(!tooltipOpen)} title={data.header.title} data={data.header.data} />
        {data.data ? <TooltipBody elements={data.data} /> : null}
      </Tooltip>
    </span>
  );
};
