import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";
import {
  Pagination,
  PaginationWithDetails,
} from "../../Components/Common/pagination";
// import { DefaultColumnFilter } from "./filters";
// import {
//   ProductsGlobalFilter,
//   CustomersGlobalFilter,
//   OrderGlobalFilter,
//   ContactsGlobalFilter,
//   CompaniesGlobalFilter,
//   LeadsGlobalFilter,
//   CryptoOrdersGlobalFilter,
//   InvoiceListGlobalSearch,
//   TicketsListGlobalFilter,
//   NFTRankingGlobalFilter,
//   TaskListGlobalFilter
// } from "../../Components/Common/GlobalSearchFilter";

// Define a default UI for filtering
// function GlobalFilter({
//   preGlobalFilteredRows,
//   globalFilter,
//   setGlobalFilter,
//   isCustomerFilter,
//   isOrderFilter,
//   isContactsFilter,
//   isCompaniesFilter,
//   isCryptoOrdersFilter,
//   isInvoiceListFilter,
//   isTicketsListFilter,
//   isNFTRankingFilter,
//   isTaskListFilter,
//   isProductsFilter,
//   isLeadsFilter
// }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <React.Fragment>
//       <CardBody className="border border-dashed border-end-0 border-start-0">
//         <form>
//           <Row className="g-3">
//             <Col>
//               <div className={(isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter) ? "search-box me-2 mb-2 d-inline-block" : "search-box me-2 mb-2 d-inline-block col-12"}>
//                 <input
//                   onChange={(e) => {
//                     setValue(e.target.value);
//                     onChange(e.target.value);
//                   }}
//                   id="search-bar-0"
//                   type="text"
//                   className="form-control search /"
//                   placeholder={`${count} Search...`}
//                   value={value || ""}
//                 />
//                 <i className="bx bx-search-alt search-icon"></i>
//               </div>
//             </Col>
//             {isProductsFilter && (
//               <ProductsGlobalFilter />
//             )}
//             {isCustomerFilter && (
//               <CustomersGlobalFilter />
//             )}
//             {isOrderFilter && (
//               <OrderGlobalFilter />
//             )}
//             {isContactsFilter && (
//               <ContactsGlobalFilter />
//             )}
//             {isCompaniesFilter && (
//               <CompaniesGlobalFilter />
//             )}
//             {isLeadsFilter && (
//               <LeadsGlobalFilter />
//             )}
//             {isCryptoOrdersFilter && (
//               <CryptoOrdersGlobalFilter />
//             )}
//             {isInvoiceListFilter && (
//               <InvoiceListGlobalSearch />
//             )}
//             {isTicketsListFilter && (
//               <TicketsListGlobalFilter />
//             )}
//             {isNFTRankingFilter && (
//               <NFTRankingGlobalFilter />
//             )}
//             {isTaskListFilter && (
//               <TaskListGlobalFilter />
//             )}
//           </Row>
//         </form>
//       </CardBody>

//     </React.Fragment>
//   );
// }

const TableContainer = ({
  columns,
  data,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  baseLink,
  // PageSize,
  currentPage,
  pageSize,
  total,
  numOfPages,
  onPageChange,
  hasPagination = true,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // page,
    prepareRow,
    rows,
    // canPreviousPage,
    // canNextPage,
    // pageOptions,
    // gotoPage,
    // nextPage,
    // previousPage,
    // setPageSize,
    // state,
    preGlobalFilteredRows,
    setGlobalFilter,
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      // defaultColumn: { Filter: DefaultColumnFilter },
      // initialState: {
      //   pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
      //     {
      //       desc: true,
      //     },
      //   ],
      // },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    // usePagination,
    useRowSelect
  );

  // const canNextPage = currentPage < numberOfPages;
  // const canPreviousPage = currentPage > 1;
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
  };

  // const onChangeInSelect = (event) => {
  //   setPageSize(Number(event.target.value));
  // };

  // const onChangeInInput = (event) => {
  //   const page = event.target.value ? Number(event.target.value) - 1 : 0;
  //   gotoPage(page);
  // };

  return (
    <Fragment>
      <Row className="mb-3">
        {/* {isGlobalSearch && (
          <Col md={1}>
            <select
              className="form-select"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isTaskListFilter={isTaskListFilter}
          />
        )} */}
      </Row>

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {headerGroups.map((headerGroup) => (
              <tr
                className={trClass}
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    key={column.id}
                    className={thClass}
                    {...column.getSortByToggleProps()}
                  >
                    {column.render("Header")}
                    {generateSortingIndicator(column)}
                    {/* <Filter column={column} /> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Fragment key={row.getRowProps().key}>
                  <tr>
                    {row.cells.map((cell) => {
                      return (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
      {hasPagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <PaginationWithDetails
            onPageChange={onPageChange}
            numOfPages={numOfPages}
            baseLink={baseLink}
            showingLength={data.length}
            currentPage={currentPage}
            pageSize={pageSize}
            total={total}
          />
          {/* < Pagination onPageChange={onPageChange} baseLink={baseLink} PageSize={PageSize}/> */}
        </Row>
      )}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
