import { useState,useEffect, useMemo } from 'react';
import { useSpring,animated } from "react-spring";

import active from '../../../assets/images/down-arrow.svg';


function removeDuplicates(arr1,arr2) {
    let unique = [];
    arr1.forEach(element => {
        if (arr2.findIndex(ele => element.value === ele.value) === -1) {
            unique.push(element);
        }
    });
    return unique;
}

export const SpaceFilter = ({
    levels,
    setValue,
    getValue,
}) =>{

    const val = getValue()

    const changeVal = (option , action) => {
        if(action === 'deselect') {
            setValue(val.filter((val) => val.value !== option.value) ,action , option)
        }else if(action === 'select') {
            setValue([ ...val , option],action , option)
        }else if(action === 'bulk-add') {
            const arr = removeDuplicates(option,val);
            setValue([ ...val , ...arr],action , option)
        }else if(action === 'bulk-remove'){
            setValue(val.filter((val) => option.findIndex((el) => el.value === val.value) === -1 ), action , option )
        }
    }

    const allOptions = useMemo(() => {
        var arr = []
        for(let level of levels){
            for (let option of level.options){
                arr.push(option)
            } 
        }
        return arr
    },[levels])

    function SelectAllClick(){
        if(val.length < allOptions.length){
            setValue(allOptions)
        }
    }

    function CancelClick(){
        if(val.length > 0){
            setValue([])
        }
    }

    return (
        <div className='brand-filter'>
            <h4 className='mb-3'>Spaces</h4>
            {levels.map((level)=> <FilterSection data={level.options} title={level.label} Val={val} onChange={changeVal} /> )}
            
            <div className='mt-3 p-2 d-flex justify-content-between'>
                <span onClick={CancelClick} className='cancel-button'>Clear All</span>
                <button type="button" onClick={SelectAllClick} className='add-item' style={{width:"30%",minWidth:"80px"}}>Select All</button>
            </ div>
        </div>
    )

}

const FilterSection = (props) => {

    const [showBrands,setShowBrands] =  useState(false);
    const checkboxes = useMemo(()=> {
        const arr = props.data.map((item,idx)=>{
            return (
                <BrandCheckButton key={idx} {...item} onChangeVal={props.onChange} Val={props.Val}/>
            )
        })
        return arr 
    },[props.data])

    const heightSpring  = useSpring({
        maxHeight: `${showBrands ? "300px" : "0px"}`,
        config: { mass: 5, tension: 500, friction: 80 },
    })

    const rotateSpring  = useSpring({
        transform: `rotate(${showBrands ? 0 : 90}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    })

    const SelectAllClick = () => {
        props.onChange(props.data,"bulk-add")
    }

    function CancelClick(){
        props.onChange(props.data,"bulk-remove")
    }

    return (
        <div>
             <div style={{color:"#002575",fontSize:"20px",fontWeight:"500"}} className= 'pt-2 ml-2 d-flex justify-content-between'>
                <span style={{}}>{props.title}</span>
                <animated.div onClick={()=>setShowBrands((showBrands)=> !showBrands)} style={{...rotateSpring,cursor:"pointer",padding:'3px'}}>
                    <img src={active}/>
                </animated.div>      
            </div>
            <animated.div style={{overflowY:"auto",maxHeight:"290px",...heightSpring}}>
                <div className='mb-1 p-2 d-flex justify-content-between'>
                    <span onClick={CancelClick} className='cancel-button'>Clear All</span>
                    <button type="button" onClick={SelectAllClick} className='add-item' style={{width:"30%",minWidth:"80px"}}>Select All</button>
                </ div>
                {checkboxes}
            </animated.div >
        </div>
    )
}

const BrandCheckButton = ({onChangeVal,Val,...props}) => {

    const [index,setIndex] = useState(false);
    
    function onChange(){
        
        if(index){
            setIndex(false);
            onChangeVal(props,"deselect");     
        
        }else{
            
            setIndex(true);
            onChangeVal(props,"select");
        }
    }

    useEffect(()=>{
        
        const idx = Val.findIndex((element) => element.value === props.value)
        if(idx === -1){
            setIndex(false)
        }else{
            setIndex(true);
        }
        

    },[Val])

    

    return (
        <div className='p-1'>
            <div onClick={onChange} style={{cursor:"pointer"}}  className="form-check">
                <input checked={index} className="form-check-input" name={props.label} type="checkbox"/>
                <label className="form-check-label">{props.label}</label>
            </div>
        </div>
    )
}