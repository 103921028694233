import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

export const ListCard = ({
  linkTo,
  title,
  zipCode,
  location,
  last_modified,
  type,
  cardHeaderClass = "info",
  fav = "active",
  statusClass = "success",
}) => {
  const activebtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  return (
    <Col xxl={3} sm={6} className="project-card">
      <Card>
        <CardBody>
          <div
            className={`p-3 mt-n3 mx-n3 bg-soft-${cardHeaderClass} rounded-top`}
          >
            <div className="d-flex align-items-center">
              <div className="flex-grow-1">
                <h5 className="mb-0 fs-15">
                  <Link to={linkTo} className="text-dark">
                    {title}
                  </Link>
                </h5>
              </div>
              {/* <div className="flex-shrink-0">
                <div className="d-flex gap-1 align-items-center my-n2">
                  <button
                    type="button"
                    className={`btn avatar-xs mt-n1 p-0 favourite-btn ${fav}`}
                    onClick={(e) => activebtn(e.target)}
                  >
                    <span className="avatar-title bg-transparent fs-15">
                      <i className="ri-star-fill"></i>
                    </span>
                  </button>
                  <UncontrolledDropdown direction="start">
                    <DropdownToggle
                      tag="button"
                      className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15"
                    >
                      <FeatherIcon icon="more-horizontal" className="icon-sm" />
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem href="apps-projects-overview">
                        <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                        View
                      </DropdownItem>
                      <DropdownItem href="apps-projects-create">
                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                        Edit
                      </DropdownItem>
                      <div className="dropdown-divider"></div>
                      <DropdownItem
                        href="#"
                        onClick={() => onClickData(item)}
                        data-bs-toggle="modal"
                        data-bs-target="#removeProjectModal"
                      >
                        <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                        Remove
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div> */}
            </div>
          </div>

          <div className="py-3">
            <Row className="gy-3">
              <Col xs={6}>
                <div>
                  <p className="text-muted mb-1">ZipCode</p>
                  <h5 className="fs-14">{zipCode}</h5>
                  <div className={"fs-12 badge badge-soft-" + statusClass}>
                    {status}
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div>
                  <p className="text-muted mb-1">Last Open Date</p>
                  <h5 className="fs-14">{last_modified}</h5>
                </div>
              </Col>
            </Row>
            {/* </div> */}
            <div className="d-flex align-items-center mt-3">
              <i className="ri-map-pin-2-line align-bottom me-1"></i>{" "}
              <span className="job-location">{location}</span>
            </div>
            
            <div className="d-flex align-items-center mt-3">
              <p className="text-muted mb-0 me-2">Type :</p>
              <h5 className="fs-14 mb-0 me-2">{type}</h5>
            </div>
            
          </div>
          {/* <div>
            <div className="d-flex mb-2">
              <div className="flex-grow-1">
                <div>Progress</div>
              </div>
              <div className="flex-shrink-0">
                <div>{item.progressBar}</div>
              </div>
            </div>
            <div className="progress progress-sm animated-progess">
              <div
                className="progress-bar bg-secondary"
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: item.progressBar }}
              ></div>
            </div>
          </div> */}
        </CardBody>
      </Card>
    </Col>
  );
};
