
import {  setIsLoading , setspaces , spaceFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getAllSpaces , createSpace , deleteSpace } from "../../../helpers/backend_helper";

export const ListSpacesThunk = (fid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getAllSpaces(fid,params)
    .then((res)=> dispatch(setspaces(res)))
    .catch((err) => dispatch(spaceFailure(err)))

}

export const CreateSpaceThunk = (fid,data,onSuccess=()=>{}) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createSpace(fid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const DeleteSpaceThunk = (sid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteSpace(sid)
    .then((res)=> dispatch(deleteSuccess(sid)))
    .catch((err)=> dispatch(spaceFailure(err)))
    .finally(()=> closeModal())
}