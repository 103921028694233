import { createSlice } from "@reduxjs/toolkit";
// import { publish } from "../../../events";

export const initialState = {
  error: null,
  success: null,
  utility:{},
  selected:null,
  count: {},
  page_size:{},
  total_pages: {},
  page_num:{},
  loading:{},
  btnLoading:false,
  filters:[],
  changed:-1
};

const utilitySlice = createSlice({
  name: "utility",
  initialState,
  reducers: { 
    setutility(state,action){
      state.utility[action.payload.type] = action.payload.results
      state.count[action.payload.type] = action.payload.count
      state.total_pages[action.payload.type] = action.payload.total_pages
      state.page_size[action.payload.type] = action.payload.page_size
      state.page_num[action.payload.type] = action.payload.page_num
      state.loading[action.payload.type] = false
    },
    resetUtility(state,action){
      state.utility = {}
      state.count = {}
      state.total_pages = {}
      state.page_size = {}
      state.page_num = {}
      state.loading = {}
      state.filters = state.filters.map((elem) => ({type:elem.type}))
    },
    setIsLoading(state,action){
        state.loading[action.payload] = true
    },
    setBtnLoading(state,action){
      state.btnLoading = action.payload;
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    createFailure(state,action){
      state.error = action.payload;
      state.btnLoading = false;
    },
    utilityFailure(state,action){
      const { type, ...payload } = action.payload;
      state.error = payload;
      state.loading[type] = false;
    },
    setSelected(state,action){
      state.selected = action.payload
    },
    createSucess(state,action){
      state.success = "Created Successfully"
      state.btnLoading = false;
    },
    deleteSuccess(state,action){
      state.success = "Deleted Successfully"
      state.btnLoading = false;
    },
    setFilterParams(state,action){
      state.filters = action.payload.filters
      state.changed = action.payload.changed
    }
  }
});


export const {
  setIsLoading,
  setutility,
  resetMessages,
  utilityFailure,
  setSelected,
  deleteSuccess,
  createSucess,
  setBtnLoading,
  createFailure,
  setFilterParams,
  resetUtility
} = utilitySlice.actions;

export default utilitySlice.reducer;