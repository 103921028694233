import React from "react";
import { Navigate } from "react-router-dom";

//AuthenticationInner pages
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";
import CoverSignUp from "../pages/AuthenticationInner/Register/CoverSignUp";
import CoverPasswCreate from "../pages/AuthenticationInner/PasswordCreate/CoverPasswCreate";
import Cover404 from "../pages/AuthenticationInner/Errors/Cover404";
import CoverTwosVerify from "../pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify";
import CoverSuccessMsg from "../pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import CoverLogout from "../pages/AuthenticationInner/Logout/CoverLogout";
import CoverLockScreen from "../pages/AuthenticationInner/LockScreen/CoverLockScr";
import CoverPasswReset from "../pages/AuthenticationInner/PasswordReset/CoverPasswReset";
import BlankPage from "../pages/blank/blank";
import ActivateEmail from "../pages/AuthenticationInner/Activate/ActivateEmail";

import Settings from "../pages/pages/Profile/Settings/Settings";

import CreateProperty, { CreatePropertyWraper } from "../pages/property/CreateProperty";

import PropertyList from "../pages/property/PropertyList";

import ViewProperty, { ViewPropertyWraper } from "../pages/property/ViewProperty";

import CaptureUpload from "../pages/property/Capture/upload";

import CreateCapture from "../pages/property/Capture/New";

import FloorUpload from "../pages/property/floors/upload";

import SpaceUpload from "../pages/property/spaces/upload";

import PropertyImageUpload from "../pages/property/PropertyImages/upload";

import InventoryUpload from "../pages/property/homeInventory/upload";

import UtilityUpload from "../pages/property/utilites/upload";

import ApplianceUpload from "../pages/property/appliance/upload";

import ApplianceDetails from "../pages/property/appliance/view/applianceDetail";

import HVACUpload from "../pages/property/HVAC/upload";
import HVACDetails from "../pages/property/HVAC/view/HVACDetail";
import { LocationPage } from "../pages/property/location/locationPage";
import MyContractorsPage from "../pages/property/MyContractors/upload";
import { CaptureDetail } from "../pages/property/Capture/detail/captureDetail";
import FLOORINGUpload from "../pages/property/flooring/upload";
import FinishesUpload from "../pages/property/finishes/upload";
import FlooringDetails from "../pages/property/flooring/view/FlooringDetail";
import FinishesDetails from "../pages/property/finishes/view/FinishDetail";
import InsuranceUpload from "../pages/property/Insurance/upload";
import InsuranceDetails from "../pages/property/Insurance/view/insuranceDetail";
import MaintenanceUpload from "../pages/property/maintenance/upload";
import MaintenanceDetails from "../pages/property/maintenance/view/Details";
import PropertyElementsPage from "../pages/dashboard/propertyElements";
import PropertyFilesPage from "../pages/property/PropertyFiles/upload";
import SupportDetailsPage from "../pages/support/Detail";
import SupportPage from "../pages/support";
import PropertyReportsPage from "../pages/property/reports/upload";
import PropertyElementsSettingsPage from "../pages/property/elementSettings";
import NotesTable from "../pages/property/notes/upload";
import RemindersTable from "../pages/property/reminder/upload";


const authProtectedRoutes = [
  { path: "/", component: <Settings /> },
  
  { path: "/profile", component: <Settings /> },
  // property
  // { path: "/create-property", component: <CreateProperty /> },
  { path: "/create-property", component: <CreatePropertyWraper /> },
  { path: "/property/list/", component: <PropertyList /> },
  { path: "/property/:id/", component: <ViewPropertyWraper /> },
  { path: "/3dCapture", component: <CaptureUpload /> },
  { path: "/3dCapture/create", component: <CreateCapture /> },
  { path: "/3dCapture/update/:id/", component: <CreateCapture /> },
  { path: "/3dCapture/:id/" , component: <CaptureDetail /> },
  { path: "/floorplans", component: <FloorUpload /> },
  { path: "/level/:id", component: <SpaceUpload /> },
  // { path: "/property/images", component: <PropertyImageUpload /> },
  { path: "/inventory", component: <InventoryUpload /> },
  { path: "/utilities", component: <UtilityUpload /> },
  { path: "/appliance", component: <ApplianceUpload /> },
  { path: "/appliance/:id", component: <ApplianceDetails /> },
  { path: "/hvac" , component: <HVACUpload /> } ,
  { path: "/hvac/:id" , component: <HVACDetails /> },
  { path: "/location" , component: <LocationPage />},
  { path: "/myContractors" , component: <MyContractorsPage />},
  { path: "/flooring" , component: <FLOORINGUpload />},
  { path: "/flooring/:id" , component: <FlooringDetails />},
  { path: "/finishes" , component: <FinishesUpload />},
  { path: "/finishes/:id" , component: <FinishesDetails />},
  { path: "/insurance", component: <InsuranceUpload />},
  { path: "/insurance/:id" , component:<InsuranceDetails />},
  { path: "/maintenance" , component:<MaintenanceUpload />},
  { path: "/maintenance/:id" , component: <MaintenanceDetails />},
  { path: "/property/files/" , component: <PropertyFilesPage />},
  { path: '/propertyElements/settings/' , component: <PropertyElementsSettingsPage />},
  { path: '/notes' , component: <NotesTable />},
  { path: '/reminders' , component: <RemindersTable />},

  // reports
  { path: "/reports", component: <PropertyReportsPage /> },

  //support
  { path: "/support/" , component: <SupportPage /> },
  { path: "/support/:id" , component: <SupportDetailsPage /> },
  // dashboard
  { path: "/propertyElements", component: <PropertyElementsPage /> },

];

const publicRoutes = [
  //AuthenticationInner pages

  { path: "/signin", component: <CoverSignIn /> },
  { path: "/signup", component: <CoverSignUp /> },
  { path: "/pass-reset", component: <CoverPasswReset /> },
  { path: "/logout", component: <CoverLogout /> },
  { path: "/reset", component: <CoverPasswCreate /> },
  { path: "/activate", component: <ActivateEmail /> },
  // { path: "/activate/success-msg-cover", component: <CoverSuccessMsg /> },
  // { path: "/auth-twostep-cover", component: <CoverTwosVerify /> },
  // { path: "/auth-404-cover", component: <Cover404 /> },
  // { path: "/auth-lockscreen-cover", component: <CoverLockScreen /> },
];

export { authProtectedRoutes, publicRoutes };
