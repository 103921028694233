import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";


// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";
import createPasswordSlice from "./auth/passCreate/reducer"

import loadingReducer from "./loading/reducer";
import activateEmailReducer from "./auth/activateEmail/reducer" 

import CreatePropertyReducer from "./property/createProperty/reducer";

import EditPropertyReducer from "./property/editProperty/reducer";

import ListPropertyReducer from "./property/listProperty/reducer"

import ViewPropertyReducer from "./property/viewProperty/reducer"

import  PropertiesDropdownReducer from "./property/dropdown/reducer";

import  CaptureUploadReducer from "./property/capture/reducer";
import  FloorUploadReducer from "./property/floors/reducer";
import  SpaceUploadReducer from "./property/spaces/reducer";
import  PropertyImageUploadReducer from "./property/propertyImages/reducer";
import  InventoryReducer from "./property/homeInventory/reducer";
import  UtilityReducer from "./property/utilites/reducer";
import  ApplianceReducer from "./property/appliance/reducer";
import  HVACReducer from "./property/HVAC/reducer";
import  FlooringReducer from "./property/flooring/reducer";
import  FinishesReducer from "./property/finishes/reducer";
import  InsuranceReducer from "./property/Insurance/reducer";
import  maintenanceReducer from "./property/maintenance/reducer";
import  MyContractorsReducer from "./property/myContractors/reducer";
import  PropertyFilesReducer from "./property/propertyFiles/reducer";
import  NotesReducer from "./property/notes/reducer";
import  RemindersReducer from "./property/reminders/reducer";

import  PropertyElementsSettings from "./property/elementSettings/reducer";

import  ReportsReducer from "./property/reports/reducer";

import  ImagesFilesReducer from "./property/propertyFilesImages/reducer";
import  RecieptsFilesReducer from "./property/propertyFilesReciepts/reducer";

import  SupportReducer from "./support/reducer";


// dashboard
import  propertyElementsReducer from "./dashboard/propertyElements/reducer";


import SideNavReducer from './sideNav/reducer'
import galleryReducer from "./gallery/reducer";

// import CaptureUpload from "../pages/property/Capture/upload";
const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Account: AccountReducer,
    Profile: ProfileReducer,
    ForgetPassword: ForgetPasswordReducer,
    pwdCreate: createPasswordSlice,
    loading:loadingReducer,
    activateEmail:activateEmailReducer,
    createProperty:CreatePropertyReducer,
    EditProperty:EditPropertyReducer,
    listProperty:ListPropertyReducer,
    viewProperty:ViewPropertyReducer,
    PropertiesDropdown:PropertiesDropdownReducer,
    captureUpload:CaptureUploadReducer,
    floorUpload:FloorUploadReducer,
    spaceUpload:SpaceUploadReducer,
    sideNav: SideNavReducer,
    propertyImage:PropertyImageUploadReducer,
    inventory:InventoryReducer,
    utility:UtilityReducer,
    appliance:ApplianceReducer,
    HVAC:HVACReducer,
    gallery: galleryReducer,
    myContractors:MyContractorsReducer,
    FLOORING:FlooringReducer,
    Finishes:FinishesReducer,
    Insurance: InsuranceReducer,
    maintenance:maintenanceReducer,
    propertyElements:propertyElementsReducer,
    files:PropertyFilesReducer,
    propertyFilesImages:ImagesFilesReducer,
    propertyFilesReciepts:RecieptsFilesReducer,
    support:SupportReducer,
    reports:ReportsReducer,
    elementSettings:PropertyElementsSettings,
    notes:NotesReducer,
    reminders:RemindersReducer
});

export default rootReducer;