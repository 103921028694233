
import {  setIsLoading , setfiles , fileFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getFilesProperty , deleteFileProperty } from "../../../helpers/backend_helper";

export const ListfilesThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getFilesProperty(pid,params)
    .then((res)=> dispatch(setfiles(res)))
    .catch((err) => dispatch(fileFailure(err)))

}



