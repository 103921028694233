import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useSelector, useDispatch } from "react-redux";
import {
  GetLocation,
  updateLocationData,
} from "../../../helpers/backend_helper";
import { TooltipItem } from "../../../Components/Common/ToolTipItem";
import {
  locationAreatooltip,
  locationExacttooltip,
  locationNeighbourhoodtooltip,
  locationTooltip,
} from "../../../Components/constants/tooltips";
import { LocationEditModal } from "./locationEditModal";
import Prompt, { FormPrompt } from "../../../Components/Common/navBlocker/FormPrompt";

// import { unstable_usePrompt as usePrompt } from "react-router-dom";



function extractPosition(url) {
  const [lat, lng] = url.split("?q=")[1].split("&")[0].split(",");
  console.log(lat, lng);
  return {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
}

export const LocationPage = (props) => {
  const [property, setProperty] = useState({});

  const [neighbourhood, setNeighbourhood] = useState("");

  const [area, setArea] = useState("");

  const [neighbourhoodView, setNeighbourhoodView] = useState("");

  const [areaView, setAreaView] = useState("");

  const [loading, setLoading] = useState(false);

  const [editModal, seteditModal] = useState(false);

  const [mapsLocation, setmapsLocation] = useState("");

  const updates = useMemo(() => {
    var data = {};
    if ( (property.neighbourhood || neighbourhoodView) && neighbourhoodView !== property.neighbourhood) {
      data["neighbourhood"] = neighbourhoodView;
    }

    if ((property.area || areaView) && areaView !== property.area) {
      data["area"] = areaView;
    }

    if (mapsLocation !== property.mapsLocation) {
      data["mapsLocation"] = mapsLocation;
    }
    return data;
  }, [property,neighbourhoodView, areaView, mapsLocation]);

  const initialPosition = useMemo(() => {
    if (mapsLocation) {
      return extractPosition(mapsLocation);
    } else {
      return null;
    }
  }, [mapsLocation]);
  console.log(updates)
  const toggleEditModal = () => seteditModal(!editModal);

  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  document.title = "Location | DwellDoc";

  useEffect(() => {
    if (selectedProperty) {
      GetLocation(selectedProperty.id).then((res) => setProperty(res));
    }
  }, [selectedProperty]);
  //
  useEffect(() => {
    if (property) {
      setNeighbourhood(property.neighbourhood|| "");
      setArea(property.area|| "");
      setNeighbourhoodView(property.neighbourhood|| "");
      setAreaView(property.area|| "");
      setmapsLocation(property.mapsLocation);
    }
  }, [property]);

  function saveChanges() {
    if (Object.keys(updates).length === 0) {
      toast.info("Every thing is up to date");
    } else {
      setLoading(true);
      updateLocationData(selectedProperty.id, updates)
        .then((res) => {
          setProperty(res);
          toast.success("property location is updated successfully");
        })
        .catch((err) => {
          console.log(err);
          toast.error("something went wrong, please try again");
        })
        .finally(() => setLoading(false));
    }
  }

  const onSaveClick = (val) => {
    setmapsLocation(val);
  };

  // usePrompt(
  //   "Hello from usePrompt -- Are you sure you want to leave?",
  //   Object.keys(updates).length > 0
  // );

  return (
    <React.Fragment>
      <div className="page-content">
        <Prompt when={Object.keys(updates).length > 0} message="you have unsaved changes, you might lose" />
        
        <LocationEditModal
          city={property.location ? property.location.city : null}
          country={property.location ? property.location.country : null}
          show={editModal}
          toggle={toggleEditModal}
          onSaveClick={onSaveClick}
          initialPosition={initialPosition}
        />
        <Container fluid>
          <BreadCrumb
            pageTitle="Property Workbook"
            title="Location"
            pageTitleComponent={
              <>
                <h4 to="">
                  Location{" "}
                  {Object.keys(updates).length > 0 ? (
                    // <i
                    //   style={{ color: "red" }}
                    //   className="ri-alert-fill fs-16"
                    // ></i>
                    "*"
                  ) : ""}{" "}
                  <TooltipItem id={1} data={locationTooltip} />
                </h4>
              </>
            }
          />
          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Area <TooltipItem id={3} data={locationAreatooltip} />
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Row>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="area-link"
                          name="areaLink"
                          placeholder="Your Area"
                          value={area}
                          onChange={(e) => setArea(e.target.value)}
                        />
                        <button
                          class="btn btn-outline-success"
                          type="button"
                          id="button-addon2"
                          onClick={() => setAreaView(area)}
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className="mdi mdi-map-search-outline"
                          ></i>
                        </button>
                      </div>
                    </Row>
                  </div>
                  {areaView ? (
                    <iframe
                      src={
                        "https://maps.google.com/?q=" +
                        encodeURIComponent(areaView) +
                        "&hl=es;z%3D14&amp&output=embed"
                      }
                      width="100%"
                      height="480px"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        width: '"100%',
                        height: "480px",
                        backgroundColor: "black",
                      }}
                    >
                      <p
                        style={{
                          paddingTop: "50px",
                          padding: "20px",
                          fontWeight: 400,
                          fontSize: "20px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        You didn't add your Area
                      </p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Neighborhood{" "}
                    <TooltipItem id={2} data={locationNeighbourhoodtooltip} />
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Row>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="neighbourhood-link"
                          name="neighbourhoodLink"
                          placeholder="Your Neighborhood"
                          value={neighbourhood}
                          onChange={(e) => setNeighbourhood(e.target.value)}
                        />
                        <button
                          class="btn btn-outline-success"
                          type="button"
                          id="button-addon2"
                          onClick={() => setNeighbourhoodView(neighbourhood)}
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            className="mdi mdi-map-search-outline"
                          ></i>

                        </button>
                      </div>
                    </Row>
                  </div>
                  {neighbourhoodView ? (
                    <iframe
                      src={
                        "https://maps.google.com/?q=" +
                        encodeURIComponent(neighbourhoodView) +
                        "&hl=es;z%3D14&amp&output=embed"
                      }
                      width="100%"
                      height="480px"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        width: '"100%',
                        height: "480px",
                        backgroundColor: "black",
                      }}
                    >
                      <p
                        style={{
                          paddingTop: "50px",
                          padding: "20px",
                          fontWeight: 400,
                          fontSize: "20px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        You didn't add your neighbourhood
                      </p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Exact Location{" "}
                    <TooltipItem id={4} data={locationExacttooltip} />
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="location-link">
                      Location Link
                    </Label>
                    <Row>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          id="location-link"
                          name="locationLink"
                          placeholder="Property Location Link"
                          readOnly
                          disabled
                          value={property.mapsLocation || "You didn't provide your location"}
                        />
                        <button
                          class="btn btn-outline-success"
                          type="button"
                          id="button-addon2"
                          onClick={() => {
                            if (navigator) {
                              navigator.clipboard.writeText(
                                property.mapsLocation
                              );
                              toast.info("copied to clipboard!", {
                                position: "bottom-center",
                              });
                            } else {
                              toast.error(
                                "your browser does not support this feature you can copy it manually",
                                {
                                  position: "bottom-center",
                                }
                              );
                            }
                          }}
                          disabled={!property.mapsLocation}
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            class="ri-clipboard-line"
                          ></i>
                        </button>
                        <button
                          class="btn btn-outline-success"
                          type="button"
                          onClick={toggleEditModal}
                        >
                          <i
                            style={{ fontSize: "20px" }}
                            class="ri-pencil-line"
                          ></i>
                        </button>
                      </div>
                    </Row>
                  </div>
                  {mapsLocation ? (
                    <iframe
                      src={mapsLocation + "&hl=es;z%3D14&amp&output=embed"}
                      width="100%"
                      height="480px"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  ) : (
                    
                    <div
                      style={{
                        textAlign: "center",
                        width: '"100%',
                        height: "480px",
                        backgroundColor: "black",
                      }}
                    >
                      <p
                        style={{
                          paddingTop: "50px",
                          padding: "20px",
                          fontWeight: 400,
                          fontSize: "20px",
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        Exact Location isn't provided
                        {/* <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner> */}
                      </p>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="d-flex align-items-start gap-3 m-4">
            <button
              type="button"
              className="btn btn-success right ms-auto"
              onClick={saveChanges}
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Save Changes {
                Object.keys(updates).length > 0 ? "*" : ""
              }
            </button>
          </div>
          {/* <ToastContainer /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};
