import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetStatuses, getTagsProperty } from "../../helpers/backend_helper";
import Select from "react-select";
import { SupportStatusDropdown } from "./supportStatus";
import { Spinner } from "reactstrap";

export const useStatusSelect = (placeholder = "Select Status") => {
  const [selectedValue, setSelectedValue] = useState("");
  const [selectValues, setSelectValues] = useState(null);

  useEffect(() => {
    GetStatuses().then((res) =>
      setSelectValues(
        res.map((elm) => ({
          label: elm,
          value: elm,
        }))
      )
    );
  }, []);

  const handleChange = (val) => {
    setSelectedValue(val);
  };

  function getVal() {
    return selectedValue.label;
  }

  return [
    selectValues ? (
      <SupportStatusDropdown
        selectValues={selectValues}
        placeholder={placeholder}
        selectedValue={selectedValue}
        handleChange={handleChange}
      />
    ) : (
      <Spinner />
    ),
    getVal,
    setSelectedValue
  ];
};
