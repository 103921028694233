
export function StatusCheckBox ({
    val,
    onChange
}) {
    return (
        <div className="input-light">
            <select
              className="form-control"
              data-choices
              data-choices-search-false
              name="choices-single-default"
              value={val}
              onChange={(e) => onChange(e.target.value) }
            >
              <option value={true}>On</option>
              <option value={false}>Off</option>
            </select>
          </div>
    )
}