import {
  Col,
  Row,
  Input,
  Label,
  Form,
  Spinner,
  FormFeedback,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getFinishesTypes,
  getFinishesPlacementTypes,
  getInstallationTypes,
  // getFinishesplacementTypes,
  // retrieveFinishes,
  editRetrieveFinishes,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFinishesThunk,
  ListFinishesThunk,
  UpdateFinishesThunk,
} from "../../../../slices/thunks";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import Flatpickr from "react-flatpickr";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FinishesFailure,
  createSucess,
} from "../../../../slices/property/finishes/reducer";

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { InstallationTypeAndSearch } from "../../../../Components/Common/installationContractor";
import { get_spaces } from "../../../../Components/utils/spacesCompare";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const TypesDropdown = ({ types }) => {
  const Types = useMemo(
    () =>
      types.map((type) => {
        if (type instanceof Object) {
          const label = Object.keys(type)[0];
          return {
            name: label,
            SubTypes: type[label],
          };
        } else {
          return {
            name: type,
            SubTypes: {},
          };
        }
      }),
    [types]
  );

  const [selectedType, setSelectedType] = useState({
    selectedType: "",
    selectedSubType: ["", ""],
    currentSubTypes: {},
  });

  const [pending,setPending] = useState(null)

  const changeType = (val,reset_subTypes=true) => {
    const idx = Types.findIndex((object) => object.name === val);
    // // // // console.log(idx,Types)
    if (idx !== -1) {
      if(reset_subTypes){
        setSelectedType({
          selectedType: Types[idx].name,
          selectedSubType: ["", ""],
          currentSubTypes: Types[idx].SubTypes,
        });
      }else{
        setSelectedType((oldParams) => ({
          ...oldParams,
          selectedType: Types[idx].name,
          currentSubTypes: Types[idx].SubTypes,
        }));
      }
    }else{
      setPending(val);
      setSelectedType({
        selectedType: "",
        selectedSubType: ["", ""],
        currentSubTypes: {},
      });
    }
  };

  const changeSubType = (val, index) => {
    setSelectedType((oldParams) => {
      var selectedSubType = [...oldParams.selectedSubType];
      selectedSubType[index] = val;
      return {
        ...oldParams,
        selectedSubType: selectedSubType,
      };
    });
  };

  const placeholders = useMemo(
    () => Object.keys(selectedType.currentSubTypes),
    [selectedType.currentSubTypes]
  );

  const getSelectedTypes = () => {
    const placeholders = Object.keys(selectedType.currentSubTypes);
    const len = placeholders.length;
    return {
      selectedType: selectedType.selectedType,
      selectedSubType: selectedType.selectedSubType,
      needSubType1: len >= 1 ? placeholders[0] : "",
      needSubType2: len >= 2 ? placeholders[1] : "",
    };
  };

  useEffect(()=>{
    if(Types && Types.length > 0 && pending){
      changeType(pending,false)
      setPending(null)
    }
  },[Types])

  return [
    <>
      <Col
        lg={placeholders.length === 0 ? 6 : placeholders.length == 1 ? 3 : 2}
      >
        <div className="input-group my-2" style={{ padding: 0 }}>
          <select
            className="form-select"
            id="selectType"
            aria-label="Select"
            // style={{ backgroundColor: "#eff2f7" }}
            onChange={(e) => changeType(e.target.value)}
            value={selectedType.selectedType}
          >
            <option value="" disabled selected>
              Select Finish Type
            </option>
            {Types.map((type) => (
              <option value={type.name}>{type.name}</option>
            ))}
          </select>
        </div>
      </Col>
      {placeholders.map((placeholder, idx) => {
        return (
          <Col
            lg={
              placeholders.length === 0 ? 6 : placeholders.length == 1 ? 3 : 2
            }
          >
            <div className="input-group my-2" style={{ padding: 0 }}>
              <select
                className="form-select"
                aria-label="Select"
                // style={{ backgroundColor: "#eff2f7" }}
                onChange={(e) => changeSubType(e.target.value, idx)}
                value={selectedType.selectedSubType[idx]}
              >
                <option value="" disabled selected>
                  {placeholder}
                </option>
                {selectedType.currentSubTypes[placeholder].map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </Col>
        );
      })}
    </>,
    getSelectedTypes,
    changeType,
    changeSubType,
  ];
};

export const CreateForm = ({
  update = false,
  id = -1,
  onUpdateSucces = () => {},
}) => {
  const [Finishes, setFinishes] = useState(null);

  const [selectedSpaces, setSelectedSpaces] = useState([]);


  const [StatusSelectComponent , getStatusVal , setStatus] = useStatusSelect();
  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect()
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect()

  useEffect(() => {
    if (update && id >= 0) {
      editRetrieveFinishes(id).then((res) => setFinishes(res));
    }
  }, [id]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: Finishes ? Finishes.name : "",
      cost: Finishes ? Finishes.cost || "" : "",
      brand: Finishes ? Finishes.brand || "" : "",
      warrantyDetails: Finishes ? Finishes.warrantyDetails|| ""  : "",
      notes: Finishes ? Finishes.notes || "" : "",
      color: Finishes ? Finishes.color || "" : "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("This field is required"),
      cost: Yup.number(),
      // .required("This field is required")
      // .positive("must be a number"),
      brand: Yup.string(),
      // .required("This field is required"),
      warrantyDetails: Yup.string(),
      // .required("This field is required"),
      color: Yup.string(),
      // .required("This field is required"),
      notes: Yup.string(),
    }),
    onSubmit: (values) => {
      if (id && update) {
        Update(values);
      } else {
        onSubmit(values);
      }
    },
  });

  // Finishes type
  const [FinishesTypesList, setFinishesTypesList] = useState([]);

  const [TypesComponent, getSelectedTypes, setType, setSubType] = TypesDropdown(
    {
      types: FinishesTypesList,
    }
  );

  // // // console.log(TypesComponent,getSelectedTypes)

  // const [selectedFinishes, setselectedFinishes] = useState(null);

  // Finishes energy type
  const [FinishesplacementList, setFinishesplacementList] = useState([]);
  const [selectedFinishesplacement, setselectedFinishesplacement] =
    useState(null);

  const [SpaceSelectList, setSpaceSelectList] = useState([]);
  // const [selectedSpace, setSelectedSpace] = useState(null);

  const [
    installationComponent,
    getInstallationData,
    setInstallationType,
    setContractor,
  ] = InstallationTypeAndSearch();

  const [date, setDate] = useState("");
  const [installationDate, setinstallationDate] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.Finishes.btnLoading,
  }));

  // const [selectedFileReciept, setFileReciept] = useState(null);

  useEffect(() => {
    getFinishesTypes().then((res) => setFinishesTypesList(res) );
  }, []);

  useEffect(() => {
    getFinishesPlacementTypes().then((res) => setFinishesplacementList(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }
//
  function Update(values) {
    var formdata = {};

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val && val != Finishes[key]) {
        formdata[key] = values[key];
      }
    }

    if (date && date !== Finishes.purchaseDate) {
      formdata["purchaseDate"] = date;
    }
    if (installationDate && installationDate !== Finishes.installationDate) {
      formdata["installationDate"] = installationDate;
    }

    const statusVal = getStatusVal();
    if (statusVal && statusVal !== Finishes.status ){
      formdata["status"] =  statusVal
    }

    var imagesfiles = getImageVal()
    var [modifiedImages,Images] = getCreateAndDeleteFiles(Finishes.images.map((inst) => inst.id),imagesfiles)
    if (modifiedImages){
      formdata["images"] = Images
    }
    
    var reciepts = getRecieptVal()
    var [modifiedreciepts,recieptsFiles] = getCreateAndDeleteFiles(Finishes.reciepts.map((inst) => inst.id),reciepts)
    if (modifiedreciepts){
      formdata["reciepts"] = recieptsFiles
    }

    const type = getSelectedTypes();

    formdata["Type"] = type.selectedType
    if (type.selectedSubType[0]) {
      formdata["SubType1"] = type.selectedSubType[0]
    }
    if (type.selectedSubType[1]) {
      formdata["SubType2"] = type.selectedSubType[1]
    }

    if (
      selectedFinishesplacement &&
      selectedFinishesplacement != Finishes.placement
    ) {
      formdata["placement"] =  selectedFinishesplacement
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata["installationType"] = installationData.installationType
      if (installationData.installationType === "Service Provider") {
        formdata[
          "installationContractor"] =
          installationData.installationContractor.value
        
      }
    }
    
    const spaces = get_spaces(Finishes.spaces,selectedSpaces)
    if(spaces){
      formdata[
        "spaces"] =
        [spaces.join(",")]
      
    }
    
    if (Object.keys(formdata).length === 0) {
      dispatch(createSucess("All data are up to date"));
    } else {
      dispatch(UpdateFinishesThunk(id, formdata, onUpdateSucces));
    }
  }

  function getFormdata(values) {
    var formdata = new FormData();

    const keys = Object.keys(values);
    for (let key of keys) {
      const val = values[key];
      if (val) {
        formdata.append(key, values[key]);
      }
    }
    
    var imagesfiles = getImageVal()
    for (let image of imagesfiles) {
      formdata.append("images", image);
    }
    var reciepts = getRecieptVal()
    for (let reciept of reciepts) {
      formdata.append("reciepts", reciept);
    }

    if (date) {
      formdata.append("purchaseDate", date);
    }
    if (installationDate) {
      formdata.append("installationDate", installationDate);
    }
    
    formdata.append("status",getStatusVal());

    const type = getSelectedTypes();
    formdata.append("Type", type.selectedType);
    if (type.selectedSubType[0]) {
      formdata.append("SubType1", type.selectedSubType[0]);
    }
    if (type.selectedSubType[1]) {
      formdata.append("SubType2", type.selectedSubType[1]);
    }

    if(selectedSpaces.length > 0){
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    const installationData = getInstallationData();

    if (installationData.installationType) {
      formdata.append("installationType", installationData.installationType);
      if (installationData.installationType === "Service Provider") {
        formdata.append(
          "installationContractor",
          installationData.installationContractor.value
        );
      }
    }

    if (selectedFinishesplacement) {
      formdata.append("placement", selectedFinishesplacement);
    }

    return formdata;
  }

  function onSubmit(values) {
    var errs = [];

    const type = getSelectedTypes();
    checkOrError(type.selectedType, errs ,"you need to select Type for your Finish");

    checkOrError(
      !(type.needSubType1 && !type.selectedSubType[0]),
      errs,
      `you need to ${type.needSubType1} for type: ${type.selectedType}`
    );

    checkOrError(
      !(type.needSubType2 && !type.selectedSubType[1]),
      errs,
      `you need to ${type.needSubType2} for type: ${type.selectedType}`
    );

    checkOrError(
      selectedFinishesplacement,
      errs,
      "you need to select Finishes placement type"
    );

    const installationData = getInstallationData();
    
    checkOrError(
      !(
        installationData.installationType &&
        installationData.installationType === "Service Provider" &&
        !installationData.installationContractor
      ),
      errs,
      "you need to select Installation Contractor for installation of Type Service Provider"
    );

    const statusVal = getStatusVal();
    checkOrError(
      statusVal,
      errs,
      "you need to select status"
    );

    if (errs.length > 0) {
      dispatch(FinishesFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListFinishesThunk(selectedProperty.id));
      };
      dispatch(
        CreateFinishesThunk(selectedProperty.id, getFormdata(values), onSuccess)
      );
    }
  }

  useEffect(() => {
    if (Finishes) {
      setSelectedSpaces(
        Finishes.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setStatus({label:Finishes.status,value:Finishes.status});
      setDate(Finishes.purchaseDate);
      setinstallationDate(Finishes.installationDate);
      setselectedFinishesplacement(Finishes.placement);
      setType(Finishes.Type);
      setSubType(Finishes.SubType1, 0);
      setSubType(Finishes.SubType2, 1);
      setInstallationType(Finishes.installationType);
      dispatch(setSelectedImageFiles(Finishes.images))
      dispatch(setSelectedRecieptFiles(Finishes.reciepts))
      if (Finishes.installationContractor) {
        setContractor({
          label: Finishes.installationContractor.name,
          value: Finishes.installationContractor.id,
        });
      } else {
        setContractor("");
      }
    }
  }, [Finishes]);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit(e);
      }}
      className="needs-validation"
      noValidate
      action="index"
    >
      {RecieptModal}
      {ImageModal}
      <Row>
        <Col lg={3}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="name-input"
              placeholder="Enter name"
              name="name"
              value={validation.values.name}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.name && validation.touched.name ? true : false
              }
            />

            {validation.errors.name && validation.touched.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {TypesComponent}

        <Col className="my-2" lg={3}>
          {StatusSelectComponent}
        </Col>

        {/* {!(id && update) && ( */}
          <>
            <Col lg={6}>
              {/* <FilePond
                files={imagesfiles}
                onupdatefiles={setimagesFiles}
                allowMultiple={true}
                // maxFiles={3}
                acceptedFileTypes={['image/png', 'image/jpeg']}
                labelIdle={"Drag & Drop your Finish Image(s) or Browse"}
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {ImageViewerComponent}
            </Col>
            <Col lg={6}>
              {/* <FilePond
                files={reciepts}
                onupdatefiles={setrecieptsFiles}
                allowMultiple={true}
                // maxFiles={3}
                acceptedFileTypes={['image/png', 'image/jpeg','file/pdf','application/pdf']}
                labelIdle={
                  "Drag & Drop your Finish Reciept(s)/Barcode or Browse"
                }
                name="files"
                className="filepond filepond-input-multiple"
              /> */}
              {RecieptViewerComponent}
            </Col>
          </>
        {/* )} */}

        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              // id="floatingSelect3"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setselectedFinishesplacement(e.target.value)}
              value={selectedFinishesplacement}
            >
              <option value="" disabled selected>
                Placement Type
              </option>
              {FinishesplacementList.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="cost-input"
              placeholder="Cost per square ft"
              name="cost"
              value={validation.values.cost}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.cost && validation.touched.cost ? true : false
              }
            />

            {validation.errors.cost && validation.touched.cost ? (
              <FormFeedback type="invalid">
                {validation.errors.cost}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="brand-input"
              placeholder="Brand"
              name="brand"
              value={validation.values.brand}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.brand && validation.touched.brand
                  ? true
                  : false
              }
            />

            {validation.errors.brand && validation.touched.brand ? (
              <FormFeedback type="invalid">
                {validation.errors.brand}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
        <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="unit-input"
              placeholder="Color"
              name="color"
              value={validation.values.color}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.color && validation.touched.color
                  ? true
                  : false
              }
            />

            {validation.errors.color && validation.touched.color ? (
              <FormFeedback type="invalid">
                {validation.errors.color}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={date}
                className="form-control"
                onChange={(date, dateStr) => {
                  setDate(dateStr);
                }}
                placeholder="Purchase Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                // disableMobile={true}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div className="input-group my-2" style={{ padding: 0 }}>
              <Flatpickr
                value={installationDate}
                className="form-control"
                onChange={(date, dateStr) => {
                  setinstallationDate(dateStr);
                }}
                placeholder="Installation Date"
                style={{
                  backgroundColor: "white",
                  border: "1px solid lightgray",
                }}
                options={{disableMobile:true}}
              />
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2" style={{ padding: 0 }}>
            <SpacesDropdown
              // placeholder={"Location(s) Served"}
              stateChange={setSelectedSpaces}
              allOptions={SpaceSelectList}
              value={selectedSpaces}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="warrantyDetails-input"
              placeholder="Warranty Details"
              name="warrantyDetails"
              value={validation.values.warrantyDetails}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.warrantyDetails &&
                validation.touched.warrantyDetails
                  ? true
                  : false
              }
            />

            {validation.errors.warrantyDetails &&
            validation.touched.warrantyDetails ? (
              <FormFeedback type="invalid">
                {validation.errors.warrantyDetails}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        {installationComponent}
        <Col lg={10}>
          <div className="my-2">
            <Input
              type="text"
              className="form-control"
              id="notes-input"
              placeholder="Notes/Comments"
              name="notes"
              value={validation.values.notes}
              onBlur={validation.handleBlur}
              onChange={validation.handleChange}
              invalid={
                validation.errors.notes && validation.touched.notes
                  ? true
                  : false
              }
            />

            {validation.errors.notes && validation.touched.notes ? (
              <FormFeedback type="invalid">
                {validation.errors.notes}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Col lg={2}>
          <Button
            type="submit"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {update && id ? (
              <>Update Finish</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
