import { createSlice } from "@reduxjs/toolkit";

const existsInArray = (arr,func) => {
  return arr.findIndex(func) !== -1
}

const checkIfAllChecked = (arr) => {
  return arr.every((item) => item.checked)
}

function removeDuplicates(arr) { 
  let unique = []; 
  for (var i = 0; i < arr.length; i++) { 
      if (unique.findIndex((itm) => itm.id === arr[i].id) === -1) { 
          unique.push(arr[i]); 
      } 
  } 
  return unique; 
} 

export const initialState = {
  error: null,
  success: null,
  files:[],
  selected:null,
  count: null,
  page_size:null,
  total_pages: null,
  loading:false,
  btnLoading:false,
  refreshDropdown:false,
  selectedFiles:[],
  allChecked: false
};

const fileslice = createSlice({
  name: "recieptFiles",
  initialState,
  reducers: { 
    setfiles(state,action){
      state.files = action.payload.results.map((result) => ({...result,checked: existsInArray(state.selectedFiles,(elem) => elem.id === result.id) }))
      state.allChecked = checkIfAllChecked(state.files)
      state.count = action.payload.count
      state.total_pages = action.payload.total_pages
      state.page_size = action.payload.page_size
      state.page_num = action.payload.page_num
      state.loading = false
    },
    setIsLoading(state,action){
        state.loading = true
    },
    setBtnLoading(state,action){
      state.btnLoading = action.payload;
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    createFailure(state,action){
      state.error = action.payload;
      state.btnLoading = false;
    },
    fileFailure(state,action){
      state.error = action.payload;
      state.loading = false;
    },
    setSelected(state,action){
      state.selected = action.payload
    },
    createSucess(state,action){
      state.success = "Created Successfully"
      state.btnLoading = false;
    },
    deleteSuccess(state,action){
      state.success = "Deleted Successfully"
      state.loading = false;
    },
    setRefreshDropdown(state,action){
      state.refreshDropdown = !state.refreshDropdown
    },
    selectAll(state,action) {
      state.files = state.files.map((result) => ({...result,checked:true}))
      state.selectedFiles = removeDuplicates([...state.selectedFiles,...state.files.map((itm) => ({id:itm.id,name:itm.name,tag:itm.tag}))])
      state.allChecked = true;
    },
    unSelectAll(state,action) {
      state.files = state.files.map((result) => ({...result,checked:false}))
      state.selectedFiles = state.selectedFiles.filter((item) => !existsInArray(state.files,(elem) => item.id === elem.id))
      state.allChecked = false;
    },
    toggleFile(state,action){
      var check = false;
      if(state.files[action.payload.idx].checked){
        state.selectedFiles = state.selectedFiles.filter((itm)=>  itm.id !== state.files[action.payload.idx].id)
        state.allChecked = false;
      }else{
        state.selectedFiles.push({
          id:state.files[action.payload.idx].id,
          name: state.files[action.payload.idx].name,
          tag: state.files[action.payload.idx].tag
          }
        )
        check= true; 
      }
      state.files[action.payload.idx].checked = !state.files[action.payload.idx].checked;
      if(check){
        state.allChecked = checkIfAllChecked(state.files)
      }
    },
    unSelectFile(state,action){
      state.selectedFiles = state.selectedFiles.filter((itm)=>  itm.id !== action.payload)
      const idx = state.files.findIndex((item) => item.id === action.payload)
      if(idx !== -1) {
        state.files[idx].checked = false
        state.allChecked = false
      }
    },
    setSelectedFiles(state,action) {
      state.selectedFiles = action.payload
      state.files = state.files.map((result) => ({...result,checked: existsInArray(state.selectedFiles,(elem) => elem.id === result.id) }))
      state.allChecked = checkIfAllChecked(state.files)
    },
    saveState(state,action){
      localStorage.setItem("createRecieptSelect",JSON.stringify(state.selectedFiles))
    },
    reloadState(state,action){
      const val = localStorage.getItem("createRecieptSelect") || '[]'
      state.selectedFiles = JSON.parse(val)
      localStorage.removeItem("createRecieptSelect")
    }
  }
});


export const {
  setIsLoading,
  setfiles,
  resetMessages,
  fileFailure,
  setSelected,
  deleteSuccess,
  createSucess,
  setBtnLoading,
  createFailure,
  setRefreshDropdown,
  toggleFile,
  selectAll,
  unSelectAll,
  unSelectFile,
  setSelectedFiles,
  saveState,
  reloadState
} = fileslice.actions;

export default fileslice.reducer;