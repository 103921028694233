import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import TableContainer from "../../TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";

import { useSelector, useDispatch } from "react-redux";

import Loader from "../../../Components/Common/Loader";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../../Components/Common/privateImageViewer/privateImageViewer";
// import {
//   DeletefileThunk,
//   ListfilesThunk,
// } from "../../../slices/property/propertyFiles/thunk";
import { usePropertyFilesSearchTags } from "../../../Components/Hooks/propertyFilesSearchTags";
import TableContainer from "../../../pages/property/TableContainer";
// import { selectAll, toggleFile, unSelectAll } from "../../../slices/property/propertyFiles/reducer";
// import { SelectFilesModal } from "./SelectFilesModal";
import { SelectedFilesComponent } from "./selectedFiles";

const FilesTableSelect = ({
    selectedProperty,
    images,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
    refreshDrop,
    allChecked,
    unSelectAll,
    selectAll,
    toggleFile,
    ListfilesThunk,
    unSelectFile,
    selectedFiles,
    multiple=true
}) => {
  

  const [activeTab, setActiveTab] = useState("1");


  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    tags: "",
    page: 1,
  });


  const [selectTagsDropdown, tagsVal, getTags] = usePropertyFilesSearchTags(
    refreshDrop,
    "Search by Tag"
  );

  useEffect(() => {
    setFilterParams((oldParams) => ({
      ...oldParams,
      page: 1,
      tags: getTags(),
    }));
  }, [tagsVal]);

  useEffect(() => {
    // console.log(refreshDrop)
    setFilterParams({
      page: 1,
    });
  }, [selectedProperty,refreshDrop]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListfilesThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);


  function checkedAll() {
    if(allChecked){
        dispatch(unSelectAll())
    }else{
        dispatch(selectAll())
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "select",
        Header: (
          multiple &&
          <div
            style={{ cursor: "pointer" }}
            className="p-2"
            onClick={() => checkedAll()}
          >
            <input
              type="checkbox"
              id="checkBoxAll"
              className="form-check-input"
              checked={allChecked}
              style={{ cursor: "pointer" }}
            />
          </div>
        ),
        Cell: (cellProps) => {
          const idx = cellProps.cell.row.index;

          return (
            <div
              style={{ cursor: "pointer" }}
              className="p-2"
              onClick={() => dispatch(toggleFile({ idx: idx , multiple:multiple }))}
            >
              <input
                type="checkbox"
                className="orderCheckBox form-check-input"
                style={{ cursor: "pointer" }} 
                checked={cellProps.row.original.checked}
              />
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "file",
        filterable: false,
        Cell: (capture) => {
          return (
            <FileLinkViewer
              file={capture.row.original}
              module={"privateFile"}
            />
          );
        },
      },
      {
        Header: "Tag",
        accessor: "tag",
        filterable: false,
      },
      {
        Header: "Extension",
        accessor: "extension",
        filterable: false,
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
    ],
    [allChecked]
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
    }
  };

  return (
    <Row>
      <Col lg={12}>
        {error && <Error error={error} />}
        {success && <Alert color="success">{success}</Alert>}
        <Card id="orderList">
          <CardHeader className="border-0">
            <h5 className="card-title mb-4">Files</h5>
            <Row className="align-items-center gy-3">
              <div className="col-sm">{selectTagsDropdown}</div>
            </Row>
            <SelectedFilesComponent selectedFiles={selectedFiles} unSelectFile={unSelectFile} />
          </CardHeader>

          <CardBody className="pt-1">
            <div>
              {/* <Nav
                className="nav-tabs nav-tabs-custom nav-success"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: activeTab === "1" },
                      "fw-semibold"
                    )}
                    onClick={() => {
                      toggleTab("1", "All");
                    }}
                    href="#"
                  >
                    <i className="ri-store-2-fill me-1 align-bottom"></i> All
                    Files
                  </NavLink>
                </NavItem>
              </Nav> */}
              {loading ? (
                <Loader />
              ) : (
                <TableContainer
                  columns={columns}
                  data={images || []}
                  divClass="table-responsive table-card mb-1"
                  tableClass="align-middle table-nowrap"
                  theadClass="table-light text-muted text-uppercase"
                  SearchPlaceholder="Search for order ID, customer, order status or something..."
                  onPageChange={changePage}
                  numOfPages={numOfPages}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  total={numOfCaptures}
                />
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FilesTableSelect;
