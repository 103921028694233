import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Tooltip,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import { CreateForm } from "./createForm";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  ListMyContractorsThunk,
  DeleteCaptureThunk,
  DeleteMyContractorsThunk,
} from "../../../../slices/thunks";
import { getCaptureDataSrcs } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import {
  resetMessages,
  setSelected,
} from "../../../../slices/property/myContractors/reducer";
import UpdateModal from "../update/updateModal";

import { TooltipItem } from "../../../../Components/Common/ToolTipItem";
import { ToastContainer } from "react-toastify";
// import { myContractorsActionstooltip, myContractorsDataSrctooltip, myContractorsDisplayUrltooltip, myContractorsNametooltip, myContractorsNewEDitListTooltip, myContractorsSpacestooltip, myContractorstooltip } from "../../../../Components/constants/tooltips";

const MyContractorsPage = () => {
  document.title = "My Contractors | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");

  const [myContractorsDelete, setCaptureDelete] = useState(null);

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    page: 1,
  });

  const {
    selectedProperty,
    myContractors,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    myContractors: state.myContractors.myContractors,
    numOfPages: state.myContractors.total_pages,
    numOfCaptures: state.myContractors.count,
    pageSize: state.myContractors.page_size,
    currentPage: state.myContractors.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.myContractors.loading,
    error: state.myContractors.error,
    success: state.myContractors.success,
  }));

  useEffect(() => {
    setFilterParams({
      page: 1,
    });
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListMyContractorsThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function onDeleteClick() {
    dispatch(
      DeleteMyContractorsThunk(myContractorsDelete, () => setCaptureDelete(null))
    );
  }


  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: (
          <>
            <span>
              Name
              {/* <TooltipItem id={3} placement="bottom" data={myContractorsNametooltip} /> */}
            </span>
          </>
        ),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          const user = cell.row.original.user;
          return user ? (
            <Link to={`/profile/${user}`} className="fw-medium link-primary">
              {cell.value}
            </Link>
          ) : (
            <span>{cell.value}</span>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Email
              {/* <TooltipItem id={4} placement="bottom" data={myContractorsDataSrctooltip} /> */}
            </span>
          </>
        ),
        id: "email",
        Cell: (cell) => {
          const email = cell.row.original.email;
          return <span>{email || "--"}</span>;
        },
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Phone Number
              {/* <TooltipItem id={5} placement="bottom" data={myContractorsDisplayUrltooltip} /> */}
            </span>
          </>
        ),
        id: "phoneNumber",
        Cell: (cell) => {
          const phoneNumber = cell.row.original.phoneNumber;
          return <span>{phoneNumber || "--"}</span>;
        },
        filterable: false,
      },
     
     
      {
        Header: (
          <>
            <span>
              Action
              {/* <TooltipItem id={7} placement="bottom" data={myContractorsActionstooltip} /> */}
            </span>
          </>
        ),
        id: "Actions",
        Cell: (myContractors) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    setIsEdit(myContractors.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    setCaptureDelete(myContractors.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );


  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1 }));
    }
  };

  const onSuccess = () => {
    if (selectedProperty) {
      dispatch(ListMyContractorsThunk(selectedProperty.id, filterParams));
    }
  };

  return (
    <div className="page-content">
      

      <DeleteModal
        show={myContractorsDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateModal
        success={success}
        error={error}
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={onSuccess}
      />

     
      <Container fluid>
        <BreadCrumb
          title="Table"
          pageTitle="My Contractors"
          pageTitleComponent={
            <>
              <h4 to="">
                My Contractors{" "}
                {/* <TooltipItem id={1} data={myContractorstooltip} /> */}
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      New/List/View/Edit
                      {/* // <TooltipItem id={2} data={myContractorsNewEDitListTooltip} /> */}
                    </h5>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <CreateForm onSuccess={onSuccess} />
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        All
                        Contractors
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={myContractors || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                  
                </div>
                <ToastContainer closeButton={false} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyContractorsPage;
