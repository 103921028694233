
import {  setIsLoading , setmaintenance , maintenanceFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getmaintenance, createmaintenance, deletemaintenance } from "../../../helpers/backend_helper";

import { updatemaintenance } from "../../../helpers/backend_helper";

export const ListmaintenanceThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getmaintenance(pid,params)
    .then((res)=> dispatch(setmaintenance(res)))
    .catch((err) => dispatch(maintenanceFailure(err)))

}

export const CreatemaintenanceThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createmaintenance(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdatemaintenanceThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updatemaintenance(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeletemaintenanceThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deletemaintenance(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(maintenanceFailure(err)))
    .finally(()=> closeModal())
}