import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({
  title,
  pageTitle,
  pageLink = "#",
  pageTitleComponent = null,
}) => {
  return (
    <React.Fragment>
      <Row>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            {pageTitleComponent ? (
              pageTitleComponent
            ) : (
              <h4 className="mb-sm-0">{title}</h4>
            )}

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to={pageLink}>{pageTitle}</Link>
                </li>
                {title instanceof Array ? (
                  title.map((val, idx) => (
                    <li key={idx} className="breadcrumb-item active">
                      {val}
                    </li>
                  ))
                ) : (
                  <li className="breadcrumb-item active">{title}</li>
                )}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
