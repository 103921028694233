import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Modal,ModalBody,ModalHeader,Form
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {

  getSpaceTypes,
  updateSpace
} from "../../../../helpers/backend_helper";
import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setWith } from "lodash";

const UpdateSpaceModal = (
  {
    edited,
    toggle,
    onSuccess = ()=>{}
  }
) => {

  const id = edited? edited.id: null

  const [msg, setmsg] = useState({ err: false, msg: null });
  const [loading, setLoading] = useState(false);
  
  
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [spaceType, setSpaceType] = useState("");
  const [name, setName] = useState(null);
  const [length, setlength] = useState(null);
  const [width, setwidth] = useState(null);
  const [height, setheight] = useState(null);
  const [windows, setwindows] = useState(null);
  const [doors, setdoors] = useState(null);
  const [notes, setnotes] = useState("");

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getData() {
    
    return {
      "name":name,
      "length": length,
      "width": width,
      "height": height,
      "windows": windows,
      "doors": doors,
      "spaceType": spaceType,
      "notes":notes
  };
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add name for your Space");
    // checkOrError(length, errs, "you need to add Length for your Space");
    // checkOrError(width, errs, "you need to add Width for your Space");
    // checkOrError(height, errs, "you need to add height for your Space");
    // checkOrError(spaceType, errs, "you need to select type for your Space");
    // checkOrError(
    //   windows,
    //   errs,
    //   "you need to add no. of windows for your Space"
    // );
    // checkOrError(doors, errs, "you need to add no. of doors for your Space");

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = getData();
      setLoading(true);
      if (id) {
        updateSpace(id, data)
          .then((res) => {
            setmsg({ err: false, msg: "Updated Successfully" });
            onSuccess()
          })
          .catch((err) => setmsg({ err: true, msg: err }))
          .finally(() => setLoading(false));
      }
    }
  }

  useEffect(() => {
    getSpaceTypes().then((res) =>
      setSpaceTypes(res.map((type) => type.spaceName))
    );
  }, []);

  useEffect(()=>{
    if(edited){
      setName(edited.name)
      setlength(edited.length)
      setheight(edited.height)
      setwidth(edited.width)
      setSpaceType(edited.spaceType)
      setdoors(edited.doors)
      setwindows(edited.windows)
      setnotes(edited.notes||"")
    }
    setmsg({ err: false, msg: null })
  },[edited])

  

  return (
    <Modal
      id="UpdateSpaceModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
    >
      <ModalHeader
        className="p-3"
        toggle={toggle}
      >
        Spaces/Location Edit
      </ModalHeader>

      {!msg.err && msg.msg ? <Alert color="success">{msg.msg}</Alert> : null}

      {msg.err && msg.msg ? <Error error={msg.msg} /> : null}

      <ModalBody>
      <Form action="#">
      <Row>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                // type="number"
                className="form-control"
                placeholder="Length"
                aria-label="Length"
                value={length}
                onChange={(e) => setlength(e.target.value)}
              />
            </div>
          </div>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                // type="number"
                className="form-control"
                placeholder="Width"
                aria-label="width"
                value={width}
                onChange={(e) => setwidth(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                // type="number"
                className="form-control"
                placeholder="Height"
                aria-label="height"
                value={height}
                onChange={(e) => setheight(e.target.value)}
              />
            </div>
          </div>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                style={{ backgroundRowor: "#eff2f7" }}
                onChange={(e) => setSpaceType(e.target.value)}
                value={spaceType}
              >
                <option value="" disabled selected>
                  Space Type
                </option>
                {spaceTypes.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="number"
                className="form-control"
                placeholder="No. Windows"
                aria-label="No. Windows"
                value={windows}
                onChange={(e) => setwindows(e.target.value)}
              />
            </div>
          </div>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="number"
                className="form-control"
                placeholder="No. Doors"
                aria-label="No. Doors"
                value={doors}
                onChange={(e) => setdoors(e.target.value)}
              />
            </div>
          </div>
        
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
            </div>
          </div>
        
      </Row>
    </Form>

        <div className="d-flex align-items-start gap-3 mt-4">
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Edit Space
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpdateSpaceModal;
