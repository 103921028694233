import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = localStorage.getItem('userToken') || sessionStorage.getItem('userToken')
if(token)
  axios.defaults.headers.common["Authorization"] = "Token " + token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    let errMsg = NaN;

    switch (error.response.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 400:
        message = "Bad request";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
    }
    if (error.response.data instanceof Array){
      error.response.data = error.response.data.join('\n')
    }
    errMsg = error.response.data || message || err;
    return Promise.reject({ status:error.response.status , message:errMsg , autoMessage: message});
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
};

const resetAuthorization = () => {
  delete axios.defaults.headers.common["Authorization"]
}

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };

  

  get = (url, params,headers) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        if(params[key]){
          paramKeys.push(key + '=' + params[key]);
          return paramKeys;
        }
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      // console.log(headers)
      response = axios.get(`${url}?${queryString}`, params,headers||{});
    } else {
      response = axios.get(`${url}`, params,headers||{});
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data,headers) => {
    return axios.post(url, data,headers || {});
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("userToken");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

function camelize(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

export { APIClient, setAuthorization, resetAuthorization , getLoggedinUser , camelize };