import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledCarousel,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import classnames from "classnames";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import {
  HVACaddImage,
  HVACaddReciept,
  HVACdeleteImage,
  HVACdeleteReciept,
  retrieveHVAC,
  retrieveHVACSpaces,
} from "../../../../helpers/backend_helper";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import UpdateHVACModal from "../New";
import { useDispatch, useSelector } from "react-redux";
import { DeleteHVACThunk } from "../../../../slices/thunks";
import { PdfPreview } from "../../../../Components/Common/pdfPreview";
// import { GallerySlide } from "./GallerySlide";
import { galleryActions } from "../../../../slices/gallery/reducer";
import { HVACADDIMAGE } from "../../../../helpers/url_helper";
import { ImagesCarousalWithGallery } from "../../../../Components/Common/detailsPageDisplay/imagesDisplayEdit";
import { PrivateRecieptDisplay, RecieptsDisplay } from "../../../../Components/Common/detailsPageDisplay/recieptDisplay";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

const HVACDetails = () => {
  document.title = "HVAC Details | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  let { id } = useParams();
  const [HVAC, setHVAC] = useState({});

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect(true,"Reciept(s)")
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(true,"Image(s)")

  // const isPdf = HVAC.reciept
  //   ? HVAC.reciept.split(".").pop().toUpperCase() === "PDF"
  //   : false;

  const navigate = useNavigate();

  const { selectedProperty, loading, error, success } = useSelector(
    (state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.HVAC.loading,
      error: state.HVAC.error,
      success: state.HVAC.success,
    })
  );

  useEffect(() => {
    if (id) {
      retrieveHVAC(id).then((res) => setHVAC(res));
    }
  }, [id]);

  useEffect(() => {
    if (HVAC.propertyy && selectedProperty.id !== HVAC.propertyy) {
      navigate("/HVAC");
    }
  }, [selectedProperty]);

  useEffect(()=>{
    if(HVAC.images){
      dispatch(setSelectedImageFiles(HVAC.images))
    }
    if(HVAC.reciepts){
      dispatch(setSelectedRecieptFiles(HVAC.reciepts))
    }
  },[HVAC])

  const dispatch = useDispatch();

  const [SpacesViewModalComp, setSpacesId] = useSpaceViewer({
    func: retrieveHVACSpaces,
  });

  function onDeleteClick() {
    dispatch(
      DeleteHVACThunk(captureDelete, () => {
        navigate("/HVAC");
      })
    );
  }

  function OnUpdateSuccess() {
    if (id) {
      retrieveHVAC(id).then((res) => setHVAC(res));
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [isTop, setIsTop] = useState(false);

  useEffect(()=>{
    if(!isEdit){
      if(HVAC.images){
        dispatch(setSelectedImageFiles(HVAC.images))
      }
      if(HVAC.reciepts){
        dispatch(setSelectedRecieptFiles(HVAC.reciepts))
      }
    }
  },[isEdit])

  const toggleTopCanvas = () => {
    console.log(isTop);
    setIsTop(!isTop);
  };

  function deleteImage(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    HVACdeleteImage(pk)
      .then(() => {
        retrieveHVAC(id).then((res) => setHVAC(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Deleted Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function handleAddToGallery(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("image", file);
    HVACaddImage(id, form)
      .then(() => {
        retrieveHVAC(id).then((res) => setHVAC(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Added Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function deleteReciept(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    HVACdeleteReciept(pk)
      .then(() => {
        retrieveHVAC(id).then((res) => setHVAC(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Deleted Successfully"
          )
        );
        callback();
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function handleAddToGalleryReciept(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("reciept", file);
    HVACaddReciept(id, form)
      .then(() => {
        retrieveHVAC(id).then((res) => setHVAC(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Added Successfully"
          )
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  

  return (
    
    
    <React.Fragment>
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />
      {/* {RecieptModal} */}
      {/* {ImageModal} */}
      <UpdateHVACModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />
      {SpacesViewModalComp}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="HVAC" pageTitle="Property Workbook" />
          <Card>
            <CardBody>
              <Row className="g-4">
                <Col lg={4}>
                  <div className="sticky-side-div">
                    {/* <Card className="ribbon-box border shadow-none right"> */}
                      {/* <CardHeader>Image(s)</CardHeader> */}
                      {/* <ImagesCarousalWithGallery
                        images={(HVAC.images || []).map((img) => ({
                          ...img,
                          image: img.image,
                        }))}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                      /> */}
                      {/* <ImagesCarousalWithGallery
                        images={(HVAC.images || [])}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                        module="privateFile"
                        privateFile={true}
                      /> */}
                      {ImageViewerComponent}
                    {/* </Card> */}
                    {/* <Card className="ribbon-box border shadow-none right"> */}
                      {/* <RecieptsDisplay
                        reciepts={HVAC.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                      /> */}
                      {/* <PrivateRecieptDisplay 
                        reciepts={HVAC.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                        privateFile={true}
                        module="privateFile" /> */}
                        {RecieptViewerComponent}
                    {/* </Card> */}
                  </div>
                </Col>
                <Col lg={8}>
                  <div>
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn btn-ghost-primary btn-icon"
                        role="button"
                      >
                        <i className="ri-more-fill align-middle fs-16"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="#" className="view-item-btn">
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                          View
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => setIsEdit(HVAC)}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setCaptureDelete(id)}
                          className="remove-item-btn"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <h4>
                      {HVAC.name} - {HVAC.HVACType}
                    </h4>
                    <div className="hstack gap-3 flex-wrap">
                      <div className="text-muted">
                        Make :{" "}
                        <Link to="" className="text-primary fw-medium">
                          {HVAC.make}
                        </Link>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Model :{" "}
                        <span className="text-body fw-medium">
                          {HVAC.model}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Serial Number :{" "}
                        <span className="text-body fw-medium">
                          {HVAC.serialNumber}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Cost :{" "}
                        <span className="text-body fw-medium">
                          {HVAC.cost} $
                        </span>
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Purachase Date :
                            </p>
                            <h5 className="fs-17 mb-0">{HVAC.purchaseDate}</h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Location
                            </p>
                            <h5 className="fs-17 mb-0">{HVAC.unitLocation}</h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Energy Type
                            </p>
                            <h5 className="fs-17 mb-0">{HVAC.energyType}</h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Energy Rate - KWhs
                            </p>
                            <h5 className="fs-17 mb-0">{HVAC.energyRate}</h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Location(s) Serviced :</h5>
                      <p>
                        {/* {(HVAC.spaces || [])
                          .map((space) => space.name)
                          .join(", ")} */}
                        <Link to="#" onClick={() => setSpacesId(HVAC.id)}>
                          See List
                        </Link>
                      </p>
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Warranty Details :</h5>
                      <p>{HVAC.warrantyDetails}</p>
                    </div>
                    {HVAC.notes ? (
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Notes :</h5>
                        <p>{HVAC.notes}</p>
                      </div>
                    ) : null}

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">Service Provider :</h5>

                      <Nav tabs className="nav-tabs-custom nav-success">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            Installation Information
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={customActiveTab}
                        className="border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <TabPane id="nav-speci" tabId="1">
                          <div>
                            <p className="mb-2">
                              <i
                                style={{ color: "#299CDB", fontSize: "30px" }}
                                className="mdi mdi-circle-medium me-1 align-middle"
                              ></i>{" "}
                              {HVAC.installationDate}
                            </p>
                            <h5 className="font-size-16 mb-3">
                              Installation Contractor
                            </h5>
                            {HVAC.installationContractor && (
                              <p>{HVAC.installationContractor.name}</p>
                            )}
                            <div></div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HVACDetails;
