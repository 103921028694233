import {
    Card,
    Col,  
} from "reactstrap";


import { Link } from "react-router-dom";
import { AddToGalleryModal } from "./AddToGalleryModal";

import { useEffect, useState } from "react";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { useSelector, useDispatch } from "react-redux";
import { deleteGalleryThunk } from "../../../slices/thunks";
import { ToastContainer, toast } from "react-toastify";

export const GalleryCard = ({
    imgSrc,
    imgCaption,
    hasClear=false,
    onClick=() => {},
    id=null,
  }) => {

  const [deleteModal, setdeleteModal] = useState(false);
  const { error , btnloading , success } = useSelector((state) => ({
    error: state.viewProperty.galleryDeleteErr,
    btnloading: state.viewProperty.galleryLoading,
    success: state.viewProperty.galleryDeleteSuccess,
  }));
  
  const dispatch = useDispatch()


  return (
    <Col xl={3} lg={4} sm={6}>
      <Card  className="gallery-box light mb-0">
        <div style={{position:'relative'}} className="gallery-container">
          <Link onClick={onClick} to="#" title="">
            <img className="gallery-img img-fluid mx-auto" src={imgSrc} alt="" />
            <div className="gallery-overlay">
              <h5 className="overlay-caption">
                {imgCaption}
              </h5>
            </div>
          </Link>
          {
          hasClear&&
          <div onClick={() => setdeleteModal(true)} style={{ cursor: 'pointer', position:'absolute', background:'white', padding:5 ,top:10,right:10}}>
            <i style={{ fontSize:'20px' ,color:'red'}} class="mdi mdi-delete-alert"></i>
          </div>
          }
        </div>
      </Card>
      {
        hasClear &&
        <>
        <DeleteModal
         show={deleteModal}
         onCloseClick={() => setdeleteModal(false)}
         onDeleteClick ={()=> dispatch(deleteGalleryThunk(id))}
        />
        </>
      }
      
    </Col>
  );
};
