import React, { useMemo, useRef, useState } from "react";
import Select, { components } from "react-select";
import { SpaceFilter } from "./spacesDropdown/components";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {


  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

const MenuContainer = (props) => {
  
  return <components.Menu {...props}><SpaceFilter setValue={props.setValue} getValue={props.getValue} levels={props.options} /></components.Menu>

};

export function SpacesDropdown({
  stateChange,
  placeholder = "Select Space(s)",
  allOptions,
  value,
}) {

    const allOptionsSelect =
    allOptions.map((option)=> ({
      label:option.name,
      options: option.spaces.map((space)=> ({
        label:space.name,
        value: space.id
      }))
    })
    ) 


  const onChange = (newValue, actionMeta) => {
    stateChange(newValue);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFFFFF",
    }),
  };

  return (
    <Select
      defaultValue={[]}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      onChange={onChange}
      value={value}
      options={allOptionsSelect}
      placeholder={placeholder}
      components={{
        Option: InputOption,
        Menu: MenuContainer
      }}
      styles={customStyles}
    />
  );
}
