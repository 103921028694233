import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  Msg: null,
  Error: false,
  Success:false,
  loading:false
};

const createPasswordSlice = createSlice({
  name: "resetpwd",
  initialState,
  reducers: {
      setLoading(state,action){
        state.loading = action.payload
      },
      userForgetPasswordSuccess(state, action) {
          state.Msg = action.payload
          state.Success = true
          state.Error = false
          state.loading = false
      },
      userForgetPasswordError(state, action) {
          state.Msg = action.payload
          state.Error = true
          state.loading = false
      },
      setMsg(state, action){
        state.Msg = action.payload
      }
  },
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setLoading , setMsg
} = createPasswordSlice.actions

export default createPasswordSlice.reducer;
