import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// // font awesome icons explict import for smaller bundle size
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";

import CaptureIcon from "../../assets/images/icons/3D Captures.svg";
import AppliancesIcon from "../../assets/images/icons/Appliances.svg";
import FinishesIcon from "../../assets/images/icons/Finishes_Alt.svg";
import FlooringIcon from "../../assets/images/icons/Flooring.svg";
import HVACIcon from "../../assets/images/icons/HVAC.svg";
import InventoryIcon from "../../assets/images/icons/Inventory.svg";

import { ReactSVG } from 'react-svg'

import * as backend from "../../helpers/backend_helper";

export const MODULESICONS = {
  "3D Capture(s)": <ReactSVG className="left-nav-icons" src={CaptureIcon} />,
  Utilities: <FontAwesomeIcon
  icon={faLightbulb}
  style={{ fontSize: "15px", color: "#ABB9E8" }}
/>,
  Maintenance: <FontAwesomeIcon
  icon={faClock}
  style={{ fontSize: "15px", color: "#ABB9E8" }}
/>,
  Inventory: <ReactSVG className="left-nav-icons"
  src={InventoryIcon} />,
  HVAC:  <ReactSVG className="left-nav-icons" src={HVACIcon} />,
  Flooring: <ReactSVG className="left-nav-icons"
  src={FlooringIcon} />,
  Finishes: <ReactSVG className="left-nav-icons" src={FinishesIcon} />,
  Appliance: <ReactSVG className="left-nav-icons" src={AppliancesIcon} />
};


export const MODULES_FILE_HANDLERS = {
  appliancePhoto :backend.appliancePhoto ,
  applianceReciept :backend.applianceReciept,
  finishPhoto: backend.finishPhoto,
  finishReciept: backend.finishReciept,
  flooringPhoto:backend.flooringPhoto ,
  flooringReciept:backend.flooringReciept ,
  levelPhoto:backend.levelPhoto ,
  HVACPhoto:backend.HVACPhoto ,
  HVACReciept:backend.HVACReciept ,
  insurancePhoto:backend.insurancePhoto ,
  inventoryPhoto:backend.inventoryPhoto ,
  maintenancePhoto:backend.maintenancePhoto ,
  maintenanceReciept:backend.maintenanceReciept ,
  billPhoto:backend.billPhoto,
  privateFile:backend.retrieveFileProperty,
  supportFile:backend.SupportCaseFileRetrieve,
  privatereport: backend.ReportRetrieve
}


export const MODULESLINKS = {
  "3D Capture(s)": "/3dCapture",
  Utilities: "/utilities",
  Maintenance: "/maintenance",
  Inventory: "/inventory",
  HVAC:  "/hvac",
  Flooring: "/flooring",
  Finishes: "/finishes",
  Appliance: "/appliance",
  "Insurance":"/insurance",
};

export const REPORT_FUNCS = {
  Utilities: backend.BillsReportCreate,
  Insurance: backend.InsuranceReportCreate,
  Inventory: backend.InventoryReportCreate,
  HVAC:  backend.HVACReportCreate,
  Flooring: backend.FlooringReportCreate,
  Finishes: backend.FinishesReportCreate,
  Appliance: backend.ApplianceReportCreate
}