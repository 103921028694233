//Auth
// export const POST_FAKE_LOGIN = "/auth/signin";
// export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
// export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
// export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
// export const SOCIAL_LOGIN = "/social-login";

// registeration
export const LOGIN = '/user/login/';
export const GETUSERINFO = '/user/getInfo/'
export const RESETPWD = (uid,token) =>  `/user/setpassword/${uid}/${token}/`
export const GETACTIVATEUSER = (uid,token) => `/user/activate/${uid}/${token}/`
export const GETROLES = '/user/roles/'
export const CHECKEMAIL = '/user/checkEmail/'
export const REGISTER = '/profile/register/'
export const CHANGEPWD = '/user/password-change/'

// profile 
export const GETPROFILE = (uid) =>  `/profile/get/profile/${uid}/`
export const UPDATEPROFILE = (uid) => `/profile/update/profile/${uid}/`

// property

// property v1
export const  GETALLPROPERTYUSE = '/property/uses/'
export const  GETALLPROPERTYTYPE = '/property/types/'
export const  CREATEPROPERTY = '/property/create/'
export const  GETPROPERTIES =  '/property/properties/'
export const  GETPROPERTY = (id) => `/property/retrieve/${id}/`
export const  EDITPROPERTY = (id) => `/property/update/${id}/`
// gallery
export const  ADDTOGALLERY = '/property/gallery/'
export const  GETGALLERY = (propertyy) => `/property/gallery/?propertyy=${propertyy}`
export const  DELETEGALLERY = (id) => `/property/gallery/${id}/`
// property v2
export const  GETDROPDOWNPROPERTIES = '/property/dropdown/properties/'
// captures
export const  PROPERTYCAPTURES = (id) =>  `/property/3dCaptures/${id}/`
export const  CAPTUREDATASRC = '/property/dataSource/'
export const  CAPTUREUPDATE = (cid) => `/property/3dCapture/${cid}/`
export const  SPACESELECTLIST = (pid) => `/property/select/spaces/${pid}/`
export const  CAPTURESpaces = (cid) => `/property/3dCapture/spaces/${cid}/`
// floors
export const  SIDENAVLEVELS = (pid) => `/property/nav/levels/${pid}/`
export const  GETALLFLOORS = (pid) => `/property/levels/${pid}/`
export const  GETALLFLOORFILETYPES = '/property/levels/fileTypes/'
export const  GETALLFLOORTYPES = '/property/levels/types/'
export const  CREATEFLOOR = (pid) => `/property/levels/${pid}/`
export const  DELETEFLOOR = (fid) => `/property/level/${fid}/`
export const  UPDATEFLOOR = (fid) => `/property/level/${fid}/`
// spaces
export const  GETALLSPACES = (fid) => `/property/spaces/${fid}/`
export const  CREATESPACE = (fid) => `/property/spaces/${fid}/`
export const  DELETESPACE = (sid) => `/property/space/${sid}/`
export const  UPDATESPACE = (sid) => `/property/space/${sid}/`
export const  GETSPACETYPES = '/property/spaceTypes/'

export const SCANSPACESFROMIMAGE = '/scanner/floorplan/'
export const SCANSPACESFROMID = (id) => `/scanner/floorplan/${id}/`
export const STATUSELEMENT = '/property/statuses/'

// images
export const  PROPERTYIMAGES = (id) =>  `/property/property/images/${id}/`
export const  PROPERTYIMAGESUPDATE = (Iid) => `/property/property/image/${Iid}/`
export const  PROPERTYIMAGESpaces = (Iid) => `/property/property/image/${Iid}/`
// inventory
export const  GETINVENTORY = (pid) => `/property/property/inventories/${pid}/`
export const  UPDATEINVENTORY = (pk) => `/property/property/inventory/${pk}/`
export const  INVENTORYTYPES = '/property/property/inventory/types/' 
export const  INVENTORYSpaces = (pk) => `/property/property/inventory/spaces/${pk}/`

// utilites
export const  GETBILLS = (pid) => `/property/property/bills/${pid}/`
export const  UPDATEBILL = (pk) => `/property/property/bill/${pk}/`
export const  GETBILLTYPES = '/property/property/bills/types/'
export const  BILLSpaces = (pk) => `/property/property/bill/spaces/${pk}/`

// Appliance
export const  GETAPPLIANCE = (pid) => `/property/property/appliances/${pid}/`
export const  UPDATEAPPLIANCE = (pk) => `/property/property/appliance/${pk}/`
export const  APPLIANCETYPES = '/property/property/appliances/types/'
export const  APPLIANCEENERGYTYPES = '/property/property/appliances/energy_types/'
export const  APPLIANCEDETAIL =  (pk) => `/property/property/appliance/detail/${pk}/`
export const  ApplianceADDIMAGE = (hid) => `/property/property/appliance/images/add/${hid}/`
export const  ApplianceREMOVEIMAGE = (pk) => `/property/property/appliance/images/delete/${pk}/`
export const  ApplianceADDRECIEPT = (hid) => `/property/property/appliance/reciepts/add/${hid}/`
export const  ApplianceREMOVERECIEPT = (pk) => `/property/property/appliance/reciepts/delete/${pk}/`
// HVAC
export const  GETHVAC = (pid) => `/property/property/hvac/${pid}/`
export const  UPDATEHVAC = (pk) => `/property/property/hvac/detail/${pk}/`
export const  HVACTYPES = '/property/property/hvac/types/'
export const  HVACENERGYTYPES = '/property/property/hvac/energy_types/'
export const  HVACADDIMAGE = (hid) => `/property/property/hvac/images/add/${hid}/`
export const  HVACREMOVEIMAGE = (pk) => `/property/property/hvac/images/${pk}/`
export const  EDITRETRIEVE = (pk) => `/property/property/hvac/edit/${pk}/`
export const  HVACADDRECIEPT = (hid) => `/property/property/hvac/reciepts/add/${hid}/`
export const  HVACREMOVERECIEPT = (pk) => `/property/property/hvac/reciepts/delete/${pk}/`
export const  HVACSpaces = (pk) => `/property/property/hvac/spaces/${pk}/`
// location
export const LOCATIONURL = (pid) => `/property/locations/${pid}/`
// My Contractors
export const CONTRACTORSEARCH = '/property/contractor/search/'
export const MYCONTRACTORSLIST = (pid) => `/property/mycontractors/${pid}/`
export const MYCONTRACTORSEDIT = (pk) => `/property/mycontractors/detail/${pk}/`
export const MYCONTRACTORSELECT = (pid) =>`/property/mycontractors/select/${pid}/`
// Flooring
export const  GETFLOORING = (pid) => `/property/property/flooring/${pid}/`
export const  UPDATEFLOORING = (pk) => `/property/property/flooring/detail/${pk}/`
export const  EDITRETRIEVEFLOORING = (pk) => `/property/property/flooring/edit/${pk}/`
export const  FLOORINGTYPES = '/property/property/flooring/types/'
export const  FLOORINGPLACEMENTTYPES = '/property/property/flooring/placement/'
export const  INSTALLATIONtYPES = '/property/property/installation_type/'
export const  FLOORINGADDIMAGE = (hid) => `/property/property/flooring/images/add/${hid}/`
export const  FLOORINGREMOVEIMAGE = (pk) => `/property/property/flooring/images/delete/${pk}/`
export const  FLOORINGADDRECIEPT = (hid) => `/property/property/flooring/reciepts/add/${hid}/`
export const  FLOORINGREMOVERECIEPT = (pk) => `/property/property/flooring/reciepts/delete/${pk}/`
export const  FlooringSpaces = (pk) => `/property/property/flooring/spaces/${pk}/`
//Finishes
export const  GETFinishes = (pid) => `/property/property/finishes/${pid}/`
export const  UPDATEFinishes = (pk) => `/property/property/finishes/detail/${pk}/`
export const  EDITRETRIEVEFinishes = (pk) => `/property/property/finishes/edit/${pk}/`
export const  FinishesTYPES = '/property/property/finishes/types/'
export const  FinishesPLACEMENTTYPES = '/property/property/finishes/placement/'
export const  FinishesADDIMAGE = (hid) => `/property/property/finishes/images/add/${hid}/`
export const  FinishesREMOVEIMAGE = (pk) => `/property/property/finishes/images/delete/${pk}/`
export const  FinishesADDRECIEPT = (hid) => `/property/property/finishes/reciepts/add/${hid}/`
export const  FinishesREMOVERECIEPT = (pk) => `/property/property/finishes/reciepts/delete/${pk}/`
export const  FinishesSpaces = (pk) => `/property/property/finishes/spaces/${pk}/`
// insurance
export const  GETInsurance = (pid) => `/property/property/insurance/${pid}/`
export const  UPDATEInsurance = (pk) => `/property/property/insurance/detail/${pk}/`
export const  insurancePolicyTYPES = '/property/property/insurance/policyForms/'
export const  insuranceDeductableTYPES = '/property/property/insurance/deductableTypes/'
export const  RetrieveInsurance = (pk) => `/property/property/insurance/retrieve/${pk}/`
// maintenance
export const  GETmaintenance = (pid) => `/property/property/maintenance/${pid}/`
export const  UPDATEmaintenance = (pk) => `/property/property/maintenance/detail/${pk}/`
export const  maintenanceTYPES = '/property/property/maintenance/types/'
export const  maintenancePRIORITIES = '/property/property/maintenance/priorites/'
export const  maintenanceFREQUENCIES = '/property/property/maintenance/frequency/'
export const  maintenanceADDIMAGE = (hid) => `/property/property/maintenance/images/add/${hid}/`
export const  maintenanceREMOVEIMAGE = (pk) => `/property/property/maintenance/images/${pk}/`
export const  EDITRETRIEVEmaintenance = (pk) => `/property/property/maintenance/edit/${pk}/`
export const  maintenanceADDRECIEPT = (hid) => `/property/property/maintenance/reciepts/add/${hid}/`
export const  maintenanceREMOVERECIEPT = (pk) => `/property/property/maintenance/reciepts/delete/${pk}/`
export const  maintenanceSpaces = (pk) => `/property/property/maintenance/spaces/${pk}/`

// private photos
// export const  maintenance = (pk) => `/property/property/maintenance/detail/${pk}/`

export const  appliancePhoto = (pk) =>`/property/property/Appliance/photo/${pk}/`
export const  applianceReciept = (pk) =>`/property/property/Appliance/reciept/${pk}/`
export const  finishPhoto = (pk) =>`/property/property/finishes/photo/${pk}/`
export const  finishReciept = (pk) =>`/property/property/finishes/reciept/${pk}/`
export const  flooringPhoto = (pk) =>`/property/property/flooring/photo/${pk}/`
export const  flooringReciept = (pk) =>`/property/property/flooring/reciept/${pk}/`
export const  levelPhoto = (pk) =>`/property/level/photo/${pk}/`
export const  HVACPhoto = (pk) =>`/property/property/HVAC/photo/${pk}/`
export const  HVACReciept = (pk) =>`/property/property/HVAC/reciept/${pk}/`
export const  insurancePhoto = (pk) =>`/property/property/insurance/photo/${pk}/`
export const  inventoryPhoto = (pk) =>`/property/property/inventory/photo/${pk}/`
export const  maintenancePhoto = (pk) =>`/property/property/Maintenance/photo/${pk}/`
export const  maintenanceReciept = (pk) =>`/property/property/Maintenance/reciept/${pk}/`
export const  billPhoto = (pk) =>`/property/property/bill/photo/${pk}/`

// notes
export const  notesEndpoint = (pid) =>`/property/notes/${pid}/`
export const  noteEndpoint = (pk) =>`/property/note/${pk}/`
export const  noteSpacesEndpoint = (pk) =>`/property/note/spaces/${pk}/`
export const  noteCategoriesEndpoint = (pid) =>`/property/notes/categories/${pid}/`

// reminders
export const  remindersEndpoint = (pid) =>`/property/reminders/${pid}/`
export const  reminderEndpoint = (pk) =>`/property/reminder/${pk}/`
export const  reminderSpacesEndpoint = (pk) =>`/property/reminder/spaces/${pk}/`
export const  reminderCategoriesEndpoint = (pid) =>`/property/reminders/categories/${pid}/`

// files
export const  GETFilesProperty = (pid) => `/property/files/${pid}/`
export const  UpdateFileProperty = (pk) => `/property/files/file/${pk}/`
export const  GetFileTagsProperty = (pid) => `/property/files/tags/${pid}/`

// support
export const submitFeedback = '/support/feedback/'

// trouble ticket
export const CaseCreateUrl = '/support/supportCase/create/'
export const CaseMsgCreateUrl = (id) => `/support/supportMsg/create/${id}/`
export const CasesListUrl = '/support/supportCases/'
export const CaseRetrieveUrl = (id) =>  `/support/supportCase/${id}/`
export const supportFileUrl = (id) =>  `/support/support/file/${id}/`
export const supportCaseChangeState = (id) =>  `/support/supportCase/changeState/${id}/`
export const supportCaseClose = (id) =>  `/support/supportCase/close/${id}/`

// reports
export const allReports = (pid) =>  `/report/reports/${pid}/`
export const deleteRetrieveReports = (pk) =>  `/report/report/${pk}/`

export const generatePropertyReport = (pid) =>  `/report/generate/property/${pid}/`
export const generatePropertyWorkbookReport = (pid) =>  `/report/generate/propertyWorkbook/${pid}/`
export const generateApplianceReport = (pid) =>  `/report/generate/appliances/${pid}/`
export const generateFinishesReport = (pid) =>  `/report/generate/finishes/${pid}/`
export const generateFlooringReport = (pid) =>  `/report/generate/flooring/${pid}/`
export const generateHVACReport = (pid) =>  `/report/generate/hvac/${pid}/`
export const generateInventoryReport = (pid) =>  `/report/generate/inventory/${pid}/`
export const generateInsuranceReport = (pid) =>  `/report/generate/insurance/${pid}/`
export const generateBillsReport = (pid) =>  `/report/generate/bills/${pid}/`
export const generatePropertyElementsReport = (pid) =>  `/report/generate/propertyElements/${pid}/`
export const generateFloorsReport = (pid) =>  `/report/generate/floors/${pid}/`


// property elements
export const GETPropertyElements = (pid) =>  `/property/propertyElements/${pid}/`
export const GETSpacesPropertyElements = (pk) => `/property/propertyElement/spaces/${pk}/`
export const DeletePropertyElement = (pk) => `/property/propertyElements/destroy/${pk}/`

// settings
export const GETPropertyElementsSettingsTable = (pid) =>  `/settings/propertyElements/table/${pid}/`
export const GETPropertyElementsSettingsConfig = (pid) => `/settings/propertyElements/config/${pid}/`