import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Spinner,
  Label,
  Alert,
  Row,
  Button,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

// import Masonry from "react-masonry-component";
import { useSelector, useDispatch } from "react-redux";

import img12 from "../../../assets/images/addToGallery.png";

import { GalleryCard } from "./GalleryCard";

import { Error, toastError } from "../../../Components/error";

// backend
import { PropertyReportCreate, getGallery, getProperty } from "../../../helpers/backend_helper";

import { useParams } from "react-router-dom";
import { AddToGalleryModal } from "./AddToGalleryModal";
import {
  EditPropertyThunk,
  PropertiesDropdownThunk,
  getGalleryThunk,
  getPropertyThunk,
  requestReportThunk,
} from "../../../slices/thunks";

import { LevelsTap } from "./LevelsTap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editProductFailure,
  editProductSuccess,
  resetGalleryDelete,
  resetMessages,
} from "../../../slices/property/viewProperty/reducer";

// import { GalleryDetail } from "./GalleryCarousal";
import { GallerySlide } from "./GallerySlide";
import { useAddress } from "../CreateProperty/address";
import { usePropertyType } from "../../../Components/Hooks/propertyType";
import { EditProductSuccess } from "../../../slices/property/editProperty/reducer";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { loadingActions } from "../../../slices/loading/reducer";
import axios from "axios";

function getAddressDetail(city) {
  const splitted = city.split(", ");
  if (splitted.length === 2) {
    return {
      city: splitted[0],
      country: splitted[1],
    };
  } else {
    return {
      city: splitted[0],
      country: splitted[2],
      state: splitted[1],
    };
  }
}

const ViewProperty = ({cities}) => {
  document.title = "View Property | DwellDoc";

  const [isTop, setIsTop] = useState(false);

  const [propertyState, setPropertyState] = useState({});

  const [addressComponents, getAddress, setAddressState, getAddressState] =
    useAddress({cities:cities});

  const [propertyTypeComponent, setPropertyType, getPropertyType] =
    usePropertyType();

  const [showDeleteModal, setDeleteModal] = useState(false);
  // const propertyVal = getPropertyType()
  // const addressVal = getAddressState()

  const {
    property,
    galleryDelerror,
    galleryDelsuccess,
    error,
    btnloading,
    success,
    user,
  } = useSelector((state) => ({
    property: state.viewProperty.property,
    error: state.viewProperty.error,
    btnloading: state.viewProperty.btnloading,
    success: state.viewProperty.success,
    galleryDelerror: state.viewProperty.galleryDeleteErr,
    galleryDelsuccess: state.viewProperty.galleryDeleteSuccess,
    user: state.Login.user,
  }));

  let { id } = useParams();

  const dispatch = useDispatch();

  const toggleTopCanvas = () => {
    setIsTop(!isTop);
  };

  useEffect(() => {
    dispatch(getPropertyThunk(id));
  }, [id]);

  useEffect(() => {
    if (galleryDelerror) {
      toast.error(galleryDelerror, {
        position: "bottom-center",
        onClose: () => dispatch(resetGalleryDelete()),
      });
    } else if (galleryDelsuccess) {
      toast.success(galleryDelsuccess, {
        position: "bottom-center",
        onClose: () => dispatch(resetGalleryDelete()),
      });
    }
  }, [galleryDelerror, galleryDelsuccess]);

  const address = useMemo(() => {
    if (property.city) {
      return getAddressDetail(property.city);
    } else {
      return {};
    }
  }, [property]);

  useEffect(() => {
    if (property.nickname) {
      setPropertyState(property);
      setAddressState({
        cityName: property.city,
        stateName: property.state,
        country: property.country,
      });
      setPropertyType({
        type: property.propertyType.id,
        use: property.propertyType.use,
      });
    }
  }, [property]);

  const changePropertyState = (key, val) => {
    setPropertyState((old_state) => {
      var new_state = { ...old_state };
      new_state[key] = val;
      return new_state;
    });
  };

  const onUpdateClick = () => {
    var body = {};
    const keys = Object.keys(property);
    var errs = [];
    for (let key of keys) {
      if (!propertyState[key]) {
        var dict = {};
        dict[key] = "This field can't be empty.";
        errs.push(dict);
      } else if (propertyState[key] && propertyState[key] != property[key]) {
        body[key] = propertyState[key];
      }
    }

    try {
      const cityAddress = getAddress();
      if (cityAddress.city.cityName !== property.city) {
        if (cityAddress.hasState) {
          body["city"] = cityAddress.city.cityName + ", " + cityAddress.state;
        } else {
          body["city"] = cityAddress.city.cityName;
        }
      }
    } catch (err) {
      errs.push(err);
    }

    const type = getPropertyType();

    if (!type.type) {
      errs.push({ "Property Type": "You need select your property type" });
    } else {
      if (type.type != property.propertyType.id) {
        body["propertyType"] = type.type;
      }
    }

    if (errs.length > 0) {
      dispatch(editProductFailure(errs));
    } else {
      const len = Object.keys(body).length;
      if (len > 0) {
        if (body["address"]) {
          setDeleteModal(body);
        } else {
          const onSuccess = () => {
            dispatch(PropertiesDropdownThunk(user.id));
            // dispatch()
          };
          dispatch(EditPropertyThunk(id, body, onSuccess));
        }
      } else {
        dispatch(editProductSuccess({ msg: "EveryThing is up to date" }));
      }
    }
  };

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [success, error]);

  const onDeleteClick = () => {
    const onSuccess = () => {
      setDeleteModal(false);
      dispatch(PropertiesDropdownThunk(user.id));
    };
    dispatch(EditPropertyThunk(id, showDeleteModal, onSuccess));
  };

  const exportPropertyClick = () => {
    // PropertyReportCreate(property.id)
    // .then((res) => toast.info("Your report is now being created. You can find it in reports Section."))
    // .catch((err) => toastError(err))
    dispatch(requestReportThunk(id))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <DeleteModal
            show={showDeleteModal}
            onDeleteClick={onDeleteClick}
            onCloseClick={() => setDeleteModal(false)}
            isLoading={btnloading}
            header="Warning"
            body="Editing Property Address Will Delete ALL DATA associated with this property"
          />
          <BreadCrumb
            title="View Property"
            pageTitle="Properties"
            pageLink={"/properties"}
          />
          <Row>
            <div className="p-2">
              {success && success ? (
                <Alert color="success">{success}</Alert>
              ) : null}

              {error && error ? <Error error={error} /> : null}
            </div>

            <div className="text-start mb-4">
              {/* <Button
                color="primary"
                disabled={btnloading || success || error}
                onClick={toggleTopCanvas}
              >
                {btnloading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Show Gallery
              </Button> */}
              <button
                type="button"
                disabled={btnloading || success || error}
                className="btn btn-soft-info"
                style={{backgroundColor:'#4fb6d3',color:'white'}}
                onClick={exportPropertyClick}
              >
                {btnloading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                <i className="ri-file-list-3-line align-middle"></i> Export Property
              </button>
            </div>

            <GallerySlide isTop={isTop} toggleTopCanvas={toggleTopCanvas} />

            <Col lg={8}>
              {/* location */}
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Property Location</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="address">
                      Address
                    </Label>
                    <Row>
                      <div class="input-group">
                        <textarea
                          type="text"
                          rows={3}
                          onChange={(e) =>
                            changePropertyState("address", e.target.value)
                          }
                          class="form-control"
                          id="address"
                          name="address"
                          placeholder="Short Description For Your Location"
                          aria-describedby="button-addon2"
                          value={propertyState.address}
                        />
                      </div>
                    </Row>
                  </div>
                  <Row>
                    {addressComponents}

                    {address.state && (
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="state-link">
                            State
                          </Label>
                          <Row>
                            <div class="input-group">
                              <input
                                type="text"
                                class="form-control"
                                id="state-link"
                                name="state"
                                placeholder="Property state"
                                onChange={(e) =>
                                  changePropertyState("state", e.target.value)
                                }
                                value={address.state}
                              />
                            </div>
                          </Row>
                        </div>
                      </Col>
                    )}
                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="zipCode-link">
                          Zip Code
                        </Label>
                        <Row>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              id="zipCode-link"
                              name="zipCodeLink"
                              placeholder="Your Property Zip Code"
                              onChange={(e) =>
                                changePropertyState("zipCode", e.target.value)
                              }
                              value={propertyState.zipCode}
                            />
                          </div>
                        </Row>
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="country-link">
                          Country
                        </Label>
                        <Row>
                          <div class="input-group">
                            <input
                              type="text"
                              class="form-control"
                              id="country-link"
                              name="country"
                              placeholder="Property Country"
                              disabled
                              readOnly
                              value={property.country}
                            />
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Property data</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="property-nickname" className="form-label">
                      Property Name
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      id="property-nickname"
                      name="nickname"
                      placeholder="Property Name (to recognize it in your dashboard)"
                      value={propertyState.nickname}
                      onChange={(e) =>
                        changePropertyState("nickname", e.target.value)
                      }
                    />
                  </div>

                  {propertyTypeComponent}
                </CardBody>
              </Card>
            </Col>
            <div className="text-end mb-4">
              <button
                onClick={onUpdateClick}
                disabled={btnloading || success || error}
                className="btn btn-primary w-lg"
              >
                {btnloading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Edit Property
              </button>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewProperty;


export const ViewPropertyWraper = () => {
  
  const [cities,setcities] = useState(null)

  const dispatch = useDispatch();

  useEffect(()=>{
    const url = window.location.origin;
    dispatch(loadingActions.setLoading(true));
    Promise.all([
      axios.get(url+'/cities.json'),
    ]).then((res) => {
      setcities(res[0]);
    });
  },[])

  useEffect(()=>{
    if(cities){
      dispatch(loadingActions.setLoading(false));
    }
  },[cities])

  return cities ? <ViewProperty cities={cities} /> :<div></div>
}