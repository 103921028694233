import React, { useEffect, useMemo, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Col,
  Spinner,
  Input,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";
import { SpaceFilterView } from "./viewComponents";



export const SpacesViewModal = ({
  show,
  toggle,
  allSpaces,
  spaces
}) => {

    const SelectList = useMemo(()=>{
        const removedLevels = allSpaces.filter((element) => spaces.findIndex((space) => element.id === space.level) !== -1 )
        return removedLevels.map((element) => ({
            label:element.name,
            options: element.spaces.filter((outSpace)=>  spaces.findIndex((space) => outSpace.id === space.id) !== -1)
        }) 
        )
    },[allSpaces,spaces])
 

  
  return (
    <Modal
    //   id="spaces"
      tabIndex="-1"
      isOpen={show}
      toggle={toggle}
      centered
      size="xl"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Space(s)/Location(s)
      </ModalHeader>

      <ModalBody>
        <SpaceFilterView levels={SelectList}/>
        {/* <SpacesDropdown allOptions={allSpaces} value={spaces}/>  */}
      </ModalBody>
    </Modal>
  );
};
