import { Col, Row, Input, Label, Form, Spinner, NavItem } from "reactstrap";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSpaceTypes } from "../../../../helpers/backend_helper";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFloorThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListFloorsThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// 

export const CreateSpaceForm = ({id , disabled , onSuccess=()=>{}}) => {
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [spaceType, setSpaceType] = useState("");
  const [name, setName] = useState(null);
  const [length, setlength] = useState(null);
  const [width, setwidth] = useState(null);
  const [height, setheight] = useState(null);
  const [windows, setwindows] = useState(null);
  const [doors, setdoors] = useState(null);
  const [notes, setnotes] = useState("");

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.spaceUpload.btnLoading,
  }));

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getData() {
    
    return {
      "name":name,
      "length": length,
      "width": width,
      "height": height,
      "windows": windows,
      "doors": doors,
      "spaceType": spaceType,
      "notes":notes
  };
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add name for your Space");
    // checkOrError(length, errs, "you need to add Length for your Space");
    // checkOrError(width, errs, "you need to add Width for your Space");
    // checkOrError(height, errs, "you need to add height for your Space");
    // checkOrError(spaceType, errs, "you need to select type for your Space");
    // checkOrError(
    //   windows,
    //   errs,
    //   "you need to add no. of windows for your Space"
    // );
    // checkOrError(doors, errs, "you need to add no. of doors for your Space");

    if (errs.length > 0) {
      dispatch(spaceFailure(errs));
    } else {
      dispatch(CreateSpaceThunk(id, getData(),onSuccess));
    }
  }

  useEffect(() => {
    getSpaceTypes().then((res) =>
      setSpaceTypes(res.map((type) => type.spaceName))
    );
  }, []);

  return (
    <Form action="#">
      <Row>
        <Col xl={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col xl={1}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Length"
                aria-label="Length"
                value={length}
                onChange={(e) => setlength(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col xl={1}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Width"
                aria-label="width"
                value={width}
                onChange={(e) => setwidth(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col xl={1}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Height"
                aria-label="height"
                value={height}
                onChange={(e) => setheight(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col xl={2}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <select
                className="form-select"
                id="floatingSelect"
                aria-label="Floating label select example"
                // style={{ backgroundColor: "#eff2f7" }}
                onChange={(e) => setSpaceType(e.target.value)}
                value={spaceType}
              >
                <option value="" disabled selected>
                  Space Type
                </option>
                {spaceTypes.map((type) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>
        </Col>
        <Col xl={2}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="number"
                className="form-control"
                placeholder="No. Windows"
                aria-label="No. Windows"
                value={windows}
                onChange={(e) => setwindows(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col xl={2}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="number"
                className="form-control"
                placeholder="No. Doors"
                aria-label="No. Doors"
                value={doors}
                onChange={(e) => setdoors(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col lg={10}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>
        <Col xl={2}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading || disabled}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            <i className="ri-add-line align-bottom me-1 btn-success"></i> Add
            Space
          </button>
        </Col>
      </Row>
    </Form>
  );
};
