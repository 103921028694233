import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  FormFeedback,
  Container,
  Form,
  Input,
  Label,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Alert,
  Button,
} from "reactstrap";

import { Error } from "../../../../Components/error";

import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { useSelector, useDispatch } from "react-redux";
// import {useSelector}
import * as Yup from "yup";

//import images
// import progileBg from "../../../../assets/images/profile-bg.jpg";
import avatar1 from "../../../../assets/images/profile/default.png";
import {
  changePWD,
  getProfileThunk,
} from "../../../../slices/auth/profile/thunk";
import { useFormik } from "formik";
import { editProfile } from "../../../../slices/auth/profile/thunk";
import {
  resetMessages,
  setError,
} from "../../../../slices/auth/profile/reducer";

import ImageOrLoad from "../../../../Components/Common/ImageOrLoad";

const Settings = () => {
  document.title = "DwellDoc | Profile";

  const [activeTab, setActiveTab] = useState("1");


  const [image, setImage] = useState(null);

  const [oldpasswordShow, setOldPasswordShow] = useState(false);
  const [newpasswordShow, setNewPasswordShow] = useState(false);
  const [confirmShow, setconfirmShow] = useState(false);

  function onImageChange(e) {
    var file = e.target.files[0];
    if (image) {
      URL.revokeObjectURL(image.url);
    }
    const url = URL.createObjectURL(file);
    setImage({
      image: file,
      url: url,
    });
  }

  const { userProfile, user, loading, btnLoading, success, error } =
    useSelector((state) => ({
      user: state.Login.user,
      userProfile: state.Profile.user,
      loading: state.Profile.loading,
      btnLoading: state.Profile.btnLoading,
      success: state.Profile.success,
      error: state.Profile.error,
    }));

  const dispatch = useDispatch();

  const initialState = {
    firstName: "",
    lastName: "",
    role: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    email: "",
    name: "",
  };

  function getFormData(values) {
    const keys = Object.keys(userProfile);
    // console.log(keys);
    var formdata = new FormData();
    for (var i = 0; i < keys.length; i++) {
      if (values[keys[i]] !== userProfile[keys[i]]) {
        formdata.append(keys[i], values[keys[i]]);
      }
    }
    return formdata;
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialState,
      ...user,
      ...userProfile,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      contactNumber: Yup.string().required("This field is required"),
      address: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      state: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
      zipCode: Yup.string().required("This field is required"),
      // yearOfBirth: Yup.number()
      //   .max(9999, "Year Of Birth must be 4 digits")
      //   .min(1000, "Year Of Birth must be 4 digits"),
    }),
    onSubmit: (values) => {
      console.log(values);

      var formdata = getFormData(values);
      if (image) {
        formdata.append("profile_picture", image.image);
        URL.revokeObjectURL(image.url);
        setImage(null);
      }

      if (formdata.entries().next().done) {
        dispatch(setError("All data are up to date"));
      } else {
        dispatch(editProfile(user.id, formdata));
      }
    },
  });

  const validation2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("This field is required"),
      new_password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .when("old_password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().notOneOf(
            [Yup.ref("old_password")],
            "New Password and Old Password must not Match"
          ),
        })
        .required("This field is required"),
      confirm_password: Yup.string().when("new_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("new_password")],
          "Confirm Password Doesn't Match"
        ),
      }),
    }),
    onSubmit: (values) => {
      // console.log(values)
      dispatch(changePWD(values));
      // console.log(values);
    },
  });

  useEffect(() => {
    if (!userProfile) {
      dispatch(getProfileThunk(user.id));
    }
  }, []);


  useEffect(() => {
    if (success || error) {
      setTimeout(() => dispatch(resetMessages()), 3000);
    }
  }, [success, error]);

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              {/* <img src={progileBg} className="profile-wid-img" alt="" /> */}
              <div className="overlay-content">
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    {loading ? (
                      <Spinner />
                    ) : (
                      //
                      <>
                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                          <ImageOrLoad
                            src={
                              image
                                ? image.url
                                : validation.values.profile_picture
                                ? validation.values.profile_picture
                                : avatar1
                            }
                            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                            alt="user-profile"
                          />
                          <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              type="file"
                              className="profile-img-file-input"
                              onChange={onImageChange}
                            />
                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <span className="avatar-title rounded-circle bg-light text-body">
                                <i className="ri-camera-fill"></i>
                              </span>
                            </Label>
                          </div>
                        </div>
                        <h5 className="fs-17 mb-1">{validation.values.name}</h5>
                        <p className="text-muted mb-0">
                          {validation.values.role}
                        </p>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                        type="button"
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Change Password
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      {loading ? (
                        <div style={{ height: 500, position: "relative" }}>
                          <Spinner
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit(e);
                          }}
                          className="needs-validation"
                          noValidate
                          action="index"
                        >
                          <Row>
                            {success && success ? (
                              <Alert color="success">{success}</Alert>
                            ) : null}

                            {error && error ? (
                              <Error error={error} />
                            ) : // <Alert color="danger">
                            //     <div>{error}</div>
                            // </Alert>
                            null}

                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="firstnameInput"
                                  className="form-label"
                                >
                                  First Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="firstName-input"
                                  placeholder="First Name"
                                  name="firstName"
                                  value={validation.values.firstName}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.firstName &&
                                    validation.touched.firstName
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.firstName &&
                                validation.touched.firstName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.firstName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="lastnameInput"
                                  className="form-label"
                                >
                                  Last Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="lastName-input"
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={validation.values.lastName}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.lastName &&
                                    validation.touched.lastName
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.lastName &&
                                validation.touched.lastName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lastName}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="phonenumberInput"
                                  className="form-label"
                                >
                                  Contact Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="contactNumber-input"
                                  placeholder="Contact Number"
                                  name="contactNumber"
                                  value={validation.values.contactNumber}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.contactNumber &&
                                    validation.touched.contactNumber
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.contactNumber &&
                                validation.touched.contactNumber ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.contactNumber}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emailInput"
                                  className="form-label"
                                >
                                  Email Address
                                </Label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="emailInput"
                                  placeholder="Enter your email"
                                  value={validation.values.email}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Col>
                            
                            {/* <Col lg={12}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="JoiningdatInput"
                                  className="form-label"
                                >
                                  Year Of Birth
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="yearofbirth"
                                  name="yearOfBirth"
                                  placeholder="Year Of Birth"
                                  value={validation.values.yearOfBirth || ""}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.yearOfBirth &&
                                    validation.touched.yearOfBirth
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.yearOfBirth &&
                                validation.touched.yearOfBirth ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.yearOfBirth}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emergencyContactName"
                                  className="form-label"
                                >
                                  Emergency Contact Name
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="emergencyContactName"
                                  name="emergencyContactName"
                                  placeholder="Emergency Contact Name"
                                  value={
                                    validation.values.emergencyContactName || ""
                                  }
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.emergencyContactName &&
                                    validation.touched.emergencyContactName
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="emergencyContactNumber-Input"
                                  className="form-label"
                                >
                                  Emergency Contact Number
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="emergencyContactNumber-Input"
                                  name="emergencyContactNumber"
                                  placeholder="Emergency Contact Number"
                                  value={
                                    validation.values.emergencyContactNumber ||
                                    ""
                                  }
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.emergencyContactNumber &&
                                    validation.touched.emergencyContactNumber
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </Col> */}
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="stateInput"
                                  className="form-label"
                                >
                                  State
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="state-input"
                                  placeholder="state"
                                  name="state"
                                  value={validation.values.state}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.state &&
                                    validation.touched.state
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.state &&
                                validation.touched.state ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.state}
                                  </FormFeedback>
                                ) : null}

                                {validation.errors.country &&
                                validation.touched.country ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="cityInput"
                                  className="form-label"
                                >
                                  City
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="city-input"
                                  placeholder="city"
                                  name="city"
                                  value={validation.values.city}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.city &&
                                    validation.touched.city
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.city &&
                                validation.touched.city ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.city}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="countryInput"
                                  className="form-label"
                                >
                                  Country
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="country-input"
                                  placeholder="Country"
                                  name="country"
                                  value={validation.values.country}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.country &&
                                    validation.touched.country
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.country &&
                                validation.touched.country ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.country}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Label
                                  htmlFor="zipcodeInput"
                                  className="form-label"
                                >
                                  Zip Code
                                </Label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="zipCode-input"
                                  placeholder="Zip Code"
                                  name="zipCode"
                                  value={validation.values.zipCode}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.zipCode &&
                                    validation.touched.zipCode
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.zipCode &&
                                validation.touched.zipCode ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.zipCode}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="mb-3 pb-2">
                                <Label
                                  htmlFor="address-input"
                                  className="form-label"
                                >
                                  Address
                                </Label>

                                <Input
                                  type="text"
                                  className="form-control"
                                  id="address-input"
                                  placeholder="Address"
                                  name="address"
                                  value={validation.values.address}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.address &&
                                    validation.touched.address
                                      ? true
                                      : false
                                  }
                                />

                                {validation.errors.address &&
                                validation.touched.address ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.address}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="hstack gap-2 justify-content-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={btnLoading || success || error  ? true : false}
                                >
                                  {btnLoading ? (
                                    <Spinner size="sm" className="me-2">
                                      Loading...
                                    </Spinner>
                                  ) : null}
                                  Update Profile
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </TabPane>

                    <TabPane tabId="2">
                      {loading ? (
                        <div style={{ height: 500, position: "relative" }}>
                          <Spinner
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                            }}
                          />
                        </div>
                      ) : (
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation2.handleSubmit(e);
                          }}
                          className="needs-validation"
                          noValidate
                          action="index"
                        >
                          <Row className="g-2">
                            {success && success ? (
                              <Alert color="success">{success}</Alert>
                            ) : null}

                            {error && error ? <Error error={error} /> : null}

                            <Col lg={4}>
                              <div>
                                <Label
                                  htmlFor="oldpasswordInput"
                                  className="form-label"
                                >
                                  Old Password*
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    type={oldpasswordShow ? "text" : "password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Enter old password"
                                    id="password-input"
                                    name="old_password"
                                    value={validation2.values.old_password}
                                    onBlur={validation2.handleBlur}
                                    onChange={validation2.handleChange}
                                    invalid={
                                      validation2.errors.old_password &&
                                      validation2.touched.old_password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation2.errors.old_password &&
                                  validation2.touched.old_password ? (
                                    <FormFeedback type="invalid">
                                      {validation2.errors.old_password}
                                    </FormFeedback>
                                  ) : null}
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      setOldPasswordShow(!oldpasswordShow)
                                    }
                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon-1"
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </Button>
                                </div>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div>
                                <Label
                                  htmlFor="newpasswordInput"
                                  className="form-label"
                                >
                                  New Password*
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    name="new_password"
                                    type={newpasswordShow ? "text" : "password"}
                                    placeholder="new Password"
                                    onChange={validation2.handleChange}
                                    onBlur={validation2.handleBlur}
                                    value={
                                      validation2.values.new_password || ""
                                    }
                                    invalid={
                                      validation2.touched.new_password &&
                                      validation2.errors.new_password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation2.touched.new_password &&
                                  validation2.errors.new_password ? (
                                    <FormFeedback type="invalid">
                                      <div>
                                        {validation2.errors.new_password}
                                      </div>
                                    </FormFeedback>
                                  ) : null}
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      setNewPasswordShow(!newpasswordShow)
                                    }
                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon-3"
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </Button>
                                </div>
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div>
                                <Label
                                  htmlFor="confirmpasswordInput"
                                  className="form-label"
                                >
                                  Confirm Password*
                                </Label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    name="confirm_password"
                                    type={confirmShow ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    onChange={validation2.handleChange}
                                    onBlur={validation2.handleBlur}
                                    value={
                                      validation2.values.confirm_password || ""
                                    }
                                    invalid={
                                      validation2.touched.confirm_password &&
                                      validation2.errors.confirm_password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation2.touched.confirm_password &&
                                  validation2.errors.confirm_password ? (
                                    <FormFeedback type="invalid">
                                      <div>
                                        {validation2.errors.confirm_password}
                                      </div>
                                    </FormFeedback>
                                  ) : null}
                                  <Button
                                    color="link"
                                    onClick={() => setconfirmShow(!confirmShow)}
                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon-2"
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </Button>
                                </div>
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="mb-3">
                                <Link
                                  to="/pass-reset"
                                  className="link-primary text-decoration-underline"
                                >
                                  Forgot Password ?
                                </Link>
                              </div>
                            </Col>

                            <Col lg={12}>
                              <div className="text-end">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  disabled={btnLoading ? true : false}
                                >
                                  {btnLoading ? (
                                    <Spinner size="sm" className="me-2">
                                      Loading...
                                    </Spinner>
                                  ) : null}
                                  Change Password
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
