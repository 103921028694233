import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row, FormFeedback, Input, Button , Alert , Spinner } from 'reactstrap';

import { ToastContainer, toast } from 'react-toastify';

import AuthSlider from '../authCarousel';

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';

// import { registerUser, apiError, resetRegisterFlag } from "../../slices/thunks";

import { registerUser , apiError, resetRegisterFlag } from '../../../slices/auth/register/thunk';
import { SignUpForm2 } from './SignUp2';
import { SignUpForm1 } from './SignUp1';

const CoverSignUp = () => {
    
    document.title = "SignUp | DwellDoc";

    const [step,setStep] = useState(1);

    const dispatch = useDispatch()

    const history = useNavigate();

    const { error, msg ,success , loading ,registrationError , signUp1Data } = useSelector(state => ({
        success: state.Account.success,
        msg : state.Account.message,
        error: state.Account.error , 
        registrationError: state.Account.registrationError,
        loading : state.Account.loading,
        
    }));


    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        if (success && step === 1) {
            setTimeout(() =>  setStep(2), 3000);
        }else if(success){
            setTimeout(() =>  history('/signIn'), 5500);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 5000);

    }, [dispatch, success, error, history]);

    // function signUp (e) {
    //     e.preventDefault()
    // }

    return (
        <React.Fragment>
            
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <Container>
                        <Row className="justify-content-center g-0">
                            <Col lg={6}>
                                <Card className="overflow-hidden m-0">
                                    <Row className="justify-content-center g-0">
                                        {/* <AuthSlider /> */}

                                        <Col lg={12}>
                                            <div className="p-lg-5 p-4">
                                                <div>
                                                    <h5 className="text-primary">Register Account</h5>
                                                    <p className="text-muted">Get your Free DwellDoc account now.</p>
                                                </div>

                                                {success && success ? (     
                                                    <Alert color="success">
                                                        {msg}
                                                    </Alert>
                                                ) : null}

                                                {error && error ? (
                                                    <Alert color="danger"><div>
                                                        {registrationError}</div></Alert>
                                                ) : null}

                                                <div className="mt-4">
                                                    {step == 1? (<SignUpForm1 />) : (<SignUpForm2 goBack={()=> setStep(1)} />)}
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p className="mb-0">Already have an account ? <Link to="/signin" className="fw-semibold text-primary text-decoration-underline"> Signin</Link> </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>

                <footer className="footer">
                    <Container>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0">&copy; 2023 DwellDoc.</p>
                                </div>
                            </div>
                        </div>
                    </Container>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default CoverSignUp;