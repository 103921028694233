import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Tooltip,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import { CreateForm } from "./createForm";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  ListCapturesThunk,
  DeleteCaptureThunk,
} from "../../../../slices/thunks";
import {
  getCaptureDataSrcs,
  retrieveCaptureSpaces,
} from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import {
  resetMessages,
  setSelected,
} from "../../../../slices/property/capture/reducer";
import UpdateModal from "../update/updateModal";

import { TooltipItem } from "../../../../Components/Common/ToolTipItem";
import {
  captureActionstooltip,
  captureDataSrctooltip,
  captureDisplayUrltooltip,
  captureNametooltip,
  captureNewEDitListTooltip,
  captureSpacestooltip,
  capturetooltip,
} from "../../../../Components/constants/tooltips";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";

const CaptureUpload = () => {
  document.title = "3D Capture | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");

  const [dataSrcs, setDataSrcs] = useState(null);

  const [captureDelete, setCaptureDelete] = useState(null);

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const mountedRef = useRef();

  const {
    selectedProperty,
    captures,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    captures: state.captureUpload.captures,
    numOfPages: state.captureUpload.total_pages,
    numOfCaptures: state.captureUpload.count,
    pageSize: state.captureUpload.page_size,
    currentPage: state.captureUpload.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.captureUpload.loading,
    error: state.captureUpload.error,
    success: state.captureUpload.success,
  }));

  useEffect(() => {
    if (mountedRef.current) {
      setFilterParams({
        dataSource: "All",
        page: 1,
      });
    }
    mountedRef.current = true;
  }, [selectedProperty]);

  const [SpacesViewModalComp, setSpacesId] = useSpaceViewer({
    func: retrieveCaptureSpaces,
  });

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListCapturesThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function onDeleteClick() {
    dispatch(DeleteCaptureThunk(captureDelete, () => setCaptureDelete(null)));
  }

  useEffect(() => {
    getCaptureDataSrcs().then((res) => setDataSrcs(res));
  }, []);

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: (
          <>
            <span>
              Name{" "}
              <TooltipItem
                id={3}
                placement="bottom"
                data={captureNametooltip}
              />
            </span>
          </>
        ),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/3dCapture/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Data Source{" "}
              <TooltipItem
                id={4}
                placement="bottom"
                data={captureDataSrctooltip}
              />
            </span>
          </>
        ),
        accessor: "dataSource",
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Display Url{" "}
              <TooltipItem
                id={5}
                placement="bottom"
                data={captureDisplayUrltooltip}
              />
            </span>
          </>
        ),
        accessor: "displayUrl",
        filterable: false,
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
      {
        Header: (
          <>
            <span>
              Spaces{" "}
              <TooltipItem
                id={6}
                placement="bottom"
                data={captureSpacestooltip}
              />
            </span>
          </>
        ),
        id: "spaces",
        accessor: "spaces",
        Cell: (capture) => (
          <Link to="#" onClick={() => setSpacesId(capture.row.original.id)}>
            See List
          </Link>
        ),
      },
      {
        Header: (
          <>
            <span>
              Action{" "}
              <TooltipItem
                id={7}
                placement="bottom"
                data={captureActionstooltip}
              />
            </span>
          </>
        ),
        id: "Actions",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const [isExportCSV, setIsExportCSV] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
      // let filteredOrders = orders;
      // if (type !== "all") {
      // filteredOrders = orders.filter((order) => order.status === type);
      // }
      // setOrderList(filteredOrders);
    }
  };

  const onSuccess = () => {
    if (selectedProperty) {
      dispatch(ListCapturesThunk(selectedProperty.id, filterParams));
    }
  };

  return (
    <div className="page-content">
      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={orderList}
      /> */}

      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateModal
        success={success}
        error={error}
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={onSuccess}
      />

      {SpacesViewModalComp}

      {/* <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      /> */}

      <Container fluid>
        <BreadCrumb
          title="Property Workbook"
          pageTitle="3D Capture"
          pageTitleComponent={
            <>
              <h4 to="">
                3D Capture <TooltipItem id={1} data={capturetooltip} />
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      New/List/View/Edit{" "}
                      <TooltipItem id={2} data={captureNewEDitListTooltip} />
                    </h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {/* <Link
                      to="/3dCapture/create"
                        type="button"
                        className="btn btn-primary add-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        id="create-btn"
                        // onClick={() => { setIsEdit(false); toggle(); }}
                      >
                        <i className="ri-add-line align-bottom me-1 btn-success"></i> Create
                        New
                      </Link>{" "} */}
                      {/* <button type="button" className="btn btn-secondary"
                    //    onClick={() => setIsExportCSV(true)}
                       >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                      {/* {" "} */}
                      {isMultiDeleteButton && (
                        <button
                          className="btn btn-danger"
                          // onClick={() => setDeleteModalMulti(true)}
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div> */}
                  {/* <Row> */}
                  {/* <SearchWithFilter /> */}
                  {/* </Row> */}
                  {/* </div> */}
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <CreateForm onSuccess={onSuccess} />
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="las la-cubes fs-17 align-middle"></i> All
                        Captures
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.name);
                          }}
                          href="#"
                        >
                          {src.iconClass && <i className={src.iconClass}></i>}
                          {src.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={captures || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      baseLink={"/3dCapture"}
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                  {/* {captures && captures.length ? (
                                     ) : (
                    
                  )} */}
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CaptureUpload;
