
import {  setIsLoading , setinventory , invFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getInventory,createInventory ,deleteInventory ,deleteImage } from "../../../helpers/backend_helper";

export const ListinventoryThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getInventory(pid,params)
    .then((res)=> dispatch(setinventory(res)))
    .catch((err) => dispatch(invFailure(err)))

}

export const CreateinventoryThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createInventory(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const DeleteinventoryThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteInventory(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(invFailure(err)))
    .finally(()=> closeModal())
}

// export const DeletefloorThunk = (cid,closeModal=()=>{}) => async (dispatch) => {
//     dispatch(setIsLoading())
//     deletefloor(cid)
//     .then((res)=> dispatch(deleteSuccess(cid)))
//     .catch((err)=> dispatch(floorFailure(err)))
//     .finally(()=> closeModal())
// }