
import {  setIsLoading , setreminders , remindersFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { createReminderBE, deleteReminderBE, getRemindersBE, updateReminderBE } from "../../../helpers/backend_helper";

export const ListremindersThunk = (pid,params) => async (dispatch) => {
    dispatch(setIsLoading())

    getRemindersBE(pid,params)
    .then((res)=> dispatch(setreminders(res)))
    .catch((err) => dispatch(remindersFailure(err)))

}

export const CreateremindersThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createReminderBE(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateremindersThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateReminderBE(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteremindersThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteReminderBE(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(remindersFailure(err)))
    .finally(()=> closeModal())
}