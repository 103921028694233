import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTagsProperty } from "../../helpers/backend_helper";
import Select from "react-select";


export const usePropertyTags = (refresh,placeholder="Enter your search tag") => {
  
  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [selectValues, setValues] = useState([]);

  useEffect(() => {
    if (selectedProperty) {
      getTagsProperty(selectedProperty.id).then((res) =>
        setValues(res.map((elm) => ({ value: elm.id, label: elm.name })))
      );
    }
  }, [selectedProperty,refresh]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#FFFFFF",
    }),
    menu: (base, state) => ({
        ...base,
        background: "#FFFFFF",
      }),
  };

  

  const handleSearchChange = (search , action) => {
    if (action.action === "input-change") {
        setSearchVal(search)
        setSelectedValue("")
    } 
  }

  const [selectedValue,setSelectedValue] = useState("")
  const [searchVal, setSearchVal] = useState("");

  const handleChange = (val) => {
    setSelectedValue(val)
    setSearchVal(val.label)
  }
  
  function getVal() {
    return selectedValue.label || searchVal
  }


  return [
    <Select
      defaultValue={[]}
      closeMenuOnSelect={true}
      hideSelectedOptions={false}
      onChange={handleChange}
      // value={selectedValue}
      value="" // always show placeholder
      noOptionsMessage={() => null}
      openOnFocus={false}
      openOnClick={false}
      onSelectResetsInput={false}
      onBlurResetsInput={false}
      onCloseResetsInput={false}
      arrowRenderer={() => null}
      clearRenderer={() => null}
      options={selectValues}
      placeholder={placeholder}
      styles={customStyles}
      onInputChange={handleSearchChange}
      inputValue={selectedValue.label || searchVal}
      components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
    />,
    selectedValue,
    setSearchVal,
    getVal
    ]
};
