import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Alert,
} from "reactstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";

import { useSelector, useDispatch } from "react-redux";

import Loader from "../../../../Components/Common/Loader";
import {
  FileLinkViewer,
  PrivatereportWraper,
} from "../../../../Components/Common/privateImageViewer/privateImageViewer";
import {
  DeletereportThunk,
  ListreportsThunk,
} from "../../../../slices/property/reports/thunk";

import {
  resetMessages,
  setRefreshDropdown,
} from "../../../../slices/property/reports/reducer";
import { Status } from "./Status";
import { RefreshButton } from "../../../../Components/Common/RefreshButton";

const PropertyReportsPage = () => {
  document.title = "Property Reports | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");
  const mountedRef = useRef()
  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const dispatch = useDispatch();

  const [showCreate, setshowCreate] = useState(false);

  const [filterParams, setFilterParams] = useState({
    tags: "",
    page: 1,
  });

  const [state, setState] = useState(false);

  const {
    selectedProperty,
    images,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
    refresh,
  } = useSelector((state) => ({
    images: state.reports.reports,
    numOfPages: state.reports.total_pages,
    numOfCaptures: state.reports.count,
    pageSize: state.reports.page_size,
    currentPage: state.reports.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.reports.loading,
    error: state.reports.error,
    success: state.reports.success,
    refresh: state.reports.refreshDropdown,
  }));

  // const [selectTagsDropdown,tagsVal,getTags] = usePropertyreportsSearchTags(refresh,"Search by Tag")

  // useEffect(()=>{
  //   setFilterParams((oldParams) =>  ({
  //     ...oldParams,
  //     "page":1,
  //     "tags":getTags()
  //   })
  //   )
  // },[tagsVal])

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      page: 1,
    });
  }
  mountedRef.current = true;
  }, [selectedProperty]);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListreportsThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams, state]);

  function OnUpdateSuccess() {
    setFilterParams((oldParams) => ({ ...oldParams, page: 1 }));
    dispatch(setRefreshDropdown());
  }

  function onDeleteClick() {
    dispatch(
      DeletereportThunk(captureDelete, () => {
        setCaptureDelete(null);
        OnUpdateSuccess();
      })
    );
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      // {
      //   Header: "Name",
      //   accessor: "name",
      //   filterable: false,
      //   Cell: (cell) => {
      //     return (
      //       <Link to="#" className="fw-medium link-primary">
      //         {cell.value}
      //       </Link>
      //     );
      //   },
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        Cell: (capture) => {
          return capture.row.original.file ? (
            <FileLinkViewer
              file={capture.row.original}
              module={"privatereport"}
            />
          ) : (
            <>{capture.value}</>
          );
        },
      },
      {
        Header: "Tag",
        accessor: "tag",
        filterable: false,
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          return <Status value={cell.value} />;
        },
      },
      {
        Header: "Creation Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              {/* <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                    onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original)
                    }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
    }
  };

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      {/* <CreateImageModal 
        show={showCreate} 
        toggle={()=> setshowCreate(false)}
        onSuccess={OnUpdateSuccess}
      /> */}

      {/* <UpdatereportModal 
        edited={isEdit}
        toggle={()=> setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      /> */}

      <Container fluid>
        <BreadCrumb title="Reports" pageTitle="Property Workbook" />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
            <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Reports</h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      <RefreshButton onClick={() => setState(!state)} />
                    </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {/* <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All reports
                      </NavLink>
                    </NavItem> */}
                  {/* {dataSrcs?.map((src,key)=> 
                      <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === `${key+2}` },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab(`${key+2}`, src.name);
                        }}
                        href="#"
                      >
                        {src.iconClass && (<i className={src.iconClass}></i> )}    
                        {src.name}
                      </NavLink>
                    </NavItem>
                    ) 
                    } */}
                  {/* </Nav> */}
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={images || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyReportsPage;
