import { useEffect, useMemo, useState } from "react";
import { SpacesDropdown } from "../Common/spacesDropdown"
import { getSpaceSelectList } from "../../helpers/backend_helper";


export const useSpacesDropdown = ({selectedProperty}) => {
    
  const [spaceSelected, setSpaceSelected] = useState({
    spaces:[],
    AllSelected:false
    });

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const length = useMemo(()=> {
    var len = 0;
    for( var level of SpaceSelectList){
        len += level.spaces.length
    }
    return len
  },[SpaceSelectList])

    return [
        <SpacesDropdown
            stateChange={(newval) => setSpaceSelected({spaces:newval , AllSelected:newval.length === length})}
            allOptions={SpaceSelectList}
            value={spaceSelected.spaces}
        />,
        spaceSelected
    ]
    
}