
import {  setIsLoading , setHVAC , HVACFailure , deleteSuccess , createSucess , setBtnLoading , createFailure } from "./reducer";

import { getHVAC, createHVAC, deleteHVAC } from "../../../helpers/backend_helper";

import { updateHVAC } from "../../../helpers/backend_helper";

export const ListHVACThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getHVAC(pid,params)
    .then((res)=> dispatch(setHVAC(res)))
    .catch((err) => dispatch(HVACFailure(err)))

}

export const CreateHVACThunk = (pid,data,onSuccess) => async (dispatch) => {
    dispatch(setBtnLoading(true))

    createHVAC(pid,data)
    .then((res) => {
        dispatch(createSucess()) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateHVACThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    dispatch(setBtnLoading(true))

    updateHVAC(pk,data)
    .then((res) => {
        dispatch(createSucess("Updated successfully")) 
        onSuccess()
    })
    .catch((err)=> dispatch(createFailure(err)))

}

export const DeleteHVACThunk = (fid,closeModal=()=>{}) => async (dispatch) => {
    
    dispatch(setIsLoading())
    
    deleteHVAC(fid)
    .then((res)=> dispatch(deleteSuccess(fid)))
    .catch((err)=> dispatch(HVACFailure(err)))
    .finally(()=> closeModal())
}