import { Col, Row, Input, Label, Form, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getInventoryTypes,
  getFloorFileTypes,
  getSpaceSelectList
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateFloorThunk,
  // CreateimageThunk,
  CreateinventoryThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListinventoryThunk,
  ListSpacesThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// import { SpacesDropdown } from "./spacesDropdown";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";

import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { invFailure } from "../../../../slices/property/homeInventory/reducer";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";
import { useStatusSelect } from "../../../../Components/Hooks/useStatusSelect";

export const CreateImageForm = () => {

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const [selectedSpaces,setSelectedSpaces] = useState([]);

  const [invTypes, setinvTypes] = useState([]);

  const [type, setType] = useState(null);

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.inventory.btnLoading,
  }));

  const [selectedFile, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [notes, setnotes] = useState("");

  const [StatusSelectComponent , getStatusVal , setStatus] = useStatusSelect();
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Inventory File",false)

  useEffect(() => {
    getInventoryTypes().then((res) => setinvTypes(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(res)
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    formdata.append("notes", notes);
    
    const image = getImageVal()
    if (image.length > 0){
      formdata.append("image", image[0]);
    }
    
    if(selectedSpaces.length > 0){
      formdata.append(
        "spaces",
        selectedSpaces.map((space) => parseInt(space.value)).join(",")
      );
    }

    formdata.append("inventorytype", type);
    formdata.append("status",getStatusVal());

    return formdata;
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Image ");

    // checkOrError(selectedFile, errs, "you need to select an Image file");

    checkOrError(type, errs, "you need to select a type");

    const statusVal = getStatusVal();
    checkOrError(
      statusVal,
      errs,
      "you need to select status"
    );

    if (errs.length > 0) {
      dispatch(invFailure(errs));
    } else {
      const onSuccess = () => {
        dispatch(ListinventoryThunk(selectedProperty.id));
      };
      dispatch(CreateinventoryThunk(selectedProperty.id, getFormdata(), onSuccess));
    }
  }

  return (
    <Form >
      {ImageModal}
      <Row>
        <Col lg={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
        </Col>
        
        <Col lg={2}>
          <div className="input-group my-2" style={{ padding: 0 }}>
            <select
              className="form-select"
              id="floatingSelect2"
              aria-label="Floating label select example"
              // style={{ backgroundColor: "#eff2f7" }}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="" disabled selected>
                Inventory Type
              </option>
              {invTypes.map((type) => (
                <option value={type.name}>{type.name}</option>
              ))}
            </select>
          </div>
        </Col>
        <Col className="my-2" lg={2}>
          {StatusSelectComponent}
        </Col>
        <Col lg={5}>
          <div className="my-2">
            <SpacesDropdown value={selectedSpaces} stateChange={setSelectedSpaces} allOptions={SpaceSelectList} />
          </div>
        </Col>
        
        <Col lg={12}>
          {/* <div className="form-group my-2"> */}
            {/* <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            > */}
              {/* <label
                for="files"
                style={{ backgroundColor: "#299CDB", width: "100%" }}
                class="btn btn-txt m-0"
              >
                {selectedFile ? selectedFile.name : "Upload Image"}
              </label>
              <input
                id="files"
                style={{ display: "None", backgroundColor: "#299CDB" }}
                type="file"
                onChange={(e) => setFile(e.target.files[0])}
              /> */}
              {ImageViewerComponent}
            {/* </div> */}
          {/* </div> */}
        </Col>

        <Col lg={10}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>

        <Col lg={2}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id="create-btn"
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            <i className="ri-add-line align-bottom me-1 btn-success"></i> Create
            New
          </button>
        </Col>
      </Row>
    </Form>
  );
};
