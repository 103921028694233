import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Modal,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import { reloadState as reloadStateImage, saveState as saveStateImage, setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { reloadState as reloadStateReciept, saveState as saveStateReciept, setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteFinishesThunk,
  DeleteimageThunk,
  DeleteinventoryThunk,
  ListFinishesThunk,
  ListimagesThunk,
  ListinventoryThunk,
} from "../../../../slices/thunks";
import { RetrieveFinishesSpaces, getCaptureDataSrcs, getSpaceSelectList } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import { resetMessages } from "../../../../slices/property/finishes/reducer";
import { CreateForm } from "./createForm";
import UpdateFinishesModal from "../New";
import {
  FinishesActionsTooltip,
  FinishesInstallDateTooltip,
  FinishesInstallerTooltip,
  FinishesNameTooltip,
  FinishesNewListTooltip,
  FinishesPlacementTooltip,
  FinishesSpacesTooltip,
  FinishesTypeTooltip,
  Finishestooltip,
} from "../../../../Components/constants/tooltips";
import { TooltipItem } from "../../../../Components/Common/ToolTipItem";
import { SpacesViewModal } from "../../../../Components/Common/spacesDropdown/SpaceViewModal";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
import { StatusComponent } from "../../../../Components/Common/Status";

const FinishesUpload = () => {
  document.title = "Finishes | DwellDoc";
  //
  const [activeTab, setActiveTab] = useState("1");

  const dataSrcs = [];

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  
  const mountedRef = useRef()
  const [ SpacesViewModalComp , setSpacesId ] = useSpaceViewer({func: RetrieveFinishesSpaces})

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const {
    selectedProperty,
    Finishes,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    Finishes: state.Finishes.Finishes,
    numOfPages: state.Finishes.total_pages,
    numOfCaptures: state.Finishes.count,
    pageSize: state.Finishes.page_size,
    currentPage: state.Finishes.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.Finishes.loading,
    error: state.Finishes.error,
    success: state.Finishes.success,
  }));

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      dataSource: "All",
      page: 1,
    });
  }
  mountedRef.current = true;
  }, [selectedProperty]);

  
  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListFinishesThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function onDeleteClick() {
    dispatch(
      DeleteFinishesThunk(captureDelete, () => {
        dispatch(ListFinishesThunk(selectedProperty.id));
        setCaptureDelete(null);
      })
    );
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListFinishesThunk(selectedProperty.id, filterParams));
    }
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);
  
  useEffect(()=>{
    dispatch(setSelectedImageFiles([]))
    dispatch(setSelectedRecieptFiles([]))
    dispatch(resetMessages())
  },[])

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      //
      {
        Header: (
          <>
            <span>
              Name{" "}
              <TooltipItem
                id={2}
                placement="bottom"
                data={FinishesNameTooltip}
              />
            </span>
          </>
        ),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`/Finishes/${cell.row.original.id}`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Type{" "}
              <TooltipItem
                id={3}
                placement="bottom"
                data={FinishesTypeTooltip}
              />
            </span>
          </>
        ),
        accessor: "Type",
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Placement{" "}
              <TooltipItem
                id={4}
                placement="bottom"
                data={FinishesPlacementTooltip}
              />
            </span>
          </>
        ),
        accessor: "placement",
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Installation Date{" "}
              <TooltipItem
                id={5}
                placement="bottom"
                data={FinishesInstallDateTooltip}
              />
            </span>
          </>
        ),
        accessor: "installationDate",
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Installer{" "}
              <TooltipItem
                id={6}
                placement="bottom"
                data={FinishesInstallerTooltip}
              />
            </span>
          </>
        ),
        accessor: "installer",
        filterable: false,
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          return <StatusComponent value={cell.value} />;
        },
      },
      {
        Header: (
          <>
            <span>
              Location{" "}
              <TooltipItem
                id={7}
                placement="bottom"
                data={FinishesSpacesTooltip}
              />
            </span>
          </>
        ),
        id: "spaces",
        accessor: "spaces",
        Cell: (capture) => (
          <Link
          to="#"
            onClick={() =>
              setSpacesId(capture.row.original.id)
            }
          >
            See List
          </Link>
        ),
      },
      {
        Header: "Date Created",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: (
          <>
            <span>
              Actions{" "}
              <TooltipItem
                id={8}
                placement="bottom"
                data={FinishesActionsTooltip}
              />
            </span>
          </>
        ),
        id: "actions",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ page: 1, dataSource: type }));
    }
  };

  useEffect(()=>{
    if(isEdit){
      dispatch(saveStateImage())
      dispatch(saveStateReciept())
    }else{
      dispatch(reloadStateImage())
      dispatch(reloadStateReciept())
    }
  },[isEdit])

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateFinishesModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />

      {/* <SpacesViewModal
        allSpaces={SpaceSelectList}
        spaces={showSpaces.spaces}
        show={showSpaces.show}
        toggle={() =>
          setshowSpaces({
            show: false,
            spaces: [],
          })
        }
      /> */}

      {SpacesViewModalComp}

      <Container fluid>
        <BreadCrumb
          title="Finishes"
          pageTitle="Property Workbook"
          pageTitleComponent={
            <>
              <h4 to="">
                Finishes <TooltipItem id={1} data={Finishestooltip} />
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      New/List/View/Edit{" "}
                      <TooltipItem id={9} data={FinishesNewListTooltip} />
                    </h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap"></div>
                  </div>
                  <div>
                    <div className="py-3">
                      <CreateForm />
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Items
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.name);
                          }}
                          href="#"
                        >
                          {src.iconClass && <i className={src.iconClass}></i>}
                          {src.name}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={Finishes || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FinishesUpload;
