import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import classnames from "classnames";
// import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";

// import {Error } from "../../../../Components"
// "../"
// Import Images

import { useParams } from "react-router-dom";
import {
  ApplianceaddImage,
  ApplianceaddReciept,
  AppliancedeleteImage,
  AppliancedeleteReciept,
  getApplianceDetail,
  retrieveAppliance,
} from "../../../../helpers/backend_helper";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import UpdateApplianceModal from "../New";
import { useDispatch, useSelector } from "react-redux";
import { DeleteapplianceThunk } from "../../../../slices/thunks";
import { PdfPreview } from "../../../../Components/Common/pdfPreview";
import { ImagesCarousalWithGallery } from "../../../../Components/Common/detailsPageDisplay/imagesDisplayEdit";
import { galleryActions } from "../../../../slices/gallery/reducer";
import { PrivateRecieptDisplay, RecieptsDisplay } from "../../../../Components/Common/detailsPageDisplay/recieptDisplay";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";


const ApplianceDetails = () => {
  document.title = "Appliance Details | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  let { id } = useParams();
  const [appliance, setAppliance] = useState({});

  // const isPdf = appliance.reciept
  //   ? appliance.reciept.split(".").pop().toUpperCase() === "PDF"
  //   : false;

  
  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect(true,"Reciept(s)")
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(true,"Image(s)")

  const navigate = useNavigate();

  const { selectedProperty, loading, error, success } = useSelector(
    (state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.appliance.loading,
      error: state.appliance.error,
      success: state.appliance.success,
    })
  );

  useEffect(() => {
    if (id) {
      getApplianceDetail(id).then((res) => setAppliance(res));
    }
  }, [id]);

  useEffect(() => {
    if (appliance.propertyy && selectedProperty.id !== appliance.propertyy) {
      navigate("/appliance");
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function onDeleteClick() {
    dispatch(
      DeleteapplianceThunk(captureDelete, () => {
        navigate("/appliance");
      })
    );
  }

  function OnUpdateSuccess() {
    if (id) {
      getApplianceDetail(id).then((res) => setAppliance(res));
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  function deleteImage(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    AppliancedeleteImage(pk)
      .then(() => {
        getApplianceDetail(id).then((res) => setAppliance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Deleted Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  function handleAddToGallery(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("image", file);
    ApplianceaddImage(id,form)
      .then(() => {
        getApplianceDetail(id).then((res) => setAppliance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Added Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }


  function deleteReciept(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    AppliancedeleteReciept(pk)
      .then(() => {
        getApplianceDetail(id).then((res) => setAppliance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Reciept is Deleted Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  function handleAddToGalleryReciept(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("reciept", file);
    ApplianceaddReciept(id,form)
      .then(() => {
        getApplianceDetail(id).then((res) => setAppliance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Reciept is Added Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  useEffect(()=>{
    if(appliance.images){
      dispatch(setSelectedImageFiles(appliance.images))
    }
    if(appliance.reciepts){
      dispatch(setSelectedRecieptFiles(appliance.reciepts))
    }
  },[appliance])

  useEffect(()=>{
    if(!isEdit){
      if(appliance.images){
        dispatch(setSelectedImageFiles(appliance.images))
      }
      if(appliance.reciepts){
        dispatch(setSelectedRecieptFiles(appliance.reciepts))
      }
    }
  },[isEdit])

  return (
    <React.Fragment>
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateApplianceModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />
      <div className="page-content">
        <Container fluid>
        <BreadCrumb title="Appliance" pageTitle="Property Workbook" />
          <Card>
            <CardBody>
              <Row className="g-4">
                <Col lg={4}>
                  <div className="sticky-side-div">
                    {/* <Card className="ribbon-box border shadow-none right">
                      <CardHeader>Image(s)</CardHeader> */}
                      {/* <ImagesCarousalWithGallery i
                      mages={(appliance.images || []).map((img) => ({...img,image:img.image}))} 
                      deleteImage={deleteImage} handleAddToGallery={handleAddToGallery} /> */}
                       {/* <ImagesCarousalWithGallery
                        images={(appliance.images || [])}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                        module="appliancePhoto"
                        privateFile={true}
                      />
                    </Card> */}
                    {/* <Card className="ribbon-box border shadow-none right"> */}
                      {/* <RecieptsDisplay reciepts={appliance.reciepts || []} deleteReciept={deleteReciept} addReciept={handleAddToGalleryReciept} /> */}
                      {/* <PrivateRecieptDisplay 
                        reciepts={appliance.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                        privateFile={true}
                        module="applianceReciept" />
                    </Card> */}
                    {ImageViewerComponent}
                    {RecieptViewerComponent}
                  </div>
                </Col>


                <Col lg={8}>
                  <div>
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn btn-ghost-primary btn-icon"
                        role="button"
                      >
                        <i className="ri-more-fill align-middle fs-16"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="#" className="view-item-btn">
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                          View
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => setIsEdit(appliance)}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setCaptureDelete(id)}
                          className="remove-item-btn"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <h4>
                      {appliance.name} - {appliance.applianceType}
                    </h4>
                    <div className="hstack gap-3 flex-wrap">
                      <div className="text-muted">
                        Make :{" "}
                        <Link to="" className="text-primary fw-medium">
                          {appliance.make}
                        </Link>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Model :{" "}
                        <span className="text-body fw-medium">
                          {appliance.model}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Serial Number :{" "}
                        <span className="text-body fw-medium">
                          {appliance.serialNumber}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Cost :{" "}
                        <span className="text-body fw-medium">
                          {appliance.cost} $
                        </span>
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Purachase Date :
                            </p>
                            <h5 className="fs-17 mb-0">
                              {appliance.purchaseDate}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Location
                            </p>
                            <h5 className="fs-17 mb-0">
                              {appliance.applianceLocation}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Energy Type
                            </p>
                            <h5 className="fs-17 mb-0">
                              {appliance.energyType}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Energy Rate - KWhs
                            </p>
                            <h5 className="fs-17 mb-0">
                              {appliance.energyRate}
                            </h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Warranty Details :</h5>
                      <p>{appliance.warrantyDetails}</p>
                    </div>
                    {appliance.notes ? (
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Notes :</h5>
                        <p>{appliance.notes}</p>
                      </div>
                    ) : null}

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">Service Provider :</h5>

                      <Nav tabs className="nav-tabs-custom nav-success">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            Installation Information
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={customActiveTab}
                        className="border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <TabPane id="nav-speci" tabId="1">
                          <div>
                            <p className="mb-2">
                              <i
                                style={{ color: "#299CDB", fontSize: "30px" }}
                                className="mdi mdi-circle-medium me-1 align-middle"
                              ></i>{" "}
                              {appliance.installationDate}
                            </p>
                            <h5 className="font-size-16 mb-3">
                              Installation Contractor
                            </h5>
                            {appliance.installationContractor && (
                              <p>{appliance.installationContractor}</p>
                            )}
                            <div></div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApplianceDetails;
