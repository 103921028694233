import { useState , useEffect } from 'react';
import {Link,useLocation} from 'react-router-dom';
import { Table, Row, Col, Button, Input, CardBody } from "reactstrap";

const NUMOFPAGESDISPLAYED = 5 // num of pages to be displayed in the pagination

function getPageUrlSearch(oldparams,pageNum){
    return new URLSearchParams([
        ...Array.from(oldparams.entries()),
        ["page",pageNum]
    ]).toString()
}


export const Pagination = ({ onPageChange ,currentPage ,NumPages , baseLink})=>{
    
    // const search = useLocation().search;
    // const [currentPage,setcurrentPage] = useState(1)

    // useEffect(()=>{
    //     const params = new URLSearchParams(search);
    //     const page = params.get('page')
    //     if (page){
    //         setcurrentPage(parseInt(page))
    //         onPageChange(page);
    //     }else{
    //         setcurrentPage(1)
    //         onPageChange(1);
    //     }
    // },[search])
    // console.log(onPageChange ,currentPage ,NumPages , baseLink)
    function getStartEnd(){
        const rem = currentPage%NUMOFPAGESDISPLAYED
        if ( rem === 1){
            const start = currentPage;
            const end = Math.min(start+(NUMOFPAGESDISPLAYED-1),NumPages);
            return [start , end]
        }
        else if(rem === 0){
            const start = currentPage-(NUMOFPAGESDISPLAYED)+1; 
            const end =  currentPage;
            return [start , end]
        }
        else{
            const start = currentPage-(rem-1); 
            const end =  Math.min(start+(NUMOFPAGESDISPLAYED-1),NumPages);
            return [start , end]
        }
    }

    const [start,end] = getStartEnd();

    // console.log(start,end)

    function getpagesLinks(){
        
        // const params = new URLSearchParams(search);
        // params.delete('page')
        // searchparams.ad
        // const searchparams = params.toString();
        var arr = []
        var i = start;
        const prevClassName = "page-item"+(currentPage > 1?"":" disabled") 
        const nextClassName = "page-item"+(currentPage < NumPages?"":" disabled")
        
        arr.push((<li key={-1} className={prevClassName}>
              <Link onClick={()=>onPageChange(currentPage-1)} to="#" className="page-link">
                Previous
              </Link>
            </li>)
        )
        
        // <li key={-1} className={prevClassName}><Link to={{pathname:"/items",search:searchparams+`&page=${currentPage-1}`}} className="page-link">Previous</Link></li>
        
        while(i<=end){
            const className = 'page-item'+(i === currentPage?' active':"") 
            const val = i;
            arr.push((
            <li key={i} className={className}>
                <Link onClick={()=>onPageChange(val)} to="#" className="page-link">
                  {i}
                </Link>
              </li>)
          )
            
            i+=1
        }

        
        arr.push((<li key={end+1} className={nextClassName}>
            <Link onClick={()=>onPageChange(currentPage+1)} to="#" className="page-link">
              Next
            </Link>
          </li>)
      )

        // arr.push(<li key={end+2} className={nextClassName}><Link to={{pathname:"/items",search:searchparams+`&page=${NumPages}`}} className="page-link"><span aria-hidden="true">&raquo;</span></Link></li>)

        return arr;
    }

    const pagesComponents = getpagesLinks()

    return (
        <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
            {pagesComponents}
        </ul>
    )
}


export const PaginationWithDetails = ({
    onPageChange,
    numOfPages,
    showingLength,
    currentPage,
    pageSize,
    total,
    baseLink
}) => {

    const start = (currentPage-1)*pageSize +1;
    const end =  start+ showingLength -1;

    return (
        <Row className="g-0 text-center text-sm-start align-items-center mb-4">
        <Col sm={6}>
          <div>
            <p className="mb-sm-0 text-muted">
              Showing <span className="fw-semibold">{Math.min(start,end)}</span> to{" "}
              <span className="fw-semibold">{end}</span> of{" "}
              <span className="fw-semibold text-decoration-underline">{total}</span>{" "}
              entries
            </p>
          </div>
        </Col>

        <Col sm={6}>
          <Pagination
          onPageChange={onPageChange}
          NumPages={numOfPages} 
          baseLink={baseLink} 
          currentPage={currentPage}
          />
        </Col>
      </Row>
    )
}