import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

export const useCategoryTabs = (selectedProperty,endpoint=(pid)=>{},refresh) => {
    const [categories, setCategories] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [selected, setSelectedTab] = useState("All");
    
    useEffect(()=>{
      if(selectedProperty){
        endpoint(selectedProperty.id).then((res) => setCategories(res));
      }
    },[selectedProperty,refresh])

    const toggleTab = (tab, type) => {
        if (activeTab !== tab) {
          setActiveTab(tab);
          setSelectedTab(type);
        }
      };

    return [
    <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === "1" }, "fw-semibold")}
          onClick={() => {
            toggleTab("1", "All");
          }}
          href="#"
        >
          {/* <i className="ri-store-2-fill me-1 align-bottom"></i>  */}
          All Items
        </NavLink>
      </NavItem>
      {categories?.map((src, key) => (
        <NavItem>
          <NavLink
            className={classnames(
              { active: activeTab === `${key + 2}` },
              "fw-semibold"
            )}
            onClick={() => {
              toggleTab(`${key + 2}`, src.name);
            }}
            href="#"
          >
            {/* {src.iconClass && <i className={src.iconClass}></i>} */}
            {src.name}
          </NavLink>
        </NavItem>
      ))}
    </Nav>,
    selected
  ];
};
