import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledCarousel,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import classnames from "classnames";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import {
  FinishesaddImage,
  FinishesaddReciept,
  FinishesdeleteImage,
  FinishesdeleteReciept,
  // FinishesaddImage,
  // FinishesdeleteImage,
  retrieveFinishes,
  RetrieveFinishesSpaces,
} from "../../../../helpers/backend_helper";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import UpdateFinishesModal from "../New";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFinishesThunk } from "../../../../slices/thunks";
import { PdfPreview } from "../../../../Components/Common/pdfPreview";
import { galleryActions } from "../../../../slices/gallery/reducer";
// import { FinishesADDIMAGE } from "../../../../helpers/url_helper";
import emptyImage from "../../../../assets/images/empty-image.jpg";
import { ImagesCarousalWithGallery } from "../../../../Components/Common/detailsPageDisplay/imagesDisplayEdit";
import { PrivateRecieptDisplay, RecieptsDisplay } from "../../../../Components/Common/detailsPageDisplay/recieptDisplay";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
//
import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

const FinishesDetails = () => {
  document.title = "Finishes Details | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  let { id } = useParams();
  const [Finishes, setFinishes] = useState({});

  // const isPdf = Finishes.reciept
  //   ? Finishes.reciept.split(".").pop().toUpperCase() === "PDF"
  //   : false;

  const navigate = useNavigate();

  const [ SpacesViewModalComp , setSpacesId ] = useSpaceViewer({func: RetrieveFinishesSpaces})


  const { selectedProperty, loading, error, success } = useSelector(
    (state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.Finishes.loading,
      error: state.Finishes.error,
      success: state.Finishes.success,
    })
  );

  useEffect(() => {
    if (id) {
      retrieveFinishes(id).then((res) => setFinishes(res));
    }
  }, [id]);

  useEffect(() => {
    if (Finishes.propertyy && selectedProperty.id !== Finishes.propertyy) {
      navigate("/Finishes");
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function onDeleteClick() {
    dispatch(
      DeleteFinishesThunk(captureDelete, () => {
        navigate("/Finishes");
      })
    );
  }

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect(true,"Reciept(s)")
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(true,"Image(s)")

  useEffect(()=>{
    if(Finishes.images){
      dispatch(setSelectedImageFiles(Finishes.images))
    }
    if(Finishes.reciepts){
      dispatch(setSelectedRecieptFiles(Finishes.reciepts))
    }
  },[Finishes])

  useEffect(()=>{
    if(!isEdit){
      if(Finishes.images){
        dispatch(setSelectedImageFiles(Finishes.images))
      }
      if(Finishes.reciepts){
        dispatch(setSelectedRecieptFiles(Finishes.reciepts))
      }
    }
  },[isEdit])

  function OnUpdateSuccess() {
    if (id) {
      retrieveFinishes(id).then((res) => setFinishes(res));
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  function deleteImage(pk,callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    FinishesdeleteImage(pk)
      .then(() => {
        retrieveFinishes(id).then((res) => setFinishes(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Deleted Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  function handleAddToGallery(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("image", file);
    FinishesaddImage(id, form)
      .then(() => {
        retrieveFinishes(id).then((res) => setFinishes(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Added Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  function deleteReciept(pk,callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    FinishesdeleteReciept(pk)
      .then(() => {
        retrieveFinishes(id).then((res) => setFinishes(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Deleted Successfully"
          )
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  function handleAddToGalleryReciept(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("reciept", file);
    FinishesaddReciept(id, form)
      .then(() => {
        retrieveFinishes(id).then((res) => setFinishes(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Added Successfully"
          )
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback());
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateFinishesModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />
      {SpacesViewModalComp}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            pageTitleComponent={
              <>
                <h4 className="mb-sm-0">Finishes (Ceiling, Trim, Wall)</h4>
              </>
            }
            title={["Finishes", "Detail"]}
            pageTitle="Property Workbook"
          />
          <Card>
            <CardBody>
              <Row className="g-4">
                <Col lg={4}>
                  <div className="sticky-side-div">
                    {/* <Card className="ribbon-box border shadow-none right">
                      <CardHeader>Image(s)</CardHeader>
                      <ImagesCarousalWithGallery
                        images={(Finishes.images || [])}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                        module="finishPhoto"
                        privateFile={true}
                      />
                    </Card> */}
                    {/* <Card className="ribbon-box border shadow-none right"> */}
                      {/* <RecieptsDisplay
                        reciepts={Finishes.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                        privateFile={true}
                        module="finishReciept"
                      /> */}
                      {/* <PrivateRecieptDisplay 
                        reciepts={Finishes.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                        privateFile={true}
                        module="finishReciept" />
                    </Card> */}
                    {ImageViewerComponent}
                    {RecieptViewerComponent}
                  </div>
                </Col>
                <Col lg={8}>
                  <div>
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn btn-ghost-primary btn-icon"
                        role="button"
                      >
                        <i className="ri-more-fill align-middle fs-16"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="#" className="view-item-btn">
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                          View
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => setIsEdit(Finishes)}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setCaptureDelete(id)}
                          className="remove-item-btn"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <h4>
                      {Finishes.name} - {Finishes.Type}
                    </h4>
                    <div className="hstack gap-3 flex-wrap">
                      <div className="text-muted">
                        Color :{" "}
                        <span to="" className="text-body fw-medium">
                          {Finishes.color}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Brand :{" "}
                        <span className="text-body fw-medium">
                          {Finishes.brand}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Cost per Sq. Ft.:{" "}
                        <span className="text-body fw-medium">
                          {Finishes.cost} $
                        </span>
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Purachase Date :
                            </p>
                            <h5 className="fs-17 mb-0">
                              {Finishes.purchaseDate}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Space(s)
                            </p>
                            <h5 className="fs-17 mb-0">
                              <Link
                                to="#"
                                onClick={() =>
                                  setSpacesId(Finishes.id)
                                }
                              >
                                See List
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Warranty Details :</h5>
                      <p>{Finishes.warrantyDetails}</p>
                    </div>

                    {Finishes.notes ? (
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Notes :</h5>
                        <p>{Finishes.notes}</p>
                      </div>
                    ) : null}

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">Service Provider :</h5>

                      <Nav tabs className="nav-tabs-custom nav-success">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            Installation Information
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={customActiveTab}
                        className="border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <TabPane id="nav-speci" tabId="1">
                          <div>
                            <p className="mb-2">
                              <i
                                style={{ color: "#299CDB", fontSize: "30px" }}
                                className="mdi mdi-circle-medium me-1 align-middle"
                              ></i>{" "}
                              {Finishes.installationDate}
                            </p>
                            <h5 className="font-size-16 mb-3">
                              Installation Contractor
                            </h5>
                            {Finishes.installer && <p>{Finishes.installer}</p>}
                            <div></div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FinishesDetails;
