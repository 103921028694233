import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error, toastError } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  createSpace,
  getSpaceTypes,
  updateSpace,
} from "../../../../helpers/backend_helper";
import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { setWith } from "lodash";
import { toast } from "react-toastify";

const ScanSpaceEntry = ({
  id,
  edited,
  removeFunc = () => {},
  setUpdateId = (id) => {},
}) => {
  const [loading, setLoading] = useState(false);

  // const [updateId,setUpdateId] = useState(-1);

  const updateId = edited.id ? edited.id : -1;

  const [spaceTypes, setSpaceTypes] = useState([]);
  const [spaceType, setSpaceType] = useState("");
  const [name, setName] = useState(null);
  const [length, setlength] = useState(null);
  const [width, setwidth] = useState(null);
  const [height, setheight] = useState(null);
  const [windows, setwindows] = useState(null);
  const [doors, setdoors] = useState(null);
  const [notes, setnotes] = useState("");

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getData() {
    return {
      name: name,
      length: length,
      width: width,
      height: height,
      windows: windows,
      doors: doors,
      spaceType: spaceType,
      notes: notes,
    };
  }

  function onSubmit() {
    var errs = [];
    checkOrError(name, errs, "you need to add name for your Space");
    if (errs.length > 0) {
      toastError(errs);
    } else {
      const data = getData();
      if (id) {
        setLoading(true);
        if (updateId === -1) {
          createSpace(id, data)
            .then((res) => {
              toast.success(`${data.name} Space: Created Successfully`);
              setUpdateId(res);
            })
            .catch((err) => toastError(err))
            .finally(() => setLoading(false));
        } else {
          updateSpace(updateId, data)
            .then((res) => {
              toast.success(`${data.name} Space: Updated Successfully`);
              setUpdateId(res);
            })
            .catch((err) => toastError(err))
            .finally(() => setLoading(false));
        }
      }
    }
  }

  useEffect(() => {
    getSpaceTypes().then((res) =>
      setSpaceTypes(res.map((type) => type.spaceName))
    );
  }, []);

  useEffect(() => {
    if (edited) {
      setName(edited.name);
      setlength(edited.length);
      setheight(edited.height);
      setwidth(edited.width);
      setSpaceType(edited.spaceType);
      setdoors(edited.doors);
      setwindows(edited.windows);
      setnotes(edited.notes || "");
    }
  }, [edited]);

  return (
    <div className="mt-2">
      <Form action="#">
        <Row>
          <Col sm={4} md={4} lg={3} xl={3}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Length"
                  aria-label="Length"
                  value={length}
                  onChange={(e) => setlength(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Width"
                  aria-label="width"
                  value={width}
                  onChange={(e) => setwidth(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={4} md={4} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Height"
                  aria-label="height"
                  value={height}
                  onChange={(e) => setheight(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={4} md={5} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  // style={{ backgroundColor: "#eff2f7" }}
                  onChange={(e) => setSpaceType(e.target.value)}
                  value={spaceType}
                >
                  <option value="" disabled selected>
                    Space Type
                  </option>
                  {spaceTypes.map((type) => (
                    <option value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </Col>
          <Col sm={3} md={3} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="number"
                  className="form-control"
                  placeholder="No. Windows"
                  aria-label="No. Windows"
                  value={windows}
                  onChange={(e) => setwindows(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={3} md={3} lg={2} xl={2}>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="number"
                  className="form-control"
                  placeholder="No. Doors"
                  aria-label="No. Doors"
                  value={doors}
                  onChange={(e) => setdoors(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <Col sm={9} md={9} lg={4} xl={4}>
            <div className="my-2">
              <input
                type="text"
                className="form-control"
                id="notes"
                name="notes"
                placeholder="Notes/Comments"
                value={notes}
                onChange={(e) => setnotes(e.target.value)}
              />
            </div>
          </Col>
          <Col xs={8} sm={8} md={8} lg={2} xl={2}>
            <button
              type="button"
              className="btn btn-primary add-btn my-2"
              data-bs-toggle="modal"
              data-bs-target="#showModal"
              id="create-btn"
              style={{ width: "100%", backgroundColor: "#0AB39C" }}
              onClick={onSubmit}
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              {updateId === -1 ? "Add Space" : "Update Space"}
            </button>
          </Col>
          <Col xs={4} sm={4} md={4} lg={2} xl={2}>
            <button
              type="button"
              className="btn btn-danger my-2"
              onClick={removeFunc}
              disabled={loading}
            >
              {loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Remove Space
            </button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ScanSpaceEntry;
