import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  getCaptureDataSrcs,
  getSpaceSelectList,
  createCapture,
  updateCapture,
} from "../../../../helpers/backend_helper";
import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const CreateCapture = () => {
  document.title = "New & Edit Capture | DwellDoc";

  const { selectedProperty , selectedCapture } =
    useSelector((state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      selectedCapture: state.captureUpload.selected
    }));


    let { id } = useParams();
  
    const navigate = useNavigate()

  const [name, setname] = useState(selectedCapture && id? selectedCapture.name : "");

  const [url, seturl] = useState(selectedCapture && id? selectedCapture.displayUrl : "");

  const [msg, setmsg] = useState({ err: false, msg: null });

  const [dataSrcs, setDataSrcs] = useState(null);

  const [dataSrc, setDataSrc] = useState(selectedCapture && id? selectedCapture.dataSource : "");

  const [allChecked, setallChecked] = useState(selectedCapture && id? false : true);

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  // const selectedSpaces = selectedCapture.spaces.map((space)=> space.id)

  const [spaceSelected, setSpaceSelected] = useState(selectedCapture && id? selectedCapture.spaces.map((space)=> space.id) : []);

  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    if(id && !selectedCapture){
      setLoading(true)
      setTimeout(()=>{ 
        if(id && !selectedCapture){
          navigate({pathname:'/3dCapture'})
        }
      },5000)
    }
  },[])
  
  useEffect(() => {
    getCaptureDataSrcs().then((res) => setDataSrcs(res));
  }, []);

  useEffect(()=>{
    if(dataSrcs && dataSrcs.length > 0){
      setDataSrc(dataSrcs[0].name)
    }
  },[dataSrcs])

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(res)
      );
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (msg.msg) {
      setTimeout(() => setmsg({ err: false, msg: null }), 5000);
    }
  }, [msg]);

  function changedSpace(space) {
    const index = spaceSelected.indexOf(space.id);
    if (index === -1) {
      setSpaceSelected([...spaceSelected, space.id]);
    } else {
      setSpaceSelected(
        spaceSelected.slice(0, index).concat(spaceSelected.slice(index + 1))
      );
    }
  }

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getSpaceSelected(){
    if(allChecked){
      return SpaceSelectList.map((space)=> space.id)
    }else{
      return spaceSelected
    }
  }

  function submit() {
    var errs = [];

    checkOrError(name, errs, "you need Name for your capture");

    checkOrError(url, errs, "you need to add url for your capture");

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = {
        name: name,
        displayUrl: url,
        dataSource: dataSrc,
        spaces: getSpaceSelected(),
      };
      setLoading(true)
      if(id){
        updateCapture(id,data)
        .then((res)=> {
          setmsg({ err: false, msg: "Updated Successfully" });
        })
        .catch((err) => setmsg({ err: true, msg: err }))
        .finally(()=> setLoading(false));
      }else{
        createCapture(selectedProperty.id, data)
        .then((res) => {
          setmsg({ err: false, msg: "Capture Created Successfully" });
        })
        .catch((err) => setmsg({ err: true, msg: err }))
        .finally(()=> setLoading(false));
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="New & Edit Page"
            pageTitle="3D Capture"
            pageLink={"/3dCapture"}
          />
          <Row>
            <div className="p-2">
              {!msg.err && msg.msg ? (
                <Alert color="success">{msg.msg}</Alert>
              ) : null}

              {msg.err && msg.msg ? <Error error={msg.msg} /> : null}
            </div>

            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">3D Capture New & Edit</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label htmlFor="name" className="form-label">
                      Name
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Name of 3D capture file"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <Label htmlFor="choices-use-input" className="form-label">
                      Data Source
                    </Label>
                    <select
                      className="form-select"
                      data-choices
                      data-choices-search-false
                      id="choices-use-input"
                      value={dataSrc}
                      onChange={(e) => setDataSrc(e.target.value)}
                    >
                      {dataSrcs?.map((src, idx) => (
                        <option key={idx} value={src.name}>
                          {src.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="url" className="form-label">
                      Display Url
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      id="url"
                      name="url"
                      placeholder="Enter URL  - Example: https://my.matterport.com/show/?m=Y63h8o6zyty9"
                      value={url}
                      onChange={(e) => seturl(e.target.value)}
                    />
                  </div>

                  <div className="mb-3">
                    <div>
                      <Label htmlFor="url" className="form-label">
                        Space
                      </Label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="All"
                        checked={allChecked}
                        onChange={() => setallChecked((checked) => !checked)}
                      />
                      <label className="form-check-label" for="All">
                        Default/All
                      </label>
                    </div>
                    {SpaceSelectList.map((space, idx) => (
                      <div key={idx} className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"space" + idx}
                          onChange={() => changedSpace(space)}
                          checked={spaceSelected.indexOf(space.id) >= 0}
                        />
                        <label className="form-check-label" for={"space" + idx}>
                          {space.name}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="d-flex align-items-start gap-3 mt-4">
                    <button
                      type="button"
                      className="btn btn-success right ms-auto nexttab nexttab"
                      data-nexttab="pills-info-desc-tab"
                      onClick={submit}
                      disabled={loading || msg.msg ? true : false}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      {
                      id? "Edit" : "Save"
                      }
                    </button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateCapture;
