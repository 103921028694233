import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";


// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import { DeleteimageThunk , DeleteinventoryThunk, ListimagesThunk, ListinventoryThunk } from "../../../../slices/thunks";
import { InventorySpaces, getCaptureDataSrcs } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import { resetMessages  } from "../../../../slices/property/homeInventory/reducer";
import {CreateImageForm} from './createForm';
import UpdateInvModal from "../New";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
import { FileLinkViewer, PrivateFileWraper } from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import { reloadState as reloadStateImage, saveState as saveStateImage, setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { reloadState as reloadStateReciept, saveState as saveStateReciept, setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { StatusComponent } from "../../../../Components/Common/Status";

const InventoryUpload = () => {

  document.title = "Home Inventory | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");
  const mountedRef = useRef()
  // const [dataSrcs , setDataSrcs ] = useState(null);
  const dataSrcs = []
//   const dataSrcs = [
//     {
//         "name": "At or Above Grade",
//         "iconClass": "ri-store-2-fill me-1 align-bottom"
//     },
//     {
//         "name": "Below Grade",
//         "iconClass": "ri-checkbox-circle-line me-1 align-bottom"
//     }
// ]

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const dispatch = useDispatch();

  const [filterParams,setFilterParams] = useState({
    "dataSource":"All",
    "page":1,
  }) 

  const {
    selectedProperty,
    inventory,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success 
  } = useSelector((state) => ({
    inventory: state.inventory.inventory,
    numOfPages: state.inventory.total_pages,
    numOfCaptures: state.inventory.count,
    pageSize: state.inventory.page_size,
    currentPage: state.inventory.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.inventory.loading,
    error: state.inventory.error,
    success: state.inventory.success
  }));

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams({
      "dataSource":"All",
      "page":1,
    })
  }
  mountedRef.current = true;
  }, [selectedProperty]);

  useEffect(()=>{
    if (selectedProperty) {
      dispatch(ListinventoryThunk(selectedProperty.id, filterParams));
    }
  },[filterParams])

  function onDeleteClick(){
    dispatch(DeleteinventoryThunk(captureDelete,()=>{
      setCaptureDelete(null)
    }))
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListinventoryThunk(selectedProperty.id, filterParams));
    }
  }

  useEffect(()=>{
    if(error || success){
      setTimeout(()=>dispatch(resetMessages()),5000)
    }
  },[error,success])

  const [ SpacesViewModalComp , setSpacesId ] = useSpaceViewer({func: InventorySpaces})

  
  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: "Image",
        accessor: "image",
        filterable: false,
        Cell: (capture) => { 
          const lvlimg = capture.row.original.image;

          return lvlimg ? (
            <FileLinkViewer module={"privateFile"} lazyLoad={true} file={lvlimg} />
          ) : null;
        },
      },
      {
        Header: "Type",
        accessor: "inventorytype",
      },
      {
        Header: "Spaces",
        id: "spaces",
        accessor: "spaces",
        Cell: (capture) => (
          <Link
          to="#"
            onClick={() =>
              setSpacesId(capture.row.original.id)
            }
          >
            See List
          </Link>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          console.log(cell.value)
          return <StatusComponent value={cell.value} />;
        },
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Action",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                    onClick={() => {
                    //  dispatch(setSelected(capture.row.original))
                    setIsEdit(capture.row.original)
                    }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                    onClick={() => {
                      // const orderData = cellProps.row.original;
                      setCaptureDelete(capture.row.original.id);
                    }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  
  const changePage = (page) => {
    if(page != filterParams.page){
      setFilterParams((oldParams)=> ({...oldParams,"page":page}))
    }
  }
  
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({"page":1,"dataSource":type}))
  
    }
  };

  useEffect(()=>{
    dispatch(setSelectedImageFiles([]))
    dispatch(resetMessages())
  },[])

  useEffect(()=>{
    if(!isEdit){
      dispatch(reloadStateImage())
    }
  },[isEdit])
  
  return (
    <div className="page-content">
  
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateInvModal 
        edited={isEdit}
        toggle={()=> setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      />

      {SpacesViewModalComp}
  
      <Container fluid>
        <BreadCrumb title="Home Inventory" pageTitle="Property Workbook" />
        <Row>
          
          <Col lg={12}>
          {error && <Error error={error} />}
          {success && <Alert color="success">{success}</Alert> }
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">New/List/View/Edit</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      {/* <Link
                      to="/3dCapture/create"
                        type="button"
                        className="btn btn-primary add-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#showModal"
                        id="create-btn"
                        // onClick={() => { setIsEdit(false); toggle(); }}
                      >
                        <i className="ri-add-line align-bottom me-1 btn-success"></i> Create
                        New
                      </Link>{" "} */}
                      {/* <button type="button" className="btn btn-secondary"
                    //    onClick={() => setIsExportCSV(true)}
                       >
                        <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                        Export
                      </button> */}
                      {/* {" "} */}
                      {/* {isMultiDeleteButton && (
                        <button
                          className="btn btn-danger"
                          // onClick={() => setDeleteModalMulti(true)}
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </button>
                      )} */}
                    </div>
                  </div>
                  <div>
                    <div className="py-3">
                    <CreateImageForm />
                    </div>
                    {/* <Row>
                      <SearchWithFilter />
                    </Row> */}
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "All");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Items
                      </NavLink>
                    </NavItem>
                    {dataSrcs?.map((src,key)=> 
                      <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === `${key+2}` },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab(`${key+2}`, src.name);
                        }}
                        href="#"
                      >
                        {src.iconClass && (<i className={src.iconClass}></i> )}    
                        {src.name}
                      </NavLink>
                    </NavItem>
                    ) 
                    }
                  </Nav>
                  {
                    loading ? 
                    (
                      <Loader />
                    ):(
                      <TableContainer
                      columns={columns}
                      data={inventory || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      // baseLink={"/3dCapture"}
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                    )
                  }
                  {/* {captures && captures.length ? (
                                     ) : (
                    
                  )} */}
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InventoryUpload;
