import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
// import BreadCrumb from "../../Components/Common/BreadCrumb";
// import TableContainer from "../prope../TableContainer";
import DeleteModal from "../../Components/Common/DeleteModal";

import { useSelector, useDispatch } from "react-redux";

import Loader from "../../Components/Common/Loader";
import {
  FileLinkViewer,
  PrivateFileWraper,
} from "../../Components/Common/privateImageViewer/privateImageViewer";
import { ListsupportThunk } from "../../slices/support/thunk";

import {
  resetMessages,
  setRefreshDropdown,
} from "../../slices/support/reducer";

import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../property/TableContainer";
import { Status } from "./Status";
import { CreateSupportModal } from "./createModal";
import { TicketsListGlobalFilter } from "../../Components/Common/GlobalSearchFilter";
import { useSupportFilters } from "../../Components/Hooks/supportFilters";

// import { DeleteimageThunk , ListsupportCasesThunk } from "../../slices/thunks";
// import { Error } from "../../Components/error";
// import { resetMessages  } from "../../slices/property/supportCases/reducer";
// import {CreateImageForm} from './createForm';
// import UpdateImageModal from "../New";

const SupportPage = () => {
  document.title = "Support | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  const mountedRef = useRef()

  const dispatch = useDispatch();

  const [showCreate, setshowCreate] = useState(false);

  function toggleCreate() {
    setshowCreate(!showCreate);
  }

  const [filterParams, setFilterParams] = useState({
    page: 1,
  });

  const onSuccess = () => {
    setFilterParams((oldParams) => ({ ...oldParams, page: 1 }));
  };

  const {
    user,
    supportCases,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    user: state.Login.user,
    supportCases: state.support.support,
    numOfPages: state.support.total_pages,
    numOfCaptures: state.support.count,
    pageSize: state.support.page_size,
    currentPage: state.support.page_num,
    loading: state.support.loading,
    error: state.support.error,
    success: state.support.success,
  }));

  useEffect(() => {
    dispatch(ListsupportThunk(filterParams));
  }, [filterParams]);

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      // {
      //   Header: "Name",
      //   accessor: "name",
      //   filterable: false,
      //   Cell: (cell) => {
      //     return (
      //       <Link to="#" className="fw-medium link-primary">
      //         {cell.value}
      //       </Link>
      //     );
      //   },
      // },
      {
        Header: "id",
        accessor: "id",
        filterable: false,
        Cell: (capture) => {
          return <Link to={`/support/${capture.value}`}>#{capture.value}</Link>;
        },
      },
      {
        Header: "Tittle",
        accessor: "tittle",
        filterable: false,
        //   Cell: (capture) => {
        //     return <FileLinkViewer file={capture.row.original} module={"privateFile"} />
        // }
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          return <Status value={cell.value} />;
        },
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
      {
        Header: "Uploaded Date",
        accessor: "uploadDate",
        filterable: false,
        Cell: (capture) => (
          <>
            {capture.row.original.uploadDate.date},
            <small className="text-muted">
              {" "}
              {capture.row.original.uploadDate.time}
            </small>
          </>
        ),
      },
      // {
      //   Header: "Action",
      //   Cell: (capture) => {
      //     return (
      //       <ul className="list-inline hstack gap-2 mb-0">
      //         {/* <li className="list-inline-item">
      //           <Link to="#" className="text-primary d-inline-block">
      //             <i className="ri-eye-fill fs-16"></i>
      //           </Link>
      //         </li> */}
      //         <li className="list-inline-item edit">
      //           <Link
      //             to="#"
      //             className="text-primary d-inline-block edit-item-btn"
      //               onClick={() => {
      //               //  dispatch(setSelected(capture.row.original))
      //               setIsEdit(capture.row.original)
      //               }}
      //           >
      //             <i className="ri-pencil-fill fs-16"></i>
      //           </Link>
      //         </li>
      //         <li className="list-inline-item">
      //           <Link
      //             to="#"
      //             className="text-danger d-inline-block remove-item-btn"
      //               onClick={() => {
      //                 // const orderData = cellProps.row.original;
      //                 setCaptureDelete(capture.row.original.id);
      //               }}
      //           >
      //             <i className="ri-delete-bin-5-fill fs-16"></i>
      //           </Link>
      //         </li>
      //       </ul>
      //     );
      //   },
      // },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const [filterComponents, filterObject, resetFilters, getFilters,btnState] =
    useSupportFilters();

  useEffect(() => {
    if(mountedRef.current){
    setFilterParams((oldParams) => ({
      ...oldParams,
      ...getFilters(),
      page: 1,
    }));
  }
  mountedRef.current = true;
  }, [btnState]);

  return (
    <div className="page-content">
      {/* <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />
      <CreateImageModal 
        show={showCreate} 
        toggle={()=> setshowCreate(false)}
        onSuccess={OnUpdateSuccess}
      />
      

      <UpdateFileModal 
        edited={isEdit}
        toggle={()=> setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
      /> */}

      <CreateSupportModal
        isOpen={showCreate}
        toggle={toggleCreate}
        onSuccess={onSuccess}
      />

      <Container fluid>
        <BreadCrumb title="Support" pageTitle="Support" />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">Support</h5>
                  {!user.is_staff && (
                    <div className="flex-shrink-0">
                      <div className="d-flex flex-wrap gap-2">
                        {}
                        <button
                          className="btn btn-danger add-btn"
                          onClick={toggleCreate}
                        >
                          <i className="ri-add-line align-bottom"></i> Create
                          Tickets
                        </button>{" "}
                      </div>
                    </div>
                  )}
                </div>
              </CardHeader>

              <CardBody className="pt-0">
                {filterComponents}
                <div>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={supportCases || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SupportPage;
