//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
// import {
//   postFakeRegister,
//   postJwtRegister,
// } from "../../../helpers/fakebackend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  apiErrorChange ,
  setIsLoading,emailChecked
} from "./reducer";

import { api } from "../../../config";
import { checkEmail , register } from "../../../helpers/backend_helper";


// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
// export const registerUser = (email,name,password,onSuccess) => async (dispatch) => {
    
//     dispatch(setIsLoading()) 
    
//     const obj = {
//         email:email,
//         name:name,
//         password:password,
//     }

//     await fetch(api.API_URL+'/user/register/',{
//         method:'POST',
//         headers:{
//             'Content-Type':'application/json'
//         },
//         body:JSON.stringify(obj)
//     })
//     .then(async (res) =>{
//         if(res.ok){
//             dispatch(registerUserSuccessful({}))
//         }
//         else{
//             throw await res.json();  
//         }
//     }).catch((err)=>{
//         // console.log(err)
//         if (err instanceof Array){
//           dispatch(registerUserFailed(err[0]))
//         }else{
//           dispatch(registerUserFailed('Something went wrong, Please try again'))
//         }
//     })

// };

export const checkEmailRedux = (email,onSuccess) => async (dispatch) => { 
  dispatch(setIsLoading()) 
  
  checkEmail(email)
  .then((res)=> {
    onSuccess()
    console.log("sucess")
    dispatch(emailChecked(res))
  }).catch((err)=> {
    if (err.status === 400){
      dispatch(registerUserFailed(err.message))
    }else{
      dispatch(registerUserFailed(err.autoMessage))
    }
  })
}



export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const apiError = () => {
  try {
    const response = apiErrorChange();
    return response;
  } catch (error) {
    return error;
  }
};

export const registerUser = (data) => async (dispatch) => {
  dispatch(setIsLoading());

  register(data)
  .then((res)=> dispatch(registerUserSuccessful(res)))
  .catch((err) => dispatch(registerUserFailed(err.message)))
}