import { Col, Row, Input, Label, Form, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getFloorTypes,
  getFloorFileTypes,
  getSpaceSelectList,
  getCaptureDataSrcs,
} from "../../../../helpers/backend_helper";

import { floorFailure } from "../../../../slices/property/floors/reducer";
import { useSelector, useDispatch } from "react-redux";
import {
  CreateCaptureThunk,
  CreateFloorThunk,
  CreateimageThunk,
  CreateSpaceThunk,
  getFloorsSideNavThunk,
  ListCapturesThunk,
  ListFloorsThunk,
  ListimagesThunk,
  ListSpacesThunk,
  UpdateCaptureThunk,
} from "../../../../slices/thunks";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
// import { SpacesDropdown } from "./spacesDropdown";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import { spaceFailure } from "../../../../slices/property/spaces/reducer";
import { imageFailure } from "../../../../slices/property/propertyImages/reducer";
import { captureFailure } from "../../../../slices/property/capture/reducer";
import { CompareSpaces, get_spaces } from "../../../../Components/utils/spacesCompare";
import { update } from "react-spring";

export const CreateForm = ({ selectedCapture = null, id = null , onSuccess= () => {} }) => {
  const [url, seturl] = useState(
    selectedCapture && id ? selectedCapture.displayUrl : ""
  );

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const { selectedProperty, loading } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.captureUpload.btnLoading,
  }));

  const [dataSrcs, setDataSrcs] = useState(null);

  const [dataSrc, setDataSrc] = useState(
    selectedCapture && id ? selectedCapture.dataSource : ""
  );
  const [name, setname] = useState(
    selectedCapture && id ? selectedCapture.name : ""
  );

  const [notes, setnotes] = useState(
    selectedCapture && id ? selectedCapture.notes : ""
  );

  const [spaceSelected, setSpaceSelected] = useState([]);

  useEffect(()=>{
    if(selectedCapture){
      setSpaceSelected(selectedCapture.spaces.map((space) => ({ value: parseInt(space.id), label: space.name })))
      setname(selectedCapture.name)
      setDataSrc(selectedCapture.dataSource)
      setnotes(selectedCapture.notes)
    }
  },[id])

  useEffect(() => {
    getCaptureDataSrcs().then((res) => setDataSrcs(res));
  }, []);

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getdata(update=false) {
    if(update){
        const spaces = get_spaces(selectedCapture.spaces,spaceSelected)
        if(spaces){
          return {
            name: name,
            displayUrl: url,
            dataSource: dataSrc,
            spaces: spaces,
            notes: notes,
          };
        }else{
          return {
            name: name,
            displayUrl: url,
            dataSource: dataSrc,
            notes: notes,
          };
        }
    }
      
    return {
      name: name,
      displayUrl: url,
      dataSource: dataSrc,
      spaces: spaceSelected.map((space) => space.value),
      notes: notes,
    };
  }

  function onSubmit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Capture");
    checkOrError(
      dataSrc,
      errs,
      "you need to add a data source type for your Capture"
    );
    checkOrError(url, errs, "you need to add a display url for your Capture");
    // checkOrError(
    //   spaceSelected.length !== 0,
    //   errs,
    //   "you need to select spaces for your Capture"
    // );

    if (errs.length > 0) {
      dispatch(captureFailure(errs));
    } else {
      // const onSuccess = () => {
      //   dispatch(ListCapturesThunk(selectedProperty.id));
      // };
      if (id && selectedCapture) {
        dispatch(UpdateCaptureThunk(id, getdata(true), onSuccess));
      } else {
        dispatch(CreateCaptureThunk(selectedProperty.id, getdata(), onSuccess));
      }
    }
  }

  return (
    <Form>
      <Row>
        <Col lg={3}>
          <div className="form-group my-2">
            <div className="input-group" style={{ padding: 0 }}>
              {/* <Label htmlFor="name" className="form-label">
                Name
              </Label> */}
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder="Name of 3D capture file"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </div>
          </div>
        </Col>
        <Col lg={2}>
          <div className="form-group my-2">
            <div
              className="input-group"
              style={{ padding: 0, margin: 0, marginRight: 0 }}
            >
              <select
                className="form-select"
                data-choices
                data-choices-search-false
                id="choices-use-input"
                value={dataSrc}
                onChange={(e) => setDataSrc(e.target.value)}
              >
                <option disabled selected value="">
                  Data Source
                </option>
                {dataSrcs?.map((src, idx) => (
                  <option key={idx} value={src.name}>
                    {src.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="url"
              name="url"
              placeholder="Enter URL  - Example: https://my.matterport.com/show/?m=Y63h8o6zyty9"
              value={url}
              onChange={(e) => seturl(e.target.value)}
            />
          </div>
        </Col>

        <Col lg={3}>
          <div className="my-2">
            <SpacesDropdown
              stateChange={setSpaceSelected}
              allOptions={SpaceSelectList}
              value={spaceSelected}
            />
          </div>
        </Col>

        <Col lg={9}>
          <div className="my-2">
            <input
              type="text"
              className="form-control"
              id="notes"
              name="notes"
              placeholder="Notes/Comments"
              value={notes}
              onChange={(e) => setnotes(e.target.value)}
            />
          </div>
        </Col>

        <Col lg={3}>
          <button
            type="button"
            className="btn btn-primary add-btn my-2"
            data-bs-toggle="modal"
            data-bs-target="#showModal"
            id={id? "update-btn": "create-btn"}
            style={{ width: "100%", backgroundColor: "#0AB39C" }}
            onClick={onSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            {selectedCapture && id ? (
              <>Update Capture</>
            ) : (
              <>
                <i className="ri-add-line align-bottom me-1 btn-success"></i>{" "}
                Create New
              </>
            )}
          </button>
        </Col>
      </Row>
    </Form>
  );
};
