
import {  setIsLoading , setProperties   } from "./reducer";

import { propertiesDropdownList } from "../../../helpers/backend_helper";

import { toast } from 'react-toastify';
// import {toas}

export const PropertiesDropdownThunk = (uid) => async (dispatch) => {

    dispatch(setIsLoading())

    propertiesDropdownList()
    .then((res)=> dispatch(setProperties({properties:res,uid:uid})))
    .catch((err) => toast(err.message))
    .finally(setIsLoading(false))

}