import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Col,
  Spinner,
  Input,
  Alert,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import { Error } from "../../../../Components/error";

import { CreateImageForm } from "../upload/createForm";

const UpdateInvModal = ({
  edited,
  toggle,
  onSuccess = () => {},
  success,
  error,
}) => {

  console.log(edited)
  return (
    <Modal
      id="UpdateInvModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
      size="xl"
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Insurance Item Edit
      </ModalHeader>

      {success ? <Alert color="success">{success}</Alert> : null}

      {error ? <Error error={error} /> : null}

      <ModalBody>
        <CreateImageForm id={edited ? edited.id : -1} update onUpdateSucces={onSuccess} />
      </ModalBody>
    </Modal>
  );
};

export default UpdateInvModal;
