
import {  setIsLoading , setelements , elementFailure , deleteSuccess, setBtnLoading, createSucess, createFailure } from "./reducer";

import { DeletePropertyElementsAPI, deleteAppliance, deleteCapture, deleteFLOORING, deleteFinishes, deleteHVAC, deleteInventory, deletemaintenance, deleteutility, getAllPropertyElements } from "../../../helpers/backend_helper";

const MODULES_DELETE_FUNC = {
    "3D Capture(s)": deleteCapture,
    Utilities: DeletePropertyElementsAPI,
    Maintenance: deletemaintenance,
    Inventory: deleteInventory,
    HVAC:  deleteHVAC,
    Flooring: deleteFLOORING,
    Finishes: deleteFinishes,
    Appliance: deleteAppliance
};

export const ListelementsThunk = (pid,params) => async (dispatch) => {

    dispatch(setIsLoading())

    getAllPropertyElements(pid,params)
    .then((res)=> dispatch(setelements(res)))
    .catch((err) => dispatch(elementFailure(err)))

}

export const DeleteelementThunk = (id,module,closeModal=()=>{}) => async (dispatch) => {
    dispatch(setIsLoading())
    MODULES_DELETE_FUNC[module](id)
    .then((res)=> dispatch(deleteSuccess(id)))
    .catch((err)=> dispatch(elementFailure(err)))
    .finally(()=> closeModal())
}

export const CreateelementThunk = (pid,data,onSuccess) => async (dispatch) => {
    // dispatch(setBtnLoading(true))

    // createelement(pid,data)
    // .then((res) => {
    //     dispatch(createSucess()) 
    //     onSuccess()
    // })
    // .catch((err)=> dispatch(createFailure(err)))
}

export const UpdateelementThunk = (pk,data,onSuccess) =>  async (dispatch) => {
    // dispatch(setBtnLoading(true))

    // updateelement(pk,data)
    // .then((res) => {
    //     dispatch(createSucess("Updated successfully")) 
    //     onSuccess()
    // })
    // .catch((err)=> dispatch(createFailure(err)))
}