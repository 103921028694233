import { useState,useEffect, useMemo } from 'react';
import { useSpring,animated } from "react-spring";

import active from '../../../assets/images/down-arrow.svg';


export const SpaceFilterView = ({
    levels,
    spaces
}) =>{

   
    return (
        <div className='brand-filter'>
            <h4 className='mb-3'>Spaces</h4>
            {}
            {levels&& levels.length > 0 ? (levels.map((level)=> <FilterSection data={level.options} title={level.label}/>)) : <>No Spaces Choosen</>}
            {/* <div className='mt-3 p-2 d-flex justify-content-between'>
                <span onClick={CancelClick} className='cancel-button'>Clear All</span>
                <button type="button" onClick={SelectAllClick} className='add-item' style={{width:"30%",minWidth:"80px"}}>Select All</button>
            </ div> */}
        </div>
    )

}

const FilterSection = (props) => {

    const [showBrands,setShowBrands] =  useState(false);
    const checkboxes = useMemo(()=> {
        const arr = props.data.map((item,idx)=>{
            return (
                <CheckButton key={idx} {...item} />
            )
        })
        return arr 
    },[props.data])

    const heightSpring  = useSpring({
        maxHeight: `${showBrands ? "300px" : "0px"}`,
        config: { mass: 5, tension: 500, friction: 80 },
    })

    const rotateSpring  = useSpring({
        transform: `rotate(${showBrands ? 0 : 90}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    })

    const SelectAllClick = () => {
        props.onChange(props.data,"bulk-add")
    }

    function CancelClick(){
        props.onChange(props.data,"bulk-remove")
    }

    return (
        <div>
             <div style={{color:"#002575",fontSize:"20px",fontWeight:"500"}} className= 'pt-2 ml-2 d-flex justify-content-between'>
                <span style={{}}>{props.title}</span>
                <animated.div onClick={()=>setShowBrands((showBrands)=> !showBrands)} style={{...rotateSpring,cursor:"pointer",padding:'3px'}}>
                    <img src={active}/>
                </animated.div>      
            </div>
            <animated.div style={{overflowY:"auto",maxHeight:"290px",...heightSpring}}>
                {/* <div className='mb-1 p-2 d-flex justify-content-between'>
                    <span onClick={CancelClick} className='cancel-button'>Clear All</span>
                    <button type="button" onClick={SelectAllClick} className='add-item' style={{width:"30%",minWidth:"80px"}}>Select All</button>
                </ div> */}
                {checkboxes}
            </animated.div >
        </div>
    )
}

const CheckButton = (props) => {
    
    // onClick={onChange
    return (
        <div className='p-1'>
            <div className="form-check">
                {/* <input checked={index} className="form-check-input" name={props.label} type="checkbox"/> */}
                <label className="form-check-label">{props.name}</label>
            </div>
        </div>
    )
}