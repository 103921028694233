import React from "react";

export const Status = ({value}) => {
    return (
        <React.Fragment>
            {value === "Failed" ? <span className="badge badge-soft-danger text-uppercase">{value}</span>
                : value === "Creating" ? <span className="badge badge-soft-info text-uppercase">{value}</span>
                    : value === "Created" ? <span className="badge badge-soft-success text-uppercase">{value}</span>
                        // : value === "" ? <span className="badge badge-soft-danger text-uppercase">{value}</span>
                            : null
            }
        </React.Fragment>
    );
};