import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  Success:false,
};

const activateEmailSlice = createSlice({
  name: "activate",
  initialState,
  reducers: {
      setSuccess(state,action){
        state.Success = action.payload
      },
  },
});

export const {
  setSuccess
} = activateEmailSlice.actions

export default activateEmailSlice.reducer;
