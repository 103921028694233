import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading:false
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
      setLoading(state,action){
        state.loading = true
      },
      userForgetPasswordSuccess(state, action) {
          state.forgetSuccessMsg = action.payload
          state.loading = false
      },
      userForgetPasswordError(state, action) {
          state.forgetError = action.payload
          state.loading = false
      },
  },
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setLoading
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer;
