import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Tooltip,
  Spinner,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import TableContainer from "../../TableContainer";
import TableContainer from "../../property/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

// import { CreateForm } from "./createForm";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../Components/Common/GlobalSearchFilter";
import { ListelementsThunk, DeleteelementThunk } from "../../../slices/thunks";
import {
  PropertyElementsReportCreate,
  getAllPropertyElements,
  getSpacesPropertyElements,
} from "../../../helpers/backend_helper";
import { Error, toastError } from "../../../Components/error";
import {
  resetMessages,
  setSelected,
} from "../../../slices/dashboard/propertyElements/reducer";

import { TooltipItem } from "../../../Components/Common/ToolTipItem";
// import { elementActionstooltip, elementDataSrctooltip, elementDisplayUrltooltip, elementNametooltip, elementNewEDitListTooltip, elementSpacestooltip, elementtooltip } from "../../../Components/constants/tooltips";
import { useSpaceViewer } from "../../../Components/Hooks/spacesViewHook";
import {
  MODULESICONS,
  MODULESLINKS,
} from "../../../Components/constants/modules";
import { useSpacesDropdown } from "../../../Components/Hooks/spacesDropdownHook";
import UpdateMaintenanceModal from "../../property/maintenance/New";
// import UpdateInvModal from "../../property/homeInventory/New";
import UpdateFlooringModal from "../../property/flooring/New";
import UpdateFinishesModal from "../../property/finishes/New";
import { UpdateCaptureWraper } from "../../property/Capture/update/updateModal";
// import UpdateBillModal from "../../property/utilites/New";
import { UpdateBillWraper } from "../../property/utilites/New";
import { UpdateInvWraper } from "../../property/homeInventory/New";
import UpdateApplianceModal from "../../property/appliance/New";
import UpdateHVACModal from "../../property/HVAC/New";
import { toast } from "react-toastify";
import { useStatusSearch } from "../../../Components/Hooks/useStatusSearch";
import { StatusComponent } from "../../../Components/Common/Status";

const PropertyElementsPage = () => {
  document.title = "Property Elements | DwellDoc";

  const [activeTab, setActiveTab] = useState("1");


  const [selectStatusesDropdown, tagsVal, getStatus] = useStatusSearch(
    "Search by Status"
  );

  const tabs = useMemo(
    () =>
      Object.keys(MODULESICONS).map((key, idx) => ({
        module: key,
        icon: MODULESICONS[key],
      })),
    []
  );

  const [elementDelete, setelementDelete] = useState({
    id: null,
    module: null,
  });

  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    dataSource: "All",
    page: 1,
  });

  const {
    selectedProperty,
    elements,
    numOfPages,
    numOfelements,
    pageSize,
    currentPage,
    loading,
    error,
    success,
  } = useSelector((state) => ({
    elements: state.propertyElements.elements,
    numOfPages: state.propertyElements.total_pages,
    numOfelements: state.propertyElements.count,
    pageSize: state.propertyElements.page_size,
    currentPage: state.propertyElements.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.propertyElements.loading,
    error: state.propertyElements.error,
    success: state.propertyElements.success,
  }));

  useEffect(() => {
    setFilterParams({
      module: "",
      page: 1,
      spaces: "",
    });
  }, [selectedProperty]);

  const [SpacesViewModalComp, setSpacesId] = useSpaceViewer({
    func: getSpacesPropertyElements,
  });

  const [state, setState] = useState(false);

  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListelementsThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  function onDeleteClick() {
    dispatch(
      DeleteelementThunk(elementDelete.id, elementDelete.module, () => {
        setelementDelete({
          id: null,
          module: null,
        });
        setFilterParams((oldParams) => ({ ...oldParams, page: 1 }));
      })
    );
  }

  // useEffect(() => {
  //   getelementDataSrcs().then((res) => setDataSrcs(res));
  // }, []);

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);

  const [isEdit, setIsEdit] = useState({
    module: "",
    inst: {},
  });

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      {
        Header: (
          <>
            <span>
              Module
              {/* <TooltipItem id={4} placement="bottom" data={elementDataSrctooltip} /> */}
            </span>
          </>
        ),
        accessor: "module",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={MODULESLINKS[cell.value]}
              className="fw-medium link-primary"
            >
              {MODULESICONS[cell.value]}
              <span style={{ marginLeft: "10px" }}>{cell.value}</span>
            </Link>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Name
              {/* <TooltipItem id={3} placement="bottom" data={elementNametooltip} /> */}
            </span>
          </>
        ),
        accessor: "name",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              // to={`/3delement/${cell.row.original.id}`}
              to={MODULESLINKS[cell.row.original.module]}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Spaces
              {/* <TooltipItem id={6} placement="bottom" data={elementSpacestooltip} /> */}
            </span>
          </>
        ),
        id: "spaces",
        accessor: "spaces",
        Cell: (element) => (
          <Link to="#" onClick={() => setSpacesId(element.row.original.id)}>
            See List
          </Link>
        ),
      },
      {
        Header: "status",
        accessor: "status",
        filterable: false,
        Cell: (cell) => {
          return <StatusComponent value={cell.value} />;
        },
      },
      {
        Header: "Last Edit",
        accessor: "lastEdit",
        filterable: false,
        Cell: (element) => (
          <>
            {element.row.original.lastUpdate.date},
            <small className="text-muted">
              {" "}
              {element.row.original.lastUpdate.time}
            </small>
          </>
        ),
      },
      {
        Header: (
          <>
            <span>
              Action
              {/* <TooltipItem id={7} placement="bottom" data={elementActionstooltip} /> */}
            </span>
          </>
        ),
        id: "Actions",
        Cell: (element) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {/* <li className="list-inline-item">
                <Link to="#" className="text-primary d-inline-block">
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li> */}
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    //  dispatch(setSelected(element.row.original))
                    setIsEdit({
                      module: element.row.original.module,
                      inst: element.row.original,
                    });
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    // const orderData = cellProps.row.original;
                    setelementDelete({
                      id: element.row.original.id,
                      module: element.row.original.module,
                    });
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );


  const [spacesDropdown, selectedSpaces] = useSpacesDropdown({
    selectedProperty: selectedProperty,
  });

  useEffect(() => {
    var newParams = {};
    if (selectedSpaces.AllSelected || selectedSpaces.spaces.length === 0) {
      newParams = {page: 1, spaces: ""}
      // setFilterParams((oldParams) => ({ ...oldParams, page: 1, spaces: "" }));
    } else {
      const spaces = selectedSpaces.spaces
        .map((space) => space.value)
        .join(",");
      newParams = {page: 1, spaces: ""}
      //   setFilterParams((oldParams) => ({
      //   ...oldParams,
      //   page: 1,
      //   spaces: spaces,
      // }));
    }
    const status = getStatus()
    newParams['status'] = status

    setFilterParams((oldParams) => ({ ...oldParams, ...newParams }));
  }, [state]);

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };

  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilterParams((oldParams) => ({ ...oldParams, page: 1, module: type }));
    }
  };

  const onSuccess = () => {
    if (selectedProperty) {
      dispatch(ListelementsThunk(selectedProperty.id, filterParams));
    }
  };

  const [btnloading, setbtnLoading] = useState(false);
  

  function onCreateReportClick() {
    setbtnLoading(true);
    PropertyElementsReportCreate(selectedProperty.id)
      .then((res) => toast.info("Your report is now being created"))
      .catch((err) => toastError(err))
      .finally(() => setbtnLoading(false));
  }

  const resetEdit = () => {
    setIsEdit({
      module: "",
      inst: {},
    });
  };

  return (
    <div className="page-content">
      {/* <ExportCSVModal
        show={isExportCSV}
        onCloseClick={() => setIsExportCSV(false)}
        data={orderList}
      /> */}

      <DeleteModal
        show={elementDelete.id}
        onDeleteClick={onDeleteClick}
        onCloseClick={() =>
          setelementDelete({
            id: null,
            module: null,
          })
        }
        isLoading={loading}
      />

      {/* <UpdateModal
        success={success}
        error={error}
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={onSuccess}
      /> */}

      {/* <ApplianceModal /> */}

      <UpdateInvWraper
        edited={isEdit.module === "Inventory" ? isEdit.inst : null}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateApplianceModal
        success={success}
        error={error}
        edited={isEdit.module === "Appliance" ? isEdit.inst : null}
        fetch={true}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateCaptureWraper
        edited={isEdit.module === "3D Capture(s)" ? isEdit.inst : null}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateMaintenanceModal
        success={success}
        error={error}
        edited={isEdit.module === "Maintenance" ? isEdit.inst : null}
        fetch={true}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateBillWraper
        edited={isEdit.module === "Utilities" ? isEdit.inst : null}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateFinishesModal
        success={success}
        error={error}
        edited={isEdit.module === "Finishes" ? isEdit.inst : null}
        fetch={true}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateFlooringModal
        success={success}
        error={error}
        edited={isEdit.module === "Flooring" ? isEdit.inst : null}
        fetch={true}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      <UpdateHVACModal
        success={success}
        error={error}
        edited={isEdit.module === "HVAC" ? isEdit.inst : null}
        fetch={true}
        toggle={resetEdit}
        onSuccess={onSuccess}
      />
      {SpacesViewModalComp}

      {/* <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      /> */}

      <Container fluid>
        <BreadCrumb
          title="Dashboard"
          pageTitle="Property Elements"
          pageTitleComponent={
            <>
              <h4 to="">
                Property Elements{" "}
                {/* <TooltipItem id={1} data={elementtooltip} /> */}
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <div className="d-flex align-items-center">
                  <h5 className="card-title mb-0 flex-grow-1">
                    Property Elements
                  </h5>

                  <div className="flex-shrink-0">
                    <div className="d-flex flex-wrap gap-2">
                      {}
                      <button
                        type="button"
                        disabled={btnloading || success || error}
                        className="btn btn-soft-info"
                        onClick={onCreateReportClick}
                      >
                        {btnloading ? (
                          <Spinner size="sm" className="me-2">
                            Loading...
                          </Spinner>
                        ) : null}
                        <i className="ri-file-list-3-line align-middle"></i>{" "}
                        Export
                      </button>
                    </div>
                  </div>
                </div>
              </CardHeader>

              <CardBody className="pt-0">
              <Row className="g-3 mb-2">
                  <Col sm={8} md={9} lg={8} xxl={8}>{spacesDropdown}</Col>
                  <Col sm={8} md={9} lg={2} xxl={2}>{selectStatusesDropdown}</Col>
                  <Col sm={4} md={3} lg={2} xxl={2}>
                    <button
                      onClick={() => setState(!state)}
                      type="button"
                      className="btn btn-primary w-100"
                    >
                      {" "}
                      <i className="ri-equalizer-fill me-1 align-bottom"></i>
                      Filters
                    </button>
                  </Col>
                </Row>

                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleTab("1", "");
                        }}
                        href="#"
                      >
                        <i className="las la-cubes fs-17 align-middle"></i> All
                        elements
                      </NavLink>
                    </NavItem>
                    {(tabs || [])?.map((src, key) => (
                      <NavItem>
                        <NavLink
                          className={classnames(
                            { active: activeTab === `${key + 2}` },
                            "fw-semibold"
                          )}
                          onClick={() => {
                            toggleTab(`${key + 2}`, src.module);
                          }}
                          href="#"
                        >
                          {src.icon}
                          <span style={{ marginLeft: "10px" }}>
                            {src.module}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={elements || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      baseLink={"/3delement"}
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfelements}
                    />
                  )}
                  {/* {elements && elements.length ? (
                                     ) : (
                    
                  )} */}
                </div>
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PropertyElementsPage;
