import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Modal,
} from "reactstrap";
import * as moment from "moment";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import TableContainer from "../../TableContainer";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import { isEmpty } from "lodash";

import { reloadState as reloadStateImage, saveState as saveStateImage, setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { reloadState as reloadStateReciept, saveState as saveStateReciept, setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

//redux
import { useSelector, useDispatch } from "react-redux";

// import {PropertiesD}
import Loader from "../../../../Components/Common/Loader";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import ExportCSVModal from "../../../../Components/Common/ExportCSVModal";

import SearchOption from "../../../../Components/Common/SearchOption";

// import { SearchWithFilter } from "./search";
import { SearchWithFilter } from "../../../../Components/Common/GlobalSearchFilter";
import {
  DeleteremindersThunk,
  DeleteimageThunk,
  DeleteinventoryThunk,
  ListremindersThunk,
  ListimagesThunk,
  ListinventoryThunk,
} from "../../../../slices/thunks";
import { RetrieveReminderCategoriesBE, RetrieveReminderSpacesBE } from "../../../../helpers/backend_helper";
import { Error } from "../../../../Components/error";
import { resetMessages } from "../../../../slices/property/reminders/reducer";
import { CreateForm } from "./createForm";
// import UpdateFinishesModal from "../New";
// import {
//   remindersActionsTooltip,
//   remindersInstallDateTooltip,
//   remindersInstallerTooltip,
//   remindersNameTooltip,
//   remindersNewListTooltip,
//   remindersPlacementTooltip,
//   remindersSpacesTooltip,
//   remindersTypeTooltip,
//   reminderstooltip,
// } from "../../../../Components/constants/tooltips";
import { TooltipItem } from "../../../../Components/Common/ToolTipItem";
import { SpacesViewModal } from "../../../../Components/Common/spacesDropdown/SpaceViewModal";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";
import { StatusComponent } from "../../../../Components/Common/Status";
import UpdateRemindersModal from "../New";
import { get_date_time_from_utc_str } from "../../../../Components/utils/get_date";
import { useCategoryTabs } from "../../../../Components/Hooks/categoryTabs";
import { useFormFlipper } from "../../../../Components/Hooks/formFlipper";

const RemindersTable = () => {
  document.title = "Reminders | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  
  const mountedRef = useRef()
  const [ SpacesViewModalComp , setSpacesId ] = useSpaceViewer({func: RetrieveReminderSpacesBE})

  // const [ BtnComp , formClassName ] = useFormFlipper();
  
  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState({
    page: 1,
  });

  const {
    selectedProperty,
    reminders,
    numOfPages,
    numOfCaptures,
    pageSize,
    currentPage,
    loading,
    error,
    success,
    refresh
  } = useSelector((state) => ({
    reminders: state.reminders.reminders,
    numOfPages: state.reminders.total_pages,
    numOfCaptures: state.reminders.count,
    pageSize: state.reminders.page_size,
    currentPage: state.reminders.page_num,
    selectedProperty: state.PropertiesDropdown.selected,
    loading: state.reminders.loading,
    error: state.reminders.error,
    success: state.reminders.success,
    refresh: state.reminders.refresh,
  }));


  const [ NavComponent , selectedTab ] = useCategoryTabs(selectedProperty,RetrieveReminderCategoriesBE, refresh)

  useEffect(() => {
    if(mountedRef.current){
      setFilterParams({
        page: 1,
      });
    }
    mountedRef.current = true;
  }, [selectedProperty]);

  
  useEffect(() => {
    if (selectedProperty) {
      dispatch(ListremindersThunk(selectedProperty.id, filterParams));
    }
  }, [filterParams]);

  useEffect(()=>{
    if(selectedTab == "All"){
      setFilterParams((oldParams) => {
        if(oldParams.category){
          delete oldParams.category
        }
        return {...oldParams}
      })
    }else{
      setFilterParams((oldParams) => {
        oldParams['category'] = selectedTab
        return {...oldParams}
      })
    }
  },[selectedTab])

  function onDeleteClick() {
    dispatch(
      DeleteremindersThunk(captureDelete, () => {
        dispatch(ListremindersThunk(selectedProperty.id));
        setCaptureDelete(null);
      })
    );
  }

  function OnUpdateSuccess() {
    if (selectedProperty) {
      dispatch(ListremindersThunk(selectedProperty.id, filterParams));
    }
  }

  useEffect(() => {
    if (error || success) {
      setTimeout(() => dispatch(resetMessages()), 5000);
    }
  }, [error, success]);
  
  useEffect(()=>{
    dispatch(setSelectedImageFiles([]))
    dispatch(setSelectedRecieptFiles([]))
    dispatch(resetMessages())
  },[])

  const columns = useMemo(
    () => [
      // {
      //   Header: (
      //     <input
      //       type="checkbox"
      //       id="checkBoxAll"
      //       className="form-check-input"
      //       onClick={() => checkedAll()}
      //     />
      //   ),
      //   Cell: (cellProps) => {
      //     return (
      //       <input
      //         type="checkbox"
      //         className="orderCheckBox form-check-input"
      //         value={cellProps.row.original._id}
      //         onChange={() => deleteCheckbox()}
      //       />
      //     );
      //   },
      //   id: "#",
      // },
      //
      {
        Header: (
          <>
            <span>
              Reminder{" "}
              {/*  
              <TooltipItem
                id={2}
                placement="bottom"
                data={FinishesNameTooltip}
              />
              */}
            </span>
          </>
        ),
        accessor: "reminder",
        filterable: false,
        Cell: (cell) => {
          return (
            <Link
              to={`#`}
              className="fw-medium link-primary"
            >
              {cell.value}
            </Link>
          );
        },
      },
      {
        Header: (
          <>
            <span>
              Location{" "}
              {/* <TooltipItem
                id={7}
                placement="bottom"
                data={FinishesSpacesTooltip}
              /> */}
            </span>
          </>
        ),
        id: "spaces",
        accessor: "spaces",
        Cell: (capture) => (
          <Link
          to="#"
            onClick={() =>
              setSpacesId(capture.row.original.id)
            }
          >
            See List
          </Link>
        ),
      },
      {
        Header: "Entry Date",
        accessor: "date",
        filterable: false,
        Cell: (capture) => {
          const date = get_date_time_from_utc_str(capture.row.original.date);
          return (<>
            {date.date},
            <small className="text-muted">
              {" "}
              {date.time}
            </small>
          </>)
        },
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: false,
      },
      {
        Header: (
          <>
            <span>
              Actions{" "}
              {/* <TooltipItem
                id={8}
                placement="bottom"
                data={FinishesActionsTooltip}
              /> */}
            </span>
          </>
        ),
        id: "actions",
        Cell: (capture) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    setIsEdit(capture.row.original);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    setCaptureDelete(capture.row.original.id);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  const changePage = (page) => {
    if (page != filterParams.page) {
      setFilterParams((oldParams) => ({ ...oldParams, page: page }));
    }
  };



  useEffect(()=>{
    if(isEdit){
      dispatch(saveStateImage())
      dispatch(saveStateReciept())
    }else{
      dispatch(reloadStateImage())
      dispatch(reloadStateReciept())
    }
  },[isEdit])

  return (
    <div className="page-content">
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateRemindersModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />

      {/* <SpacesViewModal
        allSpaces={SpaceSelectList}
        spaces={showSpaces.spaces}
        show={showSpaces.show}
        toggle={() =>
          setshowSpaces({
            show: false,
            spaces: [],
          })
        }
      /> */}

      {SpacesViewModalComp}

      <Container fluid>
        <BreadCrumb
          title="Reminders"
          pageTitle="Property Workbook"
          pageTitleComponent={
            <>
              <h4 to="">
                Reminders 
                {/* <TooltipItem id={1} data={Finishestooltip} /> */}
              </h4>
            </>
          }
        />
        <Row>
          <Col lg={12}>
            {error && <Error error={error} />}
            {success && <Alert color="success">{success}</Alert>}
            <Card id="orderList">
              <CardHeader className="border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">
                      New/List/View/Edit{" "}
                      {/* <TooltipItem id={9} data={FinishesNewListTooltip} /> */}
                    </h5>
                  </div>
                  {/* {BtnComp} */}
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap"></div>
                  </div>
                  <div>
                    <div className="py-3">
                      <CreateForm />
                    </div>
                  </div>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  {NavComponent}
                  {loading ? (
                    <Loader />
                  ) : (
                    <TableContainer
                      columns={columns}
                      data={reminders || []}
                      divClass="table-responsive table-card mb-1"
                      tableClass="align-middle table-nowrap"
                      theadClass="table-light text-muted text-uppercase"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      onPageChange={changePage}
                      numOfPages={numOfPages}
                      currentPage={currentPage}
                      pageSize={pageSize}
                      total={numOfCaptures}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RemindersTable;
