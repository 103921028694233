import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  success: null,
  btnloading:false,
  propertyID: null,
};

const EditPropertySlice = createSlice({
  name: "EditProperty",
  initialState,
  reducers: { 
    EditProductSuccess(state,action){
        state.btnloading = false
        state.success = "Your property is Edited successfully"
        state.propertyID = action.payload.id
    },
    EditProductFailure(state,action){
        state.btnloading = false
        state.error = action.payload
    },
    setIsLoading(state,action){
        state.btnloading = true
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
    resetState(state,action){
      state.error = null;
      state.success = null;
      state.btnloading = false;
      state.propertyID = null;
    }
  }
});


export const {
  setIsLoading,
  EditProductFailure,
  EditProductSuccess,
  resetMessages,
  resetState
} = EditPropertySlice.actions;

export default EditPropertySlice.reducer;