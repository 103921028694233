import { userForgetPasswordSuccess, userForgetPasswordError ,setMsg , setLoading } from "./reducer"
import { api } from "../../../config";
import { getRstPWD, postRstPWD } from "../../../helpers/backend_helper";
import { loadingActions } from "../../loading/reducer";

export const getUserRstPWD = (uid,token) => async (dispatch) => {
    
    dispatch(loadingActions.setLoading(true));

    await getRstPWD(uid,token)
    .then(()=> dispatch(setMsg("Enter your new password")))
    .catch(()=> dispatch(userForgetPasswordError("Invalid token, Please check the url")))
    .finally(()=>dispatch(loadingActions.setLoading(false)))
    
}


export const resetPWD = (uid,token,data) => async (dispatch) => {
    
    dispatch(setLoading(true))
    
    await postRstPWD(uid,token,data)
    .then(()=> dispatch(userForgetPasswordSuccess("Your password changed successfully, redirecting to login page")))
    .catch(()=> dispatch(userForgetPasswordError("Invalid token, Please check the url")))
    
}