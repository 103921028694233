import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  // UncontrolledCarousel,
  CardHeader,
} from "reactstrap";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import classnames from "classnames";
// import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";
import {
  RetrievemaintenanceSpaces,
  maintenanceaddImage,
  maintenanceaddReciept,
  maintenancedeleteImage,
  maintenancedeleteReciept,
  retrievemaintenance,
} from "../../../../helpers/backend_helper";
import DeleteModal from "../../../../Components/Common/DeleteModal";
import UpdateMaintenanceModal from "../New";
import { useDispatch, useSelector } from "react-redux";
import { DeletemaintenanceThunk } from "../../../../slices/thunks";
import { PdfPreview } from "../../../../Components/Common/pdfPreview";
// import { GallerySlide } from "./GallerySlide";
import { galleryActions } from "../../../../slices/gallery/reducer";
import { maintenanceADDIMAGE } from "../../../../helpers/url_helper";
import { ImagesCarousalWithGallery } from "../../../../Components/Common/detailsPageDisplay/imagesDisplayEdit";
import { PrivateRecieptDisplay, RecieptsDisplay } from "../../../../Components/Common/detailsPageDisplay/recieptDisplay";
import { useSpaceViewer } from "../../../../Components/Hooks/spacesViewHook";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";

const MaintenanceDetails = () => {
  document.title = "Maintenance Details | DwellDoc";

  const [captureDelete, setCaptureDelete] = useState(null);
  const [isEdit, setIsEdit] = useState(null);

  let { id } = useParams();
  const [maintenance, setmaintenance] = useState({});

  // const isPdf = maintenance.reciept
  //   ? maintenance.reciept.split(".").pop().toUpperCase() === "PDF"
  //   : false;

  const navigate = useNavigate();

  const [RecieptModal , RecieptViewerComponent , getRecieptVal] = useRecieptSelect(true,"Reciept(s)")
  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(true,"Image(s)")

  const { selectedProperty, loading, error, success } = useSelector(
    (state) => ({
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.maintenance.loading,
      error: state.maintenance.error,
      success: state.maintenance.success,
    })
  );

  useEffect(() => {
    if (id) {
      retrievemaintenance(id).then((res) => setmaintenance(res));
    }
  }, [id]);

  useEffect(() => {
    if (
      maintenance.propertyy &&
      selectedProperty.id !== maintenance.propertyy
    ) {
      navigate("/maintenance");
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  function onDeleteClick() {
    dispatch(
      DeletemaintenanceThunk(captureDelete, () => {
        navigate("/maintenance");
      })
    );
  }

  function OnUpdateSuccess() {
    if (id) {
      retrievemaintenance(id).then((res) => setmaintenance(res));
    }
  }

  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [isTop, setIsTop] = useState(false);
  const toggleTopCanvas = () => {
    console.log(isTop);
    setIsTop(!isTop);
  };

  const [SpacesViewModalComp, setSpacesId] = useSpaceViewer({
    func: RetrievemaintenanceSpaces,
  });


  function deleteImage(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    maintenancedeleteImage(pk)
      .then(() => {
        retrievemaintenance(id).then((res) => setmaintenance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Deleted Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function handleAddToGallery(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("image", file);
    maintenanceaddImage(id, form)
      .then(() => {
        retrievemaintenance(id).then((res) => setmaintenance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage("Image Added Successfully")
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function deleteReciept(pk) {
    dispatch(galleryActions.setGalleryLoading(true));
    maintenancedeleteReciept(pk)
      .then(() => {
        retrievemaintenance(id).then((res) => setmaintenance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Deleted Successfully"
          )
        );
        callback();
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }

  function handleAddToGalleryReciept(file, callback) {
    dispatch(galleryActions.setGalleryLoading(true));
    var form = new FormData();
    form.append("reciept", file);
    maintenanceaddReciept(id, form)
      .then(() => {
        retrievemaintenance(id).then((res) => setmaintenance(res));
        dispatch(
          galleryActions.setGallerySuccessMessage(
            "Reciept is Added Successfully"
          )
        );
      })
      .catch((err) => dispatch(galleryActions.setGalleryErrorMessage(err)))
      .finally(callback);
  }


  useEffect(()=>{
    if(maintenance.images){
      dispatch(setSelectedImageFiles(maintenance.images))
    }
    if(maintenance.reciepts){
      dispatch(setSelectedRecieptFiles(maintenance.reciepts))
    }
  },[maintenance])

  useEffect(()=>{
    if(!isEdit){
      if(maintenance.images){
        dispatch(setSelectedImageFiles(maintenance.images))
      }
      if(maintenance.reciepts){
        dispatch(setSelectedRecieptFiles(maintenance.reciepts))
      }
    }
  },[isEdit])

  return (
    <React.Fragment>
      <DeleteModal
        show={captureDelete}
        onDeleteClick={onDeleteClick}
        onCloseClick={() => setCaptureDelete(null)}
        isLoading={loading}
      />

      <UpdateMaintenanceModal
        edited={isEdit}
        toggle={() => setIsEdit(null)}
        onSuccess={OnUpdateSuccess}
        error={error}
        success={success}
      />
      {SpacesViewModalComp}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Home maintenance" pageTitle="Property Workbook" />
          <Card>
            <CardBody>
              <Row className="g-4">
                <Col lg={4}>
                  <div className="sticky-side-div">
                    {/* <Card className="ribbon-box border shadow-none right">
                      <CardHeader>Image(s)</CardHeader> */}
                      {/* <ImagesCarousalWithGallery
                        images={(maintenance.images || []).map((img) => ({
                          ...img,
                          image: img.image,
                        }))}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                      /> */}
                      {/* <ImagesCarousalWithGallery
                        images={(maintenance.images || [])}
                        deleteImage={deleteImage}
                        handleAddToGallery={handleAddToGallery}
                        module="maintenancePhoto"
                        privateFile={true}
                      />
                    </Card>
                    <Card className="ribbon-box border shadow-none right"> */}
                      {/* <RecieptsDisplay
                        reciepts={maintenance.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                      /> */}
                      {/* <PrivateRecieptDisplay 
                        reciepts={maintenance.reciepts || []}
                        deleteReciept={deleteReciept}
                        addReciept={handleAddToGalleryReciept}
                        privateFile={true}
                        module="maintenanceReciept" />
                    </Card> */}
                    {ImageViewerComponent}
                    {RecieptViewerComponent}
                  </div>
                </Col>
                <Col lg={8}>
                  <div>
                    <UncontrolledDropdown className="float-end">
                      <DropdownToggle
                        tag="a"
                        className="btn btn-ghost-primary btn-icon"
                        role="button"
                      >
                        <i className="ri-more-fill align-middle fs-16"></i>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        {/* <DropdownItem href="#" className="view-item-btn">
                          <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                          View
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() => setIsEdit(maintenance)}
                          className="edit-item-btn"
                        >
                          <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => setCaptureDelete(id)}
                          className="remove-item-btn"
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>

                    <h4>
                      {maintenance.name} - {maintenance.taskType} -{" "}
                      {maintenance.taskSubType}
                    </h4>
                    <div className="hstack gap-3 flex-wrap">
                      <div className="text-muted">
                        Last Completed :{" "}
                        <span className="text-primary fw-medium">
                          {maintenance.lastCompleted}
                        </span>
                      </div>
                      <div className="vr"></div>
                      <div className="text-muted">
                        Next Due Date :{" "}
                        <span className="text-primary fw-medium">
                          {maintenance.nextDueDate}
                        </span>
                      </div>
                    </div>
                    <Row className="mt-4">
                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Frequency :
                            </p>
                            <h5 className="fs-17 mb-0">
                              {maintenance.frequency}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">Budget</p>
                            <h5 className="fs-17 mb-0">{maintenance.budget}</h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Priority
                            </p>
                            <h5 className="fs-17 mb-0">
                              {maintenance.priority}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col lg={3} sm={6}>
                        <div className="p-2 border border-dashed rounded text-center">
                          <div>
                            <p className="text-muted fw-medium mb-1">
                              Location
                            </p>
                            <h5 className="fs-17 mb-0">
                              {/* {(maintenance.spaces || [])
                                .map((space) => space.name)
                                .join(", ")} */}

                              <Link to="#" onClick={() => setSpacesId(maintenance.id)}>
                                See List
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Details/Other</h5>
                      <p>{maintenance.details}</p>
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Warranty Details :</h5>
                      <p>{maintenance.warrantyDetails}</p>
                    </div>
                    {maintenance.notes ? (
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Notes :</h5>
                        <p>{maintenance.notes}</p>
                      </div>
                    ) : null}

                    <div className="product-content mt-5">
                      <h5 className="fs-14 mb-3">Service Provider :</h5>

                      <Nav tabs className="nav-tabs-custom nav-success">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: customActiveTab === "1",
                            })}
                            onClick={() => {
                              toggleCustom("1");
                            }}
                          >
                            Installation Information
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        activeTab={customActiveTab}
                        className="border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <TabPane id="nav-speci" tabId="1">
                          <div>
                            <p className="mb-2">
                              <i
                                style={{ color: "#299CDB", fontSize: "30px" }}
                                className="mdi mdi-circle-medium me-1 align-middle"
                              ></i>{" "}
                              {maintenance.installationDate}
                            </p>
                            <h5 className="font-size-16 mb-3">
                              Installation Contractor
                            </h5>
                            {maintenance.installationContractor && (
                              <p>{maintenance.installationContractor.name}</p>
                            )}
                            <div></div>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MaintenanceDetails;
