import { createSlice } from "@reduxjs/toolkit";
import {
  resetAuthorization,
  setAuthorization,
} from "../../../helpers/api_helper";

export const initialState = {
  user: null,
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  info: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = true;
      state.info = false;
    },
    resetLogout(state, action) {
      state.isUserLogout = false;
    },
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action) {
      const keepMeLogin = action.payload.keepMeLogin;
      delete action.payload.keepMeLogin;
      state.user = action.payload;
      if (keepMeLogin) {
        localStorage.setItem("userToken", state.user.token);
      } else {
        sessionStorage.setItem("userToken", state.user.token);
      }
      state.loading = false;
      state.errorMsg = false;
      setAuthorization(state.user.token);
    },
    getInfoSuccess(state, action) {
      const keepMeLogin = action.payload.keepMeLogin;
      delete action.payload.keepMeLogin;
      state.user = action.payload;
      if (keepMeLogin) {
        localStorage.setItem("userToken", state.user.token);
      } else {
        sessionStorage.setItem("userToken", state.user.token);
      }
      state.loading = false;
      state.errorMsg = false;
      setAuthorization(state.user.token);
      state.info = true;
    },
    logoutUserSuccess(state, action) {
      state.user = null;
      state.error = "";
      state.loading = false;
      state.isUserLogout = true;
      state.errorMsg = false;
      localStorage.removeItem("userToken");
      sessionStorage.removeItem("userToken");
      resetAuthorization();
      state.info = false;
    },
    reset_login_flag(state) {
      state.error = null;
      state.loading = false;
      state.errorMsg = false;
    },
    set_first_login(state, action) {
      // console.log("called")
      state.user = { ...state.user, first_login: false };
    },
    hideFeedback(state, action) {
      state.user = { ...state.user, show_feedback: false };
    },
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  setLoading,
  resetLogout,
  getInfoSuccess,
  set_first_login,
  hideFeedback,
} = loginSlice.actions;

export default loginSlice.reducer;
