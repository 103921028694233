import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  error: null,
  success: null,
  properties:null,
  count: null,
  page_size:null,
  total_pages: null,
  loading:false,
};

const createPropertySlice = createSlice({
  name: "listProperties",
  initialState,
  reducers: { 
    setProperties(state,action){
      state.properties = action.payload.results
      state.count = action.payload.count
      state.total_pages = action.payload.total_pages
      state.page_size = action.payload.page_size
      state.page_num = action.payload.page_num
      state.loading = false
    },
    setIsLoading(state,action){
        state.loading = true
    },
    resetMessages(state,action){
      state.error = null;
      state.success = null;
    },
  }
});


export const {
  setIsLoading,
  setProperties,
  resetMessages
} = createPropertySlice.actions;

export default createPropertySlice.reducer;