import React, { useState } from "react";
import {
  Col,
  Row,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

function formatDate(date) {
  return date.toISOString().split("T")[0];
}

export const useSupportFilters = () => {
  const [state, setState] = useState(false);

  const [filterParams, setFilterParams] = useState({
    date: {
      range: [],
      str: "",
    },
    Id: "",
    status: "",
  });

  function resetParams() {
    setFilterParams({
      date: {
        range: [],
        str: "",
      },
      Id: "",
      status: "",
    });
  }

  function getFilterParams() {
    return {
      dateStart:
        filterParams.date.range.length === 2
          ? formatDate(filterParams.date.range[0])
          : "",
      dateEnd:
        filterParams.date.range.length === 2
          ? formatDate(filterParams.date.range[1])
          : "",
      Id: filterParams.Id,
      status: filterParams.status,
    };
  }

  return [
    <Row className="g-3">
      <Col>
        <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
          <input
            onChange={(e) => {
              setFilterParams((oldparams) => ({
                ...oldparams,
                Id: e.target.value,
              }));
            }}
            id="search-bar-0"
            type="text"
            className="form-control search /"
            placeholder={`Find Ticket With Certain ID`}
            // value={value || ""}
          />
          <i className="bx bx-search-alt search-icon"></i>
        </div>
      </Col>
      <React.Fragment>
        <Col xxl={3} sm={4}>
          <Flatpickr
            className="form-control"
            placeholder="Select date range"
            options={{
              mode: "range",
              dateFormat: "d M, Y",
            }}
            value={filterParams.date.str}
            onChange={(date, dateStr) => {
              if (date.length === 2) {
                setFilterParams((oldParams) => ({
                  ...oldParams,
                  date: {
                    range: date,
                    str: dateStr,
                  },
                }));
              } else {
                setFilterParams((oldParams) => ({
                  ...oldParams,
                  date: {
                    range: [],
                    str: "",
                  },
                }));
              }
            }}
          />
        </Col>
        <Col xxl={3} sm={4}>
          <div className="input-light">
            <select
              className="form-control"
              data-choices
              data-choices-search-false
              name="choices-single-default"
              id="idStatus"
              value={filterParams.status}
              onChange={(e) =>
                setFilterParams((oldparams) => ({
                  ...oldparams,
                  status: e.target.value,
                }))
              }
            >
              <option value="" disabled>
                Status
              </option>
              <option value="">All</option>
              <option value="Opened">Opened</option>
              <option value="Pending">Pending</option>
              <option value="Closed">Closed</option>
            </select>
          </div>
        </Col>
        <Col xxl={1} sm={4}>
          <button
            onClick={() => setState(!state)}
            type="button"
            className="btn btn-primary w-100"
          >
            {" "}
            <i className="ri-equalizer-fill me-1 align-bottom"></i>
            Filters
          </button>
        </Col>
      </React.Fragment>
    </Row>,
    filterParams,
    resetParams,
    getFilterParams,
    state,
  ];
};
