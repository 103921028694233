import { useEffect, useState } from "react";
import { SpacesViewModal } from "../Common/spacesDropdown/SpaceViewModal";
import { getSpaceSelectList } from "../../helpers/backend_helper";
import { useSelector } from "react-redux";



export const useSpaceViewer = ({
    func
}) => {

    const {
        selectedProperty,
      } = useSelector((state) => ({
        selectedProperty: state.PropertiesDropdown.selected,        
      }));

    const [spaces,setSpaces] = useState([])

    const [showSpaces, setshowSpaces] = useState(-1);

    const [showParams, setshowParams] = useState({
        id:-1,
        params:{}
    });


    const [SpaceSelectList, setSpaceSelectList] = useState([]);

    useEffect(() => {
        if (selectedProperty) {
          getSpaceSelectList(selectedProperty.id).then((res) =>
            setSpaceSelectList(res)
          );
        }
    }, [selectedProperty]);

    useEffect(()=>{
        if(showSpaces >= 0 ) {
            func(showSpaces).then((res) =>setSpaces(res.spaces))
            .catch((err) => console.log("error"))
        }

    },[showSpaces])
    
    useEffect(()=>{
        if(showParams.id >= 0){
            func(showParams.id,showParams.params).then((res) =>setSpaces(res.spaces))
            .catch((err) => console.log("error"))
        }
    },[showParams])

    return [
        <SpacesViewModal
            allSpaces={SpaceSelectList}
            spaces={spaces}
            show={showSpaces >= 0 || showParams.id >=0}
            toggle={() => {
                setshowSpaces(-1)
                setshowParams({
                    id:-1,
                    params:-1
                })
            }
        }
      />,
      setshowSpaces,
      setshowParams
    ]
}