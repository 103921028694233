import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

// import { useProfile } from "../Components/Hooks/UserHooks";

// import { logoutUser } from "../slices/auth/login/thunk";

import { resetLogout } from "../slices/auth/login/reducer";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { remove_all_file_objects } from "../Components/utils/get_file";
import { useFeedbackForm } from "../Components/Hooks/feedbackHook";

const AuthProtected = (props) => {
  const user = useSelector((state) => state.Login.user);

  const islogout = useSelector((state) => state.Login.isUserLogout);

  const feedbackComponent = useFeedbackForm();

  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    return () => remove_all_file_objects();
  },[]);

  if (!user) {
    if (islogout) {
      return <Navigate to={{ pathname: "/logout" }} />;
    } else {
      return (
        <Navigate
          to={{
            pathname: "/signIn",
            search: "redirectUri=" + location.pathname + location.search,
          }}
        />
      );
    }
  } else if (user.first_login && location.pathname !== "/create-property") {
    return <Navigate to={{ pathname: "/create-property" }} />;
  }

  // setAuthorization(user.token)

  return (
    <>
      {props.children} {feedbackComponent}
    </>
  );
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            {" "}
            <Component {...props} />{" "}
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
