import React from "react";

export const Status = ({value}) => {
    return (
        <React.Fragment>
            {value === "Pending" ? <span className="badge badge-soft-warning text-uppercase">{value}</span>
                : value === "Opened" ? <span className="badge badge-soft-info text-uppercase">{value}</span>
                    : value === "Closed" ? <span className="badge badge-soft-success text-uppercase">{value}</span>
                        // : value === "" ? <span className="badge badge-soft-danger text-uppercase">{value}</span>
                            : null
            }
        </React.Fragment>
    );
};