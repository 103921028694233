import { MODULES_FILE_HANDLERS } from "../constants/modules";


var global_urls = {}
const CACHE_NAME = 's3PrivateFiles';

export function remove_all_file_objects () {
    const keys = Object.keys(global_urls)
    for ( let key of keys){
        URL.revokeObjectURL(global_urls[key])
        delete global_urls[key]
    }
    // console.log("all revoked", Object.keys(global_urls))
}

function fetchWithBrowserCache( id , module , alternativeUrl){
    const key = `/${module},${id}`
    if (global_urls[key]){
        // console.log("url already exists")
        return global_urls[key]
    }
    return window.caches.open(CACHE_NAME).then((cache) => {
        return cache.match(key).then(async (response) => {
            if(response){
                // console.log(`${alternativeUrl} found in local cache`)
                const blob = await response.blob()
                const url = URL.createObjectURL(blob);
                global_urls[key] = url
                return url
            }
            // console.log(`No response for ${alternativeUrl} found in cache`);

            return fetch(alternativeUrl).then(async (response) => {
                // console.log(`Saving ${alternativeUrl} in cache`);
                cache.put(key, response.clone());
                const blob = await response.blob()
                const url = URL.createObjectURL(blob);
                global_urls[key] = url
                return url
            })
        })
    })
}

export function deletePrivateFileCache() {
    window.caches.delete(CACHE_NAME)
}

export async function get_file_url(id,module,lazyLoad=false) {
    const res = await MODULES_FILE_HANDLERS[module](id);
    if(!lazyLoad){
        res['file'] = await fetchWithBrowserCache(id,module,res.file)
    }
    return res
}

export async function get_files_url(ids,module) {
    return await Promise.all(ids.map((id) => get_file_url(id,module)))
}