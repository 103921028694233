import React, { useEffect, useState } from "react";
import { Link, redirect } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Spinner,
  Label,
  Alert,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
} from "reactstrap";

import classnames from "classnames";

import BreadCrumb from "../../../../Components/Common/BreadCrumb";

import { Error } from "../../../../Components/error";

import { useSelector, useDispatch } from "react-redux";
import {
  updateImage,
  getSpaceSelectList,
  getInventoryTypes,
  updateInventory,
  updateutility,
  getutilityTypes,
  retrieveUtility,
} from "../../../../helpers/backend_helper";
// import { setSelected } from "../../../../slices/property/dropdown/reducer";
import { setMsg } from "../../../../slices/auth/passCreate/reducer";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { camelize } from "../../../../helpers/api_helper";
// import { SpacesDropdown } from "../upload/spacesDropdown";
import { SpacesDropdown } from "../../../../Components/Common/spacesDropdown";
import Flatpickr from "react-flatpickr";
import { get_spaces } from "../../../../Components/utils/spacesCompare";
import { FileLinkViewer, PrivateFileWraper } from "../../../../Components/Common/privateImageViewer/privateImageViewer";

import { useRecieptSelect } from "../../../../Components/Hooks/recieptSelectHook";
import { useImageSelect } from "../../../../Components/Hooks/imageSelectHook";
import { setSelectedFiles as setSelectedImageFiles, setSelectedSaveOld } from "../../../../slices/property/propertyFilesImages/reducer";
import { setSelectedFiles as setSelectedRecieptFiles } from "../../../../slices/property/propertyFilesReciepts/reducer";
import { getCreateAndDeleteFiles } from "../../../../Components/utils/imagesCompare";

function getAdditionalValues(
  edited,
  type,
  types,
  setVarList,
  setAdditionalValues
) {
  if (type && types) {
    const vars = types[type];
    setVarList(
      vars.map((variable) => ({
        key: camelize(variable),
        placeholder: variable,
      }))
    );
    let objj = {};
    for (let variable of vars) {
      const varr = camelize(variable);
      objj[varr] = edited ? edited[varr] : "";
    }
    setAdditionalValues(objj);
  }
}




const UpdateBillModal = ({ edited, types, toggle, onSuccess = () => {} }) => {
  const id = edited ? edited.id : null;
  // const fileName =
  //   edited && edited.file ? "/" + edited.file.split("/").splice(-1) : null;

  const [msg, setmsg] = useState({ err: false, msg: null });
  const [loading, setLoading] = useState(false);

  const type = edited ? edited.type : "";

  const [ImageModal , ImageViewerComponent , getImageVal] = useImageSelect(false,"Inventory File",false)

  const [SpaceSelectList, setSpaceSelectList] = useState([]);

  const [selectedSpaces, setSelectedSpaces] = useState([]);

  const { selectedProperty } = useSelector((state) => ({
    selectedProperty: state.PropertiesDropdown.selected,
  }));

  const [selectedFile, setFile] = useState(null);
  const [name, setName] = useState(null);
  const [date, setDate] = useState("");
  const [varList, setVarList] = useState([]);
  const [additionalValues, setAdditionalValues] = useState({});
  const [notes, setnotes] = useState("");

  useEffect(() => {
    if (selectedProperty) {
      getSpaceSelectList(selectedProperty.id).then((res) =>
        setSpaceSelectList(
          res
        )
      );
    }
  }, [selectedProperty]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (edited) {
      setName(edited.name);
      setnotes(edited.notes || "");
      setSelectedSpaces(
        edited.spaces.map((space) => ({
          value: parseInt(space.id),
          label: space.name,
        }))
      );
      setDate(edited.date.dateObj);
      getAdditionalValues(
        edited,
        edited.type,
        types,
        setVarList,
        setAdditionalValues
      );
      if(edited.file){
        dispatch(setSelectedSaveOld([edited.file]))
      }else{
        dispatch(setSelectedSaveOld([]))
      }
    }
    setmsg({ err: false, msg: null });
    setFile(null);
  }, [edited]);

  function checkOrError(val, errs, err) {
    if (!val) {
      errs.push(err);
    }
  }

  function getFormdata() {
    var formdata = new FormData();
    formdata.append("name", name);
    
    const image = getImageVal()
    if (image.length > 0 ){
      if(!edited.image || image[0] !== edited.image.id){
        formdata.append("file", image[0]);
      }
    }else{
      formdata.append("file", "");
    }
    
    // if (selectedFile) {
    //   formdata.append("file", selectedFile);
    // }
    
    const spaces = get_spaces(edited.spaces,selectedSpaces)
    if(spaces){
      formdata.append(
        "spaces",
        spaces.join(",")
      );
    }

    formdata.append("typee", type);
    formdata.append("date", date);
    formdata.append("notes", notes);
    const keys = Object.keys(additionalValues);
    for (let key of keys) {
      formdata.append(key, additionalValues[key]);
    }

    return formdata;
  }

  function submit() {
    var errs = [];

    checkOrError(name, errs, "you need to add a name for your Bill ");
    checkOrError(type, errs, "you need to select a type for your bill");
    checkOrError(date, errs, "you need to select a date for your bill");
    // checkOrError(
    //   selectedSpaces,
    //   errs,
    //   "you need to select spaces which related to your bill"
    // );

    const keys = Object.keys(additionalValues);
    for (let key of keys) {
      checkOrError(
        additionalValues[key],
        errs,
        `${key} field is mandatory for bills of type ${type}`
      );
    }

    if (errs.length > 0) {
      setmsg({ err: true, msg: errs });
    } else {
      const data = getFormdata();
      setLoading(true);
      if (id) {
        updateutility(id, data, type)
          .then((res) => {
            setmsg({ err: false, msg: "Updated Successfully" });
            onSuccess(type);
          })
          .catch((err) => setmsg({ err: true, msg: err }))
          .finally(() => setLoading(false));
      }
    }
  }

  return (
    <Modal
      id="UpdateBillModal"
      tabIndex="-1"
      isOpen={edited}
      toggle={toggle}
      centered
    >
      <ModalHeader className="p-3" toggle={toggle}>
        Bill Edit
      </ModalHeader>

      {!msg.err && msg.msg ? <Alert color="success">{msg.msg}</Alert> : null}

      {msg.err && msg.msg ? <Error error={msg.msg} /> : null}

      <ModalBody>
        <Form >
        {ImageModal}
          <Row>
            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  aria-label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
        
            <div className="form-group my-2">
            {ImageViewerComponent}
            
            </div>

            <div className="form-group mb-2">
              <div className="input-group mb-2" style={{ padding: 0 }}>
                <Flatpickr
                  value={date}
                  className="form-control"
                  onChange={(date, dateStr) => {
                    setDate(dateStr);
                  }}
                  placeholder="Bill Date"
                  options={{disableMobile:true}}
                  // style={{ backgroundColor: "#ced4da" }}
                />
              </div>
            </div>

            <div className="form-group my-2">
              <div className="input-group my-2" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Type: " + type}
                  aria-label={"Type: " + type}
                  disabled
                />
              </div>
            </div>

            {varList?.map((variable) => (
              <div className="form-group my-2">
                <div className="input-group" style={{ padding: 0 }}>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={variable.placeholder}
                    aria-label={variable.placeholder}
                    value={additionalValues[variable.key]}
                    onChange={(e) =>
                      setAdditionalValues((oldVal) => {
                        var copy = { ...oldVal };
                        copy[variable.key] = e.target.value;
                        return copy;
                      })
                    }
                  />
                </div>
              </div>
            ))}

            <div className="my-2">
              <SpacesDropdown
                stateChange={setSelectedSpaces}
                allOptions={SpaceSelectList}
                value={selectedSpaces}
              />
            </div>

            <div className="form-group my-2">
              <div className="input-group" style={{ padding: 0 }}>
                <input
                  type="text"
                  className="form-control"
                  id="notes"
                  name="notes"
                  placeholder="Notes/Comments"
                  value={notes}
                  onChange={(e) => setnotes(e.target.value)}
                />
              </div>
            </div>

            <div className="d-flex align-items-start gap-3">
              <button
                type="button"
                className="btn btn-primary add-btn my-2"
                data-bs-toggle="modal"
                data-bs-target="#showModal"
                id="create-btn"
                style={{ width: "100%", backgroundColor: "#0AB39C" }}
                onClick={submit}
                disabled={loading}
              >
                {loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Edit Bill
              </button>
            </div>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export function UpdateBillWraper({ edited, toggle, onSuccess = () => {}})  {
  // useEffect(()=> )
  const [inst , setInst] = useState(null);
  const [types , setTypes] = useState([])
  useEffect(()=>{
    if(edited){
      retrieveUtility(edited.id).then((res)=> setInst(res))
    }else{
      setInst(null)
    }
  },[edited])

  useEffect(()=>{
    getutilityTypes().then((res) => {
      setTypes(res);})
  },[])

  return (
    <UpdateBillModal
     edited={edited ? inst: null}
     types={types}
     toggle={toggle}
     onSuccess={onSuccess}
    />    
  )
}

export default UpdateBillModal;
