
import { unSelectFile } from "../../../slices/property/propertyFilesImages/reducer";
import React from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  ListfilesThunk,
} from "../../../slices/property/propertyFilesImages/thunk";
import { selectAll, toggleFile, unSelectAll } from "../../../slices/property/propertyFilesImages/reducer";
import { setRefreshDropdown } from "../../../slices/property/propertyFiles/reducer";
import { SelectFilesModal, SelectFilesWithoutModal } from "./SelectFilesModal";



export const ImageInputModal = ({show,toggle,multiple=true}) => {
    const data = useSelector((state) => ({
        images: state.propertyFilesImages.files,
        numOfPages: state.propertyFilesImages.total_pages,
        numOfCaptures: state.propertyFilesImages.count,
        pageSize: state.propertyFilesImages.page_size,
        currentPage: state.propertyFilesImages.page_num,
        selectedProperty: state.PropertiesDropdown.selected,
        loading: state.propertyFilesImages.loading,
        error: state.propertyFilesImages.error,
        success: state.propertyFilesImages.success,
        refreshDrop: state.files.refreshDropdown,
        allChecked: state.propertyFilesImages.allChecked,
        selectedFiles: state.propertyFilesImages.selectedFiles,
      }));

      const functions = {
        unSelectFile,
        selectAll,
        toggleFile,
        unSelectAll,
        ListfilesThunk,
        setRefreshDropdown
      }
    return <SelectFilesModal multiple={multiple} {...functions} {...data} show={show} toggle={toggle}/>

}


export const ImageInputSelector = ({multiple=true}) => {
  
  const data = useSelector((state) => ({
      images: state.propertyFilesImages.files,
      numOfPages: state.propertyFilesImages.total_pages,
      numOfCaptures: state.propertyFilesImages.count,
      pageSize: state.propertyFilesImages.page_size,
      currentPage: state.propertyFilesImages.page_num,
      selectedProperty: state.PropertiesDropdown.selected,
      loading: state.propertyFilesImages.loading,
      error: state.propertyFilesImages.error,
      success: state.propertyFilesImages.success,
      refreshDrop: state.files.refreshDropdown,
      allChecked: state.propertyFilesImages.allChecked,
      selectedFiles: state.propertyFilesImages.selectedFiles,
    }));

    const functions = {
      unSelectFile,
      selectAll,
      toggleFile,
      unSelectAll,
      ListfilesThunk,
      setRefreshDropdown
    }
  return <SelectFilesWithoutModal multiple={multiple} {...functions} {...data} />

}